/**
 * ACTION-PLAN-TOPIC
 * Get all details about one topic for action plan
 */
import Modal from "@/components/modal";
import { store } from "@/index";
// import store from "@/core/store";
import { useEffect } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Axis } from "@/redux/axis.types";
import { topicEdit } from "@/redux/topic.actions";
import { TopicState } from "@/redux/topic.types";
import DashboardTopicWidget from "@/widgets/dashboard-topic.widget";

interface StateProps extends WithTranslation {
  topic: TopicState;
}

interface OwnProps {
  onClose: Function;
}

type Props = StateProps & OwnProps;

function TasksTopicModal(props: Props) {
  const { t } = props;

  //Open topic at first
  useEffect(() => {
    store.dispatch(topicEdit("open", true));
  }, []);

  return (
    <Modal
      onClose={props.onClose}
      isCloseButtonVisible
      isLarge
      title={t("dashboard_results")}
    >
      <div
        style={{
          margin: "0px -30px",
          width: 780,
          overflowX: "hidden",
          paddingRight: 40,
        }}
      >
        <DashboardTopicWidget
          currentTopic={props.topic.active}
          currentAxis={
            props.topic.active.Axis
              ? new Axis(props.topic.active.Axis)
              : new Axis()
          }
          isEditable
        />
      </div>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  topic: state.topic,
});

export default connect(mapStateToProps)(withTranslation()(TasksTopicModal));
