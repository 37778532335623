/**
 * DELETE-SURVEY.MODAL
 * if launch or closed trigger security
 */
import Modal from "@/components/modal";
import { connect } from "react-redux";

import { withTranslation, WithTranslation } from "react-i18next";
import { store } from "@/index";
// import store from "@/core/store";
import {
  STATUS_SAVED,
  STATUS_SAVE_ERROR,
  STATUS_SAVING,
} from "@/redux/_status.types";
import { SurveyState } from "@/redux/survey.types";
import {
  surveyDestroy,
  surveyRemove,
  surveyStatus,
} from "@/redux/survey.actions";
import EnterCodeWidget from "@/widgets/enter-code.widget";
import { NavigateFunction, useNavigate } from "react-router-dom";

interface StateProps extends WithTranslation {
  survey: SurveyState;
}

interface OwnProps {
  onClose: Function;
}

type Props = StateProps & OwnProps;

function DeleteSurveyModal(props: Props) {
  const { t } = props;
  const navigate: NavigateFunction = useNavigate();

  function close() {
    props.onClose();
  }

  //Trigger the action with the backend
  async function deleteSurveyConfirm() {
    store.dispatch(surveyStatus(STATUS_SAVING));
    const response: any = await store.dispatch(
      surveyDestroy(props.survey.active.id)
    );
    store.dispatch(
      surveyStatus(response.error ? STATUS_SAVE_ERROR : STATUS_SAVED)
    );

    if (!response.error) {
      store.dispatch(surveyRemove(props.survey.active.id));
      navigate("/dashboard/home");
      close();
    }
  }

  return (
    <Modal onClose={close} title={t("survey_delete")}>
      <p>
        <b>
          {t("survey_ask_delete", {
            survey: props.survey.active.name,
          })}
        </b>
      </p>

      <EnterCodeWidget
        onCancel={close}
        onNext={deleteSurveyConfirm}
        status={props.survey.status}
      />
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  survey: state.survey,
});

export default connect(mapStateToProps)(withTranslation()(DeleteSurveyModal));
