/**
 * REGISTER.MODAL
 * Create a password
 */

import { withTranslation, WithTranslation } from "react-i18next";
import LoadingModal from "./loading.modal";
import { useEffect, useState } from "react";
import { store } from "@/index";
// import store from "@/core/store";
import { sessionEdit } from "@/redux/_session.actions";
import { firebaseAppAuth } from "@/App";
import Modal from "@/components/modal";
import Space from "@/components/space";
import { userUpdatePasswordProvided } from "@/redux/user.actions";
import { createUserWithEmailAndPassword } from "firebase/auth";

interface OwnProps {
  email: string; //Email
  password: string; //Password
  onClose: Function; //Trigger fonction on close
}

type Props = OwnProps & WithTranslation;

function RegisterModal(props: Props) {
  const { t } = props;

  //Error message to display
  const [errorMessage, setErrorMessage] = useState<null | string>(null);

  useEffect(() => {
    //Update password status and close
    function updatePasswordAndLogin() {
      store.dispatch(userUpdatePasswordProvided(props.email));
      store.dispatch(sessionEdit("userPasswordProvided", new Date()));
      props.onClose();
    }

    //Register function
    async function register() {
      createUserWithEmailAndPassword(
        firebaseAppAuth,
        props.email,
        props.password
      )
        .then(() => {
          updatePasswordAndLogin();
        })
        .catch((error) => {
          if (error.code === "auth/email-already-in-use") {
            updatePasswordAndLogin();
          } else {
            setErrorMessage(t("firebase_" + error.code));
          }
        });
    }

    register();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {!errorMessage ? (
        <LoadingModal />
      ) : (
        <Modal isCloseButtonVisible onClose={props.onClose}>
          <div className="signup-form" style={{ textAlign: "center" }}>
            <p>{t("utils_network_error")}</p>

            <p>
              <b>{t(errorMessage)}</b>
            </p>

            <div className="height-20" />

            <div className="flex">
              <Space />
              <img
                alt="error"
                width="140"
                src={require("@/assets/sherlock.png")}
              ></img>
              <Space />
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default withTranslation()(RegisterModal);
