/**
 * ADM-EDIT-USER.MODAL
 * Add or edit a new user
 */

import Modal from "@/components/modal";
import { connect } from "react-redux";
import { withTranslation, WithTranslation } from "react-i18next";
import { User, UserState } from "@/redux/user.types";
import { useEffect, useState } from "react";
import { store } from "@/index";
// import store from "@/core/store";
import {
  userDestroy,
  userGet,
  userInsert,
  userRemove,
  userStatus,
  userUpdate,
} from "@/redux/user.actions";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";
import {
  STATUS_LOADED,
  STATUS_LOADING,
  STATUS_SAVED,
  STATUS_SAVING,
} from "@/redux/_status.types";
import ModalConfirm from "@/components/modal-confirm";
import PageLoader from "@/components/page-loader";
import { filterFetch, filterGet } from "@/redux/filter.actions";
import UserFilterInfoWidget from "@/widgets/user-filter-info.widget";
import UserGeneralInfoWidget from "@/widgets/user-general-info.widget";
import UserOptionalInfoWidget from "@/widgets/user-optional-info.widget";
import { Session } from "@/redux/_session.types";

interface StateProps extends WithTranslation {
  _session: Session;
  user: UserState;
}

interface OwnProps {
  userToUpdate?: User; //Detect if user to edit exist already (if not defined new user will be add)
  onClose: Function; //On close trigger
  onNext?: Function; //On next trigger
}

type Props = StateProps & OwnProps;

const MODAL_ASK_DELETE = "MODAL_ASK_DELETE";
const MODAL_ASK_DELETE_ADMIN = "MODAL_ASK_DELETE_ADMIN";
const MODAL_ASK_SAVE = "MODAL_ASK_SAVE";

function AdmEditUserModal(props: Props) {
  const { t } = props;

  //Current modal
  const [currentModal, setCurrentModal] = useState<string | null>(null);

  //Save changes
  const [isEdited, setIsEdited] = useState(false);

  //Loading
  const [isLoading, setIsLoading] = useState(true);

  //User to edit
  const [user, setUser] = useState<User>(
    props.userToUpdate ? new User(props.userToUpdate) : new User({ id: uuid() })
  );

  //Load filters at start
  useEffect(() => {
    load();

    async function load() {
      const filters: any = await store.dispatch(filterFetch(true, false));
      store.dispatch(filterGet(filters.error ? [] : filters));
      setIsLoading(false);
    }
  }, []);

  //Ask for deleting
  //If it is the connected user ask to delete account
  function askDelete() {
    setCurrentModal(
      user.id === props._session.userId
        ? MODAL_ASK_DELETE_ADMIN
        : MODAL_ASK_DELETE
    );
  }

  //Edit user
  function edit(key: string, value: any) {
    setUser(new User(Object.assign({}, user, { [key]: value })));
    setIsEdited(true);
  }

  //Edit attribute of the user
  function editAttribute(value, filterName) {
    const attributes = Object.assign({}, user.attributes, {
      [filterName]: value,
    });
    edit("attributes", attributes);
  }

  //Close modal
  function closeModal() {
    if (isEdited) {
      setCurrentModal(MODAL_ASK_SAVE);
    } else {
      props.onClose();
    }
  }

  //Clic on next
  //CHeck that email adress is valid
  //Else
  //> if page email save the user adress and change page
  //> else close page
  async function next() {
    if (!user.email && !user.firstname.length && !user.lastname.length) {
      toast(t("user_invalid"), { type: "error" });
    } else {
      //Insert user
      store.dispatch(userStatus(STATUS_SAVING));
      const response: any = props.userToUpdate
        ? await store.dispatch(userUpdate(user, true))
        : await store.dispatch(userInsert(user));
      store.dispatch(userStatus(STATUS_SAVED));

      //Update store
      //Different behavior if edit or insert
      if (!response.error) {
        if (props.userToUpdate) {
          store.dispatch(
            userGet(props.user.list.map((x) => (x.id === user.id ? user : x)))
          );
        } else {
          store.dispatch(userGet([user].concat(props.user.list)));
        }

        //Close popup
        if (props.onNext) props.onNext(user);
      }
    }
  }

  //Remove user
  async function remove() {
    store.dispatch(userStatus(STATUS_SAVING));
    const response: any = await store.dispatch(userDestroy(user.id));
    store.dispatch(userStatus(STATUS_SAVED));
    if (!response.error) {
      store.dispatch(userRemove(user.id));
      props.onClose();
    }
  }

  return (
    <Modal
      isCloseButtonVisible
      status={props.user.status}
      onNext={next}
      onDelete={props.userToUpdate ? askDelete : undefined}
      onClose={closeModal}
      disableClickOutside
      disableKeyEvents
      title={props.userToUpdate ? t("user_edit") : t("user_add")}
    >
      {currentModal === MODAL_ASK_DELETE && (
        <ModalConfirm
          onNo={() => setCurrentModal(null)}
          onYes={remove}
          text={t("user_ask_delete", {
            user: user.username,
          })}
          status={props.user.status}
          textBold={t("utils_next_ask")}
        />
      )}

      {currentModal === MODAL_ASK_DELETE_ADMIN && (
        <Modal onClose={() => setCurrentModal(null)} isCloseButtonVisible>
          <p>
            <b>{t("utils_delete_self")}</b>
          </p>
          <div className="height-20" />
          <div>{t("utils_delete_self_help")}</div>
          <div className="height-20" />
        </Modal>
      )}

      {currentModal === MODAL_ASK_SAVE && (
        <ModalConfirm
          onNo={() => props.onClose()}
          status={props.user.status}
          onYes={next}
          textBold={t("utils_ask_confirm_edits")}
        />
      )}

      <PageLoader status={isLoading ? STATUS_LOADING : STATUS_LOADED}>
        <UserGeneralInfoWidget
          userId={user.id}
          onEdit={edit}
          email={user.email ? user.email : ""}
          emailCanBeNull
          firstname={user.firstname}
          lastname={user.lastname}
        />

        <div className="height-40" />

        <UserFilterInfoWidget
          attributes={user.attributes}
          username={user.username}
          isEditable
          onEdit={(key, value) => editAttribute(value, key)}
        />

        <div className="height-40" />

        <UserOptionalInfoWidget
          birthDate={user.birthDate}
          companyWelcomeDate={user.companyWelcomeDate}
          phone={user.phone}
          address={user.address}
          language={user.language}
          gender={user.gender}
          genderLabel={user.genderLabel}
          onEdit={edit}
        />

        <div className="height-20" />
      </PageLoader>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  _session: state._session,
  user: state.user,
});

export default connect(mapStateToProps)(withTranslation()(AdmEditUserModal));
