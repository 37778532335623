/**
 * FETCH-CHOICE-MESSAGES
 * Load messages for question choice
 */

import { store } from "@/index";
// import store from "@/core/store";
import { DashboardFilters } from "@/redux/filter.types";
import { Message } from "@/redux/message.types";
import { topicEditQuestion } from "@/redux/topic.actions";
import { Topic } from "@/redux/topic.types";
import { fetchMessages } from "@/redux/_archive.actions";

const fetchChoiceMessages = async (
  topic: Topic,
  dashboardFilters: DashboardFilters,
  surveyId?: string,
  surveyStart?: Date
) => {
  const withMessageQuestions = topic.Questions.filter(
    (x) => x.type === "choice" && x.withMessage
  );
  for (let i = 0; i < withMessageQuestions.length; i++) {
    const question = withMessageQuestions[i];
    const messages: any = await store.dispatch(
      fetchMessages(
        dashboardFilters,
        question.aid,
        topic.aid,
        surveyId,
        surveyStart
      )
    );
    if (!messages.error) {
      store.dispatch(
        topicEditQuestion(question.id, "messagesCount", messages.length)
      );
      store.dispatch(
        topicEditQuestion(
          question.id,
          "Messages",
          messages.map((x) => new Message(x))
        )
      );
    }
  }
};

export default fetchChoiceMessages;
