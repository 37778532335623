/**
 *
 * CONSENT-OPENAI.WIDGET
 * Wrapper for OpenAi api
 * displays chat GPT only if user consents
 *
 */

import Button from "@/components/button";
import Checkbox from "@/components/checkbox";
import Space from "@/components/space";
import { store } from "@/index";
// import store from "@/core/store";
import { useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { sessionEditAccountOptions } from "@/redux/_session.actions";
import { Session } from "@/redux/_session.types";
import { accountUpdateOptions } from "@/redux/account.actions";
import { AccountOptions } from "@/redux/account.types";

interface StateProps {
  _session: Session;
}

interface OwnProps {
  children?: any;
}

type Props = StateProps & OwnProps & WithTranslation;

function ConsentOpenaiWidget(props: Props) {
  const { t } = props;

  const [allowAssistant, setAllowAssistant] = useState<boolean>(
    props._session.interfaceType === "SUPERVISOR"
      ? true
      : props._session.accountOptions.allowAssistant
  );
  const [rememberChoice, setRememberChoice] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function allow() {
    if (rememberChoice) {
      setIsLoading(true);
      const response: any = store.dispatch(
        accountUpdateOptions(
          new AccountOptions({
            ...props._session.accountOptions,
            allowAssistant: true,
          })
        )
      );

      if (!response.error) {
        store.dispatch(sessionEditAccountOptions("allowAssistant", true));
      }
    }

    setAllowAssistant(true);
    setIsLoading(false);
  }

  return allowAssistant ? (
    <>{props.children}</>
  ) : (
    <div style={{ padding: "20px 30px" }}>
      <p>{t("consent_ai_use", { name: "OpenAI" })}</p>

      <Checkbox
        active={rememberChoice}
        onClick={() => setRememberChoice(!rememberChoice)}
        text={t("utils_remember")}
      />

      <div className="height-40" />

      <div className="flex">
        <Space />
        <Button
          isLarge
          className="primary"
          onClick={allow}
          isLoading={isLoading}
        >
          {t("utils_accept")}
        </Button>
        <Space />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  _session: state._session,
});

export default connect(mapStateToProps)(withTranslation()(ConsentOpenaiWidget));
