/**
 * 
 * DASHBOARD-SUMMARIZE
 * summarize survey messages with chatGPT
 * 
 */
import { useState } from "react";
import ConsentOpenaiWidget from "./consent-openai.widget";
import GptDisplayerWidget from "./gpt-displayer.widget";

interface OwnProps{
  comments: string[]
  isPromptCustom? : boolean
  questionLabel: string
  prompt? : string
}
 
type Props = OwnProps

function DashboardSummarizeWidget(props: Props){

  const [comments] = useState(props.isPromptCustom && props.prompt ? [] : props.comments)
  const [isPromptCustom] = useState(props.isPromptCustom)
  const [questionLabel] = useState(props.questionLabel)
  const [prompt] = useState(props.isPromptCustom && props.prompt ? props.prompt.replace("{{question_name}}", props.questionLabel) + props.comments.join("\n\n- ") : "")

  return(
    <ConsentOpenaiWidget>

      <GptDisplayerWidget
        destination={isPromptCustom ? "ask" : "summarize"}
        params={{
          comments,
          questionLabel,
          prompt
        }}
      />
        
    </ConsentOpenaiWidget>
  )
}

export default DashboardSummarizeWidget