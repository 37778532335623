/**
 * report-params.WIDGET.TSX
 * Éditer les paramètres pour une slide
 */
import { store } from "@/index";
// import store from "@/core/store";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ReportState } from "@/redux/report.types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faUserFriends,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { reportEdit, reportEditAndSave } from "@/redux/report.actions";
import CardContainer from "@/components/card-container";
import CardButton from "@/components/card-button";
import { Session } from "@/redux/_session.types";
import Checkbox from "@/components/checkbox";
import { useState } from "react";
import SelectPopulationsModal from "@/modals/select-populations.modal";
import { Population } from "@/redux/population.types";
import LanguageSelection from "@/components/language-selection";
import { populationsUpdateWithAid } from "@/redux/population.actions";

interface StateProps extends WithTranslation {
  _session: Session;
  report: ReportState;
}

const MODAL_SELECT_POPULATIONS = "MODAL_SELECT_POPULATIONS";

function ReportParamsWidget(props: StateProps) {
  const { t } = props;

  //Modal
  const [currentModal, setCurrentModal] = useState<null | string>(null);

  //Edit report description
  const [description, setDescription] = useState(
    props.report.active.options.description
  );

  //Edit population
  //Update the archiveId in order to filter the data for the report
  function editPopulations(populations: Population[]) {
    editReportOptions("populations", populationsUpdateWithAid(populations));
  }

  //Edit options for the report
  function editReportOptions(key: string, value: any) {
    store.dispatch(
      reportEditAndSave(
        "options",
        Object.assign({}, props.report.active.options, { [key]: value })
      )
    );
  }

  return (
    <div
      className={
        "rel report-params" +
        (props.report.active.paramsOpen ? "" : " report-params-closed")
      }
    >
      {currentModal === MODAL_SELECT_POPULATIONS && (
        <SelectPopulationsModal
          onClose={() => setCurrentModal(null)}
          isWithoutDefaultFilters
          isSurveyActiveSelected
          selectedPopulations={props.report.active.options.populations}
          onNext={(populations: Population[]) => editPopulations(populations)}
        />
      )}

      <div
        className={
          "report-params-arrow abs _hover flex grey-t" +
          (props.report.active.paramsOpen ? "" : " report-params-arrow-closed")
        }
        onClick={() =>
          store.dispatch(
            reportEdit("paramsOpen", !props.report.active.paramsOpen)
          )
        }
      >
        <FontAwesomeIcon
          icon={props.report.active.paramsOpen ? faChevronRight : faChevronLeft}
          className="flex-auto"
        />
      </div>

      <h2>{t("report_edit_params")}</h2>

      <div className="height-10" />

      <p className="grey-t">{t("report_languages")}</p>

      <LanguageSelection
        languages={["fr", "en"]}
        activeLang={props.report.active.options.language}
        onClick={(e) => editReportOptions("language", e.code)}
      />

      <div className="height-20" />

      <p className="grey-t">{t("utils_description")}</p>

      <textarea
        className="text-area"
        style={{ height: "60px" }}
        onChange={(e) => setDescription(e.target.value)}
        name="description"
        onBlur={() => editReportOptions("description", description)}
        value={description}
      ></textarea>

      <div className="height-20" />

      {props._session.interfaceType === "SUPERVISOR" && (
        <Checkbox
          active={props.report.active.options.isForPopulations}
          onClick={() =>
            editReportOptions(
              "isForPopulations",
              !props.report.active.options.isForPopulations
            )
          }
          text="Rapport pour une équipe"
        />
      )}

      <p className="grey-t">{t("report_population")}</p>

      <CardContainer isAlignedLeft>
        <CardButton
          icon={faUsers}
          isSmall
          isActive={props.report.active.options.populations.length === 0}
          title={t("report_population_filters_false")}
          onClick={() => editReportOptions("populations", [])}
        ></CardButton>

        <CardButton
          icon={faUserFriends}
          isSmall
          isActive={props.report.active.options.populations.length > 0}
          title={t("report_population_filters_true")}
          onClick={() => setCurrentModal(MODAL_SELECT_POPULATIONS)}
        ></CardButton>
      </CardContainer>

      {props.report.active.options.populations.length > 0 && (
        <div>
          <div className="height-20" />

          <div
            className="grey-t _hover"
            onClick={() => setCurrentModal(MODAL_SELECT_POPULATIONS)}
          >
            <b>{t("report_population_selected")}</b>

            <ul>
              {props.report.active.options.populations.map((population) => (
                <li key={population.id}>
                  {population.filterName} : <b>{population.name}</b>
                </li>
              ))}
            </ul>
          </div>

          <div className="height-40" />

          <Checkbox
            active={props.report.active.options.allowObserversToCopy}
            onClick={() =>
              editReportOptions(
                "allowObserversToCopy",
                !props.report.active.options.allowObserversToCopy
              )
            }
            text={t("report_population_allow_copy")}
          />
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  _session: state._session,
  report: state.report,
});

export default connect(mapStateToProps)(withTranslation()(ReportParamsWidget));
