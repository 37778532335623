/**
 * FILTER.TYPES
 */

import { flatten } from "lodash";
import { Attribute } from "./attribute.types";
import { Population } from "./population.types";
import { Regrouping } from "./regrouping.types";
import { t } from "@/translate/t";

export class FilterPopulation {
  id: string = "";
  name: string = "";
  usersCount: number = 0;
  constructor(item: Partial<FilterPopulation> = {}) {
    if (!item) item = new FilterPopulation();
    Object.assign(this, item);
  }
}

export class Filter {
  populations: FilterPopulation[] = [];
  Regroupings: Regrouping[] = [];
  Attributes: Attribute[] = [];
  default: boolean = false;
  id: string = "";
  isTemplateFilter: boolean = false;
  importName: string | null = null;
  importStatus: string | null = null;
  name: string = "";
  isChecked: boolean = false;
  isManager: boolean = false;
  isVisible: boolean = true;

  constructor(filter: Partial<Filter> = {}) {
    if (!filter) filter = new Filter();
    filter.populations = filter.populations
      ? filter.populations.map((x: FilterPopulation) => new FilterPopulation(x))
      : [];
    filter.Regroupings = filter.Regroupings
      ? filter.Regroupings.map((x: Regrouping) => new Regrouping(x))
      : [];
    filter.Attributes = filter.Attributes
      ? filter.Attributes.map((x: Attribute) => new Attribute(x))
      : [];
    Object.assign(this, filter);
  }
}

//filter with essential information
//used in dashboardFilters
export class FilterLite {
  id: string = "";
  attributesIds: string[] = [];
  subFilter: boolean = false;

  constructor(filterLite: Partial<FilterLite> = {}) {
    Object.assign(this, filterLite);
  }
}

export class DashboardFilters {
  accounts: string[] = [];
  gender: string | null = null;
  birthDate: string | null = null;
  companyWelcomeDate: string | null = null;
  customFilters: FilterLite[] = [];

  constructor(dashboardFilters: Partial<DashboardFilters> = {}) {
    Object.assign(this, dashboardFilters);
    dashboardFilters.customFilters = dashboardFilters.customFilters
      ? dashboardFilters.customFilters.map((x: FilterLite) => new FilterLite(x))
      : [];
  }

  get customFiltersIds(): string[] {
    return flatten(this.customFilters.map((x) => x.attributesIds));
  }

  get isEmpty(): boolean {
    return (
      this.gender === null &&
      this.birthDate === null &&
      this.companyWelcomeDate === null &&
      this.customFilters.length === 0
    );
  }

  getLabel(attributes: Attribute[]): string {
    let labels: string[] = [];
    if (this.gender) labels.push(t("user_gender_" + this.gender));
    labels = labels.concat(
      attributes
        .filter((x) => this.customFiltersIds.indexOf(x.id) > -1)
        .map((x) => x.name)
    );
    return labels.join(" / ");
  }
}

export interface FilterState {
  active: Filter;
  list: Filter[];
  observerAttributes: Population[];
  dashboard: DashboardFilters;
  dashboardCompare: DashboardFilters;
  status: string;
  importResponse: any;
}

//List of default_filters
export const DEFAULT_FILTERS = [
  { id: "gender", name: "user_gender", default: true },
  { id: "birth_date", name: "user_birth_date_alt", default: true },
  {
    id: "company_welcome_date",
    name: "user_company_welcome_date_alt",
    default: true,
  },
];

//List of default attribtues (with count)
export const DEFAULT_RANDOM_ATTRIBUTES = [
  { id: "hr", name: "filter_random_hr", usersCount: 7 },
  { id: "sales", name: "filter_random_sales", usersCount: 58 },
  { id: "marketing", name: "filter_random_marketing", usersCount: 13 },
  { id: "dev", name: "filter_random_dev", usersCount: 37 },
];

export const FILTER_ACTIVATE: string = "FILTER_ACTIVATE";
export const FILTER_ADD: string = "FILTER_ADD";
export const FILTER_EDIT: string = "FILTER_EDIT";
export const FILTER_EDIT_DASHBOARD: string = "FILTER_EDIT_DASHBOARD";
export const FILTER_EDIT_DASHBOARD_COMPARE: string =
  "FILTER_EDIT_DASHBOARD_COMPARE";
export const FILTER_GET: string = "FILTER_GET";
export const FILTER_GET_IMPORT_RESPONSE: string = "FILTER_GET_IMPORT_RESPONSE";
export const FILTER_GET_OBSERVER_ATTRIBUTES: string =
  "FILTER_GET_OBSERVER_ATTRIBUTES";
export const FILTER_INIT: string = "FILTER_INIT";
export const FILTER_REMOVE: string = "FILTER_REMOVE";
export const FILTER_REPLACE_DASHBOARD: string = "FILTER_REPLACE_DASHBOARD";
export const FILTER_REPLACE_DASHBOARD_COMPARE: string =
  "FILTER_REPLACE_DASHBOARD_COMPARE";
export const FILTER_STATUS: string = "FILTER_STATUS";
