/**
 * DELETE-LEARNING.MODAL
 * Delete a learning
 */

import { withTranslation, WithTranslation } from "react-i18next";
import ModalConfirm from "@/components/modal-confirm";
import { store } from "@/index";
// import store from "@/core/store";
import { Learning } from "@/redux/learning.types";
import {
  learningDestroy,
  learningDestroy_AsSupervisor,
  learningRemove,
  learningStatus,
} from "@/redux/learning.actions";
import { toast } from "react-toastify";
import {
  STATUS_LOADED,
  STATUS_LOADING,
  STATUS_LOAD_ERROR,
} from "@/redux/_status.types";

interface OwnProps {
  onClose: Function;
  onConfirm?: Function;
  learning: Learning;
  fromSupervisor: boolean;
}

type Props = OwnProps & WithTranslation;

function DeleteLearningModal(props: Props) {
  const { t } = props;

  function close() {
    props.onClose();
  }

  async function deleteConfirm() {
    store.dispatch(learningStatus(STATUS_LOADING));
    let response: any;

    if (props.fromSupervisor) {
      response = await store.dispatch(
        learningDestroy_AsSupervisor(props.learning.id)
      );
    } else {
      response = await store.dispatch(learningDestroy(props.learning.id));
    }

    if (response.id === props.learning.id) {
      store.dispatch(learningRemove(response.id));
      store.dispatch(learningStatus(STATUS_LOADED));
      toast(t("learning_deleted"));

      close();

      if (props.onConfirm) {
        props.onConfirm();
      }
    } else {
      store.dispatch(learningStatus(STATUS_LOAD_ERROR));
      if (response.error) {
        toast(t(response.error), { type: "error" });
      } else {
        toast(t("error_occurred"), { type: "error" });
      }
    }

    close();
  }

  return (
    <ModalConfirm
      onNo={close}
      onYes={deleteConfirm}
      text={props.learning.label + " : " + t("learning_ask_delete")}
      textBold={""}
    />
  );
}

export default withTranslation()(DeleteLearningModal);
