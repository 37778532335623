/**
 * ONBOARDING-SIGNUP.WIDGET
 * Create a free account at the end of the onboarding
 */

import {
  faArrowLeft,
  faEnvelope,
  faMobileAlt,
} from "@fortawesome/free-solid-svg-icons";
import CardButton from "@/components/card-button";
import CardContainer from "@/components/card-container";
import Dropdown from "@/components/dropdown";
import Link from "@/components/link";
import Modal from "@/components/modal";
import Space from "@/components/space";
import TextInput from "@/components/text-input";
import { store } from "@/index";
// import store from "@/core/store";
import { useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  sessionEdit,
  sessionEditAccountColors,
} from "@/redux/_session.actions";
import { Session, SESSION_COLORS } from "@/redux/_session.types";
import { validateEmail } from "@/utils/validate-email.utils";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import i18n from "@/translate/i18n";
import ColorPickerWidget from "./color-picker.widget";
import Button from "@/components/button";
import LegalModal from "@/modals/legal.modal";
import { accountConfirm, accountInsertPublic } from "@/redux/account.actions";
import LoginModal from "@/modals/login.modal";
import LoadingModal from "@/modals/loading.modal";
import EnterCode from "@/components/enter-code";
import SetPasswordModal from "@/modals/set-password.modal";
import ContactModal from "@/modals/contact.modal";
import { FadeIn } from "@/utils/animations.utils";
import OnboardingFaqModal from "@/modals/onboarding-faq.modal";

interface StateProps extends WithTranslation {
  _session: Session;
}

//List of dialog to display
const MODAL_CONTACT = "MODAL_CONTACT";
const MODAL_FAQ = "MODAL_FAQ";
const MODAL_LEGAL = "MODAL_LEGAL";
const MODAL_LOADING = "MODAL_LOADING";
const MODAL_LOGIN_GOOGLE = "MODAL_LOGIN_GOOGLE";
const MODAL_MODES = "MODAL_MODES";
const MODAL_PHONE = "MODAL_PHONE";
const MODAL_CONFIRM = "MODAL_CONFIRM"; //Send email code
const MODAL_PASSWORD = "MODAL_PASSWORD"; //Page password

//Pages
const PAGE_FORM_1 = "PAGE_FORM_1"; //Fill account informations
const PAGE_FORM_2 = "PAGE_FORM_2"; //Fill email and user infos

//Widget creation of a new account
function OnboardingSignupWidget(props: StateProps) {
  const { t } = props;

  const [COMPANY_SIZE_LIST] = useState([
    { id: "1", name: t("user_company_size_1") },
    { id: "2", name: t("user_company_size_2") },
    { id: "3", name: t("user_company_size_3") },
    { id: "5", name: t("user_company_size_5") },
  ]);

  //List of values for dropdown form
  const [PERSONA_LIST] = useState([
    { id: "4", name: t("user_persona_consulting") },
    { id: "1", name: t("user_persona_hr") },
    { id: "2", name: t("user_persona_representative") },
    { id: "7", name: t("user_persona_hse") },
    { id: "10", name: t("user_persona_intern") },
    { id: "3", name: t("user_persona_director") },
    { id: "5", name: t("user_persona_other") },
  ]);

  //Modal to display
  const [currentModal, setCurrentModal] = useState<string | null>(null);

  //Current page for the form
  const [currentPage, setCurrentPage] = useState(PAGE_FORM_1);

  //Check if values are > will be check when user click on "next" butto
  const [isAccountValid, setIsAccountValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isFirstnameValid, setIsFirstnameValid] = useState(true);
  const [isLastnameValid, setIsLastnameValid] = useState(true);
  const [isPersonaValid, setIsPersonaValid] = useState(true);

  //Submit phone
  function confirmPhone() {
    if (checkPhone()) {
      submit();
    }
  }

  //Click on phone mode
  function confirmPhoneMode() {
    editSession("connexionMode", "SMS");
    setCurrentModal(MODAL_PHONE);
  }

  //Submit email
  function confirmUser() {
    if (checkUser()) {
      //editSession("connexionMode", "EMAIL")
      editSession("connexionMode", "SMS");
      setCurrentModal(MODAL_PHONE);
    }
  }

  //Check account
  function checkAccount() {
    let isValid = true;
    const messages: any[] = [];

    if (props._session.accountName === "") {
      isValid = false;
      setIsAccountValid(false);
      messages.push(t("account_name_invalid"));
    }

    if (props._session.accountAdsOptions.PERSONA === null) {
      isValid = false;
      setIsPersonaValid(false);
      messages.push(t("user_persona_invalid"));
    }

    messages.forEach((message) => {
      toast(message, { type: "error" });
    });

    return isValid;
  }

  //CHeck if phone is valid
  function checkPhone() {
    if (props._session.phone.length > 6) {
      return true;
    } else {
      toast(t("utils_phone_invalid"), { type: "error" });
      return false;
    }
  }

  //Check if user form is valid
  function checkUser() {
    let isValid = true;
    const messages: any[] = [];

    if (props._session.userFirstname === "") {
      isValid = false;
      setIsFirstnameValid(false);
      messages.push(t("user_firstname_invalid"));
    }

    if (props._session.userLastname === "") {
      isValid = false;
      setIsLastnameValid(false);
      messages.push(t("user_lastname_invalid"));
    }

    if (!validateEmail(props._session.email)) {
      isValid = false;
      setIsEmailValid(false);
      messages.push(t("utils_email_invalid"));
    }

    messages.forEach((message) => {
      toast(message, { type: "error" });
    });

    return isValid;
  }

  //Submit form and go to next page (set email)
  async function confirmAccount() {
    if (checkAccount()) {
      setCurrentPage(PAGE_FORM_2);
    }
  }

  //Connexion with google
  //If success submit form
  /*async function connectWithGoogle(){
    if (checkAccount()){
      setCurrentModal(MODAL_LOADING)
      const googleResponse = await getGoogleResponse()
      if (googleResponse){

        let firstname = ""
        let lastname = ""
        if (googleResponse.displayName){
          firstname = googleResponse.displayName.split(" ")[0]
          lastname = googleResponse.displayName.split(" ")[1]
        }

        //Update store
        editSession("email", googleResponse.email)
        editSession("connexionMode", "GOOGLE")

        //Save account creation as google
        const response:any = await store.dispatch(accountInsertPublic(
          props._session.accountAdsOptions,
          props._session.accountColors,
          "GOOGLE",
          googleResponse.email,
          props._session.accountId,
          i18n.language,
          props._session.accountName,
          firstname,
          lastname,
          "",
          props._session.accountOptions.image,
          googleResponse.photoURL
        ))

        //Open login modal
        if (response.error){
          setCurrentModal(null)
        }else{
          setCurrentModal(MODAL_LOGIN_GOOGLE)
        }

      }
    }
  }*/

  function editPhone(value: string) {
    if (value.slice(0, 3) === "330") {
      editSession("phone", "33" + value.slice(3));
    } else {
      editSession("phone", value);
    }
  }

  //Edit form values
  function editSession(key: string, value: any) {
    store.dispatch(sessionEdit(key, value));
  }

  //Get the name of the company
  function getCompanySizeName() {
    const company_size = COMPANY_SIZE_LIST.find(
      (x) => x.id === props._session.accountAdsOptions.COMPANY_SIZE
    );
    return company_size ? company_size.name : null;
  }

  //Call google auth API
  //Once log save params in cookies
  /*async function getGoogleResponse(){

    try {

      //Open google popup
      const googleResponse:any = await firebaseAppAuth.signInWithPopup(firebaseProvider)

      //Save cookies
      if (firebaseAppAuth.currentUser){
        const firebaseToken = await firebaseAppAuth.currentUser.getIdToken()
        cookie.save('firebaseToken', firebaseToken, { path: '/' })
        instanceBackend.defaults.headers.common['firebaseToken'] = firebaseToken
      }

      return googleResponse.user

    }catch(error:any){
      toast(t("firebase_" + error.code), { type : "error" })
      setCurrentModal(null)
      return null
    }

  }*/

  //Get legal text
  function getLegal() {
    return (
      <div>
        <div className="flex" style={{ fontSize: "12px" }}>
          <Space />
          <Link onClick={() => setCurrentModal(MODAL_FAQ)}>
            {t("utils_faq")}
          </Link>
          <Space />
        </div>

        <div className="flex" style={{ fontSize: "12px" }}>
          <Space />
          <Link onClick={() => setCurrentModal(MODAL_LEGAL)}>
            {t("utils_legal")}
          </Link>
          <Space />
        </div>
      </div>
    );
  }

  //Get name of the persona from the list
  function getPersonaName() {
    const persona = PERSONA_LIST.find(
      (x) => x.id === props._session.accountAdsOptions.PERSONA
    );
    return persona ? persona.name : null;
  }

  //Select a color
  function selectColor(color) {
    store.dispatch(sessionEditAccountColors("brandSecondary", color));
    store.dispatch(sessionEditAccountColors("brandPrimary", color));
  }

  function selectCompanySize(event: any) {
    console.log(event.id);
    editSession(
      "accountAdsOptions",
      Object.assign({}, props._session.accountAdsOptions, {
        COMPANY_SIZE: event.id,
      })
    );
  }

  function selectPersona(event: any) {
    editSession(
      "accountAdsOptions",
      Object.assign({}, props._session.accountAdsOptions, {
        PERSONA: event.id,
        MESSAGE: "",
        COMPANY_SIZE:
          event.id === "4" ? "" : props._session.accountAdsOptions.COMPANY_SIZE,
      })
    );

    setIsPersonaValid(true);
  }

  //Send code to confirm email
  async function sendCode(code) {
    setCurrentModal(MODAL_LOADING);
    const responseConfirm: any = await store.dispatch(
      accountConfirm(props._session.userId, code)
    );
    if (!responseConfirm.error) {
      setCurrentModal(MODAL_PASSWORD);
    }
  }

  //Submit form
  async function submit() {
    setCurrentModal(MODAL_LOADING);

    const response: any = await store.dispatch(
      accountInsertPublic(
        props._session.accountAdsOptions,
        props._session.accountColors,
        props._session.connexionMode,
        props._session.email,
        props._session.accountId,
        i18n.language,
        props._session.accountName,
        props._session.userFirstname,
        props._session.userLastname,
        props._session.phone,
        props._session.accountOptions.image,
        null
      )
    );

    if (response.error) {
      setCurrentModal(null);
    } else {
      //confirme account
      store.dispatch(sessionEdit("userId", response));
      setCurrentModal(MODAL_CONFIRM);
    }
  }

  //Go back to website
  function toWebsite() {
    window.open("https://id-tree.com", "_blank");
  }

  return (
    <div className="onboarding-login flex">
      {currentModal === MODAL_PHONE && (
        <Modal>
          <div style={{ width: 400 }}>
            <div style={{ textAlign: "center" }}>
              <b style={{ fontSize: 22 }}>{t("account_confirm_info")}</b>
              <div className="height-10" />
              <b>{t("account_ask_title")}</b>
              <div className="height-40" />
              <p>{t("account_ask_phone_details")}</p>
            </div>
          </div>
          <div className="flex" style={{ marginLeft: 50 }}>
            <PhoneInput
              isValid={(value: string, country: any) => {
                switch (country.countryCode) {
                  case "33":
                    if (value.toString()[2] === "0" || value.length > 11) {
                      return false;
                    } else {
                      return true;
                    }

                  default:
                    return true;
                }
              }}
              enableLongNumbers
              enableSearch
              country={i18n.language === "fr" ? "fr" : "be"}
              value={props._session.phone}
              onChange={editPhone}
              countryCodeEditable={false}
            />
          </div>
          <div className="height-10" />
          <p
            className="grey-t"
            style={{
              width: 400,
              textAlign: "center",
              fontSize: 12,
            }}
          >
            {t("account_ask_phone_help")}
          </p>
          <div className="height-20" />
          <div className="flex">
            <Space />
            <Button className="primary" isLarge onClick={confirmPhone}>
              {t("account_ask_phone")}
            </Button>
            <Space />
          </div>
          <div className="flex">
            <Space />
            <Button onClick={() => setCurrentModal(null)}>
              {t("utils_cancel")}
            </Button>
            <Space />
          </div>
        </Modal>
      )}

      {currentModal === MODAL_MODES && (
        <Modal isCloseButtonVisible onClose={() => setCurrentModal(null)}>
          <div className="flex">
            <Space />
            <div
              style={{
                textAlign: "center",
                width: "300px",
              }}
            >
              <p>
                <b>{t("account_ask_title")}</b>
              </p>
            </div>
            <Space />
          </div>
          <CardContainer>
            <CardButton
              title={t("account_ask_phone")}
              onClick={confirmPhoneMode}
              isCta
              icon={faMobileAlt}
            ></CardButton>
            <CardButton
              title={t("account_ask_email")}
              onClick={submit}
              icon={faEnvelope}
            ></CardButton>
          </CardContainer>
        </Modal>
      )}

      {currentModal === MODAL_PASSWORD && <SetPasswordModal loginAfter />}

      {currentModal === MODAL_CONFIRM && (
        <Modal
          title={t("account_create_email_verify")}
          disableClickOutside
          isCloseButtonVisible
          onClose={() => setCurrentModal(null)}
        >
          {props._session.connexionMode === "SMS" ? (
            <div>
              <p>{t("account_create_phone_send")}</p>

              <p>
                <b>
                  {t("account_create_phone_confirm", {
                    phone: props._session.phone,
                  })}
                </b>
              </p>
            </div>
          ) : (
            <div>
              <p>{t("account_create_email_send")}</p>

              <p>
                <b>
                  {t("account_create_email_confirm", {
                    email: props._session.email,
                  })}
                </b>
              </p>
            </div>
          )}

          <EnterCode onSetCode={sendCode} />

          <div
            className="grey-t"
            style={{
              marginTop: "22px",
              marginBottom: "12px",
              fontSize: "12px",
              textAlign: "center",
            }}
          >
            <Link
              isWithoutMargin
              onClick={() => setCurrentModal(MODAL_CONTACT)}
            >
              {t("contact_support_help")}
            </Link>
          </div>
        </Modal>
      )}

      {currentModal === MODAL_LOADING && <LoadingModal />}

      {currentModal === MODAL_LOGIN_GOOGLE && (
        <LoginModal
          authLevel={2}
          isFirstConnexion
          email={props._session.email}
        />
      )}

      {currentModal === MODAL_LEGAL && (
        <LegalModal onClose={() => setCurrentModal(null)} />
      )}

      {currentModal === MODAL_FAQ && (
        <OnboardingFaqModal onClose={() => setCurrentModal(null)} />
      )}

      {currentModal === MODAL_CONTACT && (
        <ContactModal onClose={() => setCurrentModal(null)} />
      )}

      <div className="onboarding-login-header flex">
        {currentPage === PAGE_FORM_1 ? (
          <Link isWithoutMargin onClick={toWebsite} icon={faArrowLeft}>
            {t("onboarding_login_back")}
          </Link>
        ) : (
          <Link
            isWithoutMargin
            onClick={() => setCurrentPage(PAGE_FORM_1)}
            icon={faArrowLeft}
          >
            {t("utils_back")}
          </Link>
        )}

        <Space />

        <div
          className="onboarding-login-contact _hover flex"
          onClick={() => setCurrentModal(MODAL_CONTACT)}
        >
          <img src={props._session.salesContact.imageUrl} alt="contact" />
          <span className="grey-t">
            <b>{props._session.salesContact.phone}</b>
          </span>
        </div>
      </div>

      <Space />

      <div className="flex onboarding-login-container">
        <div className="flex3 flex flex-dcol">
          <Space />

          <div className="flex onboarding-form">
            {currentPage === PAGE_FORM_1 ? (
              <div>
                <h1>{t("account_create")}</h1>

                <div style={{ width: "300px" }}>
                  <div className="flex onboarding-login-account">
                    <TextInput
                      title={t("account_name") + " *"}
                      value={props._session.accountName}
                      error={!isAccountValid}
                      isFullWidth
                      onChange={(e) => {
                        editSession("accountName", e.value);
                        setIsAccountValid(true);
                      }}
                    />

                    <div className="width-20" />

                    <ColorPickerWidget
                      colors={SESSION_COLORS}
                      title={t("color")}
                      selectedColor={props._session.accountColors.brandPrimary}
                      onSelect={selectColor}
                    />

                    <div className="width-20" />
                  </div>

                  <Dropdown
                    active={props._session.accountAdsOptions.PERSONA}
                    displayField="name"
                    list={PERSONA_LIST}
                    error={!isPersonaValid}
                    title={t("user_persona") + " *"}
                    onSelect={selectPersona}
                    value={getPersonaName()}
                  />

                  {props._session.accountAdsOptions.PERSONA === "5" && (
                    <TextInput
                      title={t("Précisez")}
                      value={props._session.accountAdsOptions.MESSAGE}
                      onChange={(e) =>
                        editSession(
                          "accountAdsOptions",
                          Object.assign({}, props._session.accountAdsOptions, {
                            MESSAGE: e.value,
                          })
                        )
                      }
                    />
                  )}

                  <div className="height-20" />

                  <Button
                    className="warning"
                    isFullWidth
                    onClick={confirmAccount}
                    isLarge
                  >
                    {t("utils_next")}
                  </Button>

                  <div className="height-10" />

                  {/*
                <Card onClick={connectWithGoogle}
                  className="_hover">
                  <div className="flex">
                    <img src={require("assets/google.png")}
                      alt="google"
                      style={{
                        width : '20px',
                        height : '20px',
                        marginRight : '20px'
                      }}
                    />
                    <div className="grey-t">
                      <b>{t("google_next").toUpperCase()}</b>
                    </div>
                  </div>
                </Card>
                */}

                  {getLegal()}
                </div>
              </div>
            ) : (
              <FadeIn>
                <h1>{t("account_create")}</h1>

                <div className="flex onboarding-login-user">
                  <TextInput
                    title={t("user_firstname") + " *"}
                    value={props._session.userFirstname}
                    error={!isFirstnameValid}
                    onChange={(e) => {
                      editSession("userFirstname", e.value);
                      setIsFirstnameValid(true);
                    }}
                  />

                  <div className="width-20" />

                  <TextInput
                    title={t("user_lastname") + " *"}
                    value={props._session.userLastname}
                    error={!isLastnameValid}
                    onChange={(e) => {
                      editSession("userLastname", e.value);
                      setIsLastnameValid(true);
                    }}
                  />
                </div>

                <TextInput
                  title={t("user_email") + " *"}
                  value={props._session.email}
                  autoFocus
                  isFullWidth
                  error={!isEmailValid}
                  onChange={(e) => {
                    editSession("email", e.value.toLowerCase());
                    setIsEmailValid(true);
                  }}
                />

                {props._session.accountAdsOptions.PERSONA !== "4" && (
                  <Dropdown
                    active={props._session.accountAdsOptions.COMPANY_SIZE}
                    displayField="name"
                    list={COMPANY_SIZE_LIST}
                    title={t("user_company_size")}
                    onSelect={selectCompanySize}
                    value={getCompanySizeName()}
                  />
                )}

                <div className="height-20" />

                <div style={{ width: "300px" }}>
                  <Button
                    className="warning"
                    isFullWidth
                    onClick={confirmUser}
                    isLarge
                  >
                    {t("utils_next")}
                  </Button>

                  <div className="height-10" />

                  {getLegal()}
                </div>
              </FadeIn>
            )}
          </div>

          <Space />
        </div>

        <div className="flex2 flex flex-dcol">
          <Space />
          <div className="onboarding-login-end">
            <ReactMarkdown>{t("onboarding_end")}</ReactMarkdown>
          </div>
          <div className="flex4" />
        </div>

        <div className="onboarding-login-background">
          <svg width="100%" height="100%" viewBox="0 0 100 100">
            <polygon
              points="100,0 100,100 49,100 62,0"
              style={{
                fill: props._session.accountColors.brandPrimary, // "#00CB7B"
              }}
            ></polygon>
          </svg>
        </div>
      </div>

      <div
        className="flex1"
        style={{ backgroundColor: props._session.accountColors.brandPrimary }}
      />

      <div className="onboarding-login-image">
        <img
          src={require("@/assets/onboarding/templates.png")}
          alt="templates"
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  _session: state._session,
});

export default connect(mapStateToProps)(
  withTranslation()(OnboardingSignupWidget)
);
