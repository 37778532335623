/**
 * PROJECT-EMAILS-SKIN.WIDGET
 * Select email skin
 */

import { connect } from "react-redux";
import { withTranslation, WithTranslation } from "react-i18next";
import { Session, SESSION_COLORS } from "@/redux/_session.types";
import { EmailState } from "@/redux/email.types";
import { ProjectState } from "@/redux/project.types";
import {
  projectEdit,
  projectStatus,
  projectUpdate,
} from "@/redux/project.actions";
import { store } from "@/index";
// import store from "@/core/store";
import { AVAILABLE_SKINS, EmailSkin } from "@/utils/email-thumbnails.utils";
import ColorPickerWidget from "./color-picker.widget";
import { sessionEditAccountColors } from "@/redux/_session.actions";
import { accountUpdateColors } from "@/redux/account.actions";
import { useState } from "react";
import {
  STATUS_SAVE_ERROR,
  STATUS_SAVED,
  STATUS_SAVING,
} from "@/redux/_status.types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import Space from "@/components/space";
import UpgradeModal from "@/modals/upgrade.modal";

interface StateProps {
  _session: Session;
  email: EmailState;
  project: ProjectState;
}

type Props = StateProps & WithTranslation;

const MODAL_UPGRADE: string = "MODAL_UPGRADE";

function ProjectEmailsSkinWidget(props: Props) {
  const { t } = props;

  const [currentModal, setCurrentModal] = useState<string | null>(null);

  function selectColor(color: string) {
    store.dispatch(sessionEditAccountColors("email", color));

    setTimeout(async () => {
      store.dispatch(projectStatus(STATUS_SAVING));
      const response: any = await store.dispatch(
        accountUpdateColors(props._session.accountColors)
      );
      store.dispatch(
        projectStatus(response.error ? STATUS_SAVE_ERROR : STATUS_SAVED)
      );
    }, 500);
  }

  function selectSkin(skin: number | null) {
    store.dispatch(projectEdit("emailSkin", skin));

    setTimeout(async () => {
      store.dispatch(projectStatus(STATUS_SAVING));
      const response: any = await store.dispatch(
        projectUpdate(props.project.active)
      );
      store.dispatch(
        projectStatus(response.error ? STATUS_SAVE_ERROR : STATUS_SAVED)
      );
    }, 500);
  }

  return (
    <div style={{ overflowX: "auto" }}>
      {currentModal === MODAL_UPGRADE && (
        <UpgradeModal
          feature="settings"
          onClose={() => setCurrentModal(null)}
        />
      )}

      <div className="flex abs">
        <p>{t("email_skin")}</p>

        <div className="width-100" />

        <div style={{ marginTop: -4 }}>
          <ColorPickerWidget
            colors={SESSION_COLORS}
            isCustom
            selectedColor={props._session.accountColors.email}
            onSelect={selectColor}
          />
        </div>
      </div>

      <div className="height-100" />

      <div style={{ display: "inline-flex", flexDirection: "row" }}>
        {AVAILABLE_SKINS.map((x: EmailSkin) => (
          <div
            key={x.id}
            className={
              (props.project.active.emailSkin === x.id ? "" : "_hover ") +
              "flex1 email-skin-preview rel"
            }
            style={{
              border:
                "1px solid " +
                (props.project.active.emailSkin === x.id
                  ? props._session.accountColors.active
                  : "#e9e9e9"),
            }}
            onClick={
              props.project.active.emailSkin !== x.id &&
              props._session.modules.settings
                ? () => selectSkin(x.id)
                : undefined
            }
          >
            {!props._session.modules.settings && x.id && (
              <div
                className="abs flex"
                style={{
                  borderRadius: 8,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#8c8c8c66",
                }}
                onClick={() => setCurrentModal(MODAL_UPGRADE)}
              >
                <Space />
                <div className="flex flex-dcol">
                  <Space />
                  <FontAwesomeIcon icon={faLock} color={"white"} size={"3x"} />
                  <Space />
                </div>
                <Space />
              </div>
            )}

            {x.render(props._session.accountColors.email)}
          </div>
        ))}
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  _session: state._session,
  email: state.email,
  project: state.project,
});

export default connect(mapStateToProps)(
  withTranslation()(ProjectEmailsSkinWidget)
);
