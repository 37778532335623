/***
 * HOME-LAST-INVITATION.WIDGET
 * Get responses to the last survey for an employee
 */
import { useEffect, useState } from "react";
import { STATUS_LOADED, STATUS_LOADING } from "@/redux/_status.types";
import { store } from "@/index";
// import store from "@/core/store";
import PageLoader from "@/components/page-loader";
import { invitationFetchLast } from "@/redux/invitation.actions";
import env from "@/env";

function HomeLastInvitationWidget() {
  //Are projects loading
  const [isLoading, setIsLoading] = useState(true);

  //Result code to load
  const [resultCode, setResultCode] = useState<string | null>(null);

  //Load projects at start
  //If one found set it as active project
  useEffect(() => {
    load();

    async function load() {
      const response: any = await store.dispatch(invitationFetchLast());

      if (response.results_code) {
        setResultCode(response.results_code);
      }

      setIsLoading(false);
    }
  }, []);

  return (
    <PageLoader status={isLoading ? STATUS_LOADING : STATUS_LOADED}>
      {resultCode && (
        <iframe
          title="results"
          style={{
            position: "fixed",
            top: 48,
            left: 0,
            width: "100%",
            height: "calc(100% - 80px)",
          }}
          src={env.REACT_APP_URL_QUICKVOTE + "/results?id=" + resultCode}
        ></iframe>
      )}
    </PageLoader>
  );
}

export default HomeLastInvitationWidget;
