/**
 * PAGE.ACTIONS
 */

import { store } from "@/index";
// import store from "@/core/store";
import { Population } from "./population.types";

//Update population with aid from attribute data
export function populationsUpdateWithAid(populations: Population[]) {
  populations.forEach((population) => {
    const attribute = store
      .getState()
      .attribute.list.find(
        (x) =>
          x.name === population.name && x.filterName === population.filterName
      );
    population.aid = attribute ? parseInt(attribute.aid) : null;
  });

  return populations;
}
