/**
 * EMAIL-THUMBNAILS.UTILS
 *
 */

import Space from "@/components/space";
import { store } from "@/index";
// import store from "@/core/store";
import { t } from "@/translate/t";

const randomText = `Le lorem ipsum (également appelé faux-texte) est, en imprimerie, une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page, le texte définitif venant remplacer le faux-texte dès qu'il est prêt ou que la mise en page est achevée.\n\nGénéralement, on utilise un texte en faux latin (le texte ne veut rien dire, il a été modifié), le Lorem ipsum ou Lipsum. L'avantage du latin est que l'opérateur sait au premier coup d'œil que la page contenant ces lignes n'est pas valide et que l'attention du lecteur n'est pas dérangée par le contenu, lui permettant de demeurer concentré sur le seul aspect graphique.\n\nIl circule des centaines de versions différentes du lorem ipsum, mais ce texte aurait originellement été tiré de l'ouvrage écrit par Cicéron en 45 av. J.-C., De finibus bonorum et malorum (Liber Primus, 32), texte populaire à cette époque, dont l'une des premières phrases est : « Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit… » (« Il n'existe personne qui aime la souffrance pour elle-même, ni qui la recherche ni qui la veuille pour ce qu'elle est… »).`;

function getImage() {
  const imageUrl: string | null =
    !store.getState().project.active.customImage &&
    store.getState().template.active.imageUrl
      ? store.getState().template.active.imageUrl
      : store.getState().project.active.imageUrl;

  return (
    <img
      style={{ marginLeft: 10 }}
      src={imageUrl!}
      width={60}
      height={60}
      alt={store.getState().template.active.label}
    />
  );
}

export type EmailSkin = { id: number | null; render: Function };

export const AVAILABLE_SKINS: EmailSkin[] = [
  {
    id: null,
    render: (color: string) => (
      <div className="flex flex-dcol" style={{ height: "100%" }}>
        <div className="flex" style={{ height: 20 }} />
        <div className="flex" style={{ color: color }}>
          <Space />
          {t("email_title")}
          <Space />
        </div>
        <div
          className="flex"
          style={{
            height: 100,
            color: "#8c8c8c",
            fontSize: 8,
            padding: 10,
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
        >
          {randomText}
        </div>
        <Space />
        <div className="flex">
          <Space />
          <div
            className="flex"
            style={{
              color: color,
              border: "1px solid " + color,
              borderRadius: 4,
              width: 60,
              height: 15,
              fontSize: 10,
            }}
          >
            <Space />
            {t("email_cta")}
            <Space />
          </div>
          <Space />
        </div>
        <div className="flex" style={{ height: 8 }} />
        <div className="flex">
          <Space />
          <div
            className="flex"
            style={{
              color: color,
              border: "1px solid " + color,
              borderRadius: 4,
              width: 60,
              height: 15,
              fontSize: 8,
            }}
          >
            <Space />
            {t("email_info")}
            <Space />
          </div>
          <Space />
        </div>
        <div className="flex" style={{ height: 25 }}>
          <div
            style={{
              color: "#8c8c8c",
              margin: 6,
              fontSize: 8,
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {randomText}
          </div>
        </div>
      </div>
    ),
  },
  {
    id: 1,
    render: (color: string) => (
      <div className="flex flex-dcol" style={{ height: "100%" }}>
        <div
          className="flex"
          style={{
            backgroundColor: color,
            height: 30,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
          }}
        >
          <Space />
          <div style={{ margin: "auto", color: "white" }}>
            {t("email_title")}
          </div>
          <Space />
        </div>
        <div
          className="flex"
          style={{
            height: 100,
            color: "#8c8c8c",
            fontSize: 8,
            padding: 10,
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
        >
          {randomText}
        </div>
        <Space />
        <div className="flex">
          <Space />
          <div
            className="flex"
            style={{
              backgroundColor: color,
              borderRadius: 4,
              width: 60,
              height: 18,
              fontSize: 10,
              color: "white",
              paddingTop: 2,
            }}
          >
            <Space />
            {t("email_cta")}
            <Space />
          </div>
          <Space />
        </div>
        <div className="flex" style={{ height: 10 }} />
        <div
          className="flex"
          style={{
            backgroundColor: "#eff2f7",
            height: 45,
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8,
          }}
        >
          <div
            style={{
              margin: 10,
              fontSize: 8,
              textOverflow: "ellipsis",
              overflow: "hidden",
              textAlign: "center",
            }}
          >
            {randomText}
          </div>
        </div>
      </div>
    ),
  },
  {
    id: 2,
    render: (color: string) => (
      <div className="flex flex-dcol" style={{ height: "100%" }}>
        <div
          className="flex"
          style={{
            backgroundColor: color,
            height: 30,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
          }}
        >
          <Space />
          <div style={{ margin: "auto", color: "white" }}>
            {t("email_title")}
          </div>
          <Space />
        </div>
        <div
          className="flex"
          style={{
            height: 100,
            color: "#8c8c8c",
            fontSize: 8,
            padding: 10,
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
        >
          <div className="flex1">{randomText}</div>
          <div className="flex1 flex flex-dcol">
            <Space />
            {getImage()}
            <Space />
          </div>
        </div>
        <Space />
        <div
          className="flex"
          style={{
            marginLeft: 10,
            backgroundColor: color,
            borderRadius: 4,
            width: 60,
            height: 18,
            fontSize: 10,
            color: "white",
            paddingTop: 2,
          }}
        >
          <Space />
          {t("email_cta")}
          <Space />
        </div>
        <div className="flex" style={{ height: 10 }} />
        <div
          className="flex"
          style={{
            backgroundColor: "#eff2f7",
            height: 45,
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8,
          }}
        >
          <div
            style={{
              margin: 10,
              fontSize: 8,
              textOverflow: "ellipsis",
              overflow: "hidden",
              textAlign: "center",
            }}
          >
            {randomText}
          </div>
        </div>
      </div>
    ),
  },
];
