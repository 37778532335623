/**
 * REGROUPING-ASSOCIATE.MODAL
 *
 */

import Modal from "@/components/modal";
import { connect } from "react-redux";

import { withTranslation, WithTranslation } from "react-i18next";
import TextInput from "@/components/text-input";
import { Regrouping, RegroupingState } from "@/redux/regrouping.types";
import { Attribute } from "@/redux/attribute.types";
import { useState } from "react";
import Space from "@/components/space";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { concat } from "lodash";
import { store } from "@/index";
// import store from "@/core/store";
import {
  regroupingCopy,
  regroupingRemove,
  regroupingStatus,
  regroupingUpdate,
} from "@/redux/regrouping.actions";
import { toast } from "react-toastify";
import RegroupingViewer from "@/components/regrouping-viewer";
import {
  STATUS_SAVED,
  STATUS_SAVE_ERROR,
  STATUS_SAVING,
} from "@/redux/_status.types";

interface StateProps extends WithTranslation {
  regrouping: RegroupingState;
}

interface OwnProps {
  onClose: Function;
  onNext: Function;
  attributes: Attribute[];
}

type Props = StateProps & OwnProps;

function RegroupingAssociateModal(props: Props) {
  const { t } = props;

  const newName: string =
    props.attributes.length > 0
      ? props.attributes
          .map((x: Attribute) => x.name)
          .join(" / ")
          .substring(0, 60)
      : props.regrouping.active.name;
  const [name, setName] = useState<string>(
    props.regrouping.active.usersCount > 0
      ? props.regrouping.active.name
      : newName
  );

  function close() {
    props.onClose();
  }

  //when association is confirmed
  async function next() {
    //check the regrouping has at least 2 differents attributes
    if (
      props.attributes.length < 1 ||
      (props.attributes.length < 2 && props.regrouping.active.usersCount === 0)
    ) {
      toast(t("not_enough_attributes_to_form_regrouping"), { type: "error" });
    } else {
      store.dispatch(regroupingStatus(STATUS_SAVING));
      const newRegrouping: Regrouping = new Regrouping(props.regrouping.active);
      newRegrouping.name = name;
      newRegrouping.Attributes = concat(
        newRegrouping.Attributes,
        props.attributes
      );
      newRegrouping.attributes = concat(
        newRegrouping.attributes,
        props.attributes.map((x: Attribute) => x.id)
      );

      const response: any = await store.dispatch(
        regroupingUpdate(newRegrouping)
      );
      if (response.id === props.regrouping.active.id) {
        store.dispatch(regroupingRemove(newRegrouping.id));
        store.dispatch(regroupingCopy(newRegrouping));
        store.dispatch(regroupingStatus(STATUS_SAVED));
        props.onNext(newRegrouping);
      } else {
        store.dispatch(regroupingStatus(STATUS_SAVE_ERROR));
        if (response.error) {
          toast(t(response.error), { type: "error" });
        } else {
          toast(t("no_response"), { type: "error" });
        }
      }
    }
  }

  return (
    <Modal
      title={t("utils_associate")}
      disableClickOutside
      disableKeyEvents
      isCloseButtonVisible
      status={props.regrouping.status}
      onNext={next}
      onClose={close}
    >
      <TextInput
        isFullWidth
        title={t("regroup_name")}
        value={name}
        onChange={(e) => setName(e.value)}
        placeholder={t("new_regrouping")}
      />

      <div className="height-40" />

      <div className="flex">
        <div className="flex1">
          {props.regrouping.active.usersCount > 0 && (
            <RegroupingViewer
              regrouping={props.regrouping.active}
              attributes={[]}
              name={props.regrouping.active.name}
            />
          )}

          {props.regrouping && <div style={{ height: "20px" }} />}

          {props.attributes.map((item: Attribute) => (
            <div key={item.id} className="flex regrouping-attributes">
              {t(item.name)}

              <Space />

              {item.usersCount > 0 && (
                <span className="grey-t">{item.usersCount}</span>
              )}
            </div>
          ))}
        </div>

        <div className="flex" style={{ width: "100px" }}>
          <Space />

          <div style={{ margin: "auto" }}>
            <FontAwesomeIcon icon={faArrowRight} size="2x" color={"#111C2B"} />
          </div>

          <Space />
        </div>

        <div className="flex1">
          <RegroupingViewer
            regrouping={props.regrouping.active}
            attributes={props.attributes}
            name={name}
          />
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  regrouping: state.regrouping,
});

export default connect(mapStateToProps)(
  withTranslation()(RegroupingAssociateModal)
);
