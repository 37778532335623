/**
 * DASHBOARD-MESSAGES-CLOUD.WIDGET
 * messages keywords
 */

import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Chip from "@/components/chip";
import { store } from "@/index";
// import store from "@/core/store";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchMessagesCloud } from "@/redux/_archive.actions";
import { Session } from "@/redux/_session.types";
import { FilterState } from "@/redux/filter.types";

interface StateProps {
  _session: Session;
  filter: FilterState;
}

interface OwnProps {
  active?: string;
  currentQuestionAid: string;
  currentTopicAid: string;
  onClick?: Function;
}

type Props = StateProps & OwnProps;

const NB_DISPLAY: number = 10;

function DashboardMessagesCloudWidget(props: Props) {
  const [activeWord, setActiveWord] = useState<string | undefined>(
    props.active
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [messagesCloud, setMessagesCloud] = useState<any[]>([]);

  useEffect(() => {
    setActiveWord(props.active);
  }, [props.active]);

  useEffect(() => {
    async function load() {
      setIsLoading(true);

      const response: any = await store.dispatch(
        fetchMessagesCloud(
          props.filter.dashboard,
          props.currentQuestionAid,
          props.currentTopicAid
        )
      );

      if (!response.error) {
        setMessagesCloud(
          Object.entries(response.cloud).sort((a: any, b: any) => b[1] - a[1])
        );
      }

      //End loading
      setIsLoading(false);
    }

    load();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filter.dashboard, props.currentQuestionAid, props.currentTopicAid]);

  function click(word: string) {
    if (props.onClick) {
      props.onClick(word);
    }
  }

  return (
    <div className="flex flex-wrap" style={{ padding: "16px 36px" }}>
      {isLoading ? (
        <FontAwesomeIcon icon={faCircleNotch} spin />
      ) : (
        messagesCloud.slice(0, NB_DISPLAY).map((x: any) => (
          <Chip
            key={x[0]}
            color={
              activeWord === x[0]
                ? props._session.accountColors.active
                : undefined
            }
            count={x[1]}
            isWithMargin
            onClick={() => click(activeWord === x[0] ? "" : x[0])}
          >
            {x[0]}
          </Chip>
        ))
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  _session: state._session,
  filter: state.filter,
});

export default connect(mapStateToProps)(DashboardMessagesCloudWidget);
