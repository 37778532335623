/**
 * DASHBOARD-EDIT-AFTER-LAUNCH
 * Edit question after the survey is launched
 */

import Checkbox from "@/components/checkbox";
import ListItem from "@/components/list-item";
import ModalConfirm from "@/components/modal-confirm";
import Space from "@/components/space";
import { store } from "@/index";
// import store from "@/core/store";
import CongratulationModal from "@/modals/congratulation.modal";
import LoadingModal from "@/modals/loading.modal";
import { useState } from "react";
import { connect } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { questionUpdateAfterLaunch } from "@/redux/question.actions";
import { Question } from "@/redux/question.types";
import { SurveyState } from "@/redux/survey.types";

interface StateProps {
  survey: SurveyState;
}

interface OwnProps {
  currentQuestion: Question;
}

type Props = StateProps & OwnProps;

const MODAL_DONE = "MODAL_DONE";
const MODAL_LOADING = "MODAL_LOADING";
const MODAL_REVERSE = "MODAL_REVERSE";
const MODAL_WITHSCORE = "MODAL_WITHSCORE";

function DashboardEditAfterLaunchWidget(props: Props) {
  const navigate: NavigateFunction = useNavigate();

  const [currentModal, setCurrentModal] = useState<null | string>(null);

  async function switchValue(key: string, value: any) {
    if (props.currentQuestion.aid) {
      setCurrentModal(MODAL_LOADING);
      const response: any = await store.dispatch(
        questionUpdateAfterLaunch(
          props.survey.active.id,
          props.currentQuestion.aid,
          key,
          value
        )
      );
      setCurrentModal(response.error ? null : MODAL_DONE);
    }
  }

  return props.survey.active.status === "launch" ? (
    <p>
      <b className="red-t">
        {"L'enquête doit être terminée pour effectuer des modifications"}
      </b>
    </p>
  ) : (
    <div>
      {currentModal === MODAL_DONE && (
        <CongratulationModal
          onClose={() => navigate("/dashboard/home")}
          text="Les changements ont bien été pris en compte. Ils seront visibles au prochain chargement de l'enquête."
        />
      )}

      {currentModal === MODAL_LOADING && <LoadingModal />}

      {currentModal === MODAL_REVERSE && (
        <ModalConfirm
          onNo={() => setCurrentModal(null)}
          onYes={() => switchValue("reverse", !props.currentQuestion.reverse)}
          text={
            "La question « " +
            props.currentQuestion.label +
            " » sera considérée comme " +
            (props.currentQuestion.reverse ? "positive" : "négative") +
            "."
          }
          textBold={"Souhaitez-vous continuer"}
        />
      )}

      {currentModal === MODAL_WITHSCORE && (
        <ModalConfirm
          onNo={() => setCurrentModal(null)}
          onYes={() =>
            switchValue("withScore", !props.currentQuestion.withScore)
          }
          text={
            "Les réponses à la question « " +
            props.currentQuestion.label +
            " » " +
            (props.currentQuestion.withScore
              ? "ne seront pas prises en compte dans les résultats"
              : "seront prises en compte dans les résultats") +
            "."
          }
          textBold={"Souhaitez-vous continuer"}
        />
      )}

      <ListItem>
        <Checkbox
          active={props.currentQuestion.reverse}
          onClick={() => setCurrentModal(MODAL_REVERSE)}
          text="Question négative (inversée)"
        />
        <Space />
      </ListItem>

      <ListItem>
        <Checkbox
          active={props.currentQuestion.withScore}
          onClick={() => setCurrentModal(MODAL_WITHSCORE)}
          text="Question avec score"
        />
        <Space />
      </ListItem>
    </div>
  );
}

const mapStateToProps = (state) => ({
  survey: state.survey,
});

export default connect(mapStateToProps)(DashboardEditAfterLaunchWidget);
