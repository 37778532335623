/**
 * QUESTION.TYPES
 */
import { compact, snakeCase, uniqBy } from "lodash"
import getLabel from "@/utils/get-label.utils"
import { Message } from "./message.types"
import { Proposition } from "./proposition.types"
import { Template } from "./template.types"
import { Topic } from "./topic.types"
import { t } from "@/translate/t"
import splitAndSum from "@/utils/split-and-sum.utils"

export class QuestionsTopic{
  QuestionId : string | null = null
  TopicId : string | null = null
  triggerValue : string | null = '<'
  triggerChoice : string | null = null
  disableNoOpinion: boolean = false //hide nspp (formerly anwsersRequired from Template)
  constructor(questionsTopic: Partial<QuestionsTopic> = {}){
    Object.assign(this, questionsTopic)
  }
}

export type QuestionType = "choice" | "scale" | "open"

export class Question{
  aid : string | null = "" //Id in archive database
  benchmarkAvailable: boolean = false //Is question comparable ?
  choiceCount : number | null = null //Is there a limitation of response possibles (for multiple choice). If null no limitations
  color : string = "" //Color for question
  draft : boolean = true //Question in draft mode (yellow in editor)
  id : string = "" //Id of the quesiton (uuid)
  isPromptCustom : boolean = false //activate custom prompt (for AI Summarize)
  Messages : Message[] = [] //List of messages for one question
  messagesCount : number = 0 //Number of message for the question (if open)
  name : any = {} //Name to edit (in multilangue)
  noAnswers : number = 0 //People that have no answered
  fk_topic: string | null = null //topic aid
  open : boolean = false //Is question open in dashboard
  originalQuestionId: string | null = null //Id of the question duplicated
  primaryId : string | null = null //Get primary question id for secondary question (dashboard)
  propositionsDeleted : string[] = [] //List of proposition to delete (before save in editor)
  propositionsOrder : string[] = [] //Order of the propositions
  Propositions : Proposition[] = [] //List of propositions for a quesiton (multiple choice)
  prompt : string = "" //Text of the prompt for AI summarize (if is prompt custom is activate)
  QuestionsTopic : QuestionsTopic = new QuestionsTopic() //Association between question and topic
  special : string | null = null //RPS tag for the question
  repartition : number[] = [] //Get repartition classes (for results)
  responseCount : number = 5 //Number of response possibles (for type scale)
  reverse : boolean = false //Are positive anwser matching with negative text ?
  fromSupervisor : boolean = false //Question of idtree
  Topics : Topic[] = [] //List of topic related for a quesiton (when i search in database of questions)
  type : QuestionType = "scale" //Type of question (scale, choice or open)
  updated : boolean = false //Is the question updated (in order to save in editor)
  usersCount : number = 0 //Number of user that have answered
  withMessage : boolean = true //Allow to add message for QCM question
  withScore : boolean = true //Can a score be calculate for the question (only for type scale)

  constructor(question: Partial<Question> = {}){
    if (!question) question = new Question()
    question.QuestionsTopic = new QuestionsTopic(question.QuestionsTopic)
    question.Topics = question.Topics ? question.Topics.map(x => new Topic(x)) : []
    question.Propositions = question.Propositions ? question.Propositions.map(x => new Proposition(x)) : []
    Object.assign(this, question)
  }

  //Is likert question
  get isLikert():boolean{
    return this.type === "scale" && (this.responseCount === 4 || this.responseCount === 5)
  }
  
  //Question numeric
  get isNumeric(): boolean{
    return this.isNumericAllowed && this.withScore
  }
  
  //Is it possible to define numeric question
  get isNumericAllowed(): boolean{
    return QUESTION_LIST_TYPE.filter(x => x.numericAllowed).map(x => x.id).indexOf(this.type) > -1
  }

  //Get label from langague
  get label(): string{
    return getLabel(this.name)
  }

  //Get list of items for list withScore
  //Filter on response count (label is different)
  get listWithScore():any[]{
    const list:any[] = QUESTION_LIST_WITH_SCORE

    list.forEach(item => {
      if (item.reverseValues){
        item.values = item.reverseValues.filter(x => x.responseCount.indexOf(this.responseCount) > -1)
        item.values.forEach(value => {
          value.prefix = "question_reverse"
          value.suffix = value.id ? "question_reverse_tag" : null
        })
      }
    })

    return list

  }

  //Get label for numeric question
  get numericLabel(): string{
    return this.withScore ? t("question_score_true") : t("question_score_false")
  }

  //Transform repartition in two scale
  get repartitionTwoScales():number[]{
    return splitAndSum(this.repartition)
  }

  //Get label for reverse
  get reverseLabel(): string{
    if (this.withScore){
      const item = QUESTION_LIST_REVERSE.find(x => x.responseCount.indexOf(this.responseCount) > -1 && x.id === this.reverse)
      return item ? t(item.name) : ""
    }else{
      return ""
    }
  }

  //Get label for tag
  get specialLabel(): string{
    if (this.special){
      return t("rps_" + snakeCase(this.special))
    }else{
      return t("utils_no")
    }
  }

  //Get label for type
  //For scale => add count of responses
  get typeLabel(): string{
    const type = QUESTION_LIST_TYPE.find(x => x.id === this.type)
    return type ? t(type.name) : ""
  }

  //Get label for type
  //For scale => add count of responses
  get typeLabelWithCount(): string{
    const type = QUESTION_LIST_TYPE.find(x => x.id === this.type)

    if (!type){
      return ""
    }else if (type.id === "open"){
      return t(type.name)
    }else if (type.id === "choice"){
      if (this.choiceCount === null){
        return t(type.name)
      }else{
        return t(type.name + '_' + this.choiceCount)
      }
    }else{
      return t(type.name + '_' + this.responseCount)
    }

  }

  isEmpty(language: string): boolean{
    return !this.name[language] || this.name[language].length < 1
  }

  getEmptyPropostions(language: string): Proposition[]{
    return this.Propositions.filter((p: Proposition)=>p.isEmpty(language))
  }

  //Get label for question primary
  getPrimaryLabel(isPrimary): string{
    return isPrimary ? t("question_primary") : t("question_secondary")
  }

  //Find primary question from a list of question
  getQuestionPrimary(questions: Question[]):Question{
    const question = questions.find(x => x.id === this.primaryId)
    return question ? question : new Question()
  }
  
  //Get name of the templates for a question
  getTemplates(language):any[]{
    const templates = this.Topics.map((x:any) => x.Template)
    return uniqBy(compact(templates), "id").map(x => x.name[language])
  }

  //Get trigger
  getTrigger(questionPrimary):string | null{
    const trigger = null

    if (questionPrimary){
      return questionPrimary.type === "scale" ? this.QuestionsTopic.triggerValue : this.QuestionsTopic.triggerChoice
    }

    return trigger

  }

  //Get label for the trigger
  getTriggerLabel(template:Template, questionPrimary:Question | null, language:string):string{
    let label:string = ""

    if (questionPrimary){
      if (questionPrimary.id !== this.id){

        const reverse = (this.QuestionsTopic.triggerValue === '>' && !questionPrimary.reverse) ||
          (this.QuestionsTopic.triggerValue === '<' && questionPrimary.reverse)

        if (questionPrimary.type === "scale"){
          if (questionPrimary.responseCount === 2){
            const response = template.getResponseLabel(questionPrimary.responseCount, reverse ? "yes" : "no", "utils")
            label =  "« " + response + " »"
          }else if (questionPrimary.responseCount > 5){
            label = t(reverse ? "question_nps_positive" : "question_nps_negative")
          }else{
            label = t("question_trigger_label", {
              response1 : template.getResponseLabel(questionPrimary.responseCount, reverse ? 5 : 1, "question_likert"),
              response2 : template.getResponseLabel(questionPrimary.responseCount, reverse ? 4 : 2, "question_likert")
            })
          }
        }else if (questionPrimary.type === "choice"){
          if(this.getTriggerValue(questionPrimary, language)){
            return "« " + this.getTriggerValue(questionPrimary, language) + " »"
          }
          else{
            return ""
          }
        }

      }
    }

    return label

  }

  //Get list of item possible to define trigger
  getTriggerList(questionPrimary:Question | null, language:string):any[]{

    let triggers:any = []

    if (questionPrimary){
      if (questionPrimary.type === 'scale'){
        
        triggers = triggers.concat([
          { id : '<',  name : t("question_emoji_reverse_no") },
          { id : '>',  name : t("question_emoji_reverse_yes") }
        ])

      }else if(questionPrimary.type === "choice"){
        questionPrimary.Propositions.forEach(proposition => {
          triggers.push({
            id : proposition.id,
            name : proposition.name[language]
          })
        })
      }
    }

    return triggers

  }

  //Get value for the trigger
  getTriggerValue(questionPrimary:Question | null, language:string):string{
    if (questionPrimary){

      let value : string | null = null
      if (questionPrimary.type === "scale"){
        value = this.QuestionsTopic.triggerValue
      }
      else if (questionPrimary.type === "choice"){
        value = this.QuestionsTopic.triggerChoice
      }

      const item = this.getTriggerList(questionPrimary, language).find(x => x.id === value)
      return item ? item.name : ""

    }else{
      return ""
    }
    
  }

}

export interface QuestionState{
  active : Question
  count : number
  countSearch : number
  list : Question[]
  status : string
}

//List of value for type of question
export const QUESTION_LIST_TYPE = [
  { 
    id : 'scale', 
    name : "question_type_scale", 
    numericAllowed : true,
    values : [
      { id : 2, name : "question_type_scale_2" },
      { id : 4, name : "question_type_scale_4" },
      { id : 5, name : "question_type_scale_5" },
      { id : 10, name : "question_type_scale_10", module : "nps" }
    ]
  },
  { id : 'choice', 
    name : "question_type_choice",
    values : [
      { id : null, name : "question_type_choice" },
      { id : 1, name : "question_type_choice_1" },
      { id : 2, name : "question_type_choice_2" },
      { id : 3, name : "question_type_choice_3" },
      { id : 4, name : "question_type_choice_4" },
      { id : 5, name : "question_type_choice_5" }
    ] 
  },
  { 
    id : 'open',
    name : "question_type_open"
  }
]

//List of value for reverse field
//List displayed will depend of the type
export const QUESTION_LIST_REVERSE = [
  { responseCount : [10], id : false, name : "10" },
  { responseCount : [10], id : true, name : "1" },
  { responseCount : [4, 5], id : false, name : "question_likert_5" },
  { responseCount : [4, 5], id : true, name : "question_likert_1" },
  { responseCount : [2], id : false, name : "utils_yes" },
  { responseCount : [2], id : true, name : "utils_no" }
]

//Liste avec valeurs oui / non
export const QUESTION_LIST_WITH_SCORE = [
  {
    id : true,
    name : "question_score_true",
    reverseValues : QUESTION_LIST_REVERSE
  },
  {
    id : false,
    name : "question_score_false"
  }
]

//Values for full screen question
export const QUESTION_LIST_FULLSCREEN = [
  { id : true, name : "utils_yes" },
  { id : false, name : "utils_no" }
]

//List of value for tag
export const QUESTION_LIST_SPECIAL = [
  { id : null, name : "utils_no" },
  { id : "decision-latitude", name : "rps_decision_latitude" },
  { id : "psychological-job-demands", name : "rps_psychological_job_demands" },
  { id : "skill-use", name : "rps_skill_use" },
  { id : "manager-support", name : "rps_manager_support" },
  { id : "team-support", name : "rps_team_support" },
  { id : "work-gratitude", name : "rps_work_gratitude" }
]

export const QUESTION_ACTIVATE = 'QUESTION_ACTIVATE'
export const QUESTION_ADD = 'QUESTION_ADD'
export const QUESTION_EDIT = 'QUESTION_EDIT'
export const QUESTION_GET = 'QUESTION_GET'
export const QUESTION_GET_COUNT = 'QUESTION_GET_COUNT'
export const QUESTION_GET_COUNT_SEARCH = 'TEMPLATE_GQUESTION_GET_COUNT_SEARCHET_COUNT_SEARCH'
export const QUESTION_INIT = 'QUESTION_INIT'
export const QUESTION_REMOVE = 'QUESTION_REMOVE'
export const QUESTION_STATUS = 'QUESTION_STATUS'