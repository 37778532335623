/**
 * IMPORT.MODAL
 * Proceed to import users
 */
import Modal from "@/components/modal";
import { connect } from "react-redux";
import { withTranslation, WithTranslation } from "react-i18next";
import { User } from "@/redux/user.types";
import { Filter } from "@/redux/filter.types";
import { useCallback, useEffect, useState } from "react";
import LoadingModal from "./loading.modal";
import { store } from "@/index";
// import store from "@/core/store";
import {
  userDestroyList,
  userFetch,
  userInsertList,
  userUpdateList,
} from "@/redux/user.actions";
import { Session } from "@/redux/_session.types";
import { validateEmail } from "@/utils/validate-email.utils";
import ListItem from "@/components/list-item";
import Space from "@/components/space";
import {
  faCheckCircle,
  faCircleNotch,
  faPauseCircle,
  faPeopleArrows,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "@/components/button";
import { pick } from "lodash";
import { v4 as uuid } from "uuid";
import CardButton from "@/components/card-button";
import {
  filterDestroyList,
  filterFetch,
  filterUpdateList,
} from "@/redux/filter.actions";
import { accountUpdateOptions } from "@/redux/account.actions";
import Checkbox from "@/components/checkbox";

interface StateProps extends WithTranslation {
  _session: Session;
}

interface OwnProps {
  filters: Filter[];
  users: User[];
  isFromIntegration?: boolean;
  notAssociatedManagers: string[];
  onClose: Function;
  onNext: Function;
}

type Props = StateProps & OwnProps;

interface ImportError {
  message: string;
  user: User;
}

//How many items at once are saved
const CHUNK_SIZE: number = 50;

function ImportModal(props: Props) {
  const { t } = props;

  //List of errors after importation
  const [importErrors, setImportErrors] = useState<ImportError[]>([]);

  //Error (unable to load users and filters)
  const [isError, setIsError] = useState(false);

  //Loading status
  const [isLoading, setIsLoading] = useState(true);

  //Saving in progress (display save modal)
  const [isSaving, setIsSaving] = useState(false);

  //Saving done (display button "next")
  const [isSaved, setIsSaved] = useState(false);

  //Current step for the saving phase
  const [savingCurrentStep, setSavingCurrentStep] = useState(0);

  //Status of save (each line container users imported and user to import)
  const [savingStatus, setSavingStatus] = useState<number[]>([]);

  //Ask to remove old values
  const [isUpdateModeAvailable, setIsUpdateModeAvailable] = useState(false);
  const [isWholeListUpdated, setIsWholeListUpdated] = useState(true);

  //Filters
  const [filtersToDelete, setFiltersToDelete] = useState<Filter[]>([]);

  //Users
  const [invalidUsers, setInvalidUsers] = useState<User[]>([]);
  const [invalidManagers, setInvalidManagers] = useState<string[]>([]);
  const [usersToInsert, setUsersToInsert] = useState<User[]>([]);
  const [usersToUpdate, setUsersToUpdate] = useState<User[]>([]);
  const [usersToDelete, setUsersToDelete] = useState<User[]>([]);

  //Keep special roles
  const [keepAdmins, setKeepAdmins] = useState<boolean>(true);
  const [keepRoles, setKeepRoles] = useState<boolean>(true);

  //Compare filter to identify filters to remove
  const compareFilters = useCallback(async () => {
    const filtersResponse: any = await store.dispatch(
      filterFetch(false, false)
    );
    const filters = filtersResponse.error
      ? []
      : filtersResponse.map((x) => new Filter(x));

    const filtersToDelete: Filter[] = [];
    filters.forEach((filter) => {
      const currentFilter = props.filters.find((x) => x.name === filter.name);
      if (!currentFilter) {
        filtersToDelete.push(filter);
      }
    });

    setFiltersToDelete(filtersToDelete);
  }, [props.filters]);

  //Load users in the database and compare it with the users that will be imported
  const compareUsers = useCallback(async () => {
    const usersResponse: any = await store.dispatch(
      userFetch("", null, null, null, "id,email,firstname,lastname,role")
    );
    if (usersResponse.error) {
      setIsError(true);
    } else {
      //Loop on every user to import
      //At first search matching by email adress
      //Else search by firstname and lastname
      //If found tag update, else tag insert
      const userList = props.users;
      userList.forEach((user) => {
        const isValid = user.email ? validateEmail(user.email) : true;
        if (!isValid) {
          user.importStatus = "INVALID";
        } else {
          let currentUser = usersResponse.find((x) => x.email === user.email);
          if (currentUser) {
            user.importStatus = "UPDATE";
          } else {
            currentUser = usersResponse.find(
              (x) => x.username === user.username
            );
            user.importStatus = currentUser ? "UPDATE" : "INSERT";
          }

          //Override admins
          if (currentUser?.role === "ADMIN") {
            user.role = "ADMIN";
            user.populationsObserver = [];
          }

          //Update or create id for the user
          user.id = currentUser ? currentUser.id : uuid();
        }
      });

      //Update lists
      //For users to delete search all user in the database that are not in the file (and is not the connected user)
      setInvalidManagers(props.notAssociatedManagers);
      setInvalidUsers(userList.filter((x) => x.importStatus === "INVALID"));
      setUsersToInsert(userList.filter((x) => x.importStatus === "INSERT"));
      setUsersToUpdate(userList.filter((x) => x.importStatus === "UPDATE"));
      setUsersToDelete(
        usersResponse
          .filter((x) => userList.map((x) => x.id).indexOf(x.id) === -1)
          .filter((x) => x.id !== props._session.userId)
      );

      //Show update mode only for successive imports
      setIsUpdateModeAvailable(usersResponse.length > 1);
    }
  }, [props.users, props.notAssociatedManagers, props._session.userId]);

  //On load
  //Compare stored list of users and filters with existing data
  //Create list of items to insert, update or delete
  useEffect(() => {
    load();

    async function load() {
      await compareFilters();
      await compareUsers();
      setIsLoading(false);
    }
  }, [compareFilters, compareUsers]);

  //Get saving steps
  function getSavingSteps() {
    const steps: string[] = [
      "import_filter_save",
      "import_user_add",
      "import_user_update",
    ];

    if (isWholeListUpdated) {
      steps.push("import_user_delete");
    }

    return steps;
  }

  //Get icon for each step
  //Can be done, pending or to do
  function getStepIcon(index) {
    if (index === savingCurrentStep) {
      return <FontAwesomeIcon icon={faCircleNotch} spin className="grey-t" />;
    } else if (index < savingCurrentStep) {
      return <FontAwesomeIcon icon={faCheckCircle} className="green-t" />;
    } else {
      return <FontAwesomeIcon icon={faPauseCircle} className="grey-t" />;
    }
  }

  //Update values
  async function save() {
    setIsSaving(true);
    await saveFilters();
    await saveUsers();
    await saveAccount();
    setSavingCurrentStep(getSavingSteps().length);
    setIsSaved(true);
  }

  //Save account options
  async function saveAccount() {
    await store.dispatch(
      accountUpdateOptions(
        Object.assign({}, props._session.accountOptions, {
          lastUpload: new Date(),
        })
      )
    );
  }

  //Save filters
  async function saveFilters() {
    await update(filterUpdateList, props.filters, "FILTERS", 0);
    await update(
      filterDestroyList,
      isWholeListUpdated ? filtersToDelete.map((x) => x.id) : [],
      null,
      0
    );
  }

  //Save users
  async function saveUsers() {
    await update(userInsertList, usersToInsert, "USERS", 1);
    await update(userUpdateList, usersToUpdate, "USERS", 2);
    await update(
      userDestroyList,
      isWholeListUpdated
        ? usersToDelete
            .filter((x: any) =>
              keepRoles ? x.role === null : keepAdmins ? x.role !== "ADMIN" : x
            )
            .map((x) => x.id)
        : [],
      null,
      3
    );
  }

  //Function to update data to the server
  //Send data by pack of 200
  async function update(
    updateFunction: Function,
    list: any[],
    listType: string | null,
    index: number
  ) {
    //Init status
    setSavingStatus([0, list.length]);

    //Update index
    setSavingCurrentStep(index);

    //Keep only important attributes
    if (listType === "USERS") {
      list = list.map(
        (x) =>
          (x = pick(x, [
            "id",
            "gender",
            "birthDate",
            "companyWelcomeDate",
            "email",
            "firstname",
            "lastname",
            "language",
            "phone",
            "address",
            "attributes",
            "role",
            "populationsObserver",
          ]))
      );
    } else if (listType === "FILTER") {
      list = list.map((x) => (x = pick(x, ["id", "name", "importName"])));
    }

    //Proceed to successive saves
    let savedItems: any[] = [];
    for (let i: number = 0; i < list.length; i += CHUNK_SIZE) {
      const chunk: User[] = list.slice(i, i + CHUNK_SIZE);
      const response: any = await store.dispatch(updateFunction(chunk));

      if (response.errors) {
        setImportErrors(
          response.errors.map((x: any) => {
            return { message: x.message.name, user: new User(x.user) };
          })
        );
      }

      savedItems = savedItems.concat(chunk);
      setSavingStatus([savedItems.length, list.length]);
      await setTimeout(() => {}, 200);
    }

    await setTimeout(() => {}, 500);
  }

  return isLoading ? (
    <LoadingModal />
  ) : isSaving ? (
    <Modal>
      {getSavingSteps().map((step, i) => (
        <ListItem key={i}>
          {t(step)}

          <Space />

          {i === savingCurrentStep && (
            <div className="grey-t">
              {savingStatus[0]} / {savingStatus[1]}
              &nbsp;
            </div>
          )}

          <div style={{ fontSize: "22px", margin: "-6px 0px" }}>
            {getStepIcon(i)}
          </div>
        </ListItem>
      ))}

      {importErrors.length > 0 && (
        <div>
          <div className="orange-t">
            {t("user_import_error", { count: importErrors.length })}
          </div>
          <ul>
            {importErrors.map((error: ImportError, i: number) => (
              <li key={i}>
                {error.user.username} : {error.message}
              </li>
            ))}
          </ul>
        </div>
      )}

      {isSaved && (
        <div className="flex">
          <Space />
          <Button className="primary" onClick={() => props.onNext()}>
            {t("utils_next")}
          </Button>
        </div>
      )}
    </Modal>
  ) : (
    <Modal>
      <div className="import-modal">
        <h1>{t("import_confirm")}</h1>

        <b>
          {t("import_count", {
            count: usersToInsert.length + usersToUpdate.length,
            s: usersToInsert.length + usersToUpdate.length > 1 ? "s" : "",
          })}
        </b>

        {isUpdateModeAvailable && !props.isFromIntegration ? (
          <div>
            <div className="flex" style={{ margin: "40px 20px" }}>
              <Space />
              <CardButton
                icon={faPeopleArrows}
                title={t("import_whole_list")}
                isActive={isWholeListUpdated}
                onClick={() => setIsWholeListUpdated(true)}
              />
              <CardButton
                icon={faUserPlus}
                title={t("import_add_to_list")}
                isActive={!isWholeListUpdated}
                onClick={() => setIsWholeListUpdated(false)}
              />
              <Space />
            </div>

            {isWholeListUpdated && usersToDelete.some((x: any) => x.role) && (
              <div>
                <div>
                  {t("delete_special_roles", {
                    count: usersToDelete.filter((x: any) => x.role).length,
                    admins: usersToDelete.filter((x: any) => x.role === "ADMIN")
                      .length,
                  })}
                </div>

                <Checkbox
                  active={keepRoles}
                  onClick={
                    keepRoles
                      ? () => setKeepRoles(false)
                      : () => {
                          setKeepRoles(true);
                          setKeepAdmins(true);
                        }
                  }
                  text={t("keep_special_roles")}
                />

                {usersToDelete.some(
                  (x: any) => x.role && x.role !== "ADMIN"
                ) && (
                  <div className={keepRoles ? "cards-inactive" : ""}>
                    <Checkbox
                      active={keepAdmins}
                      onClick={() =>
                        keepRoles ? {} : setKeepAdmins(!keepAdmins)
                      }
                      text={t("keep_admins")}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          <div className="height-40" />
        )}

        {invalidUsers.length > 0 && (
          <div className="red-t">
            <div>
              <b>{t("import_invalid_emails_1")}</b>
            </div>

            <div>
              <b>{t("import_invalid_emails_2")}</b>
            </div>

            <ul>
              {invalidUsers.map((user, i) => (
                <li key={i} style={{ textAlign: "initial" }}>
                  {user.username} ({user.email})
                </li>
              ))}
            </ul>

            <div className="height-40" />
          </div>
        )}

        {invalidManagers.length > 0 && (
          <div className="red-t">
            <div>
              <b>{t("import_invalid_managers")}</b>
            </div>

            <ul>
              {invalidManagers.map((manager, i) => (
                <li key={i} style={{ textAlign: "initial" }}>
                  {manager}
                </li>
              ))}
            </ul>

            <div className="height-40" />
          </div>
        )}

        <div className="flex">
          <Space />

          <Button
            onClick={() => props.onClose()}
            className="secondary"
            isWithBorder
          >
            {t("utils_cancel")}
          </Button>

          {!isError && (
            <Button onClick={save} className="primary" isWithBorder>
              {t("import")}
            </Button>
          )}

          {!isUpdateModeAvailable && <Space />}
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  _session: state._session,
});

export default connect(mapStateToProps)(withTranslation()(ImportModal));
