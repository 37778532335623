/**
 * EDITOR-EDIT-AXIS-IMAGE
 * Upload or choose axis image
 */
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import Space from "@/components/space";
import { AxisState } from "@/redux/axis.types";
import { remove } from "@/redux/_upload.actions";
import { store } from "@/index";
// import store from "@/core/store";
import { axisEdit } from "@/redux/axis.actions";
import { useState } from "react";
import Scroller from "@/components/scroller";
import DefaultImages from "@/components/default-images";
import ImagePicker from "@/components/image-picker";
import Checkbox from "@/components/checkbox";

interface StateProps extends WithTranslation {
  axis: AxisState;
}

function EditorEditAxisImage(props: StateProps) {
  const { t } = props;

  const [defaultImages, setDefaultImages] = useState<boolean>(
    props.axis.active.defaultImage
  );

  function changeImage(image: string | null, defaultImage: boolean) {
    if (props.axis.active.defaultImage !== defaultImage) {
      store.dispatch(axisEdit("defaultImage", defaultImage));
    }

    store.dispatch(axisEdit("image", image));
  }

  return (
    <div>
      {defaultImages ? (
        <div className="flex">
          {props.axis.active.image && (
            <div className="flex">
              <Space />
              <img
                style={{ objectFit: "cover" }}
                src={props.axis.active.picture}
                alt={props.axis.active.label}
                height={100}
                width={100}
              />
              <Space />
            </div>
          )}

          <div
            className="width-20 grey-b"
            style={{
              borderRight: "1px solid",
              marginRight: 20,
            }}
          />

          <Scroller maxHeight={320}>
            <DefaultImages
              selected={props.axis.active.image}
              onClick={(image: string) => changeImage(image, true)}
            />
          </Scroller>
        </div>
      ) : (
        <ImagePicker
          id={props.axis.active.id}
          square
          imageId={
            props.axis.active.defaultImage ? null : props.axis.active.image
          }
          model="Axes"
          onDelete={() => {
            store.dispatch(remove("Axes", props.axis.active.image!, "png"));
            changeImage(null, false);
          }}
          onSave={() => changeImage(props.axis.active.id, false)}
        />
      )}

      <Checkbox
        active={defaultImages}
        onClick={() => setDefaultImages(!defaultImages)}
        text={t("default_images")}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  axis: state.axis,
});

export default connect(mapStateToProps)(withTranslation()(EditorEditAxisImage));
