// import TextField from "@mui/material/TextField/TextField";
// import {
//   Box,
//   Chip,
//   FormControl,
//   InputLabel,
//   MenuItem,
//   Select,
//   SelectChangeEvent,
//   Stack,
//   Typography,
// } from "@mui/material";

// import { AccountOptions } from "@/redux/account.types";
import {
  // selectAccount,
  selectFilter,
  selectGollac,
  // selectSession,
} from "@/redux/selectors/selectors";
import {
  Box,
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ThunderstormIcon from "@mui/icons-material/Thunderstorm";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import WbCloudyIcon from "@mui/icons-material/WbCloudy";
import { t } from "i18next";
import { Filter, FilterPopulation } from "@/redux/filter.types";
import { RpsSliceState, setGollacParams } from "@/core/slice/rpsSlice";
import { useGetGollacQuery } from "@/core/api/rpsApi";
// import { filterStatus } from "@/redux/filter.actions";
// import { store } from "@/index";
// import { getTest } from "@/core/slice/rpsSlice";
// import { accountUpdateOptions } from "@/redux/account.actions";

export const GollacFilters = () => {
  const dispatch = useDispatch();
  const gollacParams = useSelector(selectGollac);
  // const session = useSelector(selectSession);
  const filter = useSelector(selectFilter);

  const handleSetGollacParams = () => dispatch(setGollacParams(_gollacParams));
  // const handleSetGollacParams = () =>
  //   useGetGollacQuery("gollacParams.gollacFilterName");

  // GollacParams
  const [_gollacParams, _setGollacParams] = useState<RpsSliceState>({
    gollacFilterName: gollacParams.gollacFilterName,
    gollacManagerAttributeName: gollacParams.gollacManagerAttributeName,
    ceilsGollacMinValue: gollacParams.ceilsGollacMinValue,
    ceilsGollacMaxValue: gollacParams.ceilsGollacMaxValue,
  });

  // Function to validate and adjust the value
  const validateAndAdjustValue = (
    value: string,
    referenceValue: number,
    isMinValue: boolean
  ): number => {
    let parsedValue = parseFloat(value);

    // Limiter la valeur à 10
    if (parsedValue > 10) {
      parsedValue = 10;
    }

    // Pour minValue: s'assurer qu'elle n'excède pas maxValue - 0.1
    if (isMinValue && parsedValue > referenceValue - 0.1) {
      parsedValue = referenceValue - 0.1;
    }

    // Pour maxValue: s'assurer qu'elle n'est pas inférieure à minValue + 0.1
    if (!isMinValue && parsedValue < referenceValue + 0.1) {
      parsedValue = referenceValue + 0.1;
    }

    // Forcer un seul chiffre après la virgule
    return Math.round(parsedValue * 10) / 10;
  };

  const handleBlurGollacParams = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: string
  ) => {
    let value = event.target.value;

    // Si l'utilisateur laisse le champ vide ou efface la valeur
    if (isNaN(parseFloat(value)) || value === "") {
      if (type === "ceilsGollacMinValue") {
        // Si c'est minValue, utilise une valeur par défaut raisonnable
        value = (_gollacParams.ceilsGollacMaxValue! - 0.1).toString();
      } else if (type === "ceilsGollacMaxValue") {
        // Si c'est maxValue, utilise minValue + 0.1
        value = (_gollacParams.ceilsGollacMinValue! + 0.1).toString();
      }
    } else {
      // Ajuster la valeur en fonction du type (min ou max)
      if (type === "ceilsGollacMinValue") {
        value = validateAndAdjustValue(
          value,
          _gollacParams.ceilsGollacMaxValue!,
          true // C'est une minValue
        ).toString();
      } else if (type === "ceilsGollacMaxValue") {
        value = validateAndAdjustValue(
          value,
          _gollacParams.ceilsGollacMinValue!,
          false // C'est une maxValue
        ).toString();
      }
    }

    // Appliquer la nouvelle valeur après le blur
    handleChangeGollacParams(
      { ...event, target: { ...event.target, value } },
      type
    );
  };

  type GollacEvent =
    | React.ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
      >
    | SelectChangeEvent<string | null>;

  // Ensuite, modifie les appels de la fonction pour différencier minValue et maxValue :
  const handleChangeGollacParams = (event: GollacEvent, type: string) => {
    const updatedParams = {
      gollacFilterName: _gollacParams.gollacFilterName,
      gollacManagerAttributeName: _gollacParams.gollacManagerAttributeName,
      ceilsGollacMinValue: _gollacParams.ceilsGollacMinValue,
      ceilsGollacMaxValue: _gollacParams.ceilsGollacMaxValue,
    };

    if (type === "gollacFilterName") {
      updatedParams.gollacFilterName = event.target.value;
    }
    if (type === "gollacManagerAttributeName") {
      updatedParams.gollacManagerAttributeName = event.target.value;
    }
    if (type === "ceilsGollacMinValue") {
      updatedParams.ceilsGollacMinValue = validateAndAdjustValue(
        event.target.value!,
        _gollacParams.ceilsGollacMaxValue!,
        true
      );
    }
    if (type === "ceilsGollacMaxValue") {
      updatedParams.ceilsGollacMaxValue = validateAndAdjustValue(
        event.target.value!,
        _gollacParams.ceilsGollacMinValue!,
        false
      );
    }

    _setGollacParams(updatedParams);
  };

  // async function editReferenceGollacParams(
  //   type: keyof typeof gollacParams, // Utilise les clés de gollacParams
  //   value: string | number // Autorise uniquement les chaînes ou les nombres
  // ) {

  // store.dispatch(filterStatus(STATUS_SAVING));

  // const response: any = await store.dispatch(
  //   accountUpdateOptions({
  //     account.options.accountOptions,
  //     // account.options.accountOptions,
  //     [type]: value,
  //   })
  // );

  // if (response.error) {
  //   store.dispatch(filterStatus(STATUS_SAVE_ERROR));
  // } else {
  //   store.dispatch(sessionEditAccountOptions([type], value));
  //   store.dispatch(filterStatus(STATUS_SAVED));
  // }
  // }

  //   useEffect(() => {
  //     const keysToEdit: (keyof typeof gollacParams)[] = [
  //       "gollacFilterName",
  //       "gollacManagerAttributeName",
  //       "ceilsGollacMinValue",
  //       "ceilsGollacMaxValue",
  //     ];

  //     keysToEdit.forEach((key) => {
  //       const value = gollacParams[key];
  //       // Vérifie si la valeur est définie et de type string ou number
  //       if (
  //         value !== undefined &&
  //         (typeof value === "string" || typeof value === "number")
  //       ) {
  //         editReferenceGollacParams(key, value);
  //       }
  //     });
  //   }, [
  //     gollacParams.gollacFilterName,
  //     gollacParams.gollacManagerAttributeName,
  //     gollacParams.ceilsGollacMinValue,
  //     gollacParams.ceilsGollacMaxValue,
  //   ]);

  return (
    <>
      <div style={{ paddingLeft: 30 }}>
        <b>{t("filters_custom")}</b>
      </div>

      <div className="flex height-20" />

      <Typography sx={{ pl: 4 }} variant="h6" component="h1">
        {t("golac_params_title")}
      </Typography>

      <Box sx={{ display: "flex", p: 3, alignItems: "center" }}>
        <FormControl sx={{ m: 1, minWidth: 140 }}>
          <InputLabel id="gollac-filter-select-label">
            {t("filter_reference_manager_gollac")}
          </InputLabel>
          <Select
            labelId="gollac-filter-name-label"
            id="gollac-filter-name-select-id"
            value={_gollacParams.gollacFilterName ?? ""}
            label={t("filter_reference_manager_gollac")}
            onChange={(e) => handleChangeGollacParams(e, "gollacFilterName")}
          >
            {filter.list.map((filter: Filter) => (
              <MenuItem key={filter.name} value={filter.name}>
                {filter.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {_gollacParams.gollacFilterName ? (
          <FormControl sx={{ m: 1, minWidth: 150 }}>
            <InputLabel id="gollac-manager-attribute-name-select-label">
              {t("filter_reference_manager_gollac")}
            </InputLabel>
            <Select
              labelId="gollac-manager-attribute-name-select-label"
              id="gollac-manager-attribute-name-select-id"
              value={_gollacParams.gollacManagerAttributeName ?? ""}
              label={t("filter_reference_manager_gollac")}
              onChange={(e: SelectChangeEvent) =>
                handleChangeGollacParams(e, "gollacManagerAttributeName")
              }
            >
              {filter.list
                .find((f: Filter) => f.name === _gollacParams.gollacFilterName)
                ?.populations.map((pop: FilterPopulation) => (
                  <MenuItem key={pop.name} value={pop.name}>
                    {pop.name}
                  </MenuItem>
                )) || []}
            </Select>
          </FormControl>
        ) : null}

        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <TextField
            type="number"
            id="gollac-ceil-min-value"
            label={t("golac_step_neglect")}
            variant="outlined"
            value={_gollacParams.ceilsGollacMinValue}
            slotProps={{
              htmlInput: {
                step: 0.1,
                min: 0,
                max: _gollacParams.ceilsGollacMaxValue! - 0.1,
              },
            }}
            sx={{ width: 120 }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const adjustedValue = validateAndAdjustValue(
                e.target.value,
                _gollacParams.ceilsGollacMaxValue!,
                true
              );
              handleChangeGollacParams(
                {
                  ...e,
                  target: {
                    ...e.target,
                    value: adjustedValue.toString(),
                  },
                },
                "ceilsGollacMinValue"
              );
            }}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
              handleBlurGollacParams(e, "ceilsGollacMinValue")
            }
          />
        </FormControl>

        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <TextField
            type="number"
            id="gollac-ceil-max-value"
            label={t("golac_step_critical")}
            variant="outlined"
            value={_gollacParams.ceilsGollacMaxValue}
            slotProps={{
              htmlInput: {
                step: 0.1,
                min: _gollacParams.ceilsGollacMinValue! + 0.1,
                max: 10,
              },
            }}
            sx={{ width: 120 }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const adjustedValue = validateAndAdjustValue(
                e.target.value,
                _gollacParams.ceilsGollacMinValue!,
                false
              );
              handleChangeGollacParams(
                {
                  ...e,
                  target: {
                    ...e.target,
                    value: adjustedValue.toString(),
                  },
                },
                "ceilsGollacMaxValue"
              );
            }}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
              handleBlurGollacParams(e, "ceilsGollacMaxValue")
            }
          />
        </FormControl>

        <Stack
          sx={{
            ml: "auto",
            mr: "auto",
            // display: "flex",
            flex: 1,
            justifyContent: "space-around",
          }}
          direction="row"
          spacing={1}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Chip
              sx={{ width: 150 }}
              label={`${t("golac_step_neglect")} < ${
                _gollacParams.ceilsGollacMinValue
              }`}
              color="success"
            />
            <WbSunnyIcon color="success" sx={{ ml: 1 }} />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Chip
              sx={{ width: 150 }}
              label={`${_gollacParams.ceilsGollacMinValue} < ${t(
                "golac_step_moderate"
              )} < ${_gollacParams.ceilsGollacMaxValue}`}
              color="warning"
            />
            <WbCloudyIcon color="warning" sx={{ ml: 1 }} />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Chip
              sx={{ width: 150 }}
              label={`${_gollacParams.ceilsGollacMaxValue} > ${t(
                "golac_step_critical"
              )}`}
              color="error"
            />
            <ThunderstormIcon color="error" sx={{ ml: 1 }} />
          </Box>
        </Stack>

        <Button
          onClick={handleSetGollacParams}
          sx={{ ml: "auto" }}
          variant="contained"
          color="success"
        >
          {t("utils_save")}
        </Button>
      </Box>
    </>
  );
};
