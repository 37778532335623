/**
 * ADM-EDIT-CHILDREN.MODAL
 * Edit admin for children account as superadmin
 */

import Modal from "@/components/modal";
import { connect } from "react-redux";
import { withTranslation, WithTranslation } from "react-i18next";
import { User, UserState } from "@/redux/user.types";
import { useState } from "react";
import { store } from "@/index";
// import store from "@/core/store";
import {
  userCheckEmail,
  userDestroy,
  userGet,
  userInsert_AsSuperadmin,
  userRemove,
  userStatus,
  userUpdateOptions,
  userUpdate_AsSuperadmin,
} from "@/redux/user.actions";
import TextInput from "@/components/text-input";
import { validateEmail } from "@/utils/validate-email.utils";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";
import {
  STATUS_SAVED,
  STATUS_SAVE_ERROR,
  STATUS_SAVING,
} from "@/redux/_status.types";
import { Session } from "@/redux/_session.types";
import { AccountState } from "@/redux/account.types";
import { accountEdit } from "@/redux/account.actions";
import ModalConfirm from "@/components/modal-confirm";

interface StateProps extends WithTranslation {
  _session: Session;
  account: AccountState;
  user: UserState;
}

interface OwnProps {
  onClose?: Function;
  isNew: boolean;
}

type Props = StateProps & OwnProps;

const MODAL_ADMIN_DELETE: string = "MODAL_ADMIN_DELETE";

function AdmEditChildrenModal(props: Props) {
  const { t } = props;

  const [adminId] = useState<string>(
    props.isNew ? uuid() : props.user.active.id
  );

  const [currentModal, setCurrentModal] = useState<string | null>(null);
  const [emailError, setEmailError] = useState<string>(
    props.isNew ? "utils_email_empty" : "OK"
  );

  /**INPUTS */
  const [email, setEmail] = useState<string>(
    !props.isNew && props.user.active.email ? props.user.active.email : ""
  );
  const [firstname, setFirstname] = useState<string>(
    !props.isNew && props.user.active.firstname.length > 0
      ? props.user.active.firstname
      : ""
  );
  const [lastname, setLastname] = useState<string>(
    !props.isNew && props.user.active.lastname.length > 0
      ? props.user.active.lastname
      : ""
  );

  function changeEmail(email: string) {
    setEmail(email);
    checkEmailFormat(email);
  }

  async function checkEmailAvailability() {
    if (email !== props.user.active.email) {
      if (emailError === "") {
        const response: any = await store.dispatch(
          userCheckEmail(email, props.user.active.id)
        );
        if (response.available) {
          setEmailError("OK");
        } else {
          setEmailError("utils_email_already_exist");
        }
      }
    } else {
      setEmailError("OK");
    }
  }

  function checkEmailFormat(email: string) {
    if (email.length > 0) {
      if (validateEmail(email)) {
        setEmailError("");
      } else {
        setEmailError("utils_email_invalid");
      }
    } else {
      setEmailError("utils_email_empty");
    }
  }

  //close modal
  function close() {
    if (props.onClose) {
      props.onClose();
    }
  }

  function getTitle() {
    const addOrEdit: string = props.isNew ? "_add" : "_edit";
    return t("user_admin" + addOrEdit) + " (" + props.account.active.name + ")";
  }

  async function next() {
    if (emailError === "") {
      checkEmailAvailability();
    } else if (emailError === "OK") {
      saveAdmin();
    } else {
      store.dispatch(userStatus(STATUS_SAVE_ERROR));
      toast(t(emailError), { type: "error" });
    }
  }

  //Confirm removing the active user
  async function removeAdmin() {
    store.dispatch(userStatus(STATUS_SAVING));
    const response: any = await store.dispatch(
      userDestroy(props.user.active.id)
    );

    if (response.error) {
      store.dispatch(userStatus(STATUS_SAVE_ERROR));
    } else {
      store.dispatch(userStatus(STATUS_SAVED));
      store.dispatch(userRemove(props.user.active.id));

      const admins: User[] = props.account.active.Users.filter(
        (x: User) => x.id !== props.user.active.id
      );
      store.dispatch(accountEdit("Users", admins));

      close();
    }
  }

  //Save active user
  async function saveAdmin() {
    store.dispatch(userStatus(STATUS_SAVING));

    const adminToSave: User = props.isNew
      ? new User({
          id: adminId,
          role: "ADMIN",
          AccountId: props.account.active.id,
        })
      : props.user.active;

    adminToSave.firstname = firstname;
    adminToSave.lastname = lastname;
    adminToSave.email = email;

    let response: any = null;
    if (props.isNew) {
      response = await store.dispatch(userInsert_AsSuperadmin(adminToSave));
    } else {
      response = await store.dispatch(userUpdate_AsSuperadmin(adminToSave));
    }

    if (response.AccountId === adminToSave.AccountId) {
      const imageUpdate: any = await store.dispatch(
        userUpdateOptions(adminId, adminToSave.options)
      );

      if (imageUpdate !== adminId) {
        if (imageUpdate.error) {
          toast(t(imageUpdate.error), { type: "error" });
        } else {
          toast(t("couldnt_update_user_image"), { type: "error" });
        }
      }

      const admins: User[] = props.isNew
        ? [...props.user.list, adminToSave]
        : props.user.list.map((x: User) =>
            x.id === adminToSave.id ? adminToSave : x
          );

      //update store
      store.dispatch(userGet(admins));

      //if adding/editing admins from another account, update this account's admins list too
      store.dispatch(accountEdit("Users", admins));

      store.dispatch(userStatus(STATUS_SAVED));
      toast(t("utils_saved"));
      close();
    } else {
      store.dispatch(userStatus(STATUS_SAVE_ERROR));
      if (response.error) {
        toast(t(response.error), { type: "error" });
      } else {
        close();
      }
    }
  }

  return (
    <Modal
      isCloseButtonVisible
      status={props.user.status}
      onNext={next}
      onDelete={
        props.isNew ? undefined : () => setCurrentModal(MODAL_ADMIN_DELETE)
      }
      onClose={close}
      disableKeyEvents
      title={getTitle()}
    >
      {currentModal === MODAL_ADMIN_DELETE && (
        <ModalConfirm
          onNo={() => setCurrentModal(null)}
          onYes={removeAdmin}
          status={props.user.status}
          text={t("user_ask_delete", {
            user: props.user.active.email,
          })}
          textBold={t("utils_next_ask")}
        />
      )}

      <div>
        <div className="flex">
          <TextInput
            isFullWidth
            status={props.user.status}
            onChange={(e: any) => setFirstname(e.value)}
            title={t("user_firstname")}
            value={firstname}
          />

          <TextInput
            isFullWidth
            status={props.user.status}
            onChange={(e: any) => setLastname(e.value)}
            title={t("user_lastname")}
            value={lastname}
          />
        </div>

        <div className="flex">
          <div>
            <TextInput
              status={props.user.status}
              onChange={(e: any) => changeEmail(e.value)}
              isCheck={emailError === "OK"}
              onBlur={checkEmailAvailability}
              error={emailError.length > 0 && emailError !== "OK"}
              title={t("user_email")}
              value={email}
            />

            {emailError !== "OK" && (
              <div className="red-t">{t(emailError)}</div>
            )}
          </div>
        </div>

        <div className="height-20" />
      </div>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  _session: state._session,
  account: state.account,
  user: state.user,
});

export default connect(mapStateToProps)(
  withTranslation()(AdmEditChildrenModal)
);
