/**
 * TASKS.EDIT
 * Edit an action plan
 */
import {
  faArchive,
  faCog,
  faCopy,
  faEnvelope,
  faPaperPlane,
  faPlay,
  faStop,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import ModalConfirm from "@/components/modal-confirm";
import env from "@/env";
import { store } from "@/index";
// import store from "@/core/store";
import TasksArchiveModal from "@/modals/tasks-archive.modal";
import TasksCloseModal from "@/modals/tasks-close.modal";
import TasksDeleteModal from "@/modals/tasks-delete.modal";
import TasksLaunchModal from "@/modals/tasks-launch.modal";
import TasksOptionsModal from "@/modals/tasks-options.modal";
import TasksRelaunchModal from "@/modals/tasks-relaunch.modal";
import NavFocus from "@/nav/nav.focus";
import { useEffect, useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Session } from "@/redux/_session.types";
import { actionPlanSend, actionPlanUpdate } from "@/redux/action-plan.actions";
import { ActionPlanState } from "@/redux/action-plan.types";
import { questionActivate } from "@/redux/question.actions";
import { Question, QuestionState } from "@/redux/question.types";
import { UserState } from "@/redux/user.types";
import TasksEditWidget from "@/widgets/tasks-edit.widget";
import HelpModal from "@/components/modal-help";
import QuestionTipsWidget from "@/widgets/question-tips.widget";

interface StateProps extends WithTranslation {
  _session: Session;
  actionPlan: ActionPlanState;
  question: QuestionState;
  user: UserState;
}

const ACTION_ARCHIVE = "ACTION_ARCHIVE";
const ACTION_CLOSE = "ACTION_CLOSE";
const ACTION_COPY = "ACTION_COPY";
const ACTION_DELETE = "ACTION_DELETE";
const ACTION_EDIT_OPTIONS = "ACTION_EDIT_OPTIONS";
const ACTION_LAUNCH = "ACTION_LAUNCH";
const ACTION_RELAUNCH = "ACTION_RELAUNCH";
const ACTION_SEND = "ACTION_SEND";

const MODAL_ARCHIVE = "MODAL_ARCHIVE";
const MODAL_CLOSE = "MODAL_CLOSE";
const MODAL_DELETE = "MODAL_DELETE";
const MODAL_EDIT_OPTIONS = "MODAL_EDIT_OPTIONS";
const MODAL_LAUNCH = "MODAL_LAUNCH";
const MODAL_RELAUNCH = "MODAL_RELAUNCH";
const MODAL_SEND = "MODAL_SEND";

function TasksEditRoute(props: StateProps) {
  const { t } = props;
  const navigate: NavigateFunction = useNavigate();

  //Actions of the menu top (depending of the status)
  const [actions, setActions] = useState<any[]>([]);

  //Display modal
  const [currentModal, setCurrentModal] = useState<string | null>(null);

  //Reset active question in store
  //to avoid ai assistant popping onLoad
  useEffect(() => {
    store.dispatch(questionActivate(new Question()));
  }, []);

  //Update actions
  useEffect(() => {
    const items: any[] = [
      {
        id: ACTION_EDIT_OPTIONS,
        label: t("utils_advanced_options"),
        icon: faCog,
      },
      { id: ACTION_DELETE, label: t("tasks_delete"), icon: faTrash },
    ];

    if (props.actionPlan.active.status === "active") {
      items.push({
        id: ACTION_COPY,
        icon: faCopy,
        label: t("utils_copy_link"),
      });

      items.push({
        id: ACTION_SEND,
        icon: faEnvelope,
        label: t("tasks_send"),
      });

      items.push({
        id: ACTION_CLOSE,
        icon: faStop,
        label: t("tasks_close"),
        isLarge: true,
      });
    } else if (props.actionPlan.active.status === "closed") {
      items.push({
        id: ACTION_RELAUNCH,
        icon: faPlay,
        label: t("tasks_relaunch"),
      });

      items.push({
        id: ACTION_ARCHIVE,
        icon: faArchive,
        label: t("tasks_archive"),
        isLarge: true,
      });
    } else {
      items.push({
        id: ACTION_LAUNCH,
        icon: faPaperPlane,
        label: t("tasks_launch"),
        className:
          props.actionPlan.active.tasks.length > 0 ? "primary" : "secondary",
        isLarge: true,
      });
    }

    setActions(items);
  }, [t, props.actionPlan.active.status, props.actionPlan.active.tasks.length]);

  //Click on one the button at the top
  function clickAction(action) {
    switch (action) {
      case ACTION_ARCHIVE:
        return setCurrentModal(MODAL_ARCHIVE);
      case ACTION_CLOSE:
        return setCurrentModal(MODAL_CLOSE);
      case ACTION_COPY: {
        const link =
          env.REACT_APP_URL_OBSERVER +
          "/login/mail?id=" +
          props.user.active.id +
          "&redirection=tasks";
        navigator.clipboard.writeText(link);
        return toast(t("utils_copy_link_done"));
      }
      case ACTION_DELETE:
        return setCurrentModal(MODAL_DELETE);
      case ACTION_EDIT_OPTIONS:
        return setCurrentModal(MODAL_EDIT_OPTIONS);
      case ACTION_LAUNCH:
        //Save before open (if i want to send an action plan without editing)
        store.dispatch(actionPlanUpdate(props.actionPlan.active));
        return setCurrentModal(MODAL_LAUNCH);
      case ACTION_RELAUNCH:
        return setCurrentModal(MODAL_RELAUNCH);
      case ACTION_SEND:
        return setCurrentModal(MODAL_SEND);
      default:
        return alert(action + " : à venir");
    }
  }

  //Send invitation email
  function send() {
    const response: any = store.dispatch(
      actionPlanSend(props.actionPlan.active.id, props._session.userName)
    );
    if (!response.error) {
      setCurrentModal(null);
      toast(t("utils_email_send", { email: props.user.active.email }));
    }
  }

  return (
    <NavFocus
      onBack={() => navigate("/tasks/home")}
      status={props.actionPlan.status}
      onAction={clickAction}
      actions={actions}
    >
      {
        /*Owl assistant giving advices thanks to gpt*/
        props.question.active.id.length > 0 && (
          <HelpModal
            title={`Conseils sur la question « ${props.question.active.label} »`}
            onClose={() => store.dispatch(questionActivate(new Question()))}
            isLeft
          >
            <QuestionTipsWidget question={props.question.active} />
          </HelpModal>
        )
      }

      {currentModal === MODAL_ARCHIVE && (
        <TasksArchiveModal onClose={() => setCurrentModal(null)} />
      )}

      {currentModal === MODAL_CLOSE && (
        <TasksCloseModal onClose={() => setCurrentModal(null)} />
      )}

      {currentModal === MODAL_DELETE && (
        <TasksDeleteModal onClose={() => setCurrentModal(null)} />
      )}

      {currentModal === MODAL_EDIT_OPTIONS && (
        <TasksOptionsModal onClose={() => setCurrentModal(null)} />
      )}

      {currentModal === MODAL_LAUNCH && (
        <TasksLaunchModal onClose={() => setCurrentModal(null)} />
      )}

      {currentModal === MODAL_RELAUNCH && (
        <TasksRelaunchModal onClose={() => setCurrentModal(null)} />
      )}

      {currentModal === MODAL_SEND && (
        <ModalConfirm
          onNo={() => setCurrentModal(null)}
          onYes={send}
          textBold={t("tasks_launch_text_1", {
            name: props.user.active.username,
            email: props.user.active.email,
          })}
        />
      )}

      <TasksEditWidget />
    </NavFocus>
  );
}

const mapStateToProps = (state) => ({
  _session: state._session,
  actionPlan: state.actionPlan,
  question: state.question,
  user: state.user,
});

export default connect(mapStateToProps)(withTranslation()(TasksEditRoute));
