/**
 * AXIS.ACTIONS
 * Actions for axis
 */

import { Axis, AXIS_ACTIVATE, AXIS_ADD, AXIS_COPY, AXIS_EDIT, AXIS_EDIT_NAME, AXIS_GET, AXIS_INIT, AXIS_OPEN, AXIS_REMOVE, AXIS_STATUS, AXIS_SWAP } from "./axis.types";
import { templateEditAndUpdate } from "./template.actions";
import { STATUS_LOADED, STATUS_LOADING, STATUS_LOAD_ERROR } from "./_status.types";

export const axisActivate = (axis:Axis) => ({
  type : AXIS_ACTIVATE,
  payload : {
    axis
  }
})

export const axisAdd = (language) => ({
  type : AXIS_ADD,
  payload : {
    language
  }
})

export const axisCopy = () => ({
  type : AXIS_COPY
})


export const axisEdit = (key:string, value:any) => ({
  type : AXIS_EDIT,
  payload : {
    key,
    value
  }
})

export const axisEditName = (language, value) => ({
  type : AXIS_EDIT_NAME,
  payload : {
    language,
    value
  }
})

export const axisGet = (axes:Axis[], axesOrder) => ({
  type : AXIS_GET,
  payload : {
    axes,
    axesOrder
  }
})

export const axisInit = () => ({
  type : AXIS_INIT
})

export const axisOpen = id => ({
  type : AXIS_OPEN,
  payload : {
    id
  }
})

export const axisRemove = (id:string) => ({
  type : AXIS_REMOVE,
  payload : {
    id
  }
})

export const axisStatus = (status:string) => ({
  type : AXIS_STATUS,
  payload : {
    status
  }
})

export const axisSwap = (sourceId, destinationId) => ({
  type : AXIS_SWAP,
  payload : {
    sourceId,
    destinationId
  }
})



// API


export const axisDestroy = (id:string) => ({
  type : 'API',
  payload : {
    method : "DELETE",
    url : "/axis/" + id
  }
})

export const axisDestroy_AsSupervisor = (id:string) => ({
  type : 'API',
  payload : {
    method : "DELETE",
    url : "/supervisor/axis/" + id
  }
})

export const axisFetch = (templateId, surveyId) => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/axes",
    data : {
      templateId,
      surveyId
    }
  }
})

export const axisFetchPublic = (templateId, surveyId) => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/public/axes",
    data : {
      templateId,
      surveyId
    }
  }
})

export const axisFetchOne = (axisId) => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/public/axis",
    data : {
      axisId
    }
  }
})

export const axisFetch_AsSupervisor = (templateId, surveyId) => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/supervisor/axes",
    data : {
      templateId,
      surveyId
    }
  }
})

export const axisUpdate = (axis:Axis, templateId:string) => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/axis",
    data : {
      axis,
      templateId
    }
  }
})

export const axisUpdateMultiple = (axes: Axis[], templateId: string) => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/axes",
    data : {
      axes,
      templateId
    }
  }
})

export const axisUpdate_AsSupervisor = (axis:Axis, templateId:string) => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/supervisor/axis",
    data : {
      axis,
      templateId
    }
  }
})


// THUNK

export const axisFetchAndGet:any = (axesOrder, surveyId) => async (dispatch, getState) => {
  dispatch(axisStatus(STATUS_LOADING))
  
  let loadFunction:Function
  if (getState()._session.interfaceType === "SUPERVISOR"){
    loadFunction = axisFetch_AsSupervisor
  }else if (getState()._session.authLevel > 0){
    loadFunction = axisFetch
  }else{
    loadFunction = axisFetchPublic
  }

  const response = await dispatch(loadFunction(getState().template.active.id, surveyId))
  dispatch(axisGet(response.error ? [] : response, axesOrder))
  dispatch(axisStatus(response.error ? STATUS_LOAD_ERROR : STATUS_LOADED))

}

export const axisSwapAndUpdateTemplate:any = (sourceId, destinationId) => async (dispatch, getState) => {
  dispatch(axisSwap(sourceId, destinationId))
  dispatch(templateEditAndUpdate("axesOrder", getState().axis.list.map(x => x.id)))
}