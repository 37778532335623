/**
 * PROJECT-EMAILS.WIDGET
 * Edit emails for the account
 */

import { connect } from "react-redux";
import { withTranslation, WithTranslation } from "react-i18next";
import { Session } from "@/redux/_session.types";
import ProjectEmailListWidget from "./project-email-list.widget";
import ConfirmButtons from "@/components/confirm-buttons";
import { Email, EmailContent, EmailState } from "@/redux/email.types";
import { ProjectState } from "@/redux/project.types";
import { store } from "@/index";
// import store from "@/core/store";
import {
  emailDestroy,
  emailEdit,
  emailGet,
  emailSetIsCheckedAndUpdate,
  emailUpdate,
} from "@/redux/email.actions";
import { projectCheckEmails, projectStatus } from "@/redux/project.actions";
import { STATUS_SAVED, STATUS_SAVING } from "@/redux/_status.types";
import ProjectEmailContentWidget from "./project-email-content.widget";
import { Language } from "@/redux/language.types";
import { useEffect, useState } from "react";
import LanguageSelection from "@/components/language-selection";
import Space from "@/components/space";
import ContextMenu from "@/components/context-menu";
import TranslatorWidget from "./translator.widget";
import ListItem from "@/components/list-item";
import ProjectStepErrorsWidget from "./project-step-errors.widget";
import Checkbox from "@/components/checkbox";
import ProjectEmailsSkinWidget from "./project-emails-skin.widget";

interface StateProps extends WithTranslation {
  _session: Session;
  email: EmailState;
  project: ProjectState;
}

interface OwnProps {
  onEdit?: Function;
  onNext?: Function;
}

type Props = StateProps & OwnProps;

const CONTEXT_AUTO_TRANSLATION: string = "CONTEXT_AUTO_TRANSLATION";

function ProjectEmailsWidget(props: Props) {
  const { t } = props;

  //Language displayed
  const [currentLanguage, setCurrentLanguage] = useState<string>(
    props.project.active.languages[0]
  );

  const [currentContext, setCurrentContext] = useState<string | null>(null);

  //Detect if email are added or removed
  useEffect(() => {
    store.dispatch(
      projectCheckEmails(props.project.active.languages, props.email.list)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.email.list.length]);

  async function cancel() {
    for (let i = 0; i < props.email.list.length; i++) {
      const email = props.email.list[i];
      await store.dispatch(emailDestroy(email.id));
    }

    //Add emails from template
    const email = new Email();
    const emails: Email[] = [
      email.getFromMessage(
        t("email_template_0"),
        props.project.active.messageOptions,
        props._session.accountName
      ),
    ].concat(
      email
        .getList(
          props._session.accountName,
          props.project.active.name,
          props.project.active.Template
            .label /*.length > 0 ? props.project.active.Template.label : t("template_tag_qvt")*/
        )
        .slice(1)
    );

    //Update in database (emails)
    for (let i = 0; i < emails.length; i++) {
      await store.dispatch(emailUpdate(props.project.active.id, emails[i]));
    }

    //Activate project
    store.dispatch(
      emailGet(
        emails,
        emails.map((x) => x.id)
      )
    );

    next(false, false);
  }

  async function edit() {
    if (props.onEdit) {
      props.onEdit();
    }
  }

  function next(isVisited: boolean, isConfirmed: boolean) {
    if (props.onNext) {
      props.onNext(isVisited, isConfirmed);
    }
  }

  //Save sending information to the server
  async function saveEmail() {
    await setTimeout(() => {}, 200);
    store.dispatch(projectStatus(STATUS_SAVING));
    await store.dispatch(
      emailUpdate(props.project.active.id, props.email.active)
    );
    store.dispatch(projectStatus(STATUS_SAVED));
    store.dispatch(
      projectCheckEmails(props.project.active.languages, props.email.list)
    );
  }

  //Do the translation
  function translate(texts: string[], languageId: string) {
    if (texts.length) {
      const emailContent = Object.assign({}, props.email.active.content);
      emailContent.subject[languageId] = texts[0];
      emailContent.title[languageId] = texts[1];
      emailContent.body[languageId] = texts[2];
      emailContent.cta[languageId] = texts[3];
      emailContent.info[languageId] = texts[4];
      emailContent.footer[languageId] = texts[5];
      store.dispatch(emailEdit("content", new EmailContent(emailContent)));
      saveEmail();
      setCurrentContext(null);
    }
  }

  async function uncheckAll() {
    store.dispatch(emailSetIsCheckedAndUpdate());
  }

  return (
    <div>
      <ListItem>
        <div className="setup-card-help">
          <p>{t("project_emails_help")}</p>
          <ul
            className="grey-t setup-card-help-content"
            style={{ maxWidth: "750px" }}
          >
            <li>{t("project_emails_help_1")}</li>
            <li>{t("project_emails_help_2")}</li>
            <li className="red-t">
              <b>{t("project_emails_help_3")}</b>
            </li>
          </ul>
        </div>
      </ListItem>

      <ListItem>
        <ProjectEmailsSkinWidget />
      </ListItem>

      {props.project.active.languages.length > 1 && (
        <ListItem>
          <div>
            <p className="grey-t">{t("utils_translations")}</p>

            <LanguageSelection
              languages={props.project.active.languages}
              activeLang={currentLanguage}
              onClick={(language: Language) =>
                setCurrentLanguage(language.code)
              }
            />
          </div>

          <Space />

          <div className="flex flex-dcol">
            <Space />

            <u
              className="_hover grey-t"
              onClick={() => setCurrentContext(CONTEXT_AUTO_TRANSLATION)}
            >
              {t("auto_translate")}
            </u>

            <div className="rel">
              {currentContext === CONTEXT_AUTO_TRANSLATION && (
                <ContextMenu
                  positionRight
                  onClose={() => setCurrentContext(null)}
                >
                  <div style={{ padding: "10px 20px" }}>
                    <p>{t("auto_translate")}</p>

                    <TranslatorWidget
                      targetLang={currentLanguage}
                      onTranslate={(texts: string[], language: Language) =>
                        translate(texts, language.code)
                      }
                      languages={props.project.active.languages}
                      sourceTexts={[
                        props.email.active.content.subject,
                        props.email.active.content.title,
                        props.email.active.content.body,
                        props.email.active.content.cta,
                        props.email.active.content.info,
                        props.email.active.content.footer,
                      ]}
                      onTargetChange={(lang: Language) =>
                        setCurrentLanguage(lang.code)
                      }
                    />
                  </div>
                </ContextMenu>
              )}
            </div>
          </div>
        </ListItem>
      )}

      <div className="flex">
        <ProjectEmailListWidget onEdit={edit} />

        {props.email.active.id.length > 0 && (
          <ProjectEmailContentWidget
            isEditable
            currentLanguage={currentLanguage}
            onBlur={saveEmail}
            skin={props.project.active.emailSkin}
          />
        )}
      </div>

      <ListItem>
        {props.email.list.some((email: Email) => !email.isChecked) && (
          <Checkbox
            active={props.email.list.every((email: Email) => email.isChecked)}
            onClick={uncheckAll}
            text={t("email_check_all")}
            status={props.project.status}
          />
        )}

        <Space />
      </ListItem>

      <ProjectStepErrorsWidget
        alert={props.project.active.alerts.emails}
        step="emails"
      />

      {props.onNext && (
        <ConfirmButtons
          onCancel={() => cancel()}
          onConfirm={() => next(true, true)}
          onConfirmLater={() => next(true, false)}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  _session: state._session,
  email: state.email,
  project: state.project,
});

export default connect(mapStateToProps)(withTranslation()(ProjectEmailsWidget));
