/**
 * DASHBOARD-RPS-TEAMS.WIDGET
 * Repartition of users for a survey
 */
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import Card from "@/components/card";
import { useEffect, useState } from "react";
import {
  DashboardFilters,
  Filter,
  FilterLite,
  FilterState,
} from "@/redux/filter.types";
import { store } from "@/index";
// import store from "@/core/store";
import {
  INSUFFICIENT_PARTICIPATION,
  STATUS_LOADED,
  STATUS_LOADING,
} from "@/redux/_status.types";
import { fetchRpsTeams } from "@/redux/_rps.actions";
import Link from "@/components/link";
import Plot from "react-plotly.js";
import Dropdown from "@/components/dropdown";
import Space from "@/components/space";
import { sortBy } from "lodash";
import NoResultsWidget from "./no-results.widget";
import { filterReplaceDashboard } from "@/redux/filter.actions";
import { Session } from "@/redux/_session.types";
import { NavigateFunction, useNavigate } from "react-router-dom";

interface StateProps {
  _session: Session;
  filter: FilterState;
}

interface OwnProps {
  filterName?: string;
  isReadOnly?: boolean;
}

type Props = StateProps & OwnProps & WithTranslation;

function DashboardRpsTeamsWidget(props: Props) {
  const { t } = props;
  const navigate: NavigateFunction = useNavigate();

  const layout: any = {
    margin: {
      l: 0,
      r: 0,
      b: 0,
      t: 0,
    },
    showlegend: false,
    bargap: 0.1,
    barmode: "stack",
    xaxis: {
      showgrid: false,
      showline: false,
    },
    yaxis: {
      margin: { l: 5 },
      showgrid: false,
      showline: false,
    },
  };

  //State
  // Init filterName (first element of the list)
  // status : loader
  // teams : list of attributes on the left of the graph
  const [currentFilter, setCurrentFilter] = useState<Filter>(
    initCurrentFilter()
  );
  const [status, setStatus] = useState(STATUS_LOADING);
  const [teams, setTeams] = useState<any>([]);
  const [noData, setNoData] = useState(false);
  const [data, setData] = useState<Plotly.Data[]>([]);

  useEffect(() => {
    setCurrentFilter(initCurrentFilter());
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filterName]);

  //Load data on every change on filters and filterName
  useEffect(() => {
    async function load() {
      //Load data from server
      if (currentFilter) {
        setStatus(STATUS_LOADING);
        const data: any = await store.dispatch(
          fetchRpsTeams(currentFilter.name, new DashboardFilters())
        );
        setStatus(STATUS_LOADED);

        if (data.message === "no_data") {
          setNoData(true);
        } else if (!data.error) {
          const rpsColors: any = {
            isoStrain: "#eb5a46",
            jobStrain: "#ff9500",
            active: "#8c8c8c",
            passive: "#afafaf",
            relaxed: "#ccc",
          };

          const traceIsoStrain: Plotly.Data = {
            x: data.teamsSeries.iso_strain,
            y: data.teamsSeries.attribute_name,
            name: t("rps_stressed"),
            text: data.teamsSeries.iso_strain,
            orientation: "h",
            marker: {
              color: rpsColors.isoStrain,
              width: 1,
            },
            type: "bar",
          };

          const traceJobStrain: Plotly.Data = {
            x: data.teamsSeries.job_strain,
            y: data.teamsSeries.attribute_name,
            name: t("rps_job_strain"),
            text: data.teamsSeries.job_strain,
            orientation: "h",
            marker: {
              color: rpsColors.jobStrain,
              width: 1,
            },
            type: "bar",
          };

          const traceActive: Plotly.Data = {
            x: data.teamsSeries.active,
            y: data.teamsSeries.attribute_name,
            name: t("rps_active"),
            text: data.teamsSeries.active,
            orientation: "h",
            marker: {
              color: rpsColors.active,
              width: 1,
            },
            type: "bar",
          };

          const tracePassive: Plotly.Data = {
            x: data.teamsSeries.passive,
            y: data.teamsSeries.attribute_name,
            name: t("rps_passive"),
            text: data.teamsSeries.passive,
            orientation: "h",
            marker: {
              color: rpsColors.passive,
              width: 1,
            },
            type: "bar",
          };

          const traceRelaxed: Plotly.Data = {
            x: data.teamsSeries.relaxed,
            y: data.teamsSeries.attribute_name,
            name: t("rps_relaxed"),
            text: data.teamsSeries.relaxed,
            orientation: "h",
            marker: {
              color: rpsColors.relaxed,
              width: 1,
            },
            type: "bar",
          };

          setData([
            traceIsoStrain,
            traceJobStrain,
            traceActive,
            tracePassive,
            traceRelaxed,
          ]);

          //Sort teams
          setTeams(
            sortBy(data.teamsRecords, [
              "ratio",
              "iso_strain",
              "job_strain",
              "attribute_id",
            ]).reverse()
          );
        }
      } else {
        setStatus(STATUS_LOADED);
      }
    }

    load();
  }, [t, currentFilter]);

  function clickTeams(team) {
    store.dispatch(
      filterReplaceDashboard(
        new DashboardFilters({
          customFilters: [
            new FilterLite({
              id: currentFilter.id,
              attributesIds: team.attribute_id.split("_"),
            }),
          ],
        })
      )
    );

    navigate("/dashboard/rps-team");
  }

  //Return default heatmap filter
  function initCurrentFilter() {
    let searchFilter: Filter | undefined = undefined;
    if (props.filterName) {
      searchFilter = props.filter.list.find(
        (x: Filter) => x.name === props.filterName
      );
    } else if (props._session.accountOptions.heatmapFilterName) {
      searchFilter = props.filter.list.find(
        (x: Filter) =>
          x.name === props._session.accountOptions.heatmapFilterName
      );
    }

    if (searchFilter) {
      return searchFilter;
    } else {
      return props.filter.list.length ? props.filter.list[0] : new Filter();
    }
  }

  return status === INSUFFICIENT_PARTICIPATION ? (
    <NoResultsWidget />
  ) : (
    <Card className="flex1" status={status}>
      {noData ? (
        <p className="grey-t">{t("utils_unavailable")}</p>
      ) : (
        <div className="flex">
          <div>
            <h2>{t("rps_teams_title")}</h2>
          </div>
          <Space />
          {props.isReadOnly ? (
            <p>{currentFilter.name}</p>
          ) : (
            <Dropdown
              displayField="name"
              list={props.filter.list.filter(
                (x) => props._session.userExcludedFilters.indexOf(x.name) === -1
              )}
              onSelect={(filter: Filter) => setCurrentFilter(filter)}
              value={currentFilter.name}
            />
          )}
        </div>
      )}

      <div className="flex" style={{ marginTop: "12px" }}>
        <div>
          {teams.map((team: any) => (
            <div
              key={team.attribute_id}
              style={{
                margin: "6px 20px 12px 0px",
                whiteSpace: "nowrap",
              }}
            >
              <Link isWithoutMargin onClick={() => clickTeams(team)}>
                {team.attribute_name}
              </Link>
            </div>
          ))}
        </div>

        <Plot
          data={data}
          layout={layout}
          config={{
            displayModeBar: false,
          }}
        />
        <div id="plotly" />
      </div>
    </Card>
  );
}

const mapStateToProps = (state) => ({
  _session: state._session,
  filter: state.filter,
});

export default connect(mapStateToProps)(
  withTranslation()(DashboardRpsTeamsWidget)
);
