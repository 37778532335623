/**
 * DASHBOARD-CUSTOM-FILTER-WIDGET
 * Select attributes and regroupings for a filter
 */

import { connect } from "react-redux";
import { Session } from "@/redux/_session.types";
import { useState } from "react";
import Button from "@/components/button";
import ContextMenu from "@/components/context-menu";
import ListItem from "@/components/list-item";
import { concat, flatten, orderBy, uniq } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleDown,
  faChevronCircleRight,
  faCircleNotch,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import Space from "@/components/space";
import { WithTranslation, withTranslation } from "react-i18next";
import { Attribute } from "@/redux/attribute.types";
import PageLoader from "@/components/page-loader";
import { STATUS_LOADED, STATUS_LOADING } from "@/redux/_status.types";
import { Regrouping } from "@/redux/regrouping.types";
import ReactTooltip from "react-tooltip";
import { store } from "@/index";
// import store from "@/core/store";
import {
  DashboardFilters,
  FilterLite,
  FilterState,
} from "@/redux/filter.types";
import DashboardFilterUsersCountWidget from "./dashboard-filter-users-count.widget";
import Notification from "@/components/notification";
import Modal from "@/components/modal";
import FilterRegroupingsWidget from "./filter-regroupings.widget";
import { fetchAttributes } from "@/redux/_archive.actions";

interface StateProps extends WithTranslation {
  _session: Session;
  filter: FilterState;
}

interface OwnProps {
  attributes: Attribute[];
  regroupings: Regrouping[];
  surveyId?: string;
  filterId: string;
  filterName: string;
  onOpen?: Function;
  onSelect: Function;
  isChecked?: boolean;
  isPreview?: boolean;
  isCompare?: boolean;
  showInfo?: boolean;
  readonly?: boolean;
}

type Props = StateProps & OwnProps;

const ATTRIBUTES_TO_DISPLAY: number = 3;

const MODAL_REGROUPINGS: string = "MODAL_REGROUPINGS";

function DashboardCustomFilterWidget(props: Props) {
  const { t } = props;

  const [attributes, setAttributes] = useState<Attribute[]>(props.attributes);
  const [regroupings, setRegroupings] = useState<Regrouping[]>(
    props.regroupings
  );
  const [currentModal, setCurrentModal] = useState<string | null>(null);
  const [paramsChanged, setParamsChanged] = useState<boolean>(false); //if false, widgets won't refresh
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false); //Is context menu open
  const [isRolesEditable] = useState<boolean>(
    props._session.userRole === "ADMIN"
  );
  const [isChecked, setIsChecked] = useState<boolean>(props.isChecked!);

  //Close context menu
  //create list containing selected attributes id
  //for selected regroupings, loop on their attributes
  async function close() {
    setIsOpen(false);
    if (paramsChanged) {
      setIsLoading(true);

      props.onSelect(
        attributes
          .filter((x: Attribute) => x.selected)
          .map((x: Attribute) => x.id),
        flatten(
          regroupings
            .filter((x: Regrouping) => x.selected)
            .map((x: Regrouping) => x.attributes)
        )
      );

      setParamsChanged(false);
      setIsLoading(false);
    }
  }

  //show/hide attributes list which are in regrouping
  function expand(event: any, regrouping: Regrouping) {
    event.stopPropagation();
    regrouping.isExpanded = !regrouping.isExpanded;

    setRegroupings(
      regroupings.map((x: Regrouping) =>
        x.id === regrouping.id ? regrouping : x
      )
    );
  }

  //Get className depending
  function getClassName() {
    let className = "rel filters-item flex ";

    if (isActive()) {
      className = className + " filters-item-active";
    } else if (!props.readonly) {
      className = className + " filters-item-activable";
    }

    return className;
  }

  function getSelectionCount() {
    return (
      attributes.filter((x: Attribute) => x.selected).length +
      regroupings.filter((x: Regrouping) => x.selected).length
    );
  }

  //Get text displayed
  function getText() {
    if (isActive()) {
      const selection: string[] = concat(
        regroupings
          .filter((regrouping: Regrouping) => regrouping.selected)
          .map((regrouping: Regrouping) => regrouping.name),
        attributes
          .filter((attribute: Attribute) => attribute.selected)
          .map((attribute: Attribute) => t(attribute.name))
      );

      if (getSelectionCount() > ATTRIBUTES_TO_DISPLAY) {
        return (
          uniq(selection.slice(0, ATTRIBUTES_TO_DISPLAY)).join(", ") +
          " + " +
          t("utils_more", {
            count: getSelectionCount() - ATTRIBUTES_TO_DISPLAY,
            s: getSelectionCount() - ATTRIBUTES_TO_DISPLAY > 1 ? "s" : "",
          })
        );
      } else {
        return uniq(selection).join(", ");
      }
    } else {
      return props.filterName;
    }
  }

  //Is the filter selected
  function isActive() {
    return (
      attributes.some((x: Attribute) => x.selected) ||
      regroupings.some((x: Regrouping) => x.selected)
    );
  }

  //Open filter menu
  async function open() {
    if (!isChecked) {
      setIsChecked(true);
    }

    if (props.onOpen) {
      props.onOpen();
    }

    await updateAttributesAndRegroupingsUsersCount();

    setIsOpen(true);
  }

  async function updateAttributesAndRegroupingsUsersCount() {
    setIsLoading(true);

    const dashboardFilters: DashboardFilters = props.isCompare
      ? props.filter.dashboardCompare
      : props.filter.dashboard;

    if (dashboardFilters.isEmpty) {
      setAttributes(props.attributes);
      setRegroupings(props.regroupings);
    } else {
      const response: any = await store.dispatch(
        fetchAttributes(
          new DashboardFilters({
            ...dashboardFilters,
            customFilters: dashboardFilters.customFilters.filter(
              (filterLite: FilterLite) => filterLite.id !== props.filterId
            ),
          }),
          props.filterName
        )
      );

      if (!response.error) {
        const result: any = {};
        response.forEach((x: any) => (result[x.aid] = x));

        const updatedAttributes: Attribute[] = attributes.map(
          (attribute: Attribute) =>
            new Attribute({
              ...attribute,
              usersCount: result[attribute.aid]
                ? result[attribute.aid].usersCount
                : 0,
            })
        );

        const updatedRegroupings: Regrouping[] = regroupings.map(
          (regrouping: Regrouping) =>
            new Regrouping({
              ...regrouping,
              Attributes: regrouping.Attributes.map(
                (attribute: Attribute) =>
                  new Attribute({
                    ...attribute,
                    usersCount: result[attribute.aid]
                      ? result[attribute.aid].usersCount
                      : 0,
                  })
              ),
            })
        );

        setAttributes(updatedAttributes);
        setRegroupings(updatedRegroupings);
      }
    }

    setIsLoading(false);
  }

  //Reset selected filters
  function resetFilter(event: any) {
    event.stopPropagation();

    setAttributes(
      attributes.map(
        (attribute: Attribute) =>
          new Attribute({ ...attribute, selected: false })
      )
    );
    setRegroupings(
      regroupings.map(
        (regrouping: Regrouping) =>
          new Regrouping({ ...regrouping, selected: false })
      )
    );

    props.onSelect([], []);
  }

  //On attribute check
  //withReset = false if click inside the checkbox
  function selectAttribute(attribute: Attribute) {
    attribute.selected = !attribute.selected;

    setAttributes(
      attributes.map((x: Attribute) => (x.id === attribute.id ? attribute : x))
    );

    if (!paramsChanged) {
      setParamsChanged(true);
    }
  }

  //On regrouping check
  //withReset = false if click inside the checkbox
  function selectRegrouping(regrouping: Regrouping) {
    regrouping.selected = !regrouping.selected;

    setRegroupings(
      regroupings.map((x: Regrouping) =>
        x.id === regrouping.id ? regrouping : x
      )
    );

    if (!paramsChanged) {
      setParamsChanged(true);
    }
  }

  return (
    <div id={props.filterId} className="rel">
      <div
        className={getClassName()}
        onClick={props.readonly ? undefined : open}
        style={{
          color: isActive() ? props._session.accountColors.active : "",
        }}
      >
        {getText()}

        {props.isPreview && <Notification isHidden={isChecked} />}

        {isLoading && (
          <div className="filters-reset-icon grey-t">
            <FontAwesomeIcon icon={faCircleNotch} spin />
          </div>
        )}

        {isActive() && (
          <div onClick={resetFilter} className="filters-reset-icon grey-t">
            <FontAwesomeIcon icon={faTimes} />
          </div>
        )}
      </div>

      {isOpen && (
        <ContextMenu
          onClose={close}
          positionRight={props.isCompare}
          isLarge={isRolesEditable}
        >
          <PageLoader status={isLoading ? STATUS_LOADING : STATUS_LOADED}>
            {currentModal === MODAL_REGROUPINGS && (
              <Modal
                title={t("filter_populations", { filter: props.filterName })}
                isLarge
                isCloseButtonVisible
                onClose={() => setCurrentModal(null)}
              >
                <FilterRegroupingsWidget
                  filterName={props.filterName}
                  attributes={attributes}
                  regroupings={regroupings}
                />
              </Modal>
            )}

            <div className="dashboard-filter-population">
              {regroupings.length > 0 &&
                orderBy(regroupings, "name").map((item: Regrouping) => (
                  <div key={item.id}>
                    <ListItem
                      isActive={item.selected}
                      isEditable
                      isSmall
                      onClick={() => selectRegrouping(item)}
                    >
                      {t(item.name)}

                      <Space />

                      <div className="flex" onClick={(e) => expand(e, item)}>
                        {item.usersCount > 0 && (
                          <span className="grey-t">{item.usersCount}</span>
                        )}

                        <FontAwesomeIcon
                          className="_hover"
                          style={{ margin: "2px 4px" }}
                          icon={
                            item.isExpanded
                              ? faChevronCircleDown
                              : faChevronCircleRight
                          }
                          data-tip={t("regroup_help", {
                            count: item.Attributes.length,
                            s: item.Attributes.length > 1 ? "s" : "",
                          })}
                          color="#111C2B"
                        />
                        <ReactTooltip />
                      </div>
                    </ListItem>

                    {item.isExpanded && (
                      <div style={{ marginBottom: "6px" }}>
                        {item.Attributes.map((x: Attribute) => (
                          <div
                            key={x.id}
                            className="grey-t flex"
                            style={{ padding: "6px 32px 6px 64px" }}
                          >
                            {t(x.name)}

                            <Space />

                            <DashboardFilterUsersCountWidget
                              userCount={x.usersCount}
                              populationName={x.name}
                              filterName={x.filterName}
                              showInfo={props.isPreview}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))}

              {orderBy(
                attributes.filter(
                  (attribute: Attribute) => attribute.usersCount > 0
                ),
                "name"
              ).map((item: Attribute) => (
                <ListItem
                  key={item.id}
                  isEditable
                  isActive={item.selected}
                  isSmall
                  onClick={() => selectAttribute(item)}
                >
                  {t(item.name)}

                  <Space />

                  <DashboardFilterUsersCountWidget
                    userCount={item.usersCount}
                    populationName={item.name}
                    filterName={item.filterName}
                    showInfo={props.isPreview}
                  />
                </ListItem>
              ))}
            </div>

            {getSelectionCount() > 0 && (
              <div className="flex" style={{ padding: "10px 20px" }}>
                <div>
                  {t("selected_attributes", {
                    count: getSelectionCount(),
                    s: getSelectionCount() > 1 ? "s" : "",
                  })}
                </div>
                <Space />
                <u className="_hover grey-t" onClick={resetFilter}>
                  {t("populations_reset")}
                </u>
              </div>
            )}

            <div className="flex context-menu-footer">
              {!props.isPreview &&
                !props.isCompare &&
                props._session.userRole === "ADMIN" && (
                  <Button
                    className="secondary"
                    onClick={() => setCurrentModal(MODAL_REGROUPINGS)}
                  >
                    {t("utils_associate")}
                  </Button>
                )}
              <Space />
              <Button className="primary" onClick={close}>
                {t("utils_close")}
              </Button>
            </div>
          </PageLoader>
        </ContextMenu>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  _session: state._session,
  filter: state.filter,
});

export default connect(mapStateToProps)(
  withTranslation()(DashboardCustomFilterWidget)
);
