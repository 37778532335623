/**
 * DASHBOARD-RESULTS.WIDGET
 * Display tree, podium and axis
 */

import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import Card from "@/components/card";
import { useEffect, useState } from "react";
import { DashboardFilters } from "@/redux/filter.types";
import { Topic } from "@/redux/topic.types";
import { store } from "@/index";
// import store from "@/core/store";
import {
  fetchAxes,
  fetchNote,
  fetchPropositions,
  fetchTopics,
} from "@/redux/_archive.actions";
import { Survey } from "@/redux/survey.types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartPie,
  faChevronRight,
  faDotCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Session } from "@/redux/_session.types";
import {
  INSUFFICIENT_PARTICIPATION,
  STATUS_LOADED,
  STATUS_LOADING,
  STATUS_LOAD_ERROR,
} from "@/redux/_status.types";
import { flatten, mean, orderBy, sortBy, uniqBy } from "lodash";
import { Axis } from "@/redux/axis.types";
import Space from "@/components/space";
import NoResultsWidget from "./no-results.widget";
import { generateTopics } from "@/redux/_generate.actions";
import { questionGet } from "@/redux/question.actions";
import { Question, QuestionState } from "@/redux/question.types";
import ListItem from "@/components/list-item";
import getArchiveTopic from "@/utils/get-archive-topic.utils";
import formatAxisResults from "@/utils/format-axis-results.utils";
import Button from "@/components/button";
import { surveyEdit } from "@/redux/survey.actions";
import { filterReplaceDashboard } from "@/redux/filter.actions";
import i18n from "@/translate/i18n";
import DashboardResultsTopicsWidget from "./dashboard-results-topics.widget";
import DashboardResultsAxesWidget from "./dashboard-results-axes.widget";
import DashboardTopicsPodiumWidget from "./dashboard-topics-podium.widget";
import DashboardAxesOverviewWidget from "./dashboard-axes-overview.widget";
import { NavigateFunction, useNavigate } from "react-router-dom";

interface StateProps extends WithTranslation {
  _session: Session;
  question: QuestionState;
}

interface OwnProps {
  exportName: string;
  currentAxes: Axis[];
  currentSurvey: Survey;
  currentTopics: Topic[];
  dashboardFilters: DashboardFilters;
  isPreview?: boolean;
  isSelf?: boolean;
  isSortByName?: boolean;
}

type Props = StateProps & OwnProps;

const DISPLAY_TREE_CEIL: number = 4; //Limit of topics with a note to display tree
const WIDGET_WIDTH: number = 420;

type DisplayMode = "topics" | "axes";

const modes = [
  { name: "topics", icon: faChartPie },
  { name: "axes", icon: faDotCircle },
];

function DashboardResultsWidget(props: Props) {
  const { t } = props;
  const navigate: NavigateFunction = useNavigate();

  //Init state
  const [axes, setAxes] = useState<Axis[]>([]);
  const [topics, setTopics] = useState<Topic[]>([]);
  const [status, setStatus] = useState(STATUS_LOADING);

  const [mode, setMode] = useState<DisplayMode>("topics");

  //Note to display at the center of the circle
  const [note, setNote] = useState(0);

  const [selectedAxis, setSelectedAxis] = useState<Axis | null>(null);

  //On every change on the dashboard filters, reload topics
  useEffect(() => {
    async function load() {
      setStatus(STATUS_LOADING);

      //Load topics
      let response: any = [];

      //GenerateData = true
      if (props.currentSurvey.randomData) {
        //Generate random data
        //If survey is test (not demo), load data from a surveyId
        response = await store.dispatch(
          generateTopics(
            props.currentSurvey.template.id,
            props.currentSurvey.isTest ? props.currentSurvey.id : null, //SurveyId
            true,
            props.dashboardFilters
          )
        );

        //Get topics object of response
        if (!response.error) {
          response = response.topics;
        }
      }
      //Get data for the survey from the server
      else {
        response = await store.dispatch(
          fetchTopics(
            props.dashboardFilters,
            props._session.accountOptions.topicRepartitionCeil,
            props.currentSurvey.id,
            props.currentSurvey.dateStart
          )
        );
      }

      if (response.error === INSUFFICIENT_PARTICIPATION) {
        setStatus(INSUFFICIENT_PARTICIPATION);
      } else if (!response.error) {
        //Init new topics for survey topics
        //And update note for topics
        const newTopics: Topic[] = Array.from(
          props.currentTopics,
          (topic: Topic) => getArchiveTopic(topic, response)
        );

        //Filter topic with score
        let noteTopics: Topic[] = newTopics.filter(
          (x) => x.withScore && !x.hide
        );

        //Topics uniq by id
        if (props.currentSurvey.randomData) {
          noteTopics = uniqBy(noteTopics, "labelFull");
        }

        //If count of topics is below limit, search for propositions
        if (noteTopics.length >= DISPLAY_TREE_CEIL) {
          //Set note mean
          if (!props.currentSurvey.randomData) {
            const noteResponse: any = await store.dispatch(
              fetchNote(
                props.dashboardFilters,
                props._session.dashboardDisplaySettings.dashboardDisplayMode ===
                  "satisfaction",
                props.currentSurvey.id,
                props.currentSurvey.dateStart
              )
            );
            setNote(noteResponse.error ? 0 : noteResponse.note);
          } else {
            const notes = noteTopics.map((x) => x.note);
            setNote(mean(notes));
          }

          //Save topics in state
          setTopics(
            orderBy(
              noteTopics.map((x) => new Topic(x)),
              ["Axis.label", "Axis.id", props.isSortByName ? "label" : "note"],
              ["asc", "desc"]
            )
          );

          //Fetch axes
          const axesResponse: any =
            props.currentSurvey.randomData || props.isPreview
              ? []
              : await store.dispatch(
                  fetchAxes(
                    props.dashboardFilters,
                    i18n.language,
                    props._session.dashboardDisplaySettings
                      .dashboardDisplayMode === "satisfaction",
                    props.currentSurvey.id,
                    props.currentSurvey.dateStart
                  )
                );

          //Get axes From Topics
          if (!axesResponse.error) {
            //Update axes with note (calculte from topic list)
            const newAxes: Axis[] = Array.from(
              props.currentAxes,
              (axis: Axis) =>
                formatAxisResults(
                  axis,
                  newTopics,
                  axesResponse,
                  props._session.accountId,
                  props.currentSurvey.randomData
                )
            );

            //Update axis list
            setAxes(newAxes.map((x: Axis) => new Axis(x)));
          } else {
            setAxes([]);
          }
        } else {
          //Search propositions
          let propositions: any = [];
          if (props.currentSurvey.randomData) {
            const generatedTopics: any = await store.dispatch(
              generateTopics(
                props.currentSurvey.template.id,
                props.currentSurvey.isTest ? props.currentSurvey.id : null, //SurveyId,
                false,
                props.dashboardFilters
              )
            );

            if (!generatedTopics.error) {
              propositions = generatedTopics.propositions;
            }
          } else {
            propositions = await store.dispatch(
              fetchPropositions(
                props.dashboardFilters,
                props.currentSurvey.id,
                props.currentSurvey.dateStart
              )
            );
          }

          if (!propositions.error) {
            //Get responses most voted
            propositions = sortBy(propositions, "count").reverse().slice(0, 3);

            //Get questions for theses propositions
            let questions: Question[] = flatten(
              props.currentTopics.map((x: Topic) =>
                x.Questions.map(
                  (y: Question) =>
                    new Question({
                      ...y,
                      fk_topic: x.aid,
                    })
                )
              )
            );
            questions = uniqBy(
              questions.filter(
                (x) =>
                  propositions
                    .map((y) => y.fkQuestion)
                    .indexOf(props.currentSurvey.randomData ? x.id : x.aid) > -1
              ),
              "aid"
            );

            //Edit propositions for questions
            questions.forEach((question) => {
              question.Propositions.forEach((proposition) => {
                const archiveProposition = propositions.find(
                  (x) =>
                    x.aid ===
                      (props.currentSurvey.randomData
                        ? proposition.id
                        : proposition.aid) &&
                    x.fkQuestion ===
                      (props.currentSurvey.randomData
                        ? question.id
                        : question.aid)
                );

                if (archiveProposition) {
                  proposition.count = archiveProposition.count;
                } else {
                  proposition.count = 0;
                }
              });
            });

            //Update store for questions
            store.dispatch(questionGet(questions));
          }

          setTopics([]);
        }

        //Set widget is loaded
        setStatus(STATUS_LOADED);
      } else {
        setTopics([]);
        setStatus(STATUS_LOAD_ERROR);
      }
    }

    load();

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.dashboardFilters,
    props.currentSurvey.id,
    props.currentSurvey.randomData,
  ]);

  //Click on axis : scroll at the axis in the page questions
  function clickAxis(axis) {
    if (!props.isPreview) {
      navigate("/dashboard/questions?axis_id=" + axis.id);
    }
  }

  //Click on a question
  //Scroll to topic of the question in the page question
  function clickQuestion(question) {
    if (!props.isPreview) {
      navigate(
        "/dashboard/questions?topic_id=" + question.QuestionsTopic.TopicId
      );
    }
  }

  function toogleTestDashboard() {
    store.dispatch(surveyEdit("randomData", !props.currentSurvey.randomData));
    store.dispatch(filterReplaceDashboard(new DashboardFilters()));
  }

  return (
    <Card
      className="flex1"
      exportName={props.exportName}
      isWithoutPadding={props.isPreview || topics.length < DISPLAY_TREE_CEIL}
      status={status}
    >
      <div
        style={{
          width: props.isPreview ? 350 : WIDGET_WIDTH,
        }}
      >
        {status === INSUFFICIENT_PARTICIPATION ||
        (props.isPreview &&
          props.currentSurvey.getParticipationCount(
            props._session.dashboardDisplaySettings.hideStartedForParticipation
          ) < props._session.participationMin) ? (
          <div className="flex1 flex flex-dcol">
            <div className="height-20" />
            <NoResultsWidget />
            {props.currentSurvey.isTest && (
              <div className="flex" style={{ marginBottom: 14 }}>
                <Space />
                <Button className="primary" onClick={toogleTestDashboard}>
                  {t("dashboard_test")}
                </Button>
                <Space />
              </div>
            )}
          </div>
        ) : (
          <div>
            <div
              className="flex abs"
              style={{
                top: 5,
                right: 10,
                zIndex: 4,
              }}
            >
              {!props.isPreview &&
                modes.map((x: any) => (
                  <FontAwesomeIcon
                    key={x.name}
                    icon={x.icon}
                    color={
                      mode === x.name
                        ? props._session.accountColors.active
                        : "#8C8C8C"
                    }
                    size={"lg"}
                    className={mode === x.name ? undefined : "_hover"}
                    onClick={
                      mode === x.name ? undefined : () => setMode(x.name)
                    }
                    style={{
                      margin: "10px 5px",
                    }}
                  />
                ))}
            </div>

            {mode === "topics" && (
              <div>
                {
                  /* display tree if 6 word min */
                  topics.length >= DISPLAY_TREE_CEIL ? (
                    <div>
                      <div className="flex">
                        <Space />
                        <DashboardResultsTopicsWidget
                          currentTopics={topics}
                          note={note}
                          isPreview={props.isPreview}
                        />
                        <Space />
                      </div>

                      {!props.isPreview && (
                        <p className="grey-t">{t("dashboard_axes")}</p>
                      )}

                      <DashboardAxesOverviewWidget
                        axes={axes}
                        onClick={(axis: Axis) => clickAxis(axis)}
                      />
                    </div>
                  ) : (
                    //Else display questions with the more responses
                    <div>
                      <ListItem>
                        <b>{t("dashboard_results_propositions")}</b>
                      </ListItem>

                      {props.question.list.map((question) => (
                        <ListItem
                          key={question.id}
                          isEditable
                          onClick={() => clickQuestion(question)}
                        >
                          <div className="flex1">
                            <div className="grey-t">{question.label}</div>

                            {question.Propositions.filter(
                              (x) => x.count > 0
                            ).map((proposition) => (
                              <div key={proposition.id} className="flex">
                                <FontAwesomeIcon
                                  icon={faChevronRight}
                                  style={{
                                    marginTop: "2px",
                                    marginRight: "4px",
                                  }}
                                />
                                {proposition.label}
                                <Space />
                                <div className="width-20" />
                                {proposition.count}{" "}
                                {proposition.count > 1
                                  ? t("propositions")
                                  : t("proposition")}
                                &nbsp; (
                                {proposition.getRatio(
                                  props.currentSurvey.getParticipationCount(
                                    props._session.dashboardDisplaySettings
                                      .hideStartedForParticipation
                                  )
                                )}
                                %)
                              </div>
                            ))}
                          </div>
                        </ListItem>
                      ))}
                    </div>
                  )
                }
              </div>
            )}

            {mode === "axes" && (
              <div>
                <DashboardResultsAxesWidget
                  currentAxes={axes}
                  onClick={(axisId: string | null) =>
                    setSelectedAxis(
                      axisId
                        ? selectedAxis && selectedAxis.id === axisId
                          ? null
                          : axes.find((x: Axis) => x.id === axisId)!
                        : null
                    )
                  }
                />

                <div className="flex">
                  <Space />

                  <div>
                    <p className="grey-t">{t("dashboard_topics")}</p>

                    <div style={{ fontSize: 12, padding: "0 10px" }}>
                      {selectedAxis ? (
                        <div className="flex">
                          {selectedAxis!.label}
                          <Space />
                          <u
                            className="_hover grey-t"
                            onClick={() => setSelectedAxis(null)}
                          >
                            {t("populations_reset")}
                          </u>
                        </div>
                      ) : (
                        <i>{t("filter_by_axis")}</i>
                      )}
                    </div>

                    <div className="height-10" />

                    <p className="grey-t">{t("rank_top", { number: 3 })}</p>

                    <DashboardTopicsPodiumWidget
                      currentTopics={topics}
                      selectedAxisId={
                        selectedAxis ? selectedAxis!.id : undefined
                      }
                      nbToDisplay={3}
                    />

                    <p className="grey-t">{t("rank_worst", { number: 3 })}</p>

                    <DashboardTopicsPodiumWidget
                      currentTopics={topics}
                      selectedAxisId={
                        selectedAxis ? selectedAxis!.id : undefined
                      }
                      reverse={true}
                      nbToDisplay={3}
                    />
                  </div>

                  <Space />
                </div>

                <div className="height-10" />

                <div className="flex">
                  <Space />
                  <u
                    className="_hover grey-t"
                    onClick={() =>
                      navigate(
                        selectedAxis
                          ? "/dashboard/questions?axis_id=" + selectedAxis!.id
                          : "/dashboard/questions"
                      )
                    }
                  >
                    {t("utils_show")}
                  </u>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </Card>
  );
}

const mapStateToProps = (state) => ({
  _session: state._session,
  question: state.question,
});

export default connect(mapStateToProps)(
  withTranslation()(DashboardResultsWidget)
);
