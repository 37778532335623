/**
 * RENAME-TEMPLATE.MODAL
 * Rename template on the fly
 */
import Modal from "@/components/modal";
import { useState } from "react";
import { connect } from "react-redux";
import { withTranslation, WithTranslation } from "react-i18next";
import TextInput from "@/components/text-input";
import { store } from "@/index";
// import store from "@/core/store";
import {
  STATUS_SAVED,
  STATUS_SAVE_ERROR,
  STATUS_SAVING,
} from "@/redux/_status.types";
import {
  templateStatus,
  templateEditName,
  templateUpdate,
} from "@/redux/template.actions";
import { TemplateState } from "@/redux/template.types";
import { Session } from "@/redux/_session.types";

interface StateProps extends WithTranslation {
  _session: Session;
  template: TemplateState;
}

interface OwnProps {
  onClose: Function;
}

type Props = StateProps & OwnProps;

function RenameTemplateModal(props: Props) {
  const { t } = props;

  //Name of the template to change
  const [templateName, setTemplateName] = useState(
    props.template.active.name[props.template.active.language]
  );

  //Save the template
  //Edit the store with the new name and update the BDD
  async function next() {
    store.dispatch(templateStatus(STATUS_SAVING));
    store.dispatch(
      templateEditName(props.template.active.language, templateName)
    );
    const response: any = await store.dispatch(
      templateUpdate(props.template.active)
    );

    if (response.error) {
      store.dispatch(templateStatus(STATUS_SAVE_ERROR));
    } else {
      store.dispatch(templateStatus(STATUS_SAVED));
      props.onClose();
    }
  }

  return (
    <Modal
      isCloseButtonVisible
      status={props.template.status}
      onClose={() => props.onClose()}
      onNext={next}
      title={t("utils_rename")}
    >
      <TextInput
        autoFocus
        isFullWidth
        onChange={(e) => setTemplateName(e.value)}
        title={t("template_name")}
        value={templateName}
      />

      <div className="height-20" />
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  _session: state._session,
  template: state.template,
});

export default connect(mapStateToProps)(withTranslation()(RenameTemplateModal));
