/**
 * DASHBOARD-RPS-USERS.WIDGET
 * Repartition of users for a survey
 */
import { withTranslation, WithTranslation } from "react-i18next";
import Card from "@/components/card";
import { useEffect, useState } from "react";
import { DashboardFilters } from "@/redux/filter.types";
import { store } from "@/index";
// import store from "@/core/store";
import {
  INSUFFICIENT_PARTICIPATION,
  STATUS_LOADED,
  STATUS_LOADING,
} from "@/redux/_status.types";
import { fetchRpsUsers } from "@/redux/_rps.actions";
import Plot from "react-plotly.js";
import NoResultsWidget from "./no-results.widget";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import RpsHelp from "@/help/rps.help";
import RpsUsersHelp from "@/help/rps-users.help";
import { User } from "@/redux/user.types";
import { userGet } from "@/redux/user.actions";
import { Shape } from "plotly.js";

interface OwnProps {
  dashboardFilters: DashboardFilters;
}

type Props = OwnProps & WithTranslation;

const HELP_RPS = "HELP_RPS";
const HELP_USERS = "HELP_USERS";

function DashboardRpsUsersWidget(props: Props) {
  const { t } = props;

  //Display help
  const [currentHelp, setCurrentHelp] = useState<string | null>(null);

  //Plotly data, loaded on useEffect
  const [data, setData] = useState<Plotly.Data[]>([]);

  //Plotly Layout
  const [layout, setLayout] = useState<Partial<Plotly.Layout>>({});

  //State
  // status : loader
  // teams : list of attributes on the left of the graph
  const [status, setStatus] = useState(STATUS_LOADING);

  //Load data on every change on filters and filterName
  useEffect(() => {
    async function load() {
      setStatus(STATUS_LOADING);
      const data: any = await store.dispatch(
        fetchRpsUsers(new DashboardFilters(props.dashboardFilters))
      );
      setStatus(STATUS_LOADED);

      if (!data.error) {
        const horizontalSeparatorTrace: Partial<Shape> = {
          type: "line",
          x0: data.layoutOffset.x_min + 0.5,
          x1: 100,
          y0: data.ceils.RPS_CEIL_PSYCHOLOGIC_JOB_DEMANDS,
          y1: data.ceils.RPS_CEIL_PSYCHOLOGIC_JOB_DEMANDS,
          line: { color: "silver", dash: "dot", width: 2 },
          layer: "below",
        };

        const verticalSeparatorTrace: Partial<Shape> = {
          type: "line",
          x1: data.ceils.RPS_CEIL_DECISION_LATITUDE,
          x0: data.ceils.RPS_CEIL_DECISION_LATITUDE,
          y0: data.layoutOffset.y_min + 1,
          y1: 36,
          line: { color: "silver", dash: "dot", width: 2 },
          layer: "below",
        };

        setData(MakeRpsUsersFigure(data));
        setLayout({
          width: 840,
          height: 700,
          xaxis: {
            range: data.layoutOffset.x_range,
            title: "",
            showticklabels: false,
          },
          yaxis: {
            range: data.layoutOffset.y_range,
            title: "",
            showticklabels: false,
            tickvals: [9, 16, 21, 26, 31],
          },
          font: {
            family: "'Open Sans', sans-serif",
            size: 17,
          },
          autosize: true,
          bargap: 0.15,
          bargroupgap: 0.1,
          barmode: "stack",
          hovermode: "closest",
          margin: {
            r: 30,
            l: 70,
            b: 70,
            t: 70,
          },
          plot_bgcolor: "rgba(0,0,0,0)",
          shapes: [horizontalSeparatorTrace, verticalSeparatorTrace],
          annotations: data.dotsAnnotations,
        });
      }
    }
    load();
  }, [props.dashboardFilters]);

  return status === INSUFFICIENT_PARTICIPATION ? (
    <NoResultsWidget />
  ) : (
    <Card
      className="flex1"
      status={status}
      title={t("rps_users_results")}
      exportName={t("rps_users_results")}
    >
      {currentHelp === HELP_RPS && (
        <RpsHelp
          currentHelp={currentHelp}
          onClose={() => setCurrentHelp(null)}
        />
      )}

      {currentHelp === HELP_USERS && (
        <RpsUsersHelp onClose={() => setCurrentHelp(null)} />
      )}

      <div className="grey-t">
        {t("rps_details_sumary")}
        <p>
          <b>{t("rps_details_sumary_text")}</b>
        </p>
      </div>

      <div className="flex">
        <div className="survey-rps-axis-reverse-container">
          <div className="survey-rps-axis-reverse" style={{ bottom: "75%" }}>
            {t("rps_axis_high")}
          </div>
          <div
            className="survey-rps-axis-reverse grey-t survey-rps-axis-info"
            onClick={() => setCurrentHelp(HELP_RPS)}
            style={{ bottom: "calc(50% - 20px)" }}
          >
            <FontAwesomeIcon icon={faInfoCircle} />
          </div>
          <div className="survey-rps-axis-reverse" style={{ bottom: "50%" }}>
            <b>{t("rps_axis_stress")}</b>
          </div>
          <div className="survey-rps-axis-reverse" style={{ bottom: "30%" }}>
            {t("rps_axis_low")}
          </div>
        </div>
        <div className="flex1" style={{ position: "relative" }}>
          <Plot
            data={data}
            layout={layout}
            onClick={(data: any) => {
              const users: User[] = [];
              const point: any = data.points[0];

              point.data.fk_users[point.pointIndex].forEach((user) => {
                users.push(
                  new User({
                    id: user,
                  })
                );
              });

              store.dispatch(userGet(users));
              setCurrentHelp(HELP_USERS);
            }}
          />

          <div className="flex survey-rps-axis">
            {t("rps_axis_low")}
            <div className="flex1"></div>
            <b>{t("rps_axis_autonomy")}</b>
            &nbsp;
            <div
              className="grey-t survey-rps-axis-info"
              onClick={() => setCurrentHelp(HELP_RPS)}
            >
              <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>
            </div>
            <div className="flex1"></div>
            {t("rps_axis_high")}
          </div>
        </div>
      </div>

      <div className="flex flex-jsb" style={{ marginTop: "15px" }}>
        <div className="flex flex-ace flex-jcenter">
          <div className="survey-rps-graph-dot red" />
          <div>{t("rps_graph_iso")}</div>
        </div>
        <div className="flex flex-ace flex-jcenter">
          <div className="survey-rps-graph-dot orange" />
          <div>{t("rps_graph_job")}</div>
        </div>
        <div className="flex flex-ace flex-jcenter">
          <div className="survey-rps-graph-dot grey" />
          <div>{t("rps_graph_active_or_passive")}</div>
        </div>
        <div className="flex flex-ace flex-jcenter">
          <div className="survey-rps-graph-dot medgrey" />
          <div>{t("rps_graph_relaxed")}</div>
        </div>
        <div className="flex flex-ace flex-jcenter">
          <div
            style={{
              width: "30px",
              height: "1px",
              borderBottom: "dashed 2px silver",
              marginRight: "5px",
            }}
          />
          <div>{t("rps_graph_ceils")}</div>
        </div>
      </div>
    </Card>
  );
}

// function making figure data
function MakeRpsUsersFigure(data: any): Plotly.Data[] {
  // ajout des ombres portées si iso strain et job strain se confondent sur un même point
  const shadowTrace: Plotly.Data = {
    x: data.dlPjdSsShadow.decision_latitude,
    y: data.dlPjdSsShadow.psychological_job_demands,
    mode: "markers",
    name: "",
    type: "scatter",
    hoverinfo: "skip",
    showlegend: false,
    marker: {
      size: 17,
      color: "rgba(255, 182, 193, .9)",
    },
  };

  // la trace qui contient tout les dots supperposé potentielement sans tooltip le
  const trace: Plotly.Data = {
    x: data.rpsKarasekChartData.decision_latitude,
    y: data.rpsKarasekChartData.psychological_job_demands,
    mode: "markers",
    type: "scatter",
    hoverinfo: "skip",
    showlegend: false,
    name: "",
    marker: {
      size: 17,
      color: data.dotsColors,
      opacity: 1,
    },
  };

  // Ajout d'un scatter invisible pour n'afficher que les tooltips
  const tooltipTrace: any /*Plotly.Data*/ = {
    x: data.dlPjdSs.decision_latitude,
    y: data.dlPjdSs.psychological_job_demands,
    fk_users: data.dlPjdSs.fk_users,
    opacity: 0,
    mode: "markers",
    type: "scatter",
    name: "",
    showlegend: false,
    hoverlabel: { bgcolor: "gray", font: { color: "white" } },
    hovertemplate: data.hovertemplateTextFormated,
    marker: { size: 17 },
  };

  return [shadowTrace, trace, tooltipTrace];
}

export default withTranslation()(DashboardRpsUsersWidget);
