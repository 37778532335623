/**
 * USER-GENERAL-INFO.WIDGET
 * Edit firstname, lastname and email for an user
 */

import { WithTranslation, withTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import TextInput from "@/components/text-input";
import { checkEmail } from "@/utils/validate-email.utils";
import {
  STATUS_LOADING,
  STATUS_SAVED,
  STATUS_SAVE_ERROR,
  STATUS_SAVING,
} from "@/redux/_status.types";
import { store } from "@/index";
// import store from "@/core/store";
import { userStatus } from "@/redux/user.actions";

interface OwnProps {
  firstname?: string;
  lastname?: string;
  email?: string;
  onEdit: Function;
  userId: string;
  emailCanBeNull?: boolean; //if true do not check email and do not set error if email is empty
}

type Props = OwnProps & WithTranslation;

function UserGeneralInfoWidget(props: Props) {
  const { t } = props;

  //Status for email
  const [emailStatus, setEmailStatus] = useState(STATUS_LOADING);

  //Display error
  const [emailError, setEmailError] = useState<string | null>(null);

  const [firstname, setFirstname] = useState<string>(
    props.firstname ? props.firstname : ""
  );

  const [lastname, setLastname] = useState<string>(
    props.lastname ? props.lastname : ""
  );

  const [email, setEmail] = useState<string>(props.email ? props.email : "");

  useEffect(() => {
    setFirstname(props.firstname ? props.firstname : "");
  }, [props.firstname]);

  useEffect(() => {
    setLastname(props.lastname ? props.lastname : "");
  }, [props.lastname]);

  useEffect(() => {
    setEmail(props.email ? props.email : "");
  }, [props.email]);

  //Check if email is valid
  async function validateEmail() {
    setEmailStatus(STATUS_SAVING);
    if (props.emailCanBeNull && email === "") {
      props.onEdit("email", null);
      setEmailError(null);
      setEmailStatus(STATUS_SAVED);
      store.dispatch(userStatus(STATUS_SAVED));
    } else {
      const response: string = await checkEmail(email, props.userId);
      if (response === "OK") {
        props.onEdit("email", email);
        setEmailError(null);
        setEmailStatus(STATUS_SAVED);
        store.dispatch(userStatus(STATUS_SAVED));
      }
      //If not available
      else {
        setEmailError(response);
        store.dispatch(userStatus(STATUS_SAVE_ERROR));
      }
    }
  }

  return (
    <div>
      <p>
        <b>{t("utils_informations")}</b>
      </p>

      <div className="flex">
        <TextInput
          onChange={(e) => setFirstname(e.value)}
          isFullWidth
          onBlur={() => props.onEdit("firstname", firstname)}
          title={t("user_firstname")}
          value={firstname}
        />

        <div className="width-20" />

        <TextInput
          onChange={(e) => setLastname(e.value)}
          isFullWidth
          onBlur={() => props.onEdit("lastname", lastname)}
          title={t("user_lastname")}
          value={lastname}
        />
      </div>

      <div className="flex2">
        <TextInput
          status={emailStatus}
          onBlur={() => validateEmail()}
          onChange={(e: any) => setEmail(e.value)}
          error={emailError ? true : false}
          title={t("user_email")}
          value={email}
        />

        <div className="flex1">
          {emailError && (
            <div className="red-t">
              <b>{t(emailError)}</b>
            </div>
          )}
        </div>
      </div>

      <div className="height-10" />

      <div className="grey-t" style={{ fontSize: 12, width: 420 }}>
        {t("import_email_help")}
      </div>
    </div>
  );
}

export default withTranslation()(UserGeneralInfoWidget);
