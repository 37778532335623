/**
 * SUPERVISOR-LMS
 * Create public learnings and lessons
 */

import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { connect } from "react-redux";
import { WithTranslation, withTranslation } from "react-i18next";
import PageHeader from "@/components/page-header";
import Tabs from "@/components/tabs";
import { store } from "@/index";
// import store from "@/core/store";
import {
  STATUS_LOADED,
  STATUS_LOADING,
  STATUS_LOAD_ERROR,
  STATUS_SEARCHING,
} from "@/redux/_status.types";
import Card from "@/components/card";
import ListContainer from "@/components/list-container";
import ListItem from "@/components/list-item";
import ListFields from "@/components/list-fields";
import ListCell from "@/components/list-cell";
import Space from "@/components/space";
import ListIcon from "@/components/list-icon";
import {
  faClipboardList,
  faPencilAlt,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import env from "@/env";
import Chip from "@/components/chip";
import ListButton from "@/components/list-button";
import LoadingModal from "@/modals/loading.modal";
import { Session } from "@/redux/_session.types";
import PageLoader from "@/components/page-loader";
import SaveIcon from "@/components/save-icon";
import Button from "@/components/button";
import {
  learningActivate,
  learningFetch_AsSupervisor,
  learningGet,
  learningStatus,
} from "@/redux/learning.actions";
import {
  lessonGet,
  lessonFetch_AsSupervisor,
  lessonStatus,
  lessonActivate,
} from "@/redux/lesson.actions";
import { Learning, LearningState } from "@/redux/learning.types";
import { Lesson, LessonState, LessonTypes } from "@/redux/lesson.types";
import LessonTypesModal from "@/modals/lesson-types.modal";
import AddLearningModal from "@/modals/add-learning.modal";
import EditLessonModal from "@/modals/edit-lesson.modal";
import DeleteLearningModal from "@/modals/delete-learning.modal";
import DeleteLessonModal from "@/modals/delete-lesson.modal";
import Modal from "@/components/modal";
import NavSupervisor from "@/nav/nav.supervisor";
import { NavigateFunction, useNavigate } from "react-router-dom";

interface Props extends WithTranslation {
  _session: Session;
  learning: LearningState;
  lesson: LessonState;
}

//Dialog
const MODAL_ADD_LEARNING: string = "MODAL_ADD_LEARNING";
const MODAL_SELECT_LESSON_TYPE: string = "MODAL_SELECT_LESSON_TYPE";
const MODAL_EDIT_LESSON: string = "MODAL_EDIT_LESSON";
const MODAL_DELETE_LEARNING: string = "MODAL_DELETE_LEARNING";
const MODAL_DELETE_LESSON: string = "MODAL_DELETE_LESSON";
const MODAL_REDIRECT: string = "MODAL_REDIRECT";

//Tabs
const TAB_LEARNINGS: string = "TAB_LEARNINGS";
const TAB_LESSONS: string = "TAB_LESSONS";

//Limit of items displayed by page
const LIMIT: number = 10;

function SupervisorLms(props: Props) {
  const { t } = props;
  const navigate: NavigateFunction = useNavigate();

  const [currentTab, setCurrentTab] = useState<string>(TAB_LEARNINGS);
  const [currentModal, setCurrentModal] = useState<string | null>(null);
  const [learningOffset, setLearningOffset] = useState<number>(0);
  const [learningSearch, setLearningSearch] = useState<string>("");
  const [learningCount] = useState<number>(0);
  const [lessonOffset, setLessonOffset] = useState<number>(0);
  const [lessonSearch, setLessonSearch] = useState<string>("");
  const [lessonCount] = useState<number>(0);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  //Add lesson
  function addLesson(type: LessonTypes) {
    store.dispatch(
      lessonActivate(
        new Lesson({
          name: { [props._session.language]: t("lesson_new") },
          fromSupervisor: true,
          type,
          languages: [props._session.language],
          new: true,
        })
      )
    );
    setCurrentModal(MODAL_EDIT_LESSON);
  }

  //Edit learning
  function editLearning(learning: Learning) {
    store.dispatch(learningActivate(learning));
    setCurrentModal(MODAL_REDIRECT);
  }

  //Edit lesson
  function editLesson(lesson: Lesson) {
    store.dispatch(lessonActivate(lesson));
    setCurrentModal(MODAL_EDIT_LESSON);
  }

  //Load page on start
  useEffect(() => {
    //Load page data
    async function loadPage() {
      let response: any = {};
      store.dispatch(learningStatus(STATUS_SEARCHING));
      store.dispatch(lessonStatus(STATUS_LOADED));

      if (currentTab === TAB_LEARNINGS) {
        response = await store.dispatch(
          learningFetch_AsSupervisor(learningSearch, LIMIT, learningOffset)
        );
        store.dispatch(learningGet(response.error ? [] : response));
      }

      if (currentTab === TAB_LESSONS) {
        response = await store.dispatch(
          lessonFetch_AsSupervisor(lessonSearch, LIMIT, lessonOffset, null)
        );
        store.dispatch(lessonGet(response.error ? [] : response));
      }

      setIsLoaded(true);
      store.dispatch(
        learningStatus(response.error ? STATUS_LOAD_ERROR : STATUS_LOADED)
      );
    }

    if (!isLoaded) {
      loadPage();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);

  function removeLesson(lesson: Lesson) {
    store.dispatch(lessonActivate(lesson));
    setCurrentModal(MODAL_DELETE_LESSON);
  }

  function removeLearning(learning: Learning) {
    store.dispatch(learningActivate(learning));
    setCurrentModal(MODAL_DELETE_LEARNING);
  }

  //Search
  function searchLearning(search: string) {
    setLearningSearch(search);
    setLearningOffset(0);
    setIsLoaded(false);
  }

  function searchLesson(search: string) {
    setLessonSearch(search);
    setLessonOffset(0);
    setIsLoaded(false);
  }

  //Select tab
  function selectTab(tab: string) {
    setLearningOffset(0);
    setLessonOffset(0);
    setLearningSearch("");
    setLessonSearch("");
    setCurrentTab(tab);
    setIsLoaded(false);
  }

  //Change page (for results search)
  function setLearningPage(offset: number) {
    setLearningOffset(offset);
    setIsLoaded(false);
  }

  function setLessonPage(offset: number) {
    setLessonOffset(offset);
    setIsLoaded(false);
  }

  return (
    <NavSupervisor>
      {props.learning.status === STATUS_LOADING && <LoadingModal />}

      {currentModal === MODAL_REDIRECT && (
        <Modal
          isCloseButtonVisible
          onClose={() => setCurrentModal(null)}
          onNext={() => navigate("/learning/editor")}
        >
          <ReactMarkdown linkTarget="_blank">
            {t("learning_ask_edit", {
              name: props.learning.active.label,
            })}
          </ReactMarkdown>
        </Modal>
      )}

      {currentModal === MODAL_ADD_LEARNING && (
        <AddLearningModal onClose={() => setCurrentModal(null)} />
      )}

      {currentModal === MODAL_SELECT_LESSON_TYPE && (
        <LessonTypesModal
          onClose={() => setCurrentModal(null)}
          onSelect={(item) => addLesson(item)}
        />
      )}

      {currentModal === MODAL_EDIT_LESSON && (
        <EditLessonModal onClose={() => setCurrentModal(null)} />
      )}

      {currentModal === MODAL_DELETE_LEARNING && (
        <DeleteLearningModal
          onClose={() => setCurrentModal(null)}
          learning={props.learning.active}
          fromSupervisor
        />
      )}

      {currentModal === MODAL_DELETE_LESSON && (
        <DeleteLessonModal
          onClose={() => setCurrentModal(null)}
          lesson={props.lesson.active}
          fromSupervisor
        />
      )}

      <PageHeader title={t("learning")}>
        <Button
          className={currentTab === TAB_LEARNINGS ? "primary" : ""}
          onClick={() => setCurrentModal(MODAL_ADD_LEARNING)}
        >
          {t("learning_create")}
        </Button>

        <Button
          className={currentTab === TAB_LESSONS ? "primary" : ""}
          onClick={() => setCurrentModal(MODAL_SELECT_LESSON_TYPE)}
        >
          {t("lesson_create")}
        </Button>
      </PageHeader>

      <Tabs
        active={currentTab}
        onClick={selectTab}
        tabs={{
          TAB_LEARNINGS: t("learnings"),
          TAB_LESSONS: t("lessons"),
        }}
      />

      <PageLoader status={isLoaded ? STATUS_LOADED : STATUS_LOADING}>
        <Card isWithoutPadding>
          {currentTab === TAB_LEARNINGS && (
            <ListContainer
              countLimit={LIMIT}
              countSearch={learningCount}
              isFooterActive
              onSearch={searchLearning}
              //onSearchStart={() => setIsLoading(true)}
              onSetPage={setLearningPage}
              status={props.learning.status}
            >
              <ListFields>
                <ListCell />
                <ListCell width={300} text={t("user_lastname")} />
                <ListCell width={100} text={t("lessons")} />
                <Space />
                <ListCell width={140} text={t("utils_status")} />
                <ListCell />
                <ListCell />
              </ListFields>

              {props.learning.list.map((learning: Learning) => (
                <ListItem
                  key={learning.id}
                  isEditable
                  onClick={() => editLearning(learning)}
                >
                  <ListIcon
                    fallbackIcon={faClipboardList}
                    image={
                      learning.image
                        ? env.REACT_APP_URL_SPACE.concat(
                            "/Learnings/",
                            learning.image,
                            ".png?" + new Date().getTime()
                          )
                        : null
                    }
                  />

                  <ListCell width={300} text={learning.label} />

                  <ListCell width={100}>
                    <Chip>{learning.mediasOrder.length + ""}</Chip>
                  </ListCell>

                  <Space />

                  <ListCell width={140}>
                    {learning.visible ? (
                      <Chip color="#465AEB">{t("utils_visible")}</Chip>
                    ) : (
                      <Chip color="#EB5A46">{t("utils_hidden")}</Chip>
                    )}
                  </ListCell>

                  <ListButton
                    icon={faPencilAlt}
                    onClick={() => editLearning(learning)}
                    text={t("utils_edit")}
                  />

                  <ListButton
                    icon={faTimes}
                    onClick={() => removeLearning(learning)}
                  />
                </ListItem>
              ))}
            </ListContainer>
          )}

          {currentTab === TAB_LESSONS && (
            <ListContainer
              countLimit={LIMIT}
              onSetPage={setLessonPage}
              countSearch={lessonCount}
              isFooterActive
              onSearch={searchLesson}
              //onSearchStart={() => setIsLoading(true)}
              status={props.learning.status}
            >
              <ListFields>
                <ListCell />
                <ListCell width={300} text={t("user_lastname")} />
                <ListCell width={120} text={t("utils_type")} />
                <ListCell width={120} text={t("utils_duration")} />
                <ListCell width={140} text={t("utils_status")} />
                <Space />
                <ListCell />
                <ListCell />
              </ListFields>

              {props.lesson.list.map((lesson: Lesson) => (
                <ListItem
                  key={lesson.id}
                  isEditable
                  onClick={() => editLesson(lesson)}
                >
                  <ListIcon
                    fallbackIcon={faClipboardList}
                    image={
                      lesson.CoachId
                        ? "https://idtree.fra1.digitaloceanspaces.com/_v1/Coachings/Thumbnails/" +
                          lesson.videoLinkProd +
                          ".jpg"
                        : lesson.picture
                    }
                  />
                  <ListCell width={300}>
                    <div>{lesson.label}</div>
                    <SaveIcon
                      status={
                        props.lesson.active.id === lesson.id
                          ? props.lesson.status
                          : null
                      }
                    />
                  </ListCell>

                  <ListCell width={120}>
                    <Chip>{t("lesson_type_" + lesson.type)}</Chip>
                  </ListCell>

                  <ListCell width={120}>
                    <div>
                      {t("utils_time_minutes", {
                        value: lesson.duration,
                        s: lesson.duration > 1 ? "s" : "",
                      })}
                    </div>
                  </ListCell>

                  <ListCell width={140}>
                    {lesson.visible ? (
                      <Chip color="#465AEB">{t("utils_visible")}</Chip>
                    ) : (
                      <Chip color="#EB5A46">{t("utils_hidden")}</Chip>
                    )}
                  </ListCell>

                  <Space />

                  <ListButton
                    icon={faPencilAlt}
                    onClick={() => editLesson(lesson)}
                    text={t("utils_edit")}
                  />

                  <ListButton
                    icon={faTimes}
                    onClick={() => removeLesson(lesson)}
                  />
                </ListItem>
              ))}
            </ListContainer>
          )}
        </Card>
      </PageLoader>
    </NavSupervisor>
  );
}

const mapStateToProps = (state: any) => ({
  _session: state._session,
  learning: state.learning,
  lesson: state.lesson,
});

export default connect(mapStateToProps)(withTranslation()(SupervisorLms));
