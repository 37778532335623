/**
 * DELETE-TEMPLATE.MODAL
 * Delete questionnaire
 */
import Modal from "@/components/modal";
import { connect } from "react-redux";

import { withTranslation, WithTranslation } from "react-i18next";
import { store } from "@/index";
// import store from "@/core/store";
import {
  STATUS_SAVED,
  STATUS_SAVE_ERROR,
  STATUS_SAVING,
} from "@/redux/_status.types";
import {
  templateRemove,
  templateDestroy,
  templateDestroy_AsSupervisor,
  templateStatus,
} from "@/redux/template.actions";
import { TemplateState } from "@/redux/template.types";
import { Session } from "@/redux/_session.types";
import EnterCodeWidget from "@/widgets/enter-code.widget";
import { NavigateFunction, useNavigate } from "react-router-dom";

interface StateProps extends WithTranslation {
  _session: Session;
  template: TemplateState;
}

interface OwnProps {
  onClose: Function;
}

type Props = StateProps & OwnProps;

function DeleteTemplateModal(props: Props) {
  const { t } = props;
  const navigate: NavigateFunction = useNavigate();

  function close() {
    props.onClose();
  }

  //Delete
  //Check destroy function that is different in supervisor context
  async function next() {
    const deleteFunction =
      props._session.interfaceType === "SUPERVISOR"
        ? templateDestroy_AsSupervisor
        : templateDestroy;
    store.dispatch(templateStatus(STATUS_SAVING));
    const response: any = await store.dispatch(
      deleteFunction(props.template.active.id)
    );

    if (response.error) {
      store.dispatch(templateStatus(STATUS_SAVE_ERROR));
    } else {
      store.dispatch(templateStatus(STATUS_SAVED));
      store.dispatch(templateRemove(props.template.active.id));
      navigate(
        props._session.interfaceType === "SUPERVISOR"
          ? "/supervisor/templates"
          : "/survey/account"
      );
      close();
    }
  }

  return props.template.active.fromSupervisor &&
    props._session.interfaceType !== "SUPERVISOR" ? (
    <Modal isCloseButtonVisible onClose={close}>
      <b>{t("template_delete_impossible")}</b>
    </Modal>
  ) : (
    <Modal onClose={close} title={t("template_delete")}>
      <p>
        <b>{t("template_delete_confirm")}</b>
      </p>

      <EnterCodeWidget
        onCancel={close}
        onNext={next}
        status={props.template.status}
      />
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  _session: state._session,
  template: state.template,
});

export default connect(mapStateToProps)(withTranslation()(DeleteTemplateModal));
