import { USER_LIST_GENDER } from "@/redux/user.types";
import getUserFilterDates, {
  UserFilterDates,
} from "./get-user-filter-dates.utils";
import { Attribute } from "@/redux/attribute.types";
import { groupBy } from "lodash";
import { DashboardFilters, FilterLite } from "@/redux/filter.types";
import { store } from "@/index";
// import store from "@/core/store";
import { t } from "@/translate/t";

export type FilterDescription = { id: string; values: string[] };

export function generateDescription(
  dashboardFilters: DashboardFilters,
  referenceDate: Date
): FilterDescription[] {
  //Get params for store
  const attributes: Attribute[] =
    dashboardFilters.customFilters.length > 0
      ? store
          .getState()
          .attribute.list.filter((attribute: Attribute) =>
            dashboardFilters.customFilters
              .map((filter: FilterLite) => filter.id)
              .includes(attribute.filterName)
          )
      : [];

  const description: FilterDescription[] = [];
  if (dashboardFilters.isEmpty) {
    return description;
  } else {
    if (dashboardFilters.gender) {
      const genderName: any = USER_LIST_GENDER.find(
        (x: any) => x.id === dashboardFilters.gender
      );
      description.push({
        id: t("user_gender"),
        values: [genderName ? t(genderName.name) : t("utils_error")],
      });
    }
    if (dashboardFilters.birthDate) {
      const birthFilterDate: UserFilterDates | undefined = getUserFilterDates(
        "BIRTH_DATE",
        referenceDate
      ).find((x: UserFilterDates) => x.id === dashboardFilters.birthDate);
      description.push({
        id: t("user_birth_date_alt"),
        values: [birthFilterDate ? birthFilterDate.name : t("utils_error")],
      });
    }
    if (dashboardFilters.companyWelcomeDate) {
      const companyFilterDate: UserFilterDates | undefined = getUserFilterDates(
        "COMPANY_WELCOME_DATE",
        referenceDate
      ).find(
        (x: UserFilterDates) => x.id === dashboardFilters.companyWelcomeDate
      );
      description.push({
        id: t("user_company_welcome_date_alt"),
        values: [companyFilterDate ? companyFilterDate.name : t("utils_error")],
      });
    }
    if (attributes.length > 0) {
      const groupedAttributes: any = groupBy(attributes, "filterName");

      dashboardFilters.customFilters.forEach((x: FilterLite) => {
        description.push({
          id: x.id,
          values: groupedAttributes[x.id]
            ? groupedAttributes[x.id]
                .filter((attribute: Attribute) =>
                  x.attributesIds.map((x: any) => x + "").includes(attribute.id)
                )
                .map((x: Attribute) => x.name)
            : [t("utils_error")],
        });
      });
    }

    return description;
  }
}
