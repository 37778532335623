/**
 * REPORT-PAGE-PREVIEW.WIDGET.TSX
 * Preview of the slide
 */
import { Page, PageState } from "@/redux/page.types";
import env from "@/env";
import { connect } from "react-redux";
import { Session } from "@/redux/_session.types";
import ReportPageBackgroundWidget from "./report-page-background.widget";
import ReportPageContentWidget from "./report-page-content.widget";
import { pageEdit, pageReplaceTag } from "@/redux/page.actions";
import { store } from "@/index";
// import store from "@/core/store";
import { useState } from "react";
import TextInput from "@/components/text-input";
import ReportPageParamsWidget from "./report-page-params.widget";

interface StateProps {
  _session: Session;
  page: PageState;
}

interface OwnProps {
  currentPage: Page;
  isSmall?: boolean;
}

type Props = StateProps & OwnProps;

function ReportPagePreviewWidget(props: Props) {
  //Edit the name of the page when clicking on the title
  const [isNameEdited, setIsNameEdited] = useState(false);

  //Edit the name
  function editName(value: string) {
    store.dispatch(pageEdit("name", value));
  }

  function getContentStyle() {
    let transform;
    let width;

    if (props.isSmall) {
      transform = "scale(0.28) translate(-132%, -132%)";
      width = 682;
    }

    return {
      transform,
      width,
    };
  }

  return (
    <div
      style={{
        position: "relative",
        height: "inherit",
      }}
    >
      <div
        className="report-page-preview flex flex-dcol rel"
        id={"image-exportable-" + props.currentPage.id}
      >
        {!props.isSmall && (
          <div className="flex">
            {props._session.accountOptions.image && (
              <img
                alt="account"
                className="page-navigation-circle-img"
                src={env.REACT_APP_URL_SPACE.concat(
                  "/Accounts/",
                  props._session.accountId,
                  ".png?" + props._session.logoLastUpdate
                )}
                style={{
                  backgroundColor: props._session.accountColors.brandPrimary,
                  padding: 4,
                }}
              ></img>
            )}

            <div className="width-10" />

            {isNameEdited ? (
              <TextInput
                onChange={(e) => editName(e.value)}
                autoFocus
                onBlur={() => setIsNameEdited(false)}
                value={props.page.active.name}
              />
            ) : (
              <div className="flex flex1">
                <div
                  className="_hover"
                  onClick={() => setIsNameEdited(true)}
                  style={{
                    marginTop: 14,
                    fontSize: 18,
                    fontWeight: 500,
                    fontVariant: "small-caps",
                  }}
                >
                  {pageReplaceTag(props.currentPage.name)}
                </div>

                <ReportPageParamsWidget />
              </div>
            )}
          </div>
        )}

        <div className="flex flex1" style={getContentStyle()}>
          <ReportPageContentWidget
            pageContent={props.currentPage.contentLeft}
            isSmall={props.isSmall}
            currentPage={props.currentPage}
          />

          {props.currentPage.twoColumns && (
            <ReportPageContentWidget
              pageContent={props.currentPage.contentRight}
              isSmall={props.isSmall}
              currentPage={props.currentPage}
              isRight
            />
          )}
        </div>
      </div>

      {props.currentPage.backgroundId && (
        <div className="report-page-preview-background">
          <ReportPageBackgroundWidget
            backgroundId={props.currentPage.backgroundId}
          />
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  _session: state._session,
  page: state.page,
});

export default connect(mapStateToProps)(ReportPagePreviewWidget);
