/**
 * FETCH-ARCHIVE-FILTERS
 * Get filters from archive database and format them
 */

import { store } from "@/index";
// import store from "@/core/store";
import { sortBy } from "lodash";
import { DashboardFilters, Filter } from "@/redux/filter.types";
import { fetchFilters } from "@/redux/_archive.actions";

const fetchArchiveFilters = async (
  dashboardFilters: DashboardFilters,
  surveyId: string
) => {
  const filters: any = await store.dispatch(
    fetchFilters(dashboardFilters, surveyId)
  );
  if (filters.error) {
    return [];
  } else {
    const newFilters: Filter[] = [];
    filters.forEach((filter) => {
      filter = new Filter(filter);
      filter.id = filter.name;
      filter.isChecked = true;
      newFilters.push(filter);
    });

    return sortBy(newFilters, "name");
  }
};

export default fetchArchiveFilters;
