/**
 * REPORT-ADD.MODAL
 * Create new Report
 */

import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import Modal from "@/components/modal";
import { Session } from "@/redux/_session.types";
import Dropdown from "@/components/dropdown";
import { Survey, SurveyState } from "@/redux/survey.types";
import { store } from "@/index";
// import store from "@/core/store";
import { v4 as uuid } from "uuid";
import { surveyActivate } from "@/redux/survey.actions";
import { useEffect, useState } from "react";
import CardContainer from "@/components/card-container";
import CardButton from "@/components/card-button";
import {
  faClipboard,
  faCopy,
  faFile,
  faFileAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  reportActivate,
  reportCreateFromTemplate,
  reportFetch,
  reportFetchTemplates,
  reportStatusMessage,
  reportUpdate,
  reportUpdate_AsSupervisor,
} from "@/redux/report.actions";
import { Report, ReportState } from "@/redux/report.types";
import LoadingModal from "./loading.modal";
import { Page } from "@/redux/page.types";
import { FilterState } from "@/redux/filter.types";
import Space from "@/components/space";
import { orderBy } from "lodash";
import SelectPopulationsModal from "./select-populations.modal";
import { Population } from "@/redux/population.types";
import { populationsUpdateWithAid } from "@/redux/population.actions";
import UpgradeModal from "./upgrade.modal";
import ModalConfirm from "@/components/modal-confirm";
import { NavigateFunction, useNavigate } from "react-router-dom";
import ReportGetDataModal from "./report-get-data.modal";

interface StateProps extends WithTranslation {
  _session: Session;
  filter: FilterState;
  report: ReportState;
  survey: SurveyState;
}

interface OwnProps {
  isSurveySelected?: boolean;
  onClose: Function;
  onNext: Function;
}

type Props = StateProps & OwnProps;

const MODAL_GET_REPORT_DATA = "MODAL_GET_REPORT_DATA";
const MODAL_EXISTING_REPORT = "MODAL_EXISTING_REPORT"; //If a report already exists for the survey
const MODAL_SELECT_TEMPLATE = "MODAL_SELECT_TEMPLATE";
const MODAL_SELECT_POPULATIONS = "MODAL_SELECT_POPULATIONS";
const MODAL_UPGRADE = "MODAL_UPGRADE";

function ReportAddModal(props: Props) {
  const { t } = props;
  const navigate: NavigateFunction = useNavigate();

  //The steps for the modal
  //1 => select survey
  //2 => Create automatic wizard
  const [wizardStep, setWizardStep] = useState(props.isSurveySelected ? 2 : 1);

  //Data is saving (if build from a template)
  const [isSaving, setIsSaving] = useState(false);

  //Is widget loading
  const [isLoading, setIsLoading] = useState(true);

  //Current modal
  const [currentModal, setCurrentModal] = useState<string | null>(null);

  //Templates for observer
  const [templates, setTemplates] = useState<Report[]>([]);

  //Selected template for observer
  const [selectedTemplate, setSelectedTemplate] = useState<null | Report>(null);

  //Default template
  const [defaultTemplate, setDefaultTemplate] = useState(new Report());

  //On load search available template (for observer only)
  //If a survey is selected load also reports
  useEffect(() => {
    async function loadData() {
      if (props._session.interfaceType !== "SUPERVISOR") {
        const response: any = await store.dispatch(
          reportFetchTemplates(props._session.userRole === "OBSERVER")
        );
        if (!response.error) {
          const dt = getDefaultTemplate(response);
          setDefaultTemplate(dt ? dt : new Report());
          setSelectedTemplate(dt);
          setTemplates(orderBy(response, "default").reverse());
        }
      }

      if (props.isSurveySelected) {
        const response: any = await store.dispatch(reportFetch());
        const surveyReport = response.error
          ? null
          : response.find((x) => x.SurveyId === props.survey.active.id);
        if (surveyReport) {
          store.dispatch(reportActivate(surveyReport));
          setCurrentModal(MODAL_EXISTING_REPORT);
        }
      }

      setIsLoading(false);
    }

    if (props._session.interfaceType === "SUPERVISOR") {
      createReport([]);
    } else {
      loadData();
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props._session.interfaceType, props.survey.active.id]);

  //Create new report
  async function createReport(populations: Population[]) {
    setIsSaving(true);
    store.dispatch(reportStatusMessage(t("")));

    //Init new report
    let pages: Page[] = [];
    const reportId = uuid();
    const report = new Report({
      id: reportId,
      name: selectedTemplate ? selectedTemplate.name : props.survey.active.name,
      SurveyId: props.survey.active.id,
      AccountId: props._session.accountId,
      isAutoGenerated: selectedTemplate !== null,
      observerId:
        props._session.userRole === "OBSERVER" ? props._session.userId : null,
      timestamp: Date.now().toString(),
    });

    //Add pages if this one is auto generated from a template
    if (selectedTemplate) {
      pages = await reportCreateFromTemplate(
        selectedTemplate.Pages,
        selectedTemplate.pagesOrder
      );
      report.pagesOrder = pages.map((x) => x.id);
      report.options.populations = populationsUpdateWithAid(populations);
    }

    //Set the report as activ (in order to load pages when we are on the report edit page)
    store.dispatch(reportActivate(report));

    //Go to save
    store.dispatch(reportStatusMessage(t("report_status_message_saving")));
    const updateFunction =
      props._session.interfaceType === "SUPERVISOR"
        ? reportUpdate_AsSupervisor
        : reportUpdate;
    const response: any = await store.dispatch(updateFunction(report, pages));
    store.dispatch(reportStatusMessage(""));

    if (!response.error) {
      props.onNext();
    } else {
      props.onClose();
    }
  }

  //Get default template
  function getDefaultTemplate(inputTemplates: Report[]) {
    if (inputTemplates.length === 1) {
      const template = inputTemplates[0];
      template.default = true;
      return template;
    } else {
      const item = inputTemplates.find((x) => x.default);
      if (item) {
        return item;
      } else {
        const languageTemplate = inputTemplates.find(
          (x) => x.options.language === props._session.language
        );
        if (languageTemplate) {
          languageTemplate.default = true;
          return languageTemplate;
        } else {
          return null;
        }
      }
    }
  }

  //Get description of the selected template
  function getSelectedTemplateDescription() {
    return (
      <div
        className="flex"
        style={{
          marginTop: 20,
          textAlign: "center",
          color: props._session.accountColors.active,
        }}
      >
        <Space />
        <div style={{ width: 400 }}>
          {selectedTemplate && selectedTemplate.options.description}
        </div>
        <Space />
      </div>
    );
  }

  //Next step
  //If under step 2 change step, else to report page
  async function next() {
    //Specific use case
    //For observer if no template is defined go straight to report generator
    if (wizardStep === 1 && props._session.userRole === "OBSERVER") {
      createReport([]);
    }
    //Change step
    else if (wizardStep < 2) {
      setWizardStep(wizardStep + 1);
    }
    //End of the process => create the template
    else {
      createReport([]);
    }
  }

  //Select template
  //If population display population modal
  function selectTemplate() {
    if (selectedTemplate?.options.isForPopulations) {
      setCurrentModal(MODAL_SELECT_POPULATIONS);
    } else {
      createReport([]);
    }
  }

  //Button for no template
  function renderNoTemplateButton() {
    return (
      <CardButton
        isActive={selectedTemplate === null}
        title={t("report_is_auto_generated_false")}
        isLock={!props._session.modules.report}
        icon={faFile}
        onClick={
          props._session.modules.report
            ? () => setSelectedTemplate(null)
            : () => setCurrentModal(MODAL_UPGRADE)
        }
      />
    );
  }

  return isLoading ? (
    <LoadingModal />
  ) : (
    <Modal
      title={t("report_edit")}
      isCloseButtonVisible
      onClose={props.onClose}
      onNext={() => next()}
    >
      {isSaving && (
        <LoadingModal>
          <div
            className="grey-t"
            style={{ textAlign: "center", marginTop: 14 }}
          >
            <b>{props.report.statusMessage}</b>
          </div>
        </LoadingModal>
      )}

      {currentModal === MODAL_UPGRADE && (
        <UpgradeModal feature="report" onClose={() => setCurrentModal(null)} />
      )}

      {currentModal === MODAL_EXISTING_REPORT && (
        <ModalConfirm
          text={t("report_existing")}
          textBold={t("utils_next_ask")}
          onNo={() => setCurrentModal(null)}
          onYes={() => setCurrentModal(MODAL_GET_REPORT_DATA)}
        ></ModalConfirm>
      )}

      {currentModal === MODAL_GET_REPORT_DATA && (
        <ReportGetDataModal onClose={() => navigate("/report/edit")} />
      )}

      {currentModal === MODAL_SELECT_TEMPLATE && (
        <Modal
          onClose={() => setCurrentModal(null)}
          isCloseButtonVisible
          title={t("report_select_template")}
          onNext={() => selectTemplate()}
        >
          <div style={{ height: 268 }}>
            <CardContainer>
              {templates.map((report) => (
                <CardButton
                  isActive={selectedTemplate?.id === report.id}
                  key={report.id}
                  title={report.name}
                  icon={faClipboard}
                  onClick={() => setSelectedTemplate(report)}
                />
              ))}
            </CardContainer>

            {getSelectedTemplateDescription()}
          </div>
        </Modal>
      )}

      {currentModal === MODAL_SELECT_POPULATIONS && (
        <SelectPopulationsModal
          onClose={() => setCurrentModal(null)}
          title={t("report_population_title")}
          subtitle={t("report_population_subtitle")}
          isWithoutDefaultFilters
          isSurveyActiveSelected
          selectedPopulations={[]}
          onNext={(populations: Population[]) => createReport(populations)}
        />
      )}

      <div style={{ height: 268 }}>
        {wizardStep === 1 && (
          <div className="abs">
            <Dropdown
              active={props.survey.active.id}
              displayField="name"
              isNullAllowed
              list={props.survey.list}
              title={t("report_survey")}
              onSelect={(survey: Survey) =>
                store.dispatch(surveyActivate(survey))
              }
              status={props.survey.status}
              value={props.survey.active.name}
            ></Dropdown>
          </div>
        )}

        {wizardStep === 2 && (
          <div>
            {props._session.userRole === "ADMIN" ? (
              <CardContainer>
                <CardButton
                  isActive={selectedTemplate?.default}
                  title={defaultTemplate.name}
                  icon={faFileAlt}
                  onClick={() => setSelectedTemplate(defaultTemplate)}
                />

                {templates.length > 1 && (
                  <CardButton
                    isActive={false}
                    title={t("report_is_auto_generated_true")}
                    isLock={!props._session.modules.report}
                    icon={faCopy}
                    onClick={() =>
                      setCurrentModal(
                        props._session.modules.report
                          ? MODAL_SELECT_TEMPLATE
                          : MODAL_UPGRADE
                      )
                    }
                  />
                )}

                {renderNoTemplateButton()}
              </CardContainer>
            ) : (
              <CardContainer>
                {templates.map((template) => (
                  <CardButton
                    key={template.id}
                    isActive={template.id === selectedTemplate?.id}
                    title={template.name}
                    icon={faCopy}
                    onClick={() => setSelectedTemplate(template)}
                  />
                ))}

                {renderNoTemplateButton()}
              </CardContainer>
            )}

            {getSelectedTemplateDescription()}
          </div>
        )}
      </div>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  _session: state._session,
  filter: state.filter,
  report: state.report,
  survey: state.survey,
});

export default connect(mapStateToProps)(withTranslation()(ReportAddModal));
