/**
 * GET-HEATMAP-CEILS
 * Get page name according to route and filteres
 */

import { store } from "@/index";
// import store from "@/core/store";

//Get name of the filters active
function getHeatmapCeils(filterId) {
  const options = store.getState()._session.accountOptions;
  switch (filterId) {
    case "birth_date":
      return options.ceilsBirthDate.join(",");
    case "company_welcome_date":
      return options.ceilsCompanyWelcomeDate.join(",");
    default:
      return "";
  }
}

export default getHeatmapCeils;
