/**
 * DASHBOARD-ATTRIBUTES
 * Results by attributes (heatmap)
 */
import NavDashboard from "@/nav/nav.dashboard";
import DashboardHeatmapWidget from "@/widgets/dashboard-heatmap.widget";

function DashboardHeatmapRoute() {
  const searchParams: URLSearchParams = new URLSearchParams(
    window.location.search
  );
  const filterName: string | null = searchParams.get("filter_name");
  const resultsType: string | null = searchParams.get("results_type");

  return (
    <NavDashboard
      title="dashboard_heatmap"
      hideOptions
      hideFilters
      isFullscreen
    >
      <DashboardHeatmapWidget
        filterName={filterName!}
        resultsType={resultsType!}
      />
    </NavDashboard>
  );
}

export default DashboardHeatmapRoute;
