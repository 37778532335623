/**
 * PROJECT-EMAIL-LIST.WIDGET
 * List of emails for the account
 */

import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import { store } from "@/index";
// import store from "@/core/store";
import { STATUS_SAVED, STATUS_SAVING } from "@/redux/_status.types";
import ListItem from "@/components/list-item";
import { v4 as uuid } from "uuid";
import { projectEdit, projectStatus } from "@/redux/project.actions";
import { Email, EmailState } from "@/redux/email.types";
import {
  emailActivate,
  emailAdd,
  emailDestroy,
  emailEdit,
  emailGet,
  emailRemove,
  emailSendTest,
  emailUpdate,
} from "@/redux/email.actions";
import { ProjectState } from "@/redux/project.types";
import Link from "@/components/link";
import { WithTranslation, withTranslation } from "react-i18next";
import {
  faExclamationTriangle,
  faPaperPlane,
  faPen,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { Session } from "@/redux/_session.types";
import Space from "@/components/space";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import ModalConfirm from "@/components/modal-confirm";
import ProjectAddEmailModal from "@/modals/project-add-email.modal";
import { Draggable, Droppable, DragDropContext } from "react-beautiful-dnd";
import Modal from "@/components/modal";
import TextInput from "@/components/text-input";
import { toast } from "react-toastify";
import { validateEmail } from "@/utils/validate-email.utils";
import Dropdown from "@/components/dropdown";
import i18n from "@/translate/i18n";
import Notification from "@/components/notification";

interface StateProps extends WithTranslation {
  _session: Session;
  email: EmailState;
  project: ProjectState;
}

interface OwnProps {
  onEdit?: Function;
}

type Props = StateProps & OwnProps;

const MODAL_ADD_EMAIL: string = "MODAL_ADD_EMAIL";
const MODAL_DELETE_EMAIL: string = "MODAL_DELETE_EMAIL";
const MODAL_RENAME_EMAIL: string = "MODAL_RENAME_EMAIL";
const MODAL_TEST_EMAIL: string = "MODAL_TEST_EMAIL";

function ProjectEmailListWidget(props: Props) {
  const { t } = props;

  const [currentModal, setCurrentModal] = useState<string | null>(null);
  const [emailAddressTest, setEmailAddressTest] = useState<string>(
    props._session.email
  );
  const [language, setLanguage] = useState<string>(
    props.project.active.languages.length
      ? props.project.active.languages[0]
      : i18n.language
  );
  const [emailName, setEmailName] = useState<string>("");

  async function addEmail(email: Email) {
    email.id = uuid();
    email.name = email.name + " - " + t("utils_copy");
    store.dispatch(emailAdd(email));
    setCurrentModal(null);
    store.dispatch(projectStatus(STATUS_SAVING));
    await store.dispatch(emailUpdate(props.project.active.id, email));
    store.dispatch(projectStatus(STATUS_SAVED));
  }

  function checkEmail(email: Email, select: boolean) {
    if (!email.isChecked) {
      email.isChecked = true;
      store.dispatch(
        emailGet(
          props.email.list.map((x) => (x.id === email.id ? email : x)),
          props.project.active.emailsOrder
        )
      );
      store.dispatch(emailUpdate(props.project.active.id, email));
    }

    if (select) {
      store.dispatch(emailActivate(email));
    }
  }

  function checkFieldEmptiness(email: Email): boolean {
    return props.project.active.languages.some(
      (lang: string) => new Email(email).getEmptyFields(lang).length > 0
    );
  }

  //Delete active email
  async function deleteEmail() {
    store.dispatch(emailRemove(props.email.active.id));
    store.dispatch(projectStatus(STATUS_SAVING));
    await store.dispatch(emailDestroy(props.email.active.id));
    store.dispatch(projectStatus(STATUS_SAVED));
    setCurrentModal(null);

    if (props.onEdit) {
      props.onEdit();
    }
  }

  function editEmailName(email: Email) {
    checkEmail(email, true);
    setEmailName(email.name);
    setCurrentModal(MODAL_RENAME_EMAIL);
  }

  function editName() {
    store.dispatch(emailEdit("name", emailName));
    save(props.email.active);
  }

  async function save(email: Email) {
    await setTimeout(() => {}, 500);
    store.dispatch(projectStatus(STATUS_SAVING));
    await store.dispatch(emailUpdate(props.project.active.id, email));
    store.dispatch(projectStatus(STATUS_SAVED));
    setCurrentModal(null);
  }

  async function sendTest() {
    if (!props.project.active.TemplateId) {
      toast(t("template_empty"), { type: "error" });
    } else if (!validateEmail(emailAddressTest)) {
      toast(t("utils_email_invalid"), { type: "error" });
    } else {
      setCurrentModal(null);
      const response: any = await store.dispatch(
        emailSendTest(
          props.email.active.id,
          emailAddressTest,
          language,
          props.project.active.emailSkin
        )
      );
      if (!response.error) {
        toast(t("utils_email_send", { email: emailAddressTest }));
      }
    }
  }

  //Change order between two emails
  function swap(e) {
    if (e.destination) {
      const currentIndex = props.email.list.findIndex(
        (x) => x.id === e.destination.droppableId
      );
      let emailsOrder = props.project.active.emailsOrder.filter(
        (x) => x !== e.draggableId
      );
      emailsOrder = emailsOrder
        .slice(0, currentIndex)
        .concat([e.draggableId])
        .concat(emailsOrder.slice(currentIndex));

      store.dispatch(projectEdit("emailsOrder", emailsOrder));
      store.dispatch(emailGet(props.email.list, emailsOrder));

      if (props.onEdit) {
        props.onEdit();
      }
    }
  }

  return (
    <div
      style={{
        maxWidth: 460,
        minWidth: 460,
      }}
    >
      <DragDropContext onDragEnd={(e) => swap(e)}>
        {currentModal === MODAL_ADD_EMAIL && (
          <ProjectAddEmailModal
            onClose={() => setCurrentModal(null)}
            onNext={(email: Email) => addEmail(email)}
          />
        )}

        {currentModal === MODAL_DELETE_EMAIL && (
          <ModalConfirm
            onNo={() => setCurrentModal(null)}
            onYes={deleteEmail}
            text={t("email_ask_delete", {
              name: props.email.active.name,
            })}
            textBold={t("utils_next_ask")}
          />
        )}

        {currentModal === MODAL_RENAME_EMAIL && (
          <Modal
            disableClickOutside
            isCloseButtonVisible
            onClose={() => setCurrentModal(null)}
            onNext={
              emailName.length < 1
                ? () =>
                    toast(t("email_invitation_name_null"), { type: "error" })
                : editName
            }
            status={props.project.status}
            title={t("email_invitation_rename")}
          >
            <div className="flex">
              <Space />
              <div>
                <TextInput
                  value={emailName}
                  onChange={(e: any) => setEmailName(e.value)}
                  error={emailName.length < 1}
                />
                {emailName === "" && (
                  <b className="red-t">{t("email_invitation_name_null")}</b>
                )}
              </div>
              <Space />
            </div>
          </Modal>
        )}

        {currentModal === MODAL_TEST_EMAIL && (
          <Modal
            title={t("email_test")}
            isCloseButtonVisible
            onClose={() => setCurrentModal(null)}
            onNext={sendTest}
          >
            {props.project.active.languages.length > 1 && (
              <Dropdown
                value={language.toUpperCase()}
                title={t("user_language")}
                displayField="name"
                activeValue={language}
                list={props.project.active.languages.map((x) => {
                  return { id: x, name: x.toUpperCase() };
                })}
                onSelect={(e) => setLanguage(e.id)}
              />
            )}

            <TextInput
              title={t("email_test_address")}
              error={!validateEmail(emailAddressTest)}
              value={emailAddressTest}
              onChange={(e) => setEmailAddressTest(e.value)}
            />

            <div className="height-100" />
          </Modal>
        )}

        {props.email.list.map((email: Email) => (
          <Droppable droppableId={email.id} type="EMAIL" key={email.id}>
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <Draggable draggableId={email.id} index={0}>
                  {(provided) => (
                    <div
                      className="_hover_drag"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <div
                        key={email.id}
                        onClick={() => checkEmail(email, true)}
                        style={{
                          padding: "20px 30px",
                          color:
                            props.email.active.id === email.id
                              ? props._session.accountColors.active
                              : "",
                        }}
                        className="flex rel"
                      >
                        <div
                          className="_hover"
                          onClick={() => editEmailName(email)}
                        >
                          {email.name + "  "}
                          {props.email.active.id === email.id && (
                            <FontAwesomeIcon icon={faPen} />
                          )}
                        </div>

                        <Space />

                        {checkFieldEmptiness(email) && (
                          <FontAwesomeIcon
                            data-tip={t("email_has_empty_fields")}
                            className="_hover grey-t"
                            icon={faExclamationTriangle}
                          />
                        )}

                        <div className="width-20" />

                        <FontAwesomeIcon
                          data-for={email.id + "-test"}
                          data-tip={t("email_test")}
                          onClick={() => setCurrentModal(MODAL_TEST_EMAIL)}
                          className="_hover grey-t"
                          icon={faPaperPlane}
                        />
                        <ReactTooltip id={email.id + "-test"} />

                        <div className="width-20" />

                        <FontAwesomeIcon
                          data-for={email.id + "-delete"}
                          data-tip={t("email_delete")}
                          onClick={() => setCurrentModal(MODAL_DELETE_EMAIL)}
                          className="_hover grey-t"
                          icon={faTrash}
                        />
                        <ReactTooltip id={email.id + "-delete"} />

                        <Notification
                          onHover={() => checkEmail(email, false)}
                          isHidden={email.isChecked}
                        />
                      </div>
                    </div>
                  )}
                </Draggable>

                {provided.placeholder}
              </div>
            )}
          </Droppable>
        ))}
      </DragDropContext>

      <ListItem>
        <Link
          onClick={() => setCurrentModal(MODAL_ADD_EMAIL)}
          icon={faPlus}
          isWithoutMargin
        >
          {t("email_add")}
        </Link>
      </ListItem>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  _session: state._session,
  email: state.email,
  project: state.project,
});

export default connect(mapStateToProps)(
  withTranslation()(ProjectEmailListWidget)
);
