/**
 * ADD-TOPIC.MODAL
 * Add a new card for a questionnaire
 */
import Modal from "@/components/modal";
import { connect } from "react-redux";
import { withTranslation, WithTranslation } from "react-i18next";
import { AxisState } from "@/redux/axis.types";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { store } from "@/index";
// import store from "@/core/store";
import { topicAdd } from "@/redux/topic.actions";
import { Session } from "@/redux/_session.types";
import { axisActivate } from "@/redux/axis.actions";
import { TemplateState } from "@/redux/template.types";
import Chip from "@/components/chip";
import Link from "@/components/link";

interface StateProps extends WithTranslation {
  _session: Session;
  axis: AxisState;
  template: TemplateState;
}

interface OwnProps {
  onAddAxis: Function;
  onClose: Function;
}

type Props = StateProps & OwnProps;

function AddTopicModal(props: Props) {
  const { t } = props;

  function selectAxis(axis) {
    store.dispatch(axisActivate(axis));
    store.dispatch(
      topicAdd(
        props._session.interfaceType === "SUPERVISOR",
        axis,
        true,
        false,
        !props._session.userOptions.activateQuestions
      )
    );
    props.onClose();
  }

  return (
    <Modal isCloseButtonVisible onClose={() => props.onClose()}>
      <b>{t("question_select_axis")}</b>

      <div className="height-20" />

      <div className="flex flex-wrap">
        {props.axis.list.map((axis) => (
          <Chip
            key={axis.id}
            isWithMargin
            color={axis.color}
            onClick={() => selectAxis(axis)}
          >
            {axis.name[props.template.active.language]}
          </Chip>
        ))}

        <Chip isWithMargin icon={faPlus} onClick={() => props.onAddAxis()}>
          {t("axis_add")}
        </Chip>
      </div>

      <div className="height-20" />

      <Link onClick={() => selectAxis(null)} isWithoutMargin>
        {t("utils_later")}
      </Link>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  _session: state._session,
  axis: state.axis,
  template: state.template,
});

export default connect(mapStateToProps)(withTranslation()(AddTopicModal));
