/***
 * PROJECT-EMAIL-CONTENT.WIDGET
 * Preview of email content for an invitation
 */

import { connect } from "react-redux";
import { WithTranslation, withTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Session } from "@/redux/_session.types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { EmailContent, EmailState } from "@/redux/email.types";
import { store } from "@/index";
// import store from "@/core/store";
import ListInput from "@/components/list-input";
import { emailEdit, emailGet, emailUpdate } from "@/redux/email.actions";
import Space from "@/components/space";
import ModalConfirm from "@/components/modal-confirm";
import { ProjectState } from "@/redux/project.types";

interface StateProps extends WithTranslation {
  _session: Session;
  email: EmailState;
  project: ProjectState;
}

interface OwnProps {
  currentLanguage: string;
  emailContent?: EmailContent;
  isEditable?: boolean;
  onBlur?: Function;
  skin: number | null;
}

const MODAL_EDIT_BULK_SENDER = "MODAL_EDIT_BULK_SENDER";

type Props = StateProps & OwnProps;

function ProjectEmailContentWidget(props: Props) {
  const { t } = props;

  //Current modal
  const [currentModal, setCurrentModal] = useState<string | null>(null);

  //Display textarea to edit email
  const [isBodyEdited, setIsBodyEdited] = useState<boolean>(false);

  //Display textarea to edit footer
  const [isFooterEdited, setIsFooterEdited] = useState<boolean>(false);

  //Email skin
  const [skin, setSkin] = useState<number | null>(props.skin);

  useEffect(() => {
    setSkin(props.skin);
  }, [props.skin]);

  function blur() {
    setTimeout(() => {
      if (props.onBlur) {
        props.onBlur();
      }
    }, 500);
  }

  //Edit sender for all emails
  async function editBulkSender() {
    const newEmails = props.email.list.map((x) =>
      Object.assign({}, x, { sender: props.email.active.sender })
    );
    store.dispatch(emailGet(newEmails, props.project.active.emailsOrder));
    setCurrentModal(null);

    for (let i = 0; i < newEmails.length; i++) {
      const newEmail = newEmails[i];
      await store.dispatch(emailUpdate(props.project.active.id, newEmail));
    }
  }

  //Edit content for the email
  function editEmailContent(key: string, value: string) {
    const newEmailContent: EmailContent = new EmailContent(
      props.email.active.content
    );
    newEmailContent[key][props.currentLanguage] = value;
    store.dispatch(emailEdit("content", newEmailContent));
  }

  //Edit sender
  function editSender(value: string) {
    if (props.email.active.sender !== value) {
      setCurrentModal(MODAL_EDIT_BULK_SENDER);
    }

    store.dispatch(emailEdit("sender", value));
  }

  //if email content is passed as props, don't use store
  function getEmailContent(part: string) {
    const emailContent: EmailContent = props.emailContent
      ? props.emailContent
      : props.email.active.content;
    return emailContent[part][props.currentLanguage]
      ? emailContent[part][props.currentLanguage]
      : "";
  }

  return (
    <div className="flex3 survey-email medgrey-b">
      {currentModal === MODAL_EDIT_BULK_SENDER && (
        <ModalConfirm
          onNo={() => setCurrentModal(null)}
          onYes={() => editBulkSender()}
          textBold={t("project_email_edit_sender_bulk")}
        />
      )}

      <div className="survey-email-header medgrey flex">
        <span>
          <b>{t("survey_email_preview")}</b>
        </span>
      </div>

      <div className="survey-email-options medgrey-b">
        <div className="height-10" />

        <div className={"height-20" + (props.isEditable ? " flex" : "")}>
          <b>{t("utils_from", { lng: props.currentLanguage })}</b>
          &nbsp; : &nbsp;
          {props.isEditable ? (
            <ListInput
              isEditButtonVisible
              value={props.email.active.sender}
              onEditEnd={(value) => {
                editSender(value);
                blur();
              }}
            />
          ) : (
            props.email.active.sender
          )}
        </div>

        <div className="height-10" />

        <div className={"height-20" + (props.isEditable ? " flex" : "")}>
          <b>{t("survey_email_subject", { lng: props.currentLanguage })}</b>
          &nbsp; : &nbsp;
          {props.isEditable ? (
            <ListInput
              isEditButtonVisible
              value={getEmailContent("subject")}
              onEditEnd={(value) => {
                editEmailContent("subject", value);
                blur();
              }}
            />
          ) : (
            getEmailContent("subject")
          )}
        </div>

        <div className="height-10" />
      </div>

      <div className="survey-email-content">
        {skin ? (
          <div
            className="survey-email-titlefat flex flex-dcol"
            style={{
              backgroundColor: props._session.accountColors.email,
              color: "white",
              width: "calc(100% + 80px)",
              height: 100,
              marginTop: -10,
              marginLeft: -40,
            }}
          >
            <Space />
            {props.isEditable ? (
              <ListInput
                isEditButtonVisible
                value={getEmailContent("title")}
                onEditEnd={(value) => {
                  editEmailContent("title", value);
                  blur();
                }}
              />
            ) : (
              <b>{getEmailContent("title")}</b>
            )}
            <Space />
          </div>
        ) : (
          <div
            className="survey-email-titlefat"
            style={{ color: props._session.accountColors.email }}
          >
            {props.isEditable ? (
              <ListInput
                isEditButtonVisible
                value={getEmailContent("title")}
                onEditEnd={(value) => {
                  editEmailContent("title", value);
                  blur();
                }}
              />
            ) : (
              <b>{getEmailContent("title")}</b>
            )}
          </div>
        )}

        <div className="flex">
          <div className="flex1">
            {isBodyEdited ? (
              <textarea
                autoFocus
                onBlur={() => {
                  setIsBodyEdited(false);
                  blur();
                }}
                onChange={(event: any) =>
                  editEmailContent("body", event.target.value)
                }
                rows={skin === 2 ? 25 : 16}
                value={getEmailContent("body")}
                placeholder={t("email_content_body")}
              ></textarea>
            ) : (
              <p
                className={"grey-t" + (props.isEditable ? " _hover" : "")}
                onClick={
                  props.isEditable ? () => setIsBodyEdited(true) : undefined
                }
              >
                {getEmailContent("body")}

                {props.isEditable && (
                  <span className="survey-email-edit-block">
                    <FontAwesomeIcon icon={faPencilAlt} />
                  </span>
                )}
              </p>
            )}
          </div>

          {skin === 2 && (
            <div className="flex1">
              <img
                style={{ marginLeft: 10 }}
                src={store.getState().project.active.picture}
                width={200}
                height={200}
                alt={store.getState().template.active.label}
              />
            </div>
          )}
        </div>

        <div className="flex">
          <div
            className={
              "survey-email-button" + (props.isEditable ? " _hover" : "")
            }
            style={{
              backgroundColor: skin
                ? props._session.accountColors.email
                : "white",
              borderColor: props._session.accountColors.email,
              color: skin ? "white" : props._session.accountColors.email,
            }}
          >
            {props.isEditable ? (
              <ListInput
                isEditButtonVisible
                value={getEmailContent("cta")}
                onEditEnd={(value) => {
                  editEmailContent("cta", value);
                  blur();
                }}
              />
            ) : (
              getEmailContent("cta")
            )}
          </div>

          {skin === 2 && <Space />}
        </div>

        {skin ? (
          <div className="height-20" />
        ) : (
          <div className="flex">
            <div
              className={
                "survey-email-button" + (props.isEditable ? " _hover" : "")
              }
              style={{
                color: props._session.accountColors.email,
                borderColor: props._session.accountColors.email,
              }}
            >
              {props.isEditable ? (
                <ListInput
                  isEditButtonVisible
                  value={getEmailContent("info")}
                  onEditEnd={(value) => {
                    editEmailContent("info", value);
                    blur();
                  }}
                />
              ) : (
                getEmailContent("info")
              )}
            </div>
          </div>
        )}

        <div
          style={
            skin
              ? {
                  backgroundColor: "#eff2f7",
                  width: "calc(100% + 0px)",
                  height: 100,
                  marginBottom: -10,
                  marginLeft: -40,
                  padding: "10px 40px",
                }
              : {
                  marginTop: 18,
                }
          }
        >
          {isFooterEdited ? (
            <textarea
              autoFocus
              onBlur={() => {
                setIsFooterEdited(false);
                blur();
              }}
              onChange={(event: any) =>
                editEmailContent("footer", event.target.value)
              }
              value={getEmailContent("footer")}
              rows={5}
              placeholder={t("email_content_footer")}
            ></textarea>
          ) : (
            <p
              className={"grey-t" + (props.isEditable ? " _hover" : "")}
              onClick={
                props.isEditable ? () => setIsFooterEdited(true) : undefined
              }
              style={{ fontSize: "12px" }}
            >
              {getEmailContent("footer")}

              {props.isEditable && (
                <span className="survey-email-edit-block">
                  <FontAwesomeIcon icon={faPencilAlt} />
                </span>
              )}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  _session: state._session,
  email: state.email,
  project: state.project,
});

export default connect(mapStateToProps)(
  withTranslation()(ProjectEmailContentWidget)
);
