import { store } from "@/index";
// import store from "@/core/store";
import { surveyEdit } from "@/redux/survey.actions";
import { SurveyInvitation } from "@/widgets/dashboard-participation.widget";

export function formateParticipation(response: any) {
  if (response.error) {
    store.dispatch(surveyEdit("participationDone", 0));
    store.dispatch(surveyEdit("participationSend", 0));
    store.dispatch(surveyEdit("participationStarted", 0));
    store.dispatch(surveyEdit("participationUndone", 0));
  } else {
    const participationDone: number = response.filter(
      (x: SurveyInvitation) => x.dateDone
    ).length;
    const participationSend: number = response.length;
    const participationStarted: number =
      response.filter((x: SurveyInvitation) => x.dateStarted).length -
      participationDone;
    const participationUndone: number = participationSend - participationDone;

    store.dispatch(surveyEdit("participationDone", participationDone));
    store.dispatch(surveyEdit("participationSend", participationSend));
    store.dispatch(surveyEdit("participationStarted", participationStarted));
    store.dispatch(surveyEdit("participationUndone", participationUndone));
  }
}
