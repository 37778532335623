/**
 * REPORT.ACTIONS
 * Actions about report
 */

import {
  Report,
  REPORT_ACTIVATE,
  REPORT_ADD,
  REPORT_EDIT,
  REPORT_INIT,
  REPORT_GET,
  REPORT_REMOVE,
  REPORT_STATUS,
  REPORT_EDIT_DEFAULT,
  REPORT_STATUS_MESSAGE,
} from "./report.types";
import { PAGE_SIDES, Page, PageContentTag } from "./page.types";
import {
  STATUS_SAVED,
  STATUS_SAVE_ERROR,
  STATUS_SAVING,
} from "./_status.types";
import {
  pageCreateNew,
  pageGet,
  pageInitGenerate,
  pageUpdateTagData,
} from "./page.actions";
import { store } from "@/index";
// import store from "@/core/store";
import { flatMap, indexOf, sortBy, uniq } from "lodash";

/* REDUX */

export const reportActivate = (report: Report) => ({
  type: REPORT_ACTIVATE,
  payload: {
    report,
  },
});

export const reportAdd = (report: Report) => ({
  type: REPORT_ADD,
  payload: {
    report,
  },
});

export const reportEdit = (key: string, value: any) => ({
  type: REPORT_EDIT,
  payload: {
    key,
    value,
  },
});

export const reportEditDefault = (id: string) => ({
  type: REPORT_EDIT_DEFAULT,
  payload: {
    id,
  },
});

export const reportInit = () => ({
  type: REPORT_INIT,
});

export const reportGet = (reports: Report[]) => ({
  type: REPORT_GET,
  payload: {
    reports,
  },
});

export const reportRemove = (id: string) => ({
  type: REPORT_REMOVE,
  payload: {
    id,
  },
});

export const reportStatus = (status: string) => ({
  type: REPORT_STATUS,
  payload: {
    status,
  },
});

export const reportStatusMessage = (message: string) => ({
  type: REPORT_STATUS_MESSAGE,
  payload: {
    message,
  },
});

/** API */

//API

export const reportDelete = (reportId: string) => ({
  type: "API",
  payload: {
    method: "DELETE",
    url: "/report/delete/" + reportId,
  },
});

export const reportDelete_AsSupervisor = (reportId: string) => ({
  type: "API",
  payload: {
    method: "DELETE",
    url: "/supervisor/report/delete/" + reportId,
  },
});

export const reportFetch = () => ({
  type: "API",
  payload: {
    method: "GET",
    url: "/reports",
  },
});

export const reportFetch_AsSupervisor = () => ({
  type: "API",
  payload: {
    method: "GET",
    url: "/supervisor/reports",
  },
});

export const reportFetchTemplates = (asObserver: boolean) => ({
  type: "API",
  payload: {
    method: "GET",
    url: "/reports/templates",
    data: {
      asObserver,
    },
  },
});

export const reportUpdate = (report: Report, pages: Page[]) => ({
  type: "API",
  payload: {
    method: "POST",
    url: "/report/update",
    data: {
      report,
      pages,
    },
  },
});

export const reportUpdateDefault_AsSupervisor = (reportId: string) => ({
  type: "API",
  payload: {
    method: "POST",
    url: "/supervisor/report/update/default",
    data: {
      reportId,
    },
  },
});

export const reportUpdate_AsSupervisor = (report: Report, pages: Page[]) => ({
  type: "API",
  payload: {
    method: "POST",
    url: "/supervisor/report/update",
    data: {
      report,
      pages,
    },
  },
});

//Init pages from a specific template
export async function reportCreateFromTemplate(pageTemplates, pagesOrder) {
  //Sort pageTemplates
  pageTemplates = sortBy(pageTemplates, (template) =>
    indexOf(pagesOrder, template.id)
  );

  let pages: Page[] = [];
  pageTemplates.forEach((page) => {
    pages.push(pageCreateNew(page, store.getState().report.active.id));
  });

  //As observer loop on every page in order to create page
  pages = await pageInitGenerate(pages, true);

  return pages;
}

//Extract all tags used in array of texts
export function reportGetTagsFromTexts(texts: string[]) {
  //Init the tags and the pattern used to extract tags
  const tagPattern = /{{(.*?)}}/g;
  const tags: any[] = [];

  //Push all tags
  texts.forEach((item) => {
    let match;
    while ((match = tagPattern.exec(item)) !== null) {
      tags.push(match[1]);
    }
  });

  //Return values
  return uniq(tags);
}

//Extract all texts used in all the pages of the report
export function reportGetAllTexts(pages: Page[]) {
  let texts = pages.map((x) => x.name);
  PAGE_SIDES.forEach((side) => {
    const textBlocks = flatMap(pages, (x) => x[side].textBlocks);
    texts = texts.concat(textBlocks.map((x) => x.text));
  });

  return texts;
}

//Chain edit and save
export const reportEditAndSave: any =
  (key: string, value: any) => async (dispatch) => {
    dispatch(reportEdit(key, value));
    dispatch(reportSave());
  };

//Set report as default
export const reportEditAndUpdateDefault: any =
  (reportId: string) => async (dispatch) => {
    dispatch(reportEditDefault(reportId));
    dispatch(reportUpdateDefault_AsSupervisor(reportId));
  };

//Save template
export const reportSave: any = () => async (dispatch, getState) => {
  //Update status (trigger saving animation)
  dispatch(reportStatus(STATUS_SAVING));

  //Save order of the pages
  const pagesOrder: string[] = getState().page.list.map(
    (page: Page) => page.id
  );

  //Proceed
  const updateFunction =
    getState()._session.interfaceType === "SUPERVISOR"
      ? reportUpdate_AsSupervisor
      : reportUpdate;
  const response: any = await dispatch(
    updateFunction(
      new Report({
        ...getState().report.active,
        pagesOrder,
      }),
      getState().page.list.filter((x: Page) => x.status !== "saved")
    )
  );

  //If no error
  //> mark all pages as saved
  //> Update status
  if (!response.error) {
    dispatch(
      pageGet(
        getState().page.list.map(
          (x: Page) =>
            new Page({
              ...x,
              status: "saved",
            })
        )
      )
    );

    dispatch(reportEdit("pagesOrder", pagesOrder));
    dispatch(reportEdit("status", "saved"));
    dispatch(reportStatus(STATUS_SAVED));
  } else {
    dispatch(reportStatus(STATUS_SAVE_ERROR));
  }
};

//Function to update the Tags Data
export async function reportUpdateTagsData(tags: PageContentTag[]) {
  //Create new tagsData object that will replace the existing one
  const tagsData = Object.create(store.getState().report.active.tagsData);

  //For each tag updated it if not exist
  for (let i = 0; i < tags.length; i++) {
    const tag = tags[i];
    if (!tagsData[tag]) {
      const tagData = await pageUpdateTagData(tag);
      if (tagData !== "") {
        tagsData[tag] = tagData;
      }
    }
  }

  //Update the store
  return tagsData;
}
