/***
 * PROJECT-MESSAGE.WIDGET
 * Edit introduction message for the survey
 */

import { connect } from "react-redux";
import { WithTranslation, withTranslation } from "react-i18next";
import { Project, ProjectState } from "@/redux/project.types";
import { useState } from "react";
import { store } from "@/index";
// import store from "@/core/store";
import {
  projectCheckMessage,
  projectEdit,
  projectEditMessageOptions,
  projectEditQrcodeOptions,
  projectUpdate,
} from "@/redux/project.actions";
import { Session, SESSION_COLORS } from "@/redux/_session.types";
import { Language } from "@/redux/language.types";
import ModalConfirm from "@/components/modal-confirm";
import TextInput from "@/components/text-input";
import ConfirmButtons from "@/components/confirm-buttons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faPen, faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import LanguageSelection from "@/components/language-selection";
import Space from "@/components/space";
import ContextMenu from "@/components/context-menu";
import TranslatorWidget from "./translator.widget";
import Link from "@/components/link";
import ProjectStepErrorsWidget from "./project-step-errors.widget";
import { TemplateState } from "@/redux/template.types";
import { toast } from "react-toastify";
import DuplicateTemplateAskModal from "@/modals/duplicate-template-ask.modal";
import ListItem from "@/components/list-item";
import Checkbox from "@/components/checkbox";
import ImageSelector from "@/components/image-selector";
import ColorPickerWidget from "./color-picker.widget";
import ImagePicker from "@/components/image-picker";
import UpgradeModal from "@/modals/upgrade.modal";
import { NavigateFunction, useNavigate } from "react-router-dom";
import projectMessageInfo from "@/json/project-message-info.json";

interface StateProps extends WithTranslation {
  _session: Session;
  project: ProjectState;
  template: TemplateState;
}

interface OwnProps {
  onNext?: Function;
  onEdit: Function;
}

type Props = StateProps & OwnProps;

const CONTEXT_AUTO_TRANSLATION: string = "CONTEXT_AUTO_TRANSLATION";
const MODAL_DUPLICATE_TEMPLATE: string = "MODAL_DUPLICATE_TEMPLATE";
const MODAL_EDIT_QRCODE: string = "MODAL_EDIT_QRCODE";
const MODAL_UPGRADE: string = "MODAL_UPGRADE";

function ProjectMessageWidget(props: Props) {
  const { t } = props;
  const navigate: NavigateFunction = useNavigate();

  const [currentLanguage, setCurrentLanguage] = useState(
    props.project.active.languages[0]
  );
  const [currentModal, setCurrentModal] = useState<string | null>(null);
  const [isChanged, setIsChanged] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isVisited, setIsVisited] = useState(false);
  const [imageEdition, setImageEdition] = useState(
    props.project.active.customImage && !props.project.active.image
  );

  function cancel() {
    store.dispatch(
      projectEdit(
        "messageOptions",
        new Project().getInitialMessageOptions(
          props._session.accountName,
          props.project.active.name
        )
      )
    );
    edit();
    next(false, false);
  }

  function edit() {
    store.dispatch(
      projectCheckMessage(
        props.project.active.languages,
        props.project.active.messageOptions
      )
    );
    props.onEdit();
  }

  function editMessage(key: string, value: any) {
    if (props._session.modules.settings || key === "isWithTitleSecondary") {
      store.dispatch(projectEditMessageOptions(key, value));
      setIsChanged(true);
      setTimeout(() => {
        edit();
      }, 200);
    } else {
      toast(t("utils_unavailable"), { type: "error" });
      setCurrentModal(MODAL_UPGRADE);
    }
  }

  function editMessageText(key: string, value: string, language: string) {
    store.dispatch(
      projectEditMessageOptions(
        key,
        Object.assign({}, props.project.active.messageOptions[key], {
          [language]: value,
        })
      )
    );
    setIsChanged(true);
  }

  function editQrcode() {
    store.dispatch(
      projectEditQrcodeOptions(
        "content",
        props.project.active.messageOptions.content
      )
    );
    nextConfirm(isVisited, isConfirmed);
  }

  function editTemplate() {
    if (props.template.active.fromSupervisor) {
      setCurrentModal(MODAL_DUPLICATE_TEMPLATE);
    } else if (props.template.active.id.length) {
      navigate("/survey/template");
    } else {
      toast(t("project_error_template_no_template"), { type: "error" });
    }
  }

  function next(isVisited: boolean, isConfirmed: boolean) {
    if (props.project.active.diffusionMode.qrcode && isChanged) {
      setIsVisited(isVisited);
      setIsConfirmed(isConfirmed);
      setCurrentModal(MODAL_EDIT_QRCODE);
    } else {
      nextConfirm(isVisited, isConfirmed);
    }
  }

  function nextConfirm(isVisited: boolean, isConfirmed: boolean) {
    if (props.onNext) {
      props.onNext(isVisited, isConfirmed);
    }
  }

  //Save options
  //Timeout of 1000ms before save
  async function editProjectImage(save: boolean) {
    if (props._session.modules.settings) {
      const response: any = await store.dispatch(
        projectUpdate(new Project({ ...props.project.active, image: save }))
      );
      if (response.error) {
        toast(t("error_saving"), { type: "error" });
      } else {
        store.dispatch(projectEdit("image", save));
        setImageEdition(false);
      }
    } else {
      toast(t("utils_unavailable"), { type: "error" });
      setCurrentModal(MODAL_UPGRADE);
    }
  }

  async function selectCustomImage(value: boolean) {
    if (props._session.modules.settings) {
      const response: any = await store.dispatch(
        projectUpdate(
          new Project({ ...props.project.active, customImage: value })
        )
      );
      if (response.error) {
        toast(t("error_saving"), { type: "error" });
      } else {
        store.dispatch(projectEdit("customImage", value));
      }
    } else {
      toast(t("utils_unavailable"), { type: "error" });
      setCurrentModal(MODAL_UPGRADE);
    }
  }

  function setInfoCustom() {
    if (!props.project.active.messageOptions.infoContent[currentLanguage]) {
      editMessage("infoContent", projectMessageInfo);
    }

    editMessage(
      "isInfoCustom",
      !props.project.active.messageOptions.isInfoCustom
    );
  }

  return (
    <div>
      {currentModal === MODAL_DUPLICATE_TEMPLATE && (
        <DuplicateTemplateAskModal onClose={() => setCurrentModal(null)} />
      )}

      {currentModal === MODAL_EDIT_QRCODE && (
        <ModalConfirm
          onNo={() => nextConfirm(isVisited, isConfirmed)}
          onYes={editQrcode}
          textBold={t("project_message_edit_qrcode")}
        />
      )}

      {currentModal === MODAL_UPGRADE && (
        <UpgradeModal
          feature="settings"
          onClose={() => setCurrentModal(null)}
        />
      )}

      <ListItem>
        <div className="setup-card-help">
          <p>{t("project_message_help")}</p>
          <ul className="grey-t setup-card-help-content">
            <li>{t("project_message_help_1")}</li>
            <li>
              {t("project_message_help_2")}
              &nbsp;
              <Link isWithoutMargin onClick={() => navigate("/adm/settings")}>
                {t("account_settings")}
              </Link>
            </li>
            <li>
              {t("project_message_help_3")}
              &nbsp;
              <Link isWithoutMargin onClick={() => editTemplate()}>
                {t("template_params")}
              </Link>
            </li>
          </ul>
        </div>
      </ListItem>

      {props.project.active.languages.length > 1 && (
        <ListItem>
          <div>
            <p className="grey-t">{t("utils_translations")}</p>

            <LanguageSelection
              languages={props.project.active.languages}
              activeLang={currentLanguage}
              onClick={(language: Language) =>
                setCurrentLanguage(language.code)
              }
            />
          </div>

          <Space />

          <div className="flex flex-dcol">
            <Space />

            <u
              className="_hover grey-t"
              onClick={() => setCurrentModal(CONTEXT_AUTO_TRANSLATION)}
            >
              {t("auto_translate")}
            </u>

            <div className="rel">
              {currentModal === CONTEXT_AUTO_TRANSLATION && (
                <ContextMenu
                  positionRight
                  onClose={() => setCurrentModal(null)}
                >
                  <div style={{ padding: "10px 20px" }}>
                    <p>{t("auto_translate")}</p>

                    <TranslatorWidget
                      targetLang={currentLanguage}
                      onTranslate={(texts: string[], language: Language) => {
                        editMessageText("title", texts[0], language.code);
                        editMessageText("content", texts[1], language.code);
                        edit();
                        setCurrentModal(null);
                      }}
                      languages={props.project.active.languages}
                      sourceTexts={[
                        props.project.active.messageOptions.title,
                        props.project.active.messageOptions.content,
                      ]}
                      onTargetChange={(lang: Language) =>
                        setCurrentLanguage(lang.code)
                      }
                    />
                  </div>
                </ContextMenu>
              )}
            </div>
          </div>
        </ListItem>
      )}

      <div style={{ padding: 32 }}>
        <div className="flex">
          <div className="flex1">
            <TextInput
              title={t("project_message_title")}
              value={props.project.active.messageOptions.title[currentLanguage]}
              onBlur={edit}
              onChange={(e) =>
                editMessageText("title", e.value, currentLanguage)
              }
            />

            <div className="flex">
              <div style={{ margin: "4px 0px" }}>
                <Checkbox
                  text={t("project_message_title_secondary")}
                  active={
                    props.project.active.messageOptions.isWithTitleSecondary
                  }
                  onClick={() =>
                    editMessage(
                      "isWithTitleSecondary",
                      !props.project.active.messageOptions.isWithTitleSecondary
                    )
                  }
                />
              </div>

              <div className="width-20" />

              {props.project.active.messageOptions.isWithTitleSecondary && (
                <TextInput
                  value={
                    props.project.active.messageOptions.titleSecondary[
                      currentLanguage
                    ]
                  }
                  onBlur={edit}
                  onChange={(e) =>
                    editMessageText("titleSecondary", e.value, currentLanguage)
                  }
                />
              )}
            </div>
          </div>

          <div
            style={{ marginLeft: 14 }}
            className="_hover rel"
            onClick={() =>
              window.open(props.project.active.testTemplateLink, "_blank")
            }
          >
            <div
              className="abs"
              style={{
                textAlign: "center",
                backgroundColor: "#0000006b",
                color: "white",
                margin: "7px 10px",
                borderRadius: 2,
                width: 180,
                height: 88,
                paddingTop: 50,
              }}
            >
              <div>
                <b>{t("utils_preview")}</b>
              </div>
              <FontAwesomeIcon icon={faPlayCircle} />
            </div>

            <img
              src={require("@/assets/qv.png")}
              style={{ marginBottom: 14 }}
              alt="qv"
              width={200}
            />
          </div>
        </div>

        <div className="height-10" />

        <div className="grey-t">{t("project_invitation_content")}</div>

        <textarea
          style={{
            resize: "none",
            height: "300px",
            width: "calc(100% - 20px)",
            border: "none",
            padding: "6px 10px",
            backgroundColor: "#eaeaea",
            borderRadius: "8px",
            margin: "12px 0px",
          }}
          onChange={(e) =>
            editMessageText("content", e.target.value, currentLanguage)
          }
          onBlur={edit}
          value={
            props.project.active.messageOptions.content[currentLanguage]
              ? props.project.active.messageOptions.content[currentLanguage]
              : ""
          }
        ></textarea>

        <div className="height-20" />

        <div className="flex rel">
          {
            //Paywall
            !props._session.modules.settings && (
              <div
                className="_hover abs flex"
                style={{
                  zIndex: 10,
                  width: "100%",
                  height: "100%",
                  padding: "22px 32px",
                  left: -32,
                  top: -10,
                  backgroundColor: "#8c8c8c50",
                }}
                onClick={() => setCurrentModal(MODAL_UPGRADE)}
              >
                <Space />
                <div className="flex flex-dcol">
                  <Space />
                  <FontAwesomeIcon icon={faLock} color={"white"} size={"3x"} />
                  <Space />
                </div>
                <Space />
              </div>
            )
          }

          <div>
            <p className="grey-t">{t("project_message_layout")}</p>

            <div className="height-10" />

            <div className="flex">
              <ImageSelector
                onClick={() => editMessage("isAlternativeLayout", false)}
                width={280}
                height={180}
                texts={[
                  {
                    value: t("project_message_layout_title"),
                    top: 16,
                    left: 62,
                  },
                  {
                    value: t("project_message_layout_logo"),
                    top: 80,
                    left: 202,
                  },
                  {
                    value: t("project_message_layout_text"),
                    top: 92,
                    left: 62,
                  },
                ]}
                isActive={
                  !props.project.active.messageOptions.isAlternativeLayout
                }
                imageName="project-layout-1"
              />

              <div className="width-20" />

              <ImageSelector
                onClick={() => editMessage("isAlternativeLayout", true)}
                width={280}
                height={180}
                texts={[
                  {
                    value: t("project_message_layout_title"),
                    top: 20,
                    left: 86,
                  },
                  {
                    value: t("project_message_layout_logo"),
                    top: 20,
                    left: 222,
                  },
                  {
                    value: t("project_message_layout_text"),
                    top: 106,
                    left: 120,
                  },
                ]}
                isActive={
                  props.project.active.messageOptions.isAlternativeLayout
                }
                imageName="project-layout-2"
              />
            </div>

            <div className="height-20" />

            <div>
              <p className="grey-t">{t("project_image_description")}</p>

              <div className="height-10" />

              <div className="flex">
                {props.template.active.imageUrl && (
                  <div className="flex">
                    <div
                      className={
                        "flex1 flex flex-dcol" +
                        (props.project.active.customImage ? " _hover" : "")
                      }
                      onClick={
                        props.project.active.customImage
                          ? () => {
                              selectCustomImage(false);
                            }
                          : undefined
                      }
                      style={{
                        border: props.project.active.customImage
                          ? "none"
                          : "2px solid " + props._session.accountColors.active,
                        backgroundColor: "#e9e9e9",
                        borderRadius: 8,
                        maxWidth: 170,
                        height: 200,
                        padding: 5,
                        textAlign: "center",
                      }}
                    >
                      <Space />
                      <div className="flex">
                        <Space />
                        <img
                          src={props.template.active.imageUrl}
                          width="100"
                          height="100"
                          alt={props.template.active.label}
                        />
                        <Space />
                      </div>

                      <div className="height-20" />

                      <div className="grey-t flex">
                        <Space />
                        <div>{t("project_image_template")}</div>
                        <Space />
                      </div>
                      <Space />
                    </div>
                    <div className="width-10" />
                  </div>
                )}

                <div
                  className={
                    "flex1 flex flex-dcol" +
                    (!props.project.active.customImage ? " _hover" : "")
                  }
                  onClick={
                    !props.project.active.customImage
                      ? () => selectCustomImage(true)
                      : undefined
                  }
                  style={{
                    border: props.project.active.customImage
                      ? "2px solid " + props._session.accountColors.active
                      : "none",
                    backgroundColor: "#e9e9e9",
                    borderRadius: 8,
                    maxWidth: 170,
                    height: 200,
                    padding: 5,
                    textAlign: "center",
                  }}
                >
                  <Space />

                  {props.project.active.customImage && imageEdition ? (
                    <div>
                      <ImagePicker
                        id={props.project.active.id}
                        square
                        imageId={
                          props.project.active.image
                            ? props.project.active.id
                            : null
                        }
                        model="Projects"
                        onDelete={() => editProjectImage(false)}
                        onSave={() => editProjectImage(true)}
                      />

                      <Space />

                      {props.project.active.customImage && (
                        <div className="grey-t flex">
                          <Space />
                          <u
                            className="_hover grey-t"
                            onClick={() => setImageEdition(false)}
                          >
                            {t("utils_cancel")}
                          </u>
                          <Space />
                        </div>
                      )}
                    </div>
                  ) : (
                    <div>
                      {props.project.active.customImage && (
                        <div
                          onClick={() => setImageEdition(true)}
                          className="_hover abs"
                          style={{
                            padding: 5,
                            borderRadius: 8,
                            marginTop: 5,
                            marginLeft: 110,
                            backgroundColor:
                              props._session.accountColors.active + "90",
                          }}
                        >
                          <FontAwesomeIcon icon={faPen} />
                        </div>
                      )}

                      <div className="flex">
                        <Space />

                        <img
                          src={props.project.active.imageUrl}
                          width={100}
                          height={100}
                          alt={props.template.active.label}
                        />

                        <Space />
                      </div>

                      <div className="height-20" />

                      <div className="grey-t flex">
                        <Space />
                        <div>{t("project_image_custom")}</div>
                        <Space />
                      </div>
                    </div>
                  )}

                  <Space />
                </div>
              </div>
            </div>

            <div className="height-20" />

            <Checkbox
              active={props.project.active.messageOptions.displayImage}
              onClick={() =>
                editMessage(
                  "displayImage",
                  !props.project.active.messageOptions.displayImage
                )
              }
              text={t("project_image_display")}
            />

            <Checkbox
              active={props.project.active.messageOptions.isInfoCustom}
              onClick={() => setInfoCustom()}
              text={t("project_custom_info_message")}
            />
          </div>

          <div className="width-20" />

          <div className="flex1" />

          <div>
            <ColorPickerWidget
              title={t("project_color_background")}
              selectedColor={
                props.project.active.messageOptions.backgroundColor
                  ? props.project.active.messageOptions.backgroundColor
                  : props._session.accountColors.brandPrimary
              }
              colors={SESSION_COLORS}
              isCustom
              onSelect={(color) => editMessage("backgroundColor", color)}
            />

            <ColorPickerWidget
              title={t("project_color_text")}
              selectedColor={
                props.project.active.messageOptions.textColor
                  ? props.project.active.messageOptions.textColor
                  : props._session.accountColors.brandText
              }
              colors={SESSION_COLORS}
              isCustom
              onSelect={(color) => editMessage("textColor", color)}
            />

            <ColorPickerWidget
              title={t("project_color_button")}
              selectedColor={
                props.project.active.messageOptions.buttonColor
                  ? props.project.active.messageOptions.buttonColor
                  : props._session.accountColors.cta
              }
              colors={SESSION_COLORS}
              isCustom
              onSelect={(color) => editMessage("buttonColor", color)}
            />
          </div>
        </div>

        {props.project.active.messageOptions.isInfoCustom && (
          <div>
            <div className="height-20" />

            <Link
              isWithoutMargin
              onClick={() =>
                window.open(
                  "https://docs.github.com/fr/get-started/writing-on-github/getting-started-with-writing-and-formatting-on-github/basic-writing-and-formatting-syntax",
                  "_blank"
                )
              }
            >
              {t("project_custom_info_message_help")}
            </Link>

            <textarea
              style={{
                resize: "none",
                height: "400px",
                width: "calc(100% - 20px)",
                border: "none",
                padding: "6px 10px",
                backgroundColor: "#eaeaea",
                borderRadius: "8px",
                margin: "12px 0px",
                overflowY: "auto",
              }}
              onChange={(e) =>
                editMessageText("infoContent", e.target.value, currentLanguage)
              }
              onBlur={edit}
              value={
                props.project.active.messageOptions.infoContent[currentLanguage]
                  ? props.project.active.messageOptions.infoContent[
                      currentLanguage
                    ]
                  : ""
              }
            ></textarea>
          </div>
        )}
      </div>

      <ProjectStepErrorsWidget
        alert={props.project.active.alerts.message}
        step="message"
      />

      {props.onNext && (
        <ConfirmButtons
          onCancel={() => cancel()}
          onConfirm={() => next(true, true)}
          onConfirmLater={() => next(true, false)}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  _session: state._session,
  project: state.project,
  template: state.template,
});

export default connect(mapStateToProps)(
  withTranslation()(ProjectMessageWidget)
);
