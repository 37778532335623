/***
 * INVITATIONS-LOADBAR.WIDGET
 * Loader for multiple pdf generation
 */

import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { store } from "@/index";
// import store from "@/core/store";
import { useEffect, useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { generatePDFMultiple } from "@/redux/_qrcode.actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProgressBar from "@/components/progress-bar";
import { connect } from "react-redux";
import { ProjectState } from "@/redux/project.types";

interface OwnProps extends WithTranslation {
  isForEveryone: boolean;
  offset?: number;
  onStop: Function;
  surveyId: string;
  count: any;
  attributes: string[];
}

interface StateProps extends WithTranslation {
  project: ProjectState;
}

type Props = OwnProps & StateProps;

//How many users at once
const CHUNK_SIZE: number = 100;

function InvitationLoadbarWidget(props: Props) {
  const { t } = props;

  const [nbGenerated, setNbGenerated] = useState<number>(
    props.offset ? props.offset : 0
  );

  useEffect(() => {
    if (nbGenerated < props.count) {
      setTimeout(() => {
        loadChunk();
      }, 200);
    } else {
      props.onStop(true);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nbGenerated]);

  async function loadChunk() {
    const response: any = await store.dispatch(
      generatePDFMultiple(
        props.surveyId,
        CHUNK_SIZE,
        nbGenerated,
        props.isForEveryone,
        props.project.active.qrcodeOptions,
        props.attributes.join(",")
      )
    );
    if (!response.error) {
      setNbGenerated(nbGenerated + CHUNK_SIZE);
    }
  }

  return (
    <div>
      <FontAwesomeIcon icon={faSyncAlt} spin data-tip={t("refresh_count")} />
      {"  "}
      <u
        className="_hover grey-t"
        onClick={(event: any) => {
          event.stopPropagation();
          props.onStop(false);
        }}
      >
        {t("utils_cancel")}
      </u>
      <ProgressBar items={[{ value: nbGenerated }]} max={props.count} />
    </div>
  );
}

const mapStateToProps = (state) => ({
  project: state.project,
});

export default connect(mapStateToProps)(
  withTranslation()(InvitationLoadbarWidget)
);
