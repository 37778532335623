/**
 * TEMPLATES.WIDGET
 * Display templates (idtree or account)
 */
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import CardContainer from "@/components/card-container";
import CardButton from "@/components/card-button";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import PageLoader from "@/components/page-loader";
import { Session } from "@/redux/_session.types";
import AddTemplateModal from "@/modals/add-template.modal";
import { useEffect, useState } from "react";
import { store } from "@/index";
// import store from "@/core/store";
import { templateFetchAndGet } from "@/redux/template.actions";
import {
  Template,
  TemplateState,
  TEMPLATES_TAGS,
} from "@/redux/template.types";
import TemplateWidget from "./template.widget";
import { STATUS_LOADED, STATUS_LOADING } from "@/redux/_status.types";
import Card from "@/components/card";
import Space from "@/components/space";

interface StateProps extends WithTranslation {
  _session: Session;
  template: TemplateState;
}

interface OwnProps {
  loadSupervisorTemplates?: boolean; //Show supervisor templates
  switchForProject?: boolean; //Select for project (not for edition)
  onSelect?: Function; //Trigger action on select
}

type Props = StateProps & OwnProps;

//Modals
const MODAL_ADD = "MODAL_ADD"; //Enter name for a new custom template

function TemplatesWidget(props: Props) {
  const { t } = props;

  const [currentModal, setCurrentModal] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  //On load fetch templates
  useEffect(() => {
    if (props.loadSupervisorTemplates) {
      store.dispatch(templateFetchAndGet(true, false, false, false));
    } else {
      store.dispatch(
        templateFetchAndGet(
          false,
          props._session.accountIsChildren,
          true,
          false
        )
      );
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 150);
  }, [props._session.accountIsChildren, props.loadSupervisorTemplates]);

  //Add new template
  //If connected load modal, if not signup
  function add() {
    setCurrentModal(MODAL_ADD);
  }

  //Select template
  function select(templateId) {
    if (props.onSelect) {
      props.onSelect(templateId);
    }
  }

  return (
    <div>
      {currentModal === MODAL_ADD && (
        <AddTemplateModal onClose={() => setCurrentModal(null)} />
      )}

      {props.loadSupervisorTemplates ? (
        <PageLoader
          status={
            isLoading || props.template.status === STATUS_LOADING
              ? STATUS_LOADING
              : STATUS_LOADED
          }
        >
          <h3>{t("template_tag_fav")}</h3>

          {props.template.list.every((x) => !x.free || !x.fromSupervisor) && (
            <p className="grey-t">{t("templates_unavaible")}</p>
          )}

          <CardContainer isAlignedLeft>
            {!props._session.accountIsChildren && !props.switchForProject && (
              <CardButton
                icon={faPlus}
                title={t("template_create_new")}
                onClick={add}
              ></CardButton>
            )}

            {props.template.list
              .filter((x) => x.free && x.fromSupervisor)
              .map((template) => (
                <TemplateWidget
                  onSelect={select}
                  currentTemplate={template}
                  key={template.id}
                  switchForProject={props.switchForProject}
                />
              ))}
          </CardContainer>

          {TEMPLATES_TAGS.map((tag, i) => (
            <div key={i}>
              <h3>{t("template_tag_" + tag)}</h3>

              {props.template.list.every(
                (x) => x.options.tag !== tag || !x.fromSupervisor
              ) && <p className="grey-t">{t("templates_unavaible")}</p>}

              <CardContainer isAlignedLeft>
                {props.template.list
                  .filter((x) => x.options.tag === tag && x.fromSupervisor)
                  .map((template) => (
                    <TemplateWidget
                      currentTemplate={template}
                      onSelect={select}
                      switchForProject={props.switchForProject}
                      key={template.id}
                    />
                  ))}
              </CardContainer>
            </div>
          ))}

          <div className="height-100" />
        </PageLoader>
      ) : (
        <PageLoader
          status={
            isLoading || props.template.status === STATUS_LOADING
              ? STATUS_LOADING
              : STATUS_LOADED
          }
        >
          {props._session.accountIsChildren &&
            props.template.list.some(
              (x) => x.AccountId === props._session.superaccountId
            ) && (
              <div>
                <div className="flex">
                  <Card
                    title={t("template_tag_account", {
                      name: props._session.superaccountName,
                    })}
                  >
                    <CardContainer isAlignedLeft>
                      {props.template.list
                        .filter(
                          (x) => x.AccountId === props._session.superaccountId
                        )
                        .map((template: Template) => (
                          <TemplateWidget
                            currentTemplate={template}
                            switchForProject={props.switchForProject}
                            onSelect={select}
                            key={template.id}
                          />
                        ))}
                    </CardContainer>
                  </Card>

                  <Space />
                </div>

                <h3>
                  {t("template_tag_account", {
                    name: props._session.accountName,
                  })}
                </h3>
              </div>
            )}

          <CardContainer isAlignedLeft>
            {props.template.list
              .filter((x) => x.AccountId === props._session.accountId)
              .map((template) => (
                <TemplateWidget
                  currentTemplate={template}
                  onSelect={select}
                  switchForProject={props.switchForProject}
                  key={template.id}
                />
              ))}

            <CardButton
              icon={faPlus}
              isCta
              title={t("template_create_new")}
              onClick={add}
            ></CardButton>
          </CardContainer>
        </PageLoader>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  _session: state._session,
  template: state.template,
});

export default connect(mapStateToProps)(withTranslation()(TemplatesWidget));
