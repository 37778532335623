/**
 * TASKS-TEAMS.WIDGET
 * Get actions plan by teams
 */

import {
  faPencilAlt,
  faPlus,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import Card from "@/components/card";
import ListButton from "@/components/list-button";
import ListCell from "@/components/list-cell";
import ListContainer from "@/components/list-container";
import ListFields from "@/components/list-fields";
import ListIcon from "@/components/list-icon";
import ListItem from "@/components/list-item";
import PageLoader from "@/components/page-loader";
import Space from "@/components/space";
import { store } from "@/index";
// import store from "@/core/store";
import { useEffect, useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Session } from "@/redux/_session.types";
import {
  STATUS_LOADED,
  STATUS_LOADING,
  STATUS_SEARCHING,
} from "@/redux/_status.types";
import {
  actionPlanActivate,
  actionPlanAdd,
  actionPlanFetch,
} from "@/redux/action-plan.actions";
import { ActionPlan } from "@/redux/action-plan.types";
import {
  userActivate,
  userFetch,
  userFetchCount,
  userGet,
  userStatus,
} from "@/redux/user.actions";
import { User, UserState } from "@/redux/user.types";
import AdmUserPopulationsWidget from "./adm-user-populations.widget";
import Chip from "@/components/chip";
import ProgressBar from "@/components/progress-bar";
import { NavigateFunction, useNavigate } from "react-router-dom";

interface StateProps extends WithTranslation {
  _session: Session;
  user: UserState;
}

//Limit of users to display by page
const LIMIT: number = 10;

function TasksTeamsWidget(props: StateProps) {
  const { t } = props;
  const navigate: NavigateFunction = useNavigate();

  //Current offset for search (when page is changed)
  const [currentOffset, setCurrentOffset] = useState<number>(0);

  //Content for searchbar
  const [currentSearch, setCurrentSearch] = useState<string>("");

  //Data is loading
  const [isLoading, setIsLoading] = useState<boolean>(true);

  //Get search count of managers
  const [searchCount, setSearchCount] = useState<number>(0);

  //Load data
  //Fetch all observers for the account
  useEffect(() => {
    load();

    async function load() {
      const actionPlans: any = await store.dispatch(actionPlanFetch());
      const admins: any = await store.dispatch(
        userFetch(
          currentSearch,
          LIMIT,
          currentOffset,
          true,
          "id,email,firstname,lastname,role"
        )
      );

      if (actionPlans.error || admins.error) {
        store.dispatch(userGet([]));
      } else {
        //Get action plan for every admin
        admins.forEach((admin: User) => {
          admin.ActionPlans = actionPlans.filter(
            (x: ActionPlan) => x.UserIdResponsible === admin.id
          );
        });

        store.dispatch(userGet(admins.error ? [] : admins));
      }

      store.dispatch(userStatus(STATUS_LOADED));
      setIsLoading(false);
    }
  }, [currentOffset, currentSearch]);

  //Update count of users
  useEffect(() => {
    updateAdminCount();

    async function updateAdminCount() {
      const response: any = await store.dispatch(
        userFetchCount(currentSearch, true)
      );
      setSearchCount(response.error ? 0 : response.count);
    }
  }, [currentSearch]);

  //Create new action plan for someone
  function createActionPlan(user: User) {
    store.dispatch(userActivate(user));
    store.dispatch(actionPlanAdd(user.id, props._session.userId, null));
    navigate("/tasks/edit?isNew=true");
  }

  //Edit draft action plan
  function editActionPlan(user: User) {
    if (user.activeActionPlan) {
      store.dispatch(userActivate(user));
      store.dispatch(actionPlanActivate(user.activeActionPlan));
      navigate("/tasks/edit");
    }
  }

  return (
    <Card isWithoutPadding>
      <ListContainer
        countLimit={LIMIT}
        countSearch={searchCount}
        isFooterActive
        onAddTitle={t("user_add")}
        onSearch={setCurrentSearch}
        onSearchStart={() => store.dispatch(userStatus(STATUS_SEARCHING))}
        onSetPage={setCurrentOffset}
        status={props.user.status}
      >
        <PageLoader status={isLoading ? STATUS_LOADING : STATUS_LOADED}>
          <ListFields>
            <ListCell />
            <ListCell width={170} text={t("tasks_teams_manager")} />
            <ListCell width={155} text={t("tasks_teams_populations")} />
            <Space />
            <ListCell width={400} text={t("tasks")} />
          </ListFields>

          {props.user.list.length === 0 && (
            <ListFields>{t("roles_empty")}</ListFields>
          )}

          {props.user.list.map((user) => (
            <ListItem key={user.id}>
              <ListIcon fallbackIcon={faUserCircle} image={user.imageUrl} />

              <ListCell width={170} text={user.username} />

              <AdmUserPopulationsWidget currentUser={user} />

              <Space />

              <ListCell width={400}>
                {user.activeActionPlan ? (
                  <div className="flex">
                    <Chip>{user.activeActionPlan.statusLabel}</Chip>

                    {user.activeActionPlan.status === "active" && (
                      <div style={{ width: 100, marginLeft: 12 }}>
                        <div style={{ margin: "-6px 0px" }}>
                          <ProgressBar
                            items={[
                              { value: user.activeActionPlan.tasksDoneCount },
                            ]}
                            max={user.activeActionPlan.tasks.length}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div
                    className="medgrey-t flex flex-auto"
                    style={{ fontSize: 12 }}
                  >
                    {t("tasks_teams_empty")}
                  </div>
                )}

                <Space />

                {user.activeActionPlan ? (
                  <ListButton
                    icon={faPencilAlt}
                    text={t("utils_edit")}
                    onClick={() => editActionPlan(user)}
                  />
                ) : (
                  <ListButton
                    icon={faPlus}
                    onClick={() => createActionPlan(user)}
                    isPrimary
                    text={t("tasks_add")}
                  />
                )}
              </ListCell>
            </ListItem>
          ))}
        </PageLoader>
      </ListContainer>
    </Card>
  );
}

const mapStateToProps = (state) => ({
  _session: state._session,
  user: state.user,
});

export default connect(mapStateToProps)(withTranslation()(TasksTeamsWidget));
