/**
 * TASKS-DELETE.MODAL
 * Delete action plan
 */
import Modal from "@/components/modal";
import { useState } from "react";
import { connect } from "react-redux";
import { withTranslation, WithTranslation } from "react-i18next";
import { STATUS_LOADED, STATUS_SAVING } from "@/redux/_status.types";
import EnterCodeWidget from "@/widgets/enter-code.widget";
import { ActionPlanState } from "@/redux/action-plan.types";
import {
  actionPlanDestroy,
  actionPlanRemove,
} from "@/redux/action-plan.actions";
import { store } from "@/index";
// import store from "@/core/store";
import { UserState } from "@/redux/user.types";
import { NavigateFunction, useNavigate } from "react-router-dom";

interface StateProps extends WithTranslation {
  actionPlan: ActionPlanState;
  user: UserState;
}

interface OwnProps {
  onClose: Function;
}

type Props = StateProps & OwnProps;

function TaskDeleteModal(props: Props) {
  const { t } = props;
  const navigate: NavigateFunction = useNavigate();

  const [isSaving, setIsSaving] = useState(false);

  function close() {
    props.onClose();
  }

  //Trigger the action with the backend
  async function deleteConfirm() {
    setIsSaving(true);
    const response: any = await store.dispatch(
      actionPlanDestroy(props.actionPlan.active.id)
    );
    setIsSaving(false);

    if (!response.error) {
      store.dispatch(actionPlanRemove(props.actionPlan.active.id));
      navigate("/tasks/home");
    }
  }

  return (
    <Modal onClose={close} title={t("tasks_delete")}>
      <p>
        <b>
          {t("tasks_ask_delete", {
            name: props.user.active.username,
          })}
        </b>
      </p>

      <EnterCodeWidget
        onCancel={close}
        onNext={deleteConfirm}
        status={isSaving ? STATUS_SAVING : STATUS_LOADED}
      />
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  actionPlan: state.actionPlan,
  user: state.user,
});

export default connect(mapStateToProps)(withTranslation()(TaskDeleteModal));
