import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type RpsSliceState = {
  gollacFilterName: null | string;
  gollacManagerAttributeName: null | string;
  ceilsGollacMinValue: number;
  ceilsGollacMaxValue: number;
};

const initialState: RpsSliceState = {
  gollacFilterName: null,
  gollacManagerAttributeName: null,
  ceilsGollacMinValue: 0.4,
  ceilsGollacMaxValue: 0.8,
};

export const rpsSlice = createSlice({
  name: "rpsSlice",
  initialState: initialState,
  reducers: {
    setGollacParams: (
      state: RpsSliceState,
      action: PayloadAction<RpsSliceState>
    ) => {
      state = action.payload;
      return state;
    },
  },
});

export const { setGollacParams } = rpsSlice.actions;
export default rpsSlice.reducer;
