/**
 * FILTER-REGROUPINGS.WIDGET
 * Show populations from a custom filter
 */

import { connect } from "react-redux";
import { withTranslation, WithTranslation } from "react-i18next";
import { useState } from "react";
import { store } from "@/index";
// import store from "@/core/store";
import Chip from "@/components/chip";
import { Session } from "@/redux/_session.types";
import SearchBar from "@/components/search-bar";
import { STATUS_LOADED } from "@/redux/_status.types";
import Space from "@/components/space";
import { Attribute } from "@/redux/attribute.types";
import { Regrouping, RegroupingState } from "@/redux/regrouping.types";
import { regroupingActivate, regroupingAdd } from "@/redux/regrouping.actions";
import { toast } from "react-toastify";
import RegroupingAssociateModal from "@/modals/regrouping-associate.modal";
import RegroupingDissociateModal from "@/modals/regrouping-dissociate.modal";
import { concat, sum } from "lodash";
import Button from "@/components/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import ReactMarkdown from "react-markdown";

interface StateProps {
  _session: Session;
  regrouping: RegroupingState;
}

interface OwnProps {
  filterName: string;
  attributes: Attribute[];
  regroupings: Regrouping[];
}

type Props = WithTranslation & StateProps & OwnProps;

const ATTRIBUTES_TO_DISPLAY: number = 8;

const MODAL_ASSOCIATE_REGROUPING: string = "MODAL_ASSOCIATE_REGROUPING";
const MODAL_DISSOCIATE_REGROUPING: string = "MODAL_DISSOCIATE_REGROUPING";

function FilterRegroupingWidget(props: Props) {
  const { t } = props;

  const [attributes, setAttributes] = useState<Attribute[]>(props.attributes);
  const [regroupings, setRegroupings] = useState<Regrouping[]>(
    props.regroupings
  );

  //Search
  const [currentSearch, setCurrentSearch] = useState("");

  //Current modal
  const [currentModal, setCurrentModal] = useState<string | null>(null);

  //Get populations according search
  function getPopulations(list: (Attribute | Regrouping)[]): any {
    return currentSearch.length
      ? list.filter(
          (x: Attribute | Regrouping) =>
            x.name.toLowerCase().indexOf(currentSearch.toLowerCase()) > -1
        )
      : list;
  }

  //if regrouping in params, new attributes will be associate to this regrouping
  //otherwise create a new regrouping
  function associate(regrouping: Regrouping | undefined) {
    if (regrouping) {
      store.dispatch(regroupingActivate(regrouping));
    } else {
      store.dispatch(regroupingAdd(props.filterName));
    }
    setCurrentModal(MODAL_ASSOCIATE_REGROUPING);
  }

  function dissociate(regrouping: Regrouping | undefined) {
    if (regrouping) {
      store.dispatch(regroupingActivate(regrouping));
      setCurrentModal(MODAL_DISSOCIATE_REGROUPING);
    } else {
      toast(t("regrouping_not_found"), { type: "error" });
    }
  }

  function confirmAssociation(regrouping: Regrouping) {
    regrouping.selected = true;
    setRegroupings(
      concat(
        regroupings.filter((x: Regrouping) => x.id !== regrouping.id),
        regrouping
      )
    );
    setAttributes(
      attributes.filter(
        (x: Attribute) => regrouping.attributes.indexOf(x.id) === -1
      )
    );
    next();
  }

  function confirmDissociation() {
    const attributesFromDissociatedRegrouping: Attribute[] =
      props.regrouping.active.Attributes;
    attributesFromDissociatedRegrouping.forEach(
      (x: Attribute) => (x.selected = true)
    );
    setAttributes(concat(attributes, attributesFromDissociatedRegrouping));
    setRegroupings(
      regroupings.filter((x: Regrouping) => x.id !== props.regrouping.active.id)
    );
    next();
  }

  function getCount() {
    return currentSearch.length > 0
      ? getPopulations([...attributes, ...regroupings]).length
      : attributes.length + regroupings.length;
  }

  function next() {
    setCurrentModal(null);
    toast(t("utils_saved"));
  }

  //Search into list of populations
  function search(value: string) {
    setCurrentSearch(value);
  }

  //On attribute check
  //withReset = false if click inside the checkbox
  function selectAttribute(attribute: Attribute) {
    attribute.selected = !attribute.selected;

    setAttributes(
      attributes.map((x: Attribute) => (x.id === attribute.id ? attribute : x))
    );
  }

  //On regrouping check
  //withReset = false if click inside the checkbox
  function selectRegrouping(regrouping: Regrouping) {
    regrouping.selected = !regrouping.selected;

    setRegroupings(
      regroupings.map((x: Regrouping) =>
        x.id === regrouping.id ? regrouping : x
      )
    );
  }

  return (
    <div>
      {currentModal === MODAL_ASSOCIATE_REGROUPING && (
        <RegroupingAssociateModal
          onClose={() => setCurrentModal(null)}
          onNext={confirmAssociation}
          attributes={getPopulations(attributes).filter((x: any) => x.selected)}
        />
      )}

      {currentModal === MODAL_DISSOCIATE_REGROUPING && (
        <RegroupingDissociateModal
          onClose={() => setCurrentModal(null)}
          onNext={confirmDissociation}
        />
      )}

      <div className="height-20" />

      <ReactMarkdown>{t("regroup_info")}</ReactMarkdown>

      <div className="height-20" />

      <div
        className="flex"
        style={{
          position: "sticky",
          top: 0,
          backgroundColor: "white",
          zIndex: 10,
        }}
      >
        <SearchBar onSearch={search} status={STATUS_LOADED} />

        <p>
          {t("utils_total_displayed", {
            count: getCount(),
            s: getCount() > 1 ? "s" : "",
          })}
        </p>

        <Space />

        <div>
          {getPopulations(attributes).filter(
            (x: Attribute | Regrouping) => x.selected
          ).length > 1 ||
          (getPopulations(attributes).filter(
            (x: Attribute | Regrouping) => x.selected
          ).length > 0 &&
            getPopulations(regroupings).filter(
              (x: Attribute | Regrouping) => x.selected
            ).length === 1) ? (
            <Button
              className="primary"
              onClick={() =>
                associate(regroupings.find((x: Regrouping) => x.selected))
              }
            >
              {t("utils_associate")}
            </Button>
          ) : regroupings.filter((x: Regrouping) => x.selected).length === 1 ? (
            <Button
              className="primary"
              onClick={() =>
                dissociate(regroupings.find((x: Regrouping) => x.selected))
              }
            >
              {t("utils_dissociate")}
            </Button>
          ) : null}
        </div>
      </div>

      <div>
        <div className="height-20" />

        {getPopulations(regroupings).length > 0 && (
          <div className="flex flex-wrap grey-t">
            <p className="dark-t">
              <b>{t("attribute_regroupings")}</b>
            </p>

            {getPopulations(regroupings).map(
              (regrouping: Attribute | Regrouping | any) => (
                <div
                  key={regrouping.id}
                  className="_hover"
                  onClick={(event: any) => {
                    event.stopPropagation();
                    selectRegrouping(regrouping);
                  }}
                  style={{
                    width: "100%",
                    backgroundColor: regrouping.selected
                      ? props._session.accountColors.active + "50"
                      : "",
                    borderRadius: 8,
                    border: "1px solid",
                    padding: 5,
                    marginBottom: 5,
                  }}
                >
                  <div className="flex">
                    {regrouping.name}
                    <Space />
                    <span className={"chip-user"}>
                      <FontAwesomeIcon icon={faUser} />
                      {sum(
                        regrouping.Attributes.map(
                          (x: Attribute) => x.usersCount
                        )
                      )}
                    </span>
                  </div>

                  <div className="height-10" />

                  <div className="flex flex-wrap">
                    {regrouping.Attributes.slice(0, ATTRIBUTES_TO_DISPLAY).map(
                      (attribute: Attribute) => (
                        <Chip
                          key={attribute.id}
                          onClick={(event: any) => {
                            event.stopPropagation();
                            selectRegrouping(regrouping);
                          }}
                          color={
                            regrouping.selected
                              ? props._session.accountColors.active
                              : null
                          }
                          count={attribute.usersCount}
                          countWarning={
                            attribute.usersCount <
                            props._session.participationMin
                          }
                          isWithMargin
                        >
                          {attribute.name}
                        </Chip>
                      )
                    )}
                  </div>

                  {regrouping.Attributes.length > ATTRIBUTES_TO_DISPLAY && (
                    <div className="flex">
                      <Space />
                      <u
                        className="grey-t"
                        data-tip={regrouping.Attributes.map(
                          (x: Attribute) => x.name
                        )
                          .slice(ATTRIBUTES_TO_DISPLAY)
                          .join(", ")}
                      >
                        {" +" +
                          t("utils_more", {
                            count:
                              regrouping.Attributes.length -
                              ATTRIBUTES_TO_DISPLAY,
                            s:
                              regrouping.Attributes.length -
                                ATTRIBUTES_TO_DISPLAY >
                              1
                                ? "s"
                                : "",
                          })}
                      </u>
                    </div>
                  )}
                </div>
              )
            )}
          </div>
        )}

        <div className="height-40" />

        <p>
          <b>{t("attributes")}</b>
        </p>

        {getPopulations(attributes).length > 0 ? (
          <div className="flex flex-wrap">
            {getPopulations(attributes).map(
              (attribute: Attribute | Regrouping | any) => (
                <Chip
                  key={attribute.id}
                  onClick={(event: any) => {
                    event.stopPropagation();
                    selectAttribute(attribute);
                  }}
                  color={
                    attribute.selected
                      ? props._session.accountColors.active
                      : null
                  }
                  count={attribute.usersCount}
                  countWarning={
                    attribute.usersCount < props._session.participationMin
                  }
                  isWithMargin
                >
                  {attribute.name}
                </Chip>
              )
            )}
          </div>
        ) : (
          <div className="flex flex-dcol" style={{ height: "100px" }}>
            <Space />
            <div className="flex">
              <Space />
              {t("no_population")}
              <Space />
            </div>
            <Space />
          </div>
        )}
      </div>

      <ReactTooltip />
    </div>
  );
}

const mapStateToProps = (state) => ({
  _session: state._session,
  regrouping: state.regrouping,
});

export default connect(mapStateToProps)(
  withTranslation()(FilterRegroupingWidget)
);
