/**
 * RPS-USERS.HELP
 * Display users informations
 */
import Card from "@/components/card";
import ListIcon from "@/components/list-icon";
import ListItem from "@/components/list-item";
import HelpModal from "@/components/modal-help";
import {
  faCheckCircle,
  faTimesCircle,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { UserRps, UserState } from "@/redux/user.types";
import { store } from "@/index";
// import store from "@/core/store";
import { fetchRpsUserQuestions, fetchRpsUser } from "@/redux/_rps.actions";
import { userStatus } from "@/redux/user.actions";
import {
  STATUS_LOADED,
  STATUS_LOADING,
  STATUS_LOAD_ERROR,
} from "@/redux/_status.types";
import PageLoader from "@/components/page-loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Space from "@/components/space";
import ReactMarkdown from "react-markdown";
import Link from "@/components/link";
import ListCell from "@/components/list-cell";
import Chip from "@/components/chip";
import { flatten, orderBy, snakeCase } from "lodash";
import { TopicState } from "@/redux/topic.types";

interface StateProps extends WithTranslation {
  topic: TopicState;
  user: UserState;
}

interface OwnProps {
  onClose: Function;
}

type Props = StateProps & OwnProps;

function RpsUsersHelp(props: Props) {
  const { t } = props;

  //User data to display
  const [rpsUser, setRpsUser] = useState(new UserRps());

  //List of RPS questions
  const [rpsQuestions] = useState(
    flatten(props.topic.list.map((x) => x.Questions)).filter((x) => x.special)
  );

  //Show list on load of the widget
  const [showList, setShowList] = useState(props.user.list.length > 1);

  //Show details of questions
  const [showQuestions, setShowQuestions] = useState(false);

  //On load
  //Get user if there is only one user in the list
  useEffect(() => {
    if (props.user.list.length <= 1) {
      getUser(props.user.list[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Get family of the question
  function getQuestionAxis(tag) {
    if (tag === "psychological-job-demands") {
      return t("rps_axis_stress");
    } else if (tag === "skill-use" || tag === "decision-latitude") {
      return t("rps_axis_autonomy");
    } else if (tag === "team-support" || tag === "manager-support") {
      return t("rps_social_support");
    } else {
      return t("rps_work_gratitude");
    }
  }

  //Get color for question according rps factor
  function getQuestionColor(question) {
    switch (question.valueQvt) {
      case 1:
        return "#eb5a46";
      case 2:
        return "#f39689";
      default:
        return "#8C8C8C";
    }
  }

  //Open user
  //Load questions data for the user
  async function openQuestions() {
    store.dispatch(userStatus(STATUS_LOADING));

    const userQuestions: any = await store.dispatch(
      fetchRpsUserQuestions(rpsUser.fkUser)
    );
    if (!userQuestions.error) {
      setRpsUser(
        Object.assign({}, rpsUser, {
          Questions: userQuestions,
        })
      );
    }

    setShowQuestions(true);
    store.dispatch(userStatus(STATUS_LOADED));
  }

  //Format questions array
  //Set count of question in total for tag
  function getQuestions(questions, order) {
    questions.forEach((question) => {
      const currentQuestion = rpsQuestions.find(
        (x) => x.aid === question.fkQuestion
      );
      if (currentQuestion) {
        question.label = currentQuestion.label;
      }

      if (question.special === "psychological-job-demands") {
        question.valueQvt = 5 - question.valueSpecial;
      } else {
        question.valueQvt = question.valueSpecial;
      }

      if (question.valueQvt < 3) {
        question.order = 2;
      } else {
        question.order = 3;
      }
    });

    questions = orderBy(questions, "valueQvt");

    questions.forEach((question) => {
      if (
        question.special === "skill-use" ||
        question.special === "decision-latitude"
      ) {
        question.count = questions.filter(
          (x) => x.special === "decision-latitude" || x.special === "skill-use"
        ).length;
        question.index =
          questions
            .filter(
              (x) =>
                x.special === "decision-latitude" || x.special === "skill-use"
            )
            .findIndex((x: any) => x.fkQuestion === question.fkQuestion) + 1;
      } else {
        question.count = questions.filter(
          (x) => x.special === question.special
        ).length;
        question.index =
          questions
            .filter((x) => x.special === question.special)
            .findIndex((x: any) => x.fkQuestion === question.fkQuestion) + 1;
      }

      //Set order
      //First questions with bad answer
      //Then order by job strain trigger order by weight of the question
      question.weight = question.valueQvt * 1000;
      if (
        ["psychological-job-demands", "decision-latitude", "skill-use"].indexOf(
          question.special
        ) > -1
      ) {
        question.weight += question.count;
        if (question.order === 2) question.order = 1;
      } else if (question.special === "work-gratitude") {
        question.weight += question.count + 100;
      } else {
        question.weight += question.count + 10;
      }
    });

    return orderBy(
      questions.filter((x) => x.order === order),
      "weight"
    );
  }

  //Get response for RPS widget
  function getResponse(question) {
    if (question.reverse) {
      if (question.value === 100) return t("question_likert_1");
      else if (question.value === 75) return t("question_likert_2");
      else if (question.value === 50) return t("question_likert_3");
      else if (question.value === 25) return t("question_likert_4");
      else if (question.value === 0) return t("question_likert_5");
    } else {
      if (question.value === 100) return t("question_likert_5");
      else if (question.value === 75) return t("question_likert_4");
      else if (question.value === 50) return t("question_likert_3");
      else if (question.value === 25) return t("question_likert_2");
      else if (question.value === 0) return t("question_likert_1");
    }
  }

  //Display RPS indicator
  function getRpsItem(
    label: string,
    score: number,
    ceil: number,
    isActive: boolean,
    help: string,
    subItems: any[],
    max: number,
    isReverse: boolean
  ) {
    return (
      <div className="rps-user-item">
        <div className={isActive ? "orange-t" : "green-t"}>
          <FontAwesomeIcon icon={isActive ? faTimesCircle : faCheckCircle} />
          &nbsp;
          {isActive ? <b>{label}</b> : label}
        </div>
        <div className="grey-t rps-user-item-label">
          {score ? (
            <ReactMarkdown linkTarget="_blank">
              {t("rps_ceil", {
                score,
                ceil,
                max,
                ceilOrientation: isReverse ? "maximum" : "minimum",
              })}
            </ReactMarkdown>
          ) : (
            <i>{t("rps_ceil_nc")}</i>
          )}

          <ul>
            {subItems.map((item: any, i: number) => (
              <li key={i}>
                {item.text} :&nbsp;
                {item.value ? (
                  <b>
                    {parseInt(item.value)} / {item.max}
                  </b>
                ) : (
                  <i>{t("rps_nc")}</i>
                )}
              </li>
            ))}
          </ul>
          <div style={{ marginTop: "6px" }}>
            <i>{help}</i>
          </div>
        </div>
      </div>
    );
  }

  //Display RPS label
  function getRpsLabel(
    text: string,
    isActive: boolean,
    textActive: string,
    colorActive: string
  ) {
    return (
      <div className="flex2 flex">
        <div className="rps-user-label-border"></div>
        <div className="rps-user-label flex flex-dcol">
          <Space />
          {text}
          {isActive && (
            <p className={colorActive}>
              <b>{textActive}</b>
            </p>
          )}
          <Space />
        </div>
      </div>
    );
  }

  //Color according tag
  function getTagColor(tag) {
    if (tag === "psychological-job-demands") {
      return "#ff9500";
    } else if (tag === "decision-latitude" || tag === "skill-use") {
      return "#8bbdd9";
    } else {
      return null;
    }
  }

  //Load user data
  async function getUser(userId: string) {
    //Fetch from serveur
    store.dispatch(userStatus(STATUS_LOADING));
    const response: any = await store.dispatch(fetchRpsUser(userId));

    if (response.error) {
      store.dispatch(userStatus(STATUS_LOAD_ERROR));
    } else {
      store.dispatch(userStatus(STATUS_LOADED));
      setRpsUser(new UserRps(response));
      setShowList(false);
    }
  }

  return (
    <HelpModal
      isLarge
      onClose={props.onClose}
      title={t("utils_details") + " " + t("rps")}
    >
      <PageLoader status={props.user.status}>
        {props.user.list.length > 1 && !showList && (
          <Link isWithoutMargin onClick={() => setShowList(true)}>
            {t("utils_back")}
          </Link>
        )}

        {showList ? (
          <div>
            <p>
              <b>
                {t("users")} : {props.user.list.length}
              </b>
            </p>
            <Card isWithoutPadding>
              {props.user.list.map((user) => (
                <ListItem
                  key={user.id}
                  isEditable
                  onClick={() => getUser(user.id)}
                >
                  <ListIcon fallbackIcon={faUserCircle} />
                  <div className="grey-t">{t("utils_details")}</div>
                </ListItem>
              ))}
            </Card>
          </div>
        ) : showQuestions ? (
          <div>
            <Link onClick={() => setShowQuestions(false)} isWithoutMargin>
              {t("utils_back")}
            </Link>

            <div className="height-40" />

            {[1, 2, 3].map((order) => (
              <div
                key={order}
                style={{
                  marginRight: "-20px",
                  marginLeft: "-20px",
                }}
              >
                <ListItem>
                  <div className="flex1">
                    <h2>
                      {t(
                        "rps_user_details_" +
                          order +
                          (order === 3 ? "_user" : "")
                      )}
                    </h2>
                    <div className="flex" style={{ marginTop: "22px" }}>
                      <div className="flex1 grey-t">
                        <b>{t("rps_user_details")}</b>
                      </div>
                      <ListCell width={100}>
                        <div className="grey-t flex flex1">
                          <Space />
                          <b>{t("question_response")}</b>
                        </div>
                      </ListCell>
                    </div>
                  </div>
                </ListItem>

                {getQuestions(rpsUser.Questions, order).map((question: any) => (
                  <ListItem key={question.fkQuestion}>
                    <div className="flex1">
                      <div>{question.label}</div>
                      <div
                        className="flex"
                        style={{
                          marginTop: "8px",
                        }}
                      >
                        <Chip color={getTagColor(question.special)}>
                          {getQuestionAxis(question.special)}&nbsp;(
                          {question.index}/{question.count})
                        </Chip>
                        <div
                          className="grey-t"
                          style={{ fontSize: "12px", marginLeft: "8px" }}
                        >
                          {t("rps_" + snakeCase(question.special))}
                        </div>
                      </div>
                    </div>
                    <div className="width-10" />
                    <ListCell width={100}>
                      <span
                        className="flex1"
                        style={{
                          textAlign: "right",
                          color: getQuestionColor(question),
                        }}
                      >
                        <b>{getResponse(question)}</b>
                      </span>
                    </ListCell>
                  </ListItem>
                ))}
              </div>
            ))}
          </div>
        ) : (
          <div>
            <div className="flex">
              <div className="flex3">
                {getRpsItem(
                  t("rps_decision_latitude"),
                  rpsUser.decisionLatitude,
                  70,
                  rpsUser.decisionLatitudeActive,
                  t("rps_decision_latitude_help"),
                  [
                    {
                      text: t("rps_axis_autonomy"),
                      value: rpsUser.autonomy,
                      max: 48,
                    },
                    {
                      text: t("rps_skill_use"),
                      value: rpsUser.skillUse,
                      max: 48,
                    },
                  ],
                  96,
                  false
                )}
                {getRpsItem(
                  t("rps_psychological_job_demands"),
                  rpsUser.psychologicalJobDemands,
                  21,
                  rpsUser.psychologicalJobDemandsActive,
                  t("rps_psychological_job_demands_help"),
                  [],
                  36,
                  true
                )}
              </div>
              {getRpsLabel(
                t("rps_bottom_jobstrain"),
                rpsUser.psychologicalJobDemandsActive &&
                  rpsUser.decisionLatitudeActive,
                t("rps_job_strain"),
                "orange-t"
              )}
            </div>

            <div className="flex">
              <div className="flex3">
                {getRpsItem(
                  t("rps_social_support"),
                  rpsUser.socialSupport,
                  24,
                  rpsUser.socialSupportActive,
                  t("rps_team_support_help"),
                  [
                    {
                      text: t("rps_manager_support"),
                      value: rpsUser.managerSupport,
                      max: 16,
                    },
                    {
                      text: t("rps_team_support"),
                      value: rpsUser.teamSupport,
                      max: 16,
                    },
                  ],
                  32,
                  false
                )}
              </div>
              {getRpsLabel(
                t("rps_bottom_isostrain"),
                rpsUser.psychologicalJobDemandsActive &&
                  rpsUser.decisionLatitudeActive &&
                  rpsUser.socialSupportActive,
                t("rps_stressed"),
                "red-t"
              )}
            </div>

            <div className="flex">
              <div className="flex3">
                {getRpsItem(
                  t("rps_work_gratitude"),
                  rpsUser.workGratitude,
                  16,
                  rpsUser.workGratitudeActive,
                  t("rps_work_gratitude_help"),
                  [],
                  24,
                  false
                )}
              </div>
              {getRpsLabel(t("rps_bottom_gratitude"), false, "", "")}
            </div>

            <Link onClick={openQuestions} isWithoutMargin>
              {t("rps_user_details_btn")}
            </Link>
          </div>
        )}
      </PageLoader>
    </HelpModal>
  );
}

const mapStateToProps = (state) => ({
  topic: state.topic,
  user: state.user,
});

export default connect(mapStateToProps)(withTranslation()(RpsUsersHelp));
