/**
 * SUPERVISOR.ACCOUNT
 * Edit account options
 */
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { WithTranslation, withTranslation } from "react-i18next";
import {
  AccountModules,
  AccountState,
  ACCOUNT_LEVELS,
} from "@/redux/account.types";
import NavFocus from "@/nav/nav.focus";
import PageHeader from "@/components/page-header";
import Button from "@/components/button";
import Modal from "@/components/modal";
import TextInput from "@/components/text-input";
import { store } from "@/index";
// import store from "@/core/store";
import {
  accountEdit,
  accountFetchInvitations_AsSupervisor,
  accountStatus,
  accountUpdateModules_AsSupervisor,
  accountUpdate_AsSupervisor,
} from "@/redux/account.actions";
import {
  STATUS_LOADED,
  STATUS_LOADING,
  STATUS_SAVED,
  STATUS_SAVE_ERROR,
  STATUS_SAVING,
} from "@/redux/_status.types";
import Checkbox from "@/components/checkbox";
import ListItem from "@/components/list-item";
import Card from "@/components/card";
import Space from "@/components/space";
import {
  subscriptionAdd,
  subscriptionRemove,
  subscriptionDestroy,
} from "@/redux/subscription.actions";
import { Subscription, SubscriptionState } from "@/redux/subscription.types";
import {
  packAdd,
  packRemove,
  packDestroy,
  packEdit,
  packUpdate,
} from "@/redux/pack.actions";
import { Pack, PackState, PACK_LIST } from "@/redux/pack.types";
import DeleteAccountModal from "@/modals/delete-account.modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoginModal from "@/modals/login.modal";
import { Session } from "@/redux/_session.types";
import { faPaperPlane, faSearch } from "@fortawesome/free-solid-svg-icons";
import { v4 as uuid } from "uuid";
import SupervisorDropdown from "@/dropdowns/supervisor.dropdown";
import ModulesWidget from "@/widgets/modules.widget";
import ModalConfirm from "@/components/modal-confirm";
import Dropdown from "@/components/dropdown";
import Chip from "@/components/chip";
import SubscriptionEditorWidget from "@/widgets/subscription-editor.widget";
import PageLoader from "@/components/page-loader";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { supervisorFetch, supervisorGet } from "@/redux/supervisor.actions";
import Link from "@/components/link";
import ConvertToSuperaccountModal from "@/modals/convert-to-superaccount.modal";
import { Supervisor } from "@/redux/supervisor.types";

interface Props extends WithTranslation {
  _session: Session;
  account: AccountState;
  pack: PackState;
  subscription: SubscriptionState;
}

//Modal
const MODAL_CONVERT = "MODAL_CONVERT"; //Convertir
const MODAL_LOGIN = "MODAL_LOGIN"; //Loading before connect to account
const MODAL_DELETE_CONFIRM = "MODAL_DELETE_CONFIRM"; //Confirm delete
const MODAL_SELECT_PACK = "MODAL_SELECT_PACK"; //Select pack to update price
const MODAL_SUPERACCOUNT = "MODAL_SUPERACCOUNT"; //Warning when change superaccount

function SuperTrials(props: Props) {
  const { t } = props;
  const navigate: NavigateFunction = useNavigate();

  const queryParams: URLSearchParams = new URLSearchParams(
    window.location.search
  );
  const accountId: string | null = queryParams.get("accountId");

  const [currentModal, setCurrentModal] = useState<string | null>(null);
  const [sentInvitations, setSentInvitations] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  //Load supervisors list
  //Set supervisor and supervisor sales
  useEffect(() => {
    async function loadSupervisors() {
      const response: any = await store.dispatch(supervisorFetch(""));
      if (response.error) {
        store.dispatch(supervisorGet(response.error));
      } else {
        store.dispatch(supervisorGet(response));
        const supervisor = response.find(
          (x) => x.id === props.account.active.SupervisorId
        );
        const supervisorSales = response.find(
          (x) => x.id === props.account.active.SupervisorIdSales
        );

        if (supervisor) {
          store.dispatch(accountEdit("Supervisor", new Supervisor(supervisor)));
        }

        if (supervisorSales) {
          store.dispatch(
            accountEdit("SupervisorSales", new Supervisor(supervisorSales))
          );
        }
      }
    }

    loadSupervisors();
  }, [
    props.account.active.SupervisorId,
    props.account.active.SupervisorIdSales,
  ]);

  useEffect(() => {
    async function getRemainingInvitations() {
      const invitations: any = await store.dispatch(
        accountFetchInvitations_AsSupervisor(props.account.active.id)
      );

      if (invitations.send) {
        setSentInvitations(invitations.send);
      }

      setIsLoading(false);
    }

    getRemainingInvitations();

    if (accountId) {
      setTimeout(
        () =>
          document.querySelector("#subscriptions_section")?.scrollIntoView({
            behavior: "smooth",
          }),
        200
      );
    }
  }, [accountId, props.account.active.id]);

  //Add an invitation pack
  function addPack() {
    const pack = new Pack({ id: uuid() });
    store.dispatch(packAdd(pack));

    savePack(pack);
  }

  //Add subscription
  function addSubscription() {
    store.dispatch(subscriptionAdd(props.account.active.id));
  }

  //Delete account
  //Open confirmation modal
  function deleteAccount() {
    setCurrentModal(MODAL_DELETE_CONFIRM);
  }

  //Delete invitation pack
  async function deletePack(pack: Pack) {
    store.dispatch(packRemove(pack.id));
    store.dispatch(accountStatus(STATUS_SAVING));
    const response: any = await store.dispatch(packDestroy(pack.id));
    store.dispatch(
      accountStatus(response.error ? STATUS_SAVE_ERROR : STATUS_SAVED)
    );
  }

  //Delete subscription
  async function deleteSubscription(subscriptionId: string) {
    store.dispatch(subscriptionRemove(subscriptionId));
    store.dispatch(accountStatus(STATUS_SAVING));
    const response: any = await store.dispatch(
      subscriptionDestroy(subscriptionId)
    );
    store.dispatch(
      accountStatus(response.error ? STATUS_SAVE_ERROR : STATUS_SAVED)
    );
  }

  //Connect to account
  function getAccount() {
    setCurrentModal(MODAL_LOGIN);
  }

  //Edit informations on account
  function handleAccountChanges(event: any) {
    store.dispatch(accountEdit(event.id, event.value));
  }

  //Change checkbox value
  function handleAccountCheckbox(event: any) {
    if (event.value === false && event.id === "isSuperAccount") {
      setCurrentModal(MODAL_SUPERACCOUNT);
    } else {
      handleAccountChanges(event);
      saveAccount();
    }
  }

  //Change level of the account
  function handleAccountLevel(value) {
    handleAccountChanges({ id: "level", value });
    saveAccount();
  }

  //Change options
  function handleAccountOptionsChanges(key, value) {
    const options = Object.assign({}, props.account.active.options, {
      [key]: value,
    });
    handleAccountChanges({ id: "options", value: options });
  }

  //Handle is Unlimited
  function handleIsUnlimited(event) {
    handleAccountOptionsChanges("isUnlimited", event.value);

    if (!props.account.active.options.unlimitedUsers) {
      handleAccountOptionsChanges("unlimitedUsers", 50);
    }

    setTimeout(() => {
      saveAccount();
    }, 500);
  }
  //Change pack value
  function handlePackChanges(packId: string, event: any) {
    if (event.id === "date") {
      store.dispatch(
        packEdit(
          packId,
          event.id,
          event.value.length > 0 ? new Date(event.value) : new Date()
        )
      );
    } else {
      store.dispatch(packEdit(packId, event.id, event.value));
    }
  }

  //Remove superaccount property
  function removeSuperAccount() {
    setCurrentModal(null);
    handleAccountChanges({ id: "isSuperAccount", value: false });
    saveAccount();
  }

  //Save account
  async function saveAccount() {
    store.dispatch(accountStatus(STATUS_SAVING));
    const response: any = await store.dispatch(
      accountUpdate_AsSupervisor(props.account.active)
    );
    store.dispatch(
      accountStatus(response.error ? STATUS_SAVE_ERROR : STATUS_SAVED)
    );
  }

  //Save pack
  async function savePack(pack: Pack) {
    store.dispatch(accountStatus(STATUS_SAVING));
    const response: any = await store.dispatch(
      packUpdate(props.account.active.id, pack)
    );
    store.dispatch(
      accountStatus(response.error ? STATUS_SAVE_ERROR : STATUS_SAVED)
    );
  }

  //Select and save modules
  async function selectModules(modules: AccountModules) {
    store.dispatch(accountStatus(STATUS_SAVING));
    store.dispatch(accountEdit("modules", modules));

    const response: any = await store.dispatch(
      accountUpdateModules_AsSupervisor(props.account.active.id, modules)
    );
    store.dispatch(
      accountStatus(response.error ? STATUS_SAVE_ERROR : STATUS_SAVED)
    );
  }

  //Select pack in the list
  function selectPack(pack: Pack, item: any) {
    pack.count = item.count;
    pack.price = item.unitPrice * item.count;

    store.dispatch(packEdit(pack.id, "count", pack.count));
    store.dispatch(packEdit(pack.id, "price", pack.price));

    savePack(pack);
    setCurrentModal(null);
  }

  return (
    <NavFocus
      text={t("accounts")}
      onBack={() =>
        navigate("/supervisor/accounts" + (accountId ? "?tab=TAB_SUBS" : ""))
      }
    >
      <PageLoader status={isLoading ? STATUS_LOADING : STATUS_LOADED}>
        {currentModal === MODAL_CONVERT && (
          <ConvertToSuperaccountModal onClose={() => setCurrentModal(null)} />
        )}

        {
          /** dialog to connect to login page */
          currentModal === MODAL_LOGIN && (
            <LoginModal
              authLevel={2}
              isSupervisor
              accountIdForSuperadmin={props.account.active.id}
              email={props._session.email}
            />
          )
        }

        {
          /** warning when change superaccount */
          currentModal === MODAL_SUPERACCOUNT && (
            <ModalConfirm
              onNo={() => setCurrentModal(null)}
              onYes={removeSuperAccount}
              text={t("account_remove_superaccount")}
              textBold={t("utils_next_ask")}
            />
          )
        }

        {
          /** dialog to delete account */
          currentModal === MODAL_DELETE_CONFIRM && (
            <DeleteAccountModal
              onClose={() => setCurrentModal(null)}
              accountId={props.account.active.id}
              accountName={props.account.active.name}
            />
          )
        }

        <PageHeader title={t("account_edit")}>
          <Button onClick={deleteAccount}>{t("account_delete")}</Button>

          <Button className="primary" onClick={getAccount}>
            {t("utils_connect")}
          </Button>
        </PageHeader>

        <h3>{t("account_informations")}</h3>

        <div className="flex">
          <TextInput
            id="name"
            onBlur={saveAccount}
            onChange={handleAccountChanges}
            status={props.account.status}
            title={t("account_name")}
            value={props.account.active.name}
          />
        </div>

        <div className="flex">
          <Dropdown
            active={props.account.active.level}
            displayField="name"
            list={ACCOUNT_LEVELS.map((x) => {
              return { id: x, name: t("account_app_level_" + x) };
            })}
            title={t("account_app_level")}
            onSelect={(e) => handleAccountLevel(e.id)}
            status={props.account.status}
            value={
              props.account.active.level
                ? t("account_app_level_" + props.account.active.level)
                : null
            }
          />

          {props._session.supervisorLevel > 1 && (
            <SupervisorDropdown onSave={saveAccount} isSales />
          )}

          {props._session.supervisorLevel > 1 && (
            <SupervisorDropdown onSave={saveAccount} />
          )}
        </div>

        <div
          className="flex"
          style={{
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          <Checkbox
            active={props.account.active.isSuperAccount}
            id="isSuperAccount"
            status={props.account.status}
            text={t("account_consulting")}
            onClick={handleAccountCheckbox}
          />

          {!props.account.active.isSuperAccount && (
            <div
              className="flex-auto"
              onClick={() => setCurrentModal(MODAL_CONVERT)}
            >
              <Link isWithoutMargin>{t("account_convert")}</Link>
            </div>
          )}

          <div className="flex1" />

          <Checkbox
            active={props.account.active.isSelfService}
            id="isSelfService"
            status={props.account.status}
            text={t("account_self_service")}
            onClick={handleAccountCheckbox}
          />

          <Space />
        </div>

        <ModulesWidget
          modules={props.account.active.modules}
          status={props.account.status}
          onChange={(modules: AccountModules) => selectModules(modules)}
        />

        <div className="height-20" />

        <div className="flex">
          <TextInput
            id="participationMin"
            type="number"
            onBlur={saveAccount}
            onChange={handleAccountChanges}
            status={props.account.status}
            title={t("account_participation_min")}
            value={props.account.active.participationMin}
          />

          <TextInput
            id="ceilsTesters"
            type="number"
            onBlur={saveAccount}
            onChange={(e) =>
              handleAccountOptionsChanges(e.id, parseInt(e.value))
            }
            status={props.account.status}
            title={t("account_participation_test")}
            value={props.account.active.options.ceilsTesters}
          />

          <Space />
        </div>
        <div className="height-20" />

        <hr></hr>

        <div className="flex">
          <h3>{t("pack")}</h3>

          <Space />

          {!props.account.active.options.isUnlimited && (
            <Button onClick={addPack} className="secondary">
              {t("pack_add")}
            </Button>
          )}
        </div>

        <div className="flex">
          <Chip isOutlineColored icon={faPaperPlane}>
            {props.pack.list.reduce(
              (accumulator: number, pack: Pack) =>
                accumulator + parseInt(pack.count + ""),
              0
            ) - sentInvitations}
          </Chip>
          <Space />
        </div>

        <div className="flex" style={{ height: 90 }}>
          <Checkbox
            active={props.account.active.options.isUnlimited}
            id="isUnlimited"
            status={props.account.status}
            text={t("account_unlimited")}
            onClick={handleIsUnlimited}
          />

          {props.account.active.options.isUnlimited && (
            <TextInput
              id="unlimitedUsers"
              type="number"
              disabled={!props.account.active.options.isUnlimited}
              onBlur={saveAccount}
              onChange={(e) =>
                handleAccountOptionsChanges(e.id, parseInt(e.value))
              }
              status={props.account.status}
              title={t("account_unlimited_users")}
              value={props.account.active.options.unlimitedUsers}
            />
          )}

          <Space />
        </div>

        {!props.account.active.options.isUnlimited && (
          <div>
            {props.pack.list.length === 0 && (
              <p className="grey-t">{t("pack_empty")}</p>
            )}

            <div className="height-20" />

            {props.pack.list.map((pack: Pack) => (
              <Card key={pack.id}>
                {
                  /** select pack */
                  currentModal === MODAL_SELECT_PACK && (
                    <Modal
                      onClose={() => setCurrentModal(null)}
                      isCloseButtonVisible
                    >
                      {PACK_LIST.map((item) => (
                        <ListItem
                          key={item.count}
                          isEditable
                          onClick={() => selectPack(pack, item)}
                        >
                          <b>{t("pack_invitations", { count: item.count })}</b>
                        </ListItem>
                      ))}
                    </Modal>
                  )
                }

                <div className="flex">
                  <div className="flex flex-dcol">
                    <Space />
                    <Button onClick={() => setCurrentModal(MODAL_SELECT_PACK)}>
                      <FontAwesomeIcon icon={faSearch} />
                    </Button>
                  </div>

                  <TextInput
                    id="count"
                    title={t("invitations")}
                    onBlur={() => savePack(pack)}
                    onChange={(e) => handlePackChanges(pack.id, e)}
                    value={pack.count}
                    status={props.account.status}
                    type="number"
                  />

                  <TextInput
                    id="price"
                    title={t("pack_price")}
                    onBlur={() => savePack(pack)}
                    onChange={(e) => handlePackChanges(pack.id, e)}
                    value={pack.price}
                    status={props.account.status}
                    type="number"
                  />

                  <TextInput
                    id="date"
                    title={t("pack_date")}
                    onBlur={() => savePack(pack)}
                    onChange={(e) => handlePackChanges(pack.id, e)}
                    value={pack.dateInput}
                    status={props.account.status}
                    type="date"
                  />
                </div>

                <div className="height-20" />

                <div className="flex">
                  <Space />
                  <Button onClick={() => deletePack(pack)}>
                    {t("pack_delete")}
                  </Button>
                </div>
              </Card>
            ))}
          </div>
        )}

        <hr></hr>

        <div className="flex">
          <h3 id="subscriptions_section">{t("subscriptions")}</h3>
          <Space />
          <Button onClick={addSubscription} className="secondary">
            {t("subscription_add")}
          </Button>
        </div>

        {props.subscription.list.length === 0 && (
          <p className="grey-t">{t("account_no_active_subscription")}</p>
        )}

        <div className="height-20" />

        {props.subscription.list.map((subscription: Subscription) => (
          <Card key={subscription.id}>
            <SubscriptionEditorWidget subscription={subscription} />

            <div className="height-20" />

            <div className="flex">
              <Space />
              <Button onClick={() => deleteSubscription(subscription.id)}>
                {t("subscription_delete")}
              </Button>
            </div>
          </Card>
        ))}

        <div className="height-200" />
      </PageLoader>
    </NavFocus>
  );
}

const mapStateToProps = (state) => ({
  _session: state._session,
  account: state.account,
  pack: state.pack,
  subscription: state.subscription,
});

export default connect(mapStateToProps)(withTranslation()(SuperTrials));
