import { store } from "@/index";
// import store from "@/core/store";
import { Population } from "@/redux/population.types";
import { ProjectAxisRule } from "@/redux/project.types";

export function getSegmentationPopulations(
  axisId: string | null
): Population[] {
  const segmentation: ProjectAxisRule | undefined = store
    .getState()
    .survey.active.segmentationByAxesRules.find(
      (x: ProjectAxisRule) => x.axisId === axisId
    );

  if (segmentation) {
    return segmentation.populations;
  } else {
    return [];
  }
}
