/**
 * NAV-DASHBOARD
 * Container for dashboard page
 * Back to list of survey button & header
 */

import { withTranslation, WithTranslation } from "react-i18next";
import NavFocus from "./nav.focus";
import DashboardHeaderWidget from "@/widgets/dashboard-header.widget";
import {
  faChartLine,
  faChartPie,
  faCog,
  faColumns,
  faComments,
  faDownload,
  faExclamationTriangle,
  faList,
  faSmile,
  faTh,
  faThumbtack,
  faUsers,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { SurveyState } from "@/redux/survey.types";
import { connect } from "react-redux";
import { useState } from "react";
import Modal from "@/components/modal";
import { BookmarkState } from "@/redux/bookmark.types";
import BookmarksWidget from "@/widgets/bookmarks.widget";
import AddBookmarkModal from "@/modals/add-bookmark.modal";
import ExportDashboardModal from "@/modals/export-dashboard.modal";
import ButtonScrolltop from "@/components/button-scrolltop";
import AccountOptionsHelp from "@/help/account-options.help";
import { Session } from "@/redux/_session.types";
import LoadingModal from "@/modals/loading.modal";
import { store } from "@/index";
// import store from "@/core/store";
import { projectActivate, projectFetchOne } from "@/redux/project.actions";
import { toast } from "react-toastify";
import { NavigateFunction, useNavigate } from "react-router-dom";

interface StateProps extends WithTranslation {
  _session: Session;
  bookmark: BookmarkState;
  survey: SurveyState;
}

interface OwnProps {
  children: any; //Content inside the page
  isFullscreen?: boolean; //Display page in fullscreen
  helpPage?: number; //Open guide at specific page
  hideFilters?: boolean; //Hide filters
  hideOptions?: boolean; //Hide options
  hideRandomize?: boolean; //Hide randomize data option
  showSurveySelector?: boolean; //Show dropdown to switch current survey
  title?: string; //Title for page (if not survey name)
}

type Props = StateProps & OwnProps;

export type NavTabState = {
  id: string;
  label: string;
  icon: IconDefinition;
};

const MODAL_BOOKMARK: string = "MODAL_BOOKMARK";
const MODAL_BOOKMARK_UNAVAILABLE: string = "MODAL_BOOKMARK_UNAVAILABLE";
const MODAL_EXPORT: string = "MODAL_EXPORT";
const MODAL_ADMIN_UNAVAILABLE: string = "MODAL_ADMIN_UNAVAILABLE";
const MODAL_GROUP_SURVEY: string = "MODAL_GROUP_SURVEY";
const MODAL_LOADING: string = "MODAL_LOADING";
const MODAL_HELP_SETTINGS: string = "MODAL_HELP_SETTINGS";
const MODAL_NOT_ADMIN: string = "MODAL_NOT_ADMIN";

//Dashboard Tabs
const TAB_OVERVIEW: NavTabState = {
  id: "/dashboard/overview",
  icon: faChartPie,
  label: "dashboard_overview",
};
const TAB_PARTICIPATION: NavTabState = {
  id: "/dashboard/participation",
  icon: faUsers,
  label: "survey_participation",
};
const TAB_QUESTIONS: NavTabState = {
  id: "/dashboard/questions",
  icon: faList,
  label: "questions",
};
const TAB_HEATMAP: NavTabState = {
  id: "/dashboard/heatmap",
  icon: faTh,
  label: "dashboard_heatmap",
};
//const TAB_REPARTITION: NavTabState = { id : "/dashboard/repartition", icon : faChartBar, label : "survey_repartition" }
const TAB_COMPARE: NavTabState = {
  id: "/dashboard/compare",
  icon: faColumns,
  label: "dashboard_compare",
};
const TAB_HISTORY: NavTabState = {
  id: "/dashboard/history",
  icon: faChartLine,
  label: "dashboard_history",
};
const TAB_MESSAGES: NavTabState = {
  id: "/dashboard/messages",
  icon: faComments,
  label: "dashboard_messages",
};
const TAB_NPS: NavTabState = {
  id: "/dashboard/nps",
  icon: faSmile,
  label: "nps",
};
const TAB_RPS: NavTabState = {
  id: "/dashboard/rps",
  icon: faExclamationTriangle,
  label: "rps",
};

function NavDashboard(props: Props) {
  const { t } = props;
  const navigate: NavigateFunction = useNavigate();

  //Current dialog open
  const [currentModal, setCurrentModal] = useState<string | null>(null);

  //Tabs
  //Magic backend ELEPHANT (no access for managers)
  //if not admin no access to comparator
  const [tabs] = useState(
    props._session.userRole === "ADMIN"
      ? [
          TAB_OVERVIEW,
          TAB_PARTICIPATION,
          TAB_QUESTIONS,
          TAB_HEATMAP,
          //TAB_REPARTITION,
          TAB_COMPARE,
          TAB_HISTORY,
          TAB_MESSAGES,
          TAB_NPS,
          TAB_RPS,
        ]
      : props._session.accountId === "6654c17d-5678-4277-a4ff-04d43b255fa5"
      ? [
          TAB_OVERVIEW,
          TAB_PARTICIPATION,
          TAB_QUESTIONS,
          TAB_HEATMAP,
          //TAB_REPARTITION,
          TAB_NPS,
          TAB_RPS,
        ]
      : [
          TAB_OVERVIEW,
          TAB_PARTICIPATION,
          TAB_QUESTIONS,
          TAB_HEATMAP,
          //TAB_REPARTITION,
          TAB_MESSAGES,
          TAB_NPS,
          TAB_RPS,
        ]
  );

  //Go back from dashboard
  function back() {
    if (isItemSelected("/dashboard/overview")) {
      navigate("/home/account");
    } else {
      navigate("/dashboard/overview");
    }
  }

  //Click on nav button to redirect to page
  async function clickAction(actionId) {
    //If demo survey survey can not be admin
    if (
      (actionId === MODAL_EXPORT || actionId === MODAL_BOOKMARK) &&
      props.survey.active.randomData
    ) {
      setCurrentModal(MODAL_ADMIN_UNAVAILABLE);
    }
    //Admin unavaible for groupSurvey
    else if (
      actionId === "/project/edit" &&
      props.survey.active.isSurveyGroup
    ) {
      setCurrentModal(MODAL_GROUP_SURVEY);
    }
    //Admin unavaible for observer
    else if (
      actionId === "/project/edit" &&
      props._session.userRole !== "ADMIN"
    ) {
      setCurrentModal(MODAL_NOT_ADMIN);
    }
    //Open modal export
    else if (actionId === MODAL_EXPORT) {
      setCurrentModal(MODAL_EXPORT);
    }
    //If bookmark
    else if (actionId === MODAL_BOOKMARK) {
      if (props._session.supervisorLevel === 0) {
        setCurrentModal(MODAL_BOOKMARK);
      } else {
        setCurrentModal(MODAL_BOOKMARK_UNAVAILABLE);
      }
    }
    //Change page
    else {
      //Search project associated to the survey
      if (actionId === "/project/edit") {
        const project = await getProject();
        if (project) navigate("/project/edit");
        else toast(t("project_deleted"), { type: "error" });
      } else {
        navigate(actionId);
      }
    }
  }

  //Detect if item is selected
  function isItemSelected(itemId: string) {
    return itemId === window.location.pathname;
  }

  //Get project
  async function getProject() {
    if (props.survey.active.ProjectId) {
      setCurrentModal(MODAL_LOADING);
      const project: any = await store.dispatch(
        projectFetchOne(props.survey.active.ProjectId)
      );
      store.dispatch(projectActivate(project));
      setCurrentModal(null);
      return project;
    } else {
      return null;
    }
  }

  return (
    <NavFocus
      onBack={back}
      onAction={clickAction}
      isWithMarginTop={props.bookmark.list.length > 0}
      isNotScrollable={props.isFullscreen}
      isWithoutPadding={props.isFullscreen}
      isFullWidth={props.isFullscreen}
      tabs={tabs}
      actions={[
        { id: MODAL_BOOKMARK, icon: faThumbtack, label: t("utils_pin") },
        {
          id: MODAL_EXPORT,
          icon: faDownload,
          label: t("utils_export"),
          isLarge: true,
        },
        {
          id: "/project/edit",
          icon: faCog,
          label: t("survey_link_admin"),
          className:
            props.survey.active.status === "closed" ? "light" : "primary",
          isLarge: true,
        },
      ]}
    >
      {currentModal === MODAL_BOOKMARK_UNAVAILABLE && (
        <Modal isCloseButtonVisible onClose={() => setCurrentModal(null)}>
          <b>{t("dashboard_bookmarks_supervisor")}</b>
        </Modal>
      )}

      {currentModal === MODAL_LOADING && <LoadingModal />}

      {currentModal === MODAL_NOT_ADMIN && (
        <Modal isCloseButtonVisible onClose={() => setCurrentModal(null)}>
          <b>{t("dashboard_is_not_admin")}</b>
        </Modal>
      )}

      {
        /**
         * edit settings
         * change ceil to display red values
         */
        currentModal === MODAL_HELP_SETTINGS && (
          <AccountOptionsHelp onClose={() => setCurrentModal(null)} />
        )
      }

      {currentModal === MODAL_GROUP_SURVEY && (
        <Modal isCloseButtonVisible onClose={() => setCurrentModal(null)}>
          <b>{t("dashboard_multiple_no_admin")}</b>
        </Modal>
      )}

      {currentModal === MODAL_ADMIN_UNAVAILABLE && (
        <Modal isCloseButtonVisible onClose={() => setCurrentModal(null)}>
          <b>
            {t("dashboard_test_demo", {
              name: props.survey.active.name,
            })}
          </b>
        </Modal>
      )}

      {currentModal === MODAL_BOOKMARK && (
        <AddBookmarkModal onClose={() => setCurrentModal(null)} />
      )}

      {currentModal === MODAL_EXPORT && (
        <ExportDashboardModal onClose={() => setCurrentModal(null)} />
      )}

      <BookmarksWidget />

      {!props.isFullscreen && (
        <DashboardHeaderWidget
          title={props.title}
          hideRandomize={props.hideRandomize}
          hideFilters={props.hideFilters}
          onOptions={
            props.hideOptions
              ? undefined
              : () => setCurrentModal(MODAL_HELP_SETTINGS)
          }
          helpPage={props.helpPage}
          showSurveySelector={props.showSurveySelector}
        />
      )}

      {props.children}

      <ButtonScrolltop />
    </NavFocus>
  );
}

const mapStateToProps = (state) => ({
  _session: state._session,
  bookmark: state.bookmark,
  survey: state.survey,
});

export default connect(mapStateToProps)(withTranslation()(NavDashboard));
