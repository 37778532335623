/**
 * PROJECT-SEND-EMAIL.MODAL
 * Send an email to specific person for a survey
 */

import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import Modal from "@/components/modal";
import { ProjectState } from "@/redux/project.types";
import { useEffect, useState } from "react";
import { store } from "@/index";
// import store from "@/core/store";
import { projectStatus } from "@/redux/project.actions";
import { STATUS_SAVED, STATUS_SAVING } from "@/redux/_status.types";
import { SendingState } from "@/redux/sending.types";
import { EmailState } from "@/redux/email.types";
import Dropdown from "@/components/dropdown";
import { emailActivate } from "@/redux/email.actions";
import Link from "@/components/link";
import ProjectEmailContentWidget from "@/widgets/project-email-content.widget";
import i18n from "@/translate/i18n";
import { toast } from "react-toastify";
import { invitationSendEmail } from "@/redux/invitation.actions";
import { InvitationState } from "@/redux/invitation.types";

interface StateProps extends WithTranslation {
  email: EmailState;
  invitation: InvitationState;
  project: ProjectState;
  sending: SendingState;
}

interface OwnProps {
  onClose: Function;
}

type Props = StateProps & OwnProps;

const MODAL_PREVIEW_EMAIL = "MODAL_PREVIEW_EMAIL";

function ProjectSendEmailModal(props: Props) {
  const { t } = props;

  const [currentModal, setCurrentModal] = useState<string | null>(null);

  //Activate email > first sending
  useEffect(() => {
    store.dispatch(emailActivate(props.email.list[props.sending.list.length]));
  }, [props.email.list, props.sending.list.length]);

  //Next
  async function next() {
    store.dispatch(projectStatus(STATUS_SAVING));

    const response: any = await store.dispatch(
      invitationSendEmail(
        props.project.active.id,
        props.invitation.active.aid,
        props.email.active.id,
        props.project.active.emailSkin
      )
    );
    if (!response.error)
      toast(
        t("invitation_send_email_confirm", {
          email: props.invitation.active.email,
        })
      );

    store.dispatch(projectStatus(STATUS_SAVED));
    props.onClose();
  }

  return (
    <Modal
      isCloseButtonVisible
      title={t("invitation_send_email")}
      status={props.project.status}
      onClose={() => props.onClose()}
      onNext={() => next()}
    >
      {currentModal === MODAL_PREVIEW_EMAIL && (
        <Modal onClose={() => setCurrentModal(null)} isCloseButtonVisible>
          <ProjectEmailContentWidget
            currentLanguage={i18n.language}
            skin={props.project.active.emailSkin}
          />
        </Modal>
      )}

      <div className="flex">
        <Dropdown
          value={props.email.active.name}
          displayField="name"
          active={props.email.active.id}
          list={props.email.list}
          title={t("sending_email")}
          onSelect={(e) => store.dispatch(emailActivate(e))}
          error={props.email.list.length === 0 ? true : false}
        />

        <div style={{ marginTop: 42 }}>
          <Link onClick={() => setCurrentModal(MODAL_PREVIEW_EMAIL)}>
            {t("utils_preview")}
          </Link>
        </div>
      </div>

      <div className="height-20" />

      <div style={{ width: 440 }}>
        <p className="grey-t">
          <b>
            {t("utils_email_ask_confirm", {
              name: props.invitation.active.username,
              email: props.invitation.active.email,
            })}
          </b>
        </p>
      </div>

      <div className="height-40" />
      <div className="height-100" />
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  email: state.email,
  invitation: state.invitation,
  project: state.project,
  sending: state.sending,
});

export default connect(mapStateToProps)(
  withTranslation()(ProjectSendEmailModal)
);
