/**
 * TASKS-TOPICS.WIDGET
 * Get list of topics in order to set action
 */
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ActionPlanState } from "@/redux/action-plan.types";
import { Session } from "@/redux/_session.types";
import { TopicState } from "@/redux/topic.types";
import ListItem from "@/components/list-item";
import { sortBy } from "lodash";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import ListButton from "@/components/list-button";
import { useState } from "react";
import DashboardQuestionsNoteWidget from "./dashboard-questions-note.widget";
import Space from "@/components/space";
import Link from "@/components/link";
import { store } from "@/index";
// import store from "@/core/store";
import { topicActivate } from "@/redux/topic.actions";
import TasksTopicModal from "@/modals/tasks-topic.modal";
import Dropdown from "@/components/dropdown";
import { Survey, SurveyState } from "@/redux/survey.types";
import { surveyActivate } from "@/redux/survey.actions";
import {
  actionPlanEdit,
  actionPlanEditSurveyReferenceId,
} from "@/redux/action-plan.actions";
import TasksEditModal from "@/modals/tasks-edit.modal";

interface StateProps extends WithTranslation {
  _session: Session;
  actionPlan: ActionPlanState;
  survey: SurveyState;
  topic: TopicState;
}

const MODAL_ADD = "MODAL_ADD";
const MODAL_TOPIC = "MODAL_TOPIC";

function TasksTopicsWidget(props: StateProps) {
  const { t } = props;

  //CurrentModal (dialog box)
  const [currentModal, setCurrentModal] = useState<string | null>(null);

  //Reduced display by default
  const [isReduced, setIsReduced] = useState(true);

  //Get list of topic
  //> Search only topics with score
  //> Do not include topic already selected for tasks
  function getTopics() {
    const topicsWithTask = props.actionPlan.active.tasks.map((x) => x.topicAid);
    const list = sortBy(
      props.topic.list.filter(
        (x) => x.withScore && topicsWithTask.indexOf(x.aid) === -1
      ),
      "note"
    );
    return isReduced ? list.slice(0, 5) : list;
  }

  //Select survey and reload topics
  function selectSurvey(survey: Survey) {
    const surveyId: string | null = survey.id === "" ? null : survey.id;
    store.dispatch(surveyActivate(survey));
    store.dispatch(actionPlanEdit("referenceSurveyId", surveyId, true));
    store.dispatch(actionPlanEditSurveyReferenceId(surveyId));
  }

  return (
    <div
      style={{
        width: 360,
        marginRight: 40,
        height: "calc(100vh - 290px)",
        overflowY: "auto",
      }}
    >
      {currentModal === MODAL_ADD && (
        <TasksEditModal onClose={() => setCurrentModal(null)} />
      )}

      {currentModal === MODAL_TOPIC && (
        <TasksTopicModal onClose={() => setCurrentModal(null)} />
      )}

      <Dropdown
        value={props.survey.active.name}
        displayField="name"
        isNullAllowed
        active={props.survey.active.id}
        list={props.survey.list}
        title={t("tasks_survey")}
        onSelect={(survey) => selectSurvey(survey)}
      />

      <div className="height-20" />

      {getTopics().map((topic, i) => (
        <ListItem key={topic.aid}>
          <div className="flex flex1" style={{ margin: "0px -32px" }}>
            <DashboardQuestionsNoteWidget currentTopic={topic} />

            <span
              className="_hover"
              onClick={() => {
                store.dispatch(topicActivate(topic));
                setCurrentModal(MODAL_TOPIC);
              }}
            >
              {topic.label}
            </span>

            <Space />

            <div className="width-20" />

            <div className="flex flex-auto">
              <ListButton
                icon={faPlus}
                isPrimary
                onClick={() => {
                  store.dispatch(topicActivate(topic));
                  setCurrentModal(MODAL_ADD);
                }}
                isFat={i === 0 && props.actionPlan.active.tasks.length === 0}
                text={t("tasks_list_add")}
              />
            </div>
          </div>
        </ListItem>
      ))}

      <div className="height-20" />

      {getTopics().length > 0 && (
        <div className="flex">
          <Space />
          <Link isWithoutMargin onClick={() => setIsReduced(!isReduced)}>
            {isReduced ? t("tasks_topic_show_all") : t("tasks_topic_hide_all")}
          </Link>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  _session: state._session,
  actionPlan: state.actionPlan,
  survey: state.survey,
  topic: state.topic,
});

export default connect(mapStateToProps)(withTranslation()(TasksTopicsWidget));
