/**
 * DELETE-USER-AS-SUPERVISOR
 * Delete user from supervisor interface
 */
import { connect } from "react-redux";
import { withTranslation, WithTranslation } from "react-i18next";
import { UserState } from "@/redux/user.types";
import ModalConfirm from "@/components/modal-confirm";
import { store } from "@/index";
// import store from "@/core/store";
import {
  userDestroy_AsSupervisor,
  userRemove,
  userStatus,
} from "@/redux/user.actions";
import { STATUS_SAVED, STATUS_SAVING } from "@/redux/_status.types";

interface StateProps extends WithTranslation {
  user: UserState;
}

interface OwnProps {
  onClose: Function;
}

type Props = StateProps & OwnProps;

function DeleteUserAsSupervisorModal(props: Props) {
  const { t } = props;

  //Proceed to delete and close modal
  async function deleteConfirm() {
    store.dispatch(userStatus(STATUS_SAVING));
    const response: any = await store.dispatch(
      userDestroy_AsSupervisor(props.user.active.id)
    );
    store.dispatch(userStatus(STATUS_SAVED));

    if (!response.error) {
      store.dispatch(userRemove(props.user.active.id));
      props.onClose();
    }
  }

  return (
    <ModalConfirm
      onNo={props.onClose}
      onYes={deleteConfirm}
      text={t("user_ask_delete", {
        user: props.user.active.username,
      })}
      textBold={t("utils_next_ask")}
    />
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(
  withTranslation()(DeleteUserAsSupervisorModal)
);
