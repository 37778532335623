/**
 * PROJECT-EDIT.WIDGET
 * Load all project wizard
 */

import { connect } from "react-redux";
import { withTranslation, WithTranslation } from "react-i18next";
import Space from "@/components/space";
import { useCallback, useEffect, useState } from "react";
import {
  STATUS_LOADED,
  STATUS_LOADING,
  STATUS_SAVED,
  STATUS_SAVING,
  STATUS_SEARCHING,
} from "@/redux/_status.types";
import {
  faCalendar,
  faChartBar,
  faColumns,
  faCommentAlt,
  faEdit,
  faEnvelope,
  faFileUpload,
  faFilter,
  faGlobe,
  faLock,
  faPaperPlane,
  faQrcode,
  faStop,
  faTrash,
  faUserCog,
  faUserPlus,
  faVial,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import Button from "@/components/button";
import PageLoader from "@/components/page-loader";
import { Template, TemplateState } from "@/redux/template.types";
import { ProjectState, ProjectStep } from "@/redux/project.types";
import { store } from "@/index";
// import store from "@/core/store";
import {
  projectCheckCalendar,
  projectCheckEmails,
  projectCheckFilters,
  projectCheckRecipients,
  projectCheckTemplate,
  projectEdit,
  projectStatus,
  projectUpdate,
} from "@/redux/project.actions";
import { templateActivate, templateFetchOne } from "@/redux/template.actions";
import { sendingFetch, sendingGet } from "@/redux/sending.actions";
import {
  userFetchCount,
  userFetchRecipients,
  userGet,
  userGetCount,
  userGetCountSearch,
  userGetExcluded,
  userStatus,
} from "@/redux/user.actions";
import { Sending, SendingState } from "@/redux/sending.types";
import { UserState } from "@/redux/user.types";
import ProjectTemplateWidget from "./project-template.widget";
import ProjectModesWidget from "./project-modes.widget";
import ProjectImportWidget from "./project-import.widget";
import ProjectRecipientsWidget from "./project-recipients.widget";
import ProjectSegmentWidget from "./project-segment.widget";
import ProjectLanguagesWidget from "./project-languages.widget";
import ProjectEmailsWidget from "./project-emails.widget";
import ProjectTestModal from "@/modals/project-test.modal";
import ProjectMessageWidget from "./project-message.widget";
import PageHeader from "@/components/page-header";
import ProjectLaunchModal from "@/modals/project-launch.modal";
import ProjectCloseSurveyModal from "@/modals/project-close-survey.modal";
import { surveyActivate } from "@/redux/survey.actions";
import { Survey, SurveyState } from "@/redux/survey.types";
import Link from "@/components/link";
import ProjectDeleteModal from "@/modals/project-delete.modal";
import ProjectChecklistWidget from "./project-checklist.widget";
import StepCard from "@/components/step-card";
import ProjectQrcodeWidget from "./project-qrcode.widget";
import ProjectRenameModal from "@/modals/project-rename.modal";
import { emailFetchByProject, emailGet } from "@/redux/email.actions";
import { Email, EmailState } from "@/redux/email.types";
import ProjectCalendarWidget from "./project-calendar.widget";
import { reminderFetch, reminderGet } from "@/redux/reminder.actions";
import { ReminderState } from "@/redux/reminder.types";
import GetSurveyResultsModal from "@/modals/get-survey-results.modal";
import ProjectFiltersWidget from "./project-filters.widget";
import CardButton from "@/components/card-button";
import ProjectRepeatModal from "@/modals/project-repeat.modal";
import Modal from "@/components/modal";
import { topicFetch, topicGet } from "@/redux/topic.actions";
import { axisFetch, axisGet } from "@/redux/axis.actions";
import { Axis } from "@/redux/axis.types";
import { Session } from "@/redux/_session.types";
import ToggleButton from "@/components/toggle-button";
import { filterFetch, filterGet } from "@/redux/filter.actions";
import { attributeGet } from "@/redux/attribute.actions";
import { Filter, FilterState } from "@/redux/filter.types";
import { Attribute } from "@/redux/attribute.types";
import { Topic } from "@/redux/topic.types";
import { Question } from "@/redux/question.types";
import { intersection, sortBy } from "lodash";
import UpgradeModal from "@/modals/upgrade.modal";
import SurveyReopenModal from "@/modals/survey-reopen.modal";
import { NavigateFunction, useNavigate } from "react-router-dom";

interface StateProps extends WithTranslation {
  _session: Session;
  email: EmailState;
  filter: FilterState;
  project: ProjectState;
  reminder: ReminderState;
  sending: SendingState;
  survey: SurveyState;
  template: TemplateState;
  user: UserState;
}

interface Step {
  id: ProjectStep;
  icon: IconDefinition;
  mode?: string;
}

const STEPS: Step[] = [
  { id: "modes", icon: faPaperPlane },
  { id: "template", icon: faEdit },
  { id: "import", icon: faFileUpload },
  { id: "recipients", icon: faUserPlus },
  { id: "segment", icon: faColumns },
  { id: "filters", icon: faFilter },
  { id: "languages", icon: faGlobe },
  { id: "message", icon: faCommentAlt },
  { id: "qrcode", icon: faQrcode, mode: "qrcode" },
  { id: "emails", icon: faEnvelope, mode: "email" },
  { id: "calendar", icon: faCalendar },
];

//Modal
const MODAL_CLOSE_SURVEY: string = "MODAL_CLOSE_SURVEY"; //Close current survey if launched
const MODAL_DELETE: string = "MODAL_DELETE"; //Delete current project
const MODAL_GET_RESULTS: string = "MODAL_GET_RESULTS"; //Get results for the survey
const MODAL_LAUNCH: string = "MODAL_LAUNCH"; //Launch survey
const MODAL_LAUNCH_TEST: string = "MODAL_LAUNCH_TEST"; //Launch survey (test mode)
const MODAL_LAUNCH_UNAVAILABLE: string = "MODAL_LAUNCH_UNAVAILABLE"; //Modal listing unconfirmed steps and errors when clicking on launch
const MODAL_RENAME: string = "MODAL_RENAME"; //Rename the project
const MODAL_REPEAT_PROJECT: string = "MODAL_REPEAT_PROJECT"; //Reopen the projet after survey is closed
const MODAL_REOPEN_SURVEY: string = "MODAL_REOPEN_SURVEY"; //Reopen the survey after survey is closed
const MODAL_TEST: string = "MODAL_TEST"; //Set params for the test
const MODAL_UPGRADE: string = "MODAL_UPGRADE"; //Buy modules

function ProjectEditWidget(props: StateProps) {
  const { t } = props;
  const navigate: NavigateFunction = useNavigate();

  //Current modal open
  const [currentModal, setCurrentModal] = useState<string | null>(null);

  //Step that is opened
  const [currentStep, setCurrentStep] = useState<ProjectStep | null>(null);

  //Is the page loaded
  const [isPageLoaded, setIsPageLoaded] = useState(false);

  //Detect loading status by item
  const [itemToLoad, setItemToLoad] = useState<ProjectStep | null>(null);

  //Saving in progress
  const [itemToSave, setItemToSave] = useState<ProjectStep | null>(null);

  //List of steps for the wizard
  const [steps, setSteps] = useState<Step[]>([]);

  //Step to show for upgrade
  const [stepToUpgrade, setStepToUpgrade] = useState<ProjectStep>("languages");

  //Update user count
  const [usersCount, setUsersCount] = useState(0);

  //Load emails for the Account
  const loadEmails = useCallback(async () => {
    const emailResponse: any = await store.dispatch(
      emailFetchByProject(props.project.active.id)
    );
    const emails = emailResponse.error
      ? []
      : emailResponse.map((x) => new Email(x));
    store.dispatch(emailGet(emails, props.project.active.emailsOrder));
    store.dispatch(projectCheckEmails(props.project.active.languages, emails));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.project.active.id, props.project.active.languages]);

  //Load filters
  const loadFilters = useCallback(async () => {
    //Create survey with random data true and TemplateId of the project
    store.dispatch(
      surveyActivate(
        new Survey({
          randomData: true,
          template: new Template({
            id: props.project.active.TemplateId
              ? props.project.active.TemplateId
              : "",
          }),
        })
      )
    );

    //Fetch filters
    const filtersResponse: any = await store.dispatch(filterFetch(true, true));
    const topicsResponse: any = props.project.active.TemplateId?.length
      ? await store.dispatch(topicFetch(props.project.active.TemplateId))
      : [];

    //Format filters
    if (filtersResponse.error) {
      store.dispatch(filterGet([]));
      store.dispatch(attributeGet([]));
    } else {
      //Init filters
      const filters: Filter[] = filtersResponse.filter((x) => !x.default);

      //Init attributes
      const attributes: Attribute[] = [];
      filters.forEach((filter) => {
        filter.populations.forEach((population, i) => {
          attributes.push(
            new Attribute({
              id: population.name + "_" + filter.name,
              aid: i.toString(),
              name: population.name,
              filterName: filter.name,
              usersCount: population.usersCount,
            })
          );
        });
      });

      //Loop on topic with filters inside
      topicsResponse
        .filter((x) => x.isAtStart)
        .forEach((x) => {
          const topic = new Topic(x);
          const question = new Question(topic.Questions[0]);

          filters.push(
            new Filter({
              id: topic.id,
              name: topic.label,
              isTemplateFilter: true,
              isChecked: true,
            })
          );

          question.Propositions.forEach((propositions, i) => {
            attributes.push(
              new Attribute({
                id: propositions.label + "_" + topic.label,
                aid: i.toString(),
                name: propositions.label,
                filterName: topic.label,
                usersCount: 0,
              })
            );
          });
        });

      //Update store
      store.dispatch(filterGet(sortBy(filters, "name")));
      store.dispatch(attributeGet(attributes));
      store.dispatch(
        projectCheckFilters(filters, props._session.participationMin)
      );
    }
  }, [props._session.participationMin, props.project.active.TemplateId]);

  useEffect(() => {
    store.dispatch(
      projectCheckFilters(props.filter.list, props._session.participationMin)
    );
  }, [
    props.filter.active.isChecked,
    props.filter.list,
    props._session.participationMin,
  ]);

  //Load recipients data (count and list of users for preview)
  const loadRecipients = useCallback(async () => {
    //Fetch data
    store.dispatch(userStatus(STATUS_SEARCHING));
    const recipients: any = await store.dispatch(
      userFetchRecipients(props.project.active.id, "", 5, 0)
    );
    store.dispatch(userStatus(STATUS_LOADED));

    //Save data into the store
    store.dispatch(userGet(recipients.error ? [] : recipients.users));
    store.dispatch(userGetCountSearch(recipients.error ? 0 : recipients.count));
    store.dispatch(
      userGetExcluded(recipients.error ? [] : recipients.excludedUsers)
    );
    setUsersCount(recipients.error ? 0 : recipients.count);

    //Check recipients
    if (!recipients.error) {
      store.dispatch(
        projectCheckRecipients(
          recipients.usersWithoutEmail,
          recipients.countWithoutEmail,
          recipients.usersWithoutAddress,
          recipients.countWithoutAddress,
          recipients.count,
          props._session.participationMin
        )
      );
    }
  }, [props.project.active.id, props._session.participationMin]);

  //Load reminders
  const loadReminders = useCallback(async () => {
    const reminderResponse: any = await store.dispatch(
      reminderFetch(props.project.active.id)
    );
    store.dispatch(reminderGet(reminderResponse.error ? [] : reminderResponse));
  }, [props.project.active.id]);

  //Load sendings data
  const loadSendings = useCallback(async () => {
    const sendingResponse: any = await store.dispatch(
      sendingFetch(props.project.active.id)
    );
    const sendings = sendingResponse.error
      ? []
      : sendingResponse.map((x) => new Sending(x));
    store.dispatch(sendingGet(sendings));
    store.dispatch(
      projectCheckCalendar(props.project.active.diffusionMode, sendings)
    );
  }, [props.project.active.id, props.project.active.diffusionMode]);

  //Load template data
  const loadTemplates = useCallback(async () => {
    //Fetch
    const templateResponse: any = props.project.active.TemplateId
      ? await store.dispatch(templateFetchOne(props.project.active.TemplateId))
      : {};
    const topics: any = templateResponse.id
      ? await store.dispatch(topicFetch(templateResponse.id))
      : [];
    const axes: any = templateResponse.id
      ? await store.dispatch(axisFetch(templateResponse.id, null))
      : [];

    //Store data
    const template = templateResponse.error
      ? new Template()
      : new Template(templateResponse);
    store.dispatch(templateActivate(template));
    store.dispatch(topicGet(topics.error ? [] : topics));
    store.dispatch(
      axisGet(
        axes.error ? [] : axes.concat([new Axis({ id: "" })]),
        template.axesOrder.concat([""])
      )
    );

    //avoid project having languages that are unavailable in template
    if (
      props.project.active.languages.some(
        (lang: string) => template.languages.indexOf(lang) < 0
      )
    ) {
      store.dispatch(
        projectEdit(
          "languages",
          intersection(template.languages, props.project.active.languages)
        )
      );
    }

    //Check template
    store.dispatch(
      projectCheckTemplate(
        template,
        topics,
        axes,
        props.project.active.languages
      )
    );
  }, [props.project.active.TemplateId, props.project.active.languages]);

  //Load users
  const loadUsers = useCallback(async () => {
    const usersCountResponse: any = await store.dispatch(
      userFetchCount("", null)
    );
    const usersCount = usersCountResponse.error ? 0 : usersCountResponse.count;
    store.dispatch(userGetCount(usersCount));
  }, []);

  //Load data at start
  //No need to load all the data if survey is launch
  useEffect(() => {
    load();

    async function load() {
      await loadReminders();
      await loadSendings();
      await loadEmails();
      await loadRecipients();
      await loadFilters();
      await loadTemplates();
      await loadUsers();

      if (props.project.active.isLaunch || props.project.active.isClosed) {
        store.dispatch(surveyActivate(props.project.active.lastSurvey));
      }

      setIsPageLoaded(true);
    }
  }, [
    props.project.active.isClosed,
    props.project.active.isLaunch,
    props.project.active.lastSurvey,
    loadEmails,
    loadFilters,
    loadRecipients,
    loadReminders,
    loadSendings,
    loadTemplates,
    loadUsers,
  ]);

  //Save changes
  //Detect that a save is triggered
  //Declare then item to reload
  useEffect(() => {
    save();
    async function save() {
      if (itemToSave) {
        store.dispatch(projectStatus(STATUS_SAVING));
        await store.dispatch(projectUpdate(props.project.active));
        store.dispatch(projectStatus(STATUS_SAVED));
        setItemToLoad(itemToSave);
        setItemToSave(null);
      }
    }
  }, [props.project.active, itemToSave]);

  //Detect change and reload specific data
  useEffect(() => {
    load().then(() => {
      setItemToLoad(null);
    });

    async function load() {
      switch (itemToLoad) {
        case "template":
          return await loadTemplates();
        case "import":
          await loadRecipients();
          await loadFilters();
          return await loadUsers();
        case "recipients":
        case "modes":
        case "qrcode":
          return await loadRecipients();
        case "calendar":
          return await loadSendings();
        default:
          return null;
      }
    }
  }, [
    itemToLoad,
    loadRecipients,
    loadFilters,
    loadSendings,
    loadTemplates,
    loadUsers,
  ]);

  //Change steps according change of modes
  useEffect(() => {
    setSteps(
      STEPS.filter((x) => !x.mode || props.project.active.diffusionMode[x.mode])
    );
  }, [props.project.active.diffusionMode]);

  //Quit test modal
  function confirmTest(isToLaunch: boolean) {
    setItemToSave("test");
    setCurrentModal(isToLaunch ? MODAL_LAUNCH_TEST : null);
  }

  //Edit projet value
  function editProject(key: string, value: any, step: ProjectStep) {
    store.dispatch(projectEdit(key, value));
    setItemToSave(step);
  }

  //Display subtitle for every step
  function getSubtitle(step: ProjectStep) {
    switch (step) {
      case "template":
        return props.template.active.label;
      case "modes":
        return props.project.active.diffusionModeLabel;
      case "import":
        return t("survey_recipients_user_count", {
          count: props.user.count,
          s: props.user.count > 1 ? "s" : "",
        });
      case "filters":
        return t("filters_custom_count", {
          count: props.filter.list.length,
          s: props.filter.list.length > 1 ? "s" : "",
        });
      case "recipients":
        return t("survey_recipients_count", {
          count: usersCount,
          s: usersCount > 1 ? "s" : "",
        });
      case "segment":
        return props.project.active.isSegmentationActive
          ? t("project_segmentation_actived")
          : t("project_segmentation_inactive");
      case "languages":
        return props.project.active.languages.length > 0
          ? props.project.active.languages.join(", ").toUpperCase()
          : "";
      case "emails":
        return t("email_count", {
          count: props.email.list.length,
          s: props.email.list.length > 1 ? "s" : "",
        });
      case "calendar":
        return props.project.active.getNextEvent(
          props.sending.list,
          props.reminder.list,
          true,
          props.email.list
        ).name;
      default:
        return <span />;
    }
  }

  //get errors but hide those from inactive sending modes
  function getErrorsFiltered(): string[] {
    return props.project.active.errorsFlatten.filter((error: string) =>
      props.project.active.inactiveDiffusionModes.every(
        (inactiveMode: string) =>
          !error.includes("project_error_" + inactiveMode)
      )
    );
  }

  //get warnings but hide those from inactive sending modes
  function getWarningsFiltered(): string[] {
    return props.project.active.warningsFlatten.filter((warning: string) =>
      props.project.active.inactiveDiffusionModes.every(
        (inactiveMode: string) =>
          !warning.includes("project_warning_" + inactiveMode)
      )
    );
  }

  function getUnconfirmedSteps() {
    return STEPS.filter(
      (x: Step) =>
        !isPaywall(x.id) &&
        (!x.mode || props.project.active.diffusionMode[x.mode]) &&
        props.project.active.stepsConfirmed.indexOf(x.id) < 0
    );
  }

  function getWidget() {
    switch (currentStep) {
      case "template":
        return (
          <ProjectTemplateWidget
            onNext={nextStep}
            onEdit={() => setItemToSave("template")}
          />
        );
      case "modes":
        return (
          <ProjectModesWidget
            onNext={nextStep}
            onEdit={() => setItemToSave("modes")}
          />
        );
      case "import":
        return (
          <ProjectImportWidget
            onNext={nextStep}
            onEdit={() => setItemToSave("import")}
          />
        );
      case "filters":
        return (
          <ProjectFiltersWidget
            onNext={nextStep}
            onEdit={() => openStep("import")}
          />
        );
      case "recipients":
        return (
          <ProjectRecipientsWidget
            onNext={nextStep}
            onEdit={() => setItemToSave("recipients")}
          />
        );
      case "segment":
        return (
          <ProjectSegmentWidget
            onNext={nextStep}
            onSelect={() => setItemToSave("segment")}
          />
        );
      case "languages":
        return (
          <ProjectLanguagesWidget
            onNext={nextStep}
            onEdit={() => setItemToSave("languages")}
          />
        );
      case "message":
        return (
          <ProjectMessageWidget
            onNext={nextStep}
            onEdit={() => setItemToSave("message")}
          />
        );
      case "qrcode":
        return (
          <ProjectQrcodeWidget
            onNext={nextStep}
            onEdit={() => setItemToSave("qrcode")}
          />
        );
      case "emails":
        return (
          <ProjectEmailsWidget
            onNext={nextStep}
            onEdit={() => setItemToSave("calendar")}
          />
        );
      case "calendar":
        return <ProjectCalendarWidget onNext={nextStep} />;
      default:
        return <div>{t("widget_not_found")}</div>;
    }
  }

  function isPaywall(stepId: ProjectStep) {
    return (
      props.project.active.paywallSteps.indexOf(stepId) > -1 &&
      !props._session.modules[stepId]
    );
  }

  //Detect if the step is done
  function isStepConfirmed(stepId: ProjectStep) {
    return props.project.active.stepsConfirmed.indexOf(stepId) > -1;
  }

  //Detect if the step is opened
  function isStepVisited(stepId: ProjectStep) {
    return props.project.active.stepsVisited.indexOf(stepId) > -1;
  }

  //On click on next
  //Save if step have to be visited / confirmed
  async function nextStep(isVisited, isConfirmed) {
    if (currentStep) {
      //Mark step as confirmed
      if (isConfirmed && !isStepConfirmed(currentStep)) {
        store.dispatch(
          projectEdit(
            "stepsConfirmed",
            props.project.active.stepsConfirmed.concat([currentStep])
          )
        );
      }
      //Mark step as unconfirmed
      else if (!isConfirmed && isStepConfirmed(currentStep)) {
        store.dispatch(
          projectEdit(
            "stepsConfirmed",
            props.project.active.stepsConfirmed.filter((x) => x !== currentStep)
          )
        );
      }

      //Mark step as visited
      if (isVisited && !isStepVisited(currentStep)) {
        store.dispatch(
          projectEdit(
            "stepsVisited",
            props.project.active.stepsVisited.concat([currentStep])
          )
        );
      }
      //Mark step as not visited
      else if (!isVisited && isStepVisited(currentStep)) {
        store.dispatch(
          projectEdit(
            "stepsVisited",
            props.project.active.stepsVisited.filter((x) => x !== currentStep)
          )
        );
      }

      //Set item to save
      setItemToSave(currentStep);

      //Scroll on top the step
      scroll(currentStep);

      //Close step
      setCurrentStep(null);
    }
  }

  //Click on launch button
  function launch() {
    setCurrentStep(null);
    setCurrentModal(MODAL_LAUNCH);
  }

  //Click on the button to open the calendar
  function openCalendar() {
    openStep("calendar");
    setTimeout(() => {
      scroll("calendar");
    }, 200);
  }

  //Open step
  //Mark step as undone
  function openStep(stepId: ProjectStep) {
    //first check if module is available
    if (isPaywall(stepId)) {
      setStepToUpgrade(stepId);
      setCurrentModal(MODAL_UPGRADE);
    } else {
      setCurrentStep(stepId === currentStep ? null : stepId);
    }
  }

  //Scroll to specifi step
  function scroll(step: ProjectStep) {
    document.querySelector("#step-" + step)?.scrollIntoView({
      behavior: "smooth",
    });
  }

  //Click on test button
  function test() {
    setCurrentStep(null);
    setCurrentModal(MODAL_TEST);
  }

  return (
    <PageLoader status={isPageLoaded ? STATUS_LOADED : STATUS_LOADING}>
      {currentModal === MODAL_CLOSE_SURVEY && (
        <ProjectCloseSurveyModal onClose={() => setCurrentModal(null)} />
      )}

      {currentModal === MODAL_DELETE && (
        <ProjectDeleteModal onClose={() => setCurrentModal(null)} />
      )}

      {currentModal === MODAL_GET_RESULTS && (
        <GetSurveyResultsModal
          onClose={() => setCurrentModal(null)}
          destination="/dashboard/overview"
        />
      )}

      {currentModal === MODAL_LAUNCH && (
        <ProjectLaunchModal
          onClose={() => setCurrentModal(null)}
          recipientsCount={usersCount}
        />
      )}

      {currentModal === MODAL_LAUNCH_TEST && (
        <ProjectLaunchModal
          isTest
          onClose={() => setCurrentModal(null)}
          recipientsCount={usersCount}
        />
      )}

      {currentModal === MODAL_LAUNCH_UNAVAILABLE && (
        <Modal
          title={t("project_launch")}
          onClose={() => setCurrentModal(null)}
          isCloseButtonVisible
        >
          <p className="red-t">
            <b>{t("project_launch_unavailable")}</b>
          </p>

          {getUnconfirmedSteps().length > 0 && (
            <div>
              <div className="red-t">
                {getUnconfirmedSteps().length === 1
                  ? t("project_unconfirmed_steps_unique")
                  : t("project_unconfirmed_steps_multiple", {
                      count: getUnconfirmedSteps().length,
                    })}
              </div>
              <ul>
                {getUnconfirmedSteps().map((step: Step) => (
                  <li key={step.id} className="grey-t" style={{ fontSize: 12 }}>
                    {t("project_setup_" + step.id)}
                  </li>
                ))}
              </ul>
              <div className="height-10" />
            </div>
          )}

          {getErrorsFiltered().length > 0 && (
            <div>
              <div className="red-t">
                {t("project_error_steps_unique", {
                  count: getErrorsFiltered().length,
                  s: getErrorsFiltered().length > 1 ? "s" : "",
                })}
              </div>
              <ul>
                {getErrorsFiltered().map((error: string, i: number) => (
                  <li key={i} className="grey-t" style={{ fontSize: 12 }}>
                    {t(error)}
                  </li>
                ))}
              </ul>
              <div className="height-10" />
            </div>
          )}

          {getWarningsFiltered().length > 0 && (
            <div className="grey-t">
              {t("project_warning_steps_unique", {
                count: getWarningsFiltered().length,
                s: getWarningsFiltered().length > 1 ? "s" : "",
              })}
              <ul>
                {getWarningsFiltered().map((warning: string, i: number) => (
                  <li key={i} style={{ fontSize: 12 }}>
                    {t(warning)}
                  </li>
                ))}
              </ul>
              <div className="height-10" />
            </div>
          )}
        </Modal>
      )}

      {currentModal === MODAL_RENAME && (
        <ProjectRenameModal
          onClose={(isSaved: boolean, projectName: string) => {
            if (isSaved) editProject("name", projectName, "name");
            setCurrentModal(null);
          }}
        />
      )}

      {currentModal === MODAL_REOPEN_SURVEY && (
        <SurveyReopenModal onClose={() => setCurrentModal(null)} />
      )}

      {currentModal === MODAL_REPEAT_PROJECT && (
        <ProjectRepeatModal onClose={() => setCurrentModal(null)} />
      )}

      {currentModal === MODAL_TEST && (
        <ProjectTestModal
          onClose={() => confirmTest(false)}
          onNext={() => confirmTest(true)}
        />
      )}

      {currentModal === MODAL_UPGRADE && (
        <UpgradeModal
          feature={stepToUpgrade}
          onClose={() => setCurrentModal(null)}
        />
      )}

      <PageHeader
        title={props.project.active.name}
        subtitle={t("project_help")}
      ></PageHeader>

      {props.project.active.status === "test" ||
      props.project.active.status === "launch" ? (
        <div className="flex1 flex">
          <div className="flex flex-dcol" style={{ width: 700 }}>
            <Space />
            <ProjectChecklistWidget />
            <Space />
          </div>

          <div className="width-20" />

          <div className="flex" style={{ marginRight: "-14px" }}>
            <CardButton
              icon={faChartBar}
              isSmall
              onClick={() => setCurrentModal(MODAL_GET_RESULTS)}
              title={t("survey_link_dashboard")}
            />

            <CardButton
              icon={faCalendar}
              isSmall
              onClick={() => openCalendar()}
              title={t("project_calendar")}
            />

            <CardButton
              icon={faUserCog}
              isSmall
              isCta
              onClick={() => navigate("/project/invitations")}
              title={t("project_invitations")}
            />
          </div>
        </div>
      ) : (
        <div className="flex1 flex">
          <Space />
          <div style={{ width: 700 }}>
            <ProjectChecklistWidget />
          </div>
          <Space />
        </div>
      )}

      <div className="height-60" />

      {props.project.active.status === "closed" ? (
        <div className="flex">
          <Space />

          <CardButton
            onClick={() =>
              props.survey.active.id.length > 0
                ? setCurrentModal(MODAL_GET_RESULTS)
                : {}
            }
            subtitle={
              props.survey.active.id.length < 1
                ? t("survey_do_not_exist")
                : undefined
            }
            icon={faChartBar}
            isActive={props.survey.active.id.length > 0}
            isLock={props.survey.active.id.length < 1}
            title={t("survey_link_dashboard")}
          />

          <CardButton
            onClick={() => setCurrentModal(MODAL_REPEAT_PROJECT)}
            icon={faEdit}
            title={t("project_repeat")}
          />

          <CardButton
            onClick={() => setCurrentModal(MODAL_REOPEN_SURVEY)}
            icon={faPaperPlane}
            title={t("survey_reopen")}
          />

          <CardButton
            onClick={() => setCurrentModal(MODAL_DELETE)}
            icon={faTrash}
            title={t("project_delete")}
          />

          <Space />
        </div>
      ) : (
        <div>
          {steps.map((step: Step) => (
            <div key={step.id} id={"step-" + step.id}>
              <StepCard
                isLocked={
                  props.project.active.isLocked(step.id) || isPaywall(step.id)
                }
                title={t("project_setup_" + step.id)}
                subtitle={getSubtitle(step.id)}
                icon={step.icon}
                isConfirmed={isStepConfirmed(step.id)}
                isVisited={isStepVisited(step.id)}
                isOpen={step.id === currentStep}
                onOpen={() => openStep(step.id)}
                showWarnings={
                  props.project.active.showWarnings &&
                  props.project.active.isStepWarningActive(step.id)
                }
                tooltip={
                  isPaywall(step.id)
                    ? "project_step_paywall"
                    : props.project.active.isLocked(step.id)
                    ? "project_step_unavailable"
                    : ""
                }
                alert={props.project.active.alerts[step.id]}
              >
                {getWidget()}
              </StepCard>
            </div>
          ))}

          <div className="flex">
            <div className="flex flex-dcol">
              <Space />
              <ToggleButton
                color="#F2D600"
                isActive={props.project.active.showWarnings}
                onToogle={() =>
                  store.dispatch(
                    projectEdit(
                      "showWarnings",
                      !props.project.active.showWarnings
                    )
                  )
                }
              />
              <Space />
            </div>

            <div className="width-10" />

            <div className="flex flex-dcol">
              <Space />
              {t("project_show_warnings") +
                (props.project.active.stepsVisited.length > 1 &&
                getWarningsFiltered().length > 1
                  ? ` (${getWarningsFiltered().length})`
                  : "")}
              <Space />
            </div>
          </div>

          <div className="flex">
            <div className="flex flex-dcol">
              <Space />

              <Link
                isWithoutMargin
                onClick={() => setCurrentModal(MODAL_RENAME)}
              >
                {t("project_rename")}
              </Link>

              <div className="height-10" />

              <Link
                isWithoutMargin
                onClick={() => setCurrentModal(MODAL_DELETE)}
              >
                {t("project_delete")}
              </Link>

              <Space />
            </div>

            <Space />

            {props.project.active.status === "draft" ? (
              <div className="flex">
                <Button
                  tooltip={t("project_edit_launch_test_description", {
                    count: props._session.accountOptions.ceilsTesters,
                  })}
                  className={"secondary"}
                  icon={faVial}
                  isFullWidth
                  isWithBorder
                  isLarge
                  onClick={test}
                >
                  {" " + t("project_test")}
                </Button>

                <div className="width-20" />

                <Button
                  tooltip={t("project_edit_launch_description")}
                  className={
                    getUnconfirmedSteps().length === 0 &&
                    getErrorsFiltered().length === 0
                      ? "primary"
                      : "light"
                  }
                  icon={
                    getUnconfirmedSteps().length === 0 &&
                    getErrorsFiltered().length === 0
                      ? faPaperPlane
                      : faLock
                  }
                  isFullWidth
                  isWithBorder
                  isLarge
                  onClick={() =>
                    getUnconfirmedSteps().length === 0 &&
                    getErrorsFiltered().length === 0
                      ? launch()
                      : setCurrentModal(MODAL_LAUNCH_UNAVAILABLE)
                  }
                >
                  {" " + t("project_launch")}
                </Button>
              </div>
            ) : (
              <div>
                <Button
                  className="secondary"
                  icon={faStop}
                  tooltip={t(
                    props.project.active.status === "test"
                      ? "project_edit_close_test_description"
                      : "project_edit_close_description"
                  )}
                  isFullWidth
                  isWithBorder
                  isLarge
                  onClick={() => setCurrentModal(MODAL_CLOSE_SURVEY)}
                >
                  {" " +
                    t(
                      props.project.active.status === "test"
                        ? "project_edit_close_test"
                        : "project_edit_close"
                    )}
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
    </PageLoader>
  );
}

const mapStateToProps = (state) => ({
  _session: state._session,
  email: state.email,
  filter: state.filter,
  project: state.project,
  reminder: state.reminder,
  sending: state.sending,
  survey: state.survey,
  template: state.template,
  user: state.user,
});

export default connect(mapStateToProps)(withTranslation()(ProjectEditWidget));
