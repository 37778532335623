/**
 * PROJECT-DELETE.MODAL
 * Delete project
 */
import Modal from "@/components/modal";
import { useState } from "react";
import { connect } from "react-redux";
import { withTranslation, WithTranslation } from "react-i18next";
import { store } from "@/index";
// import store from "@/core/store";
import { STATUS_LOADED, STATUS_SAVING } from "@/redux/_status.types";
import EnterCodeWidget from "@/widgets/enter-code.widget";
import { ProjectState } from "@/redux/project.types";
import { projectDestroy, projectRemove } from "@/redux/project.actions";
import { NavigateFunction, useNavigate } from "react-router-dom";

interface StateProps extends WithTranslation {
  project: ProjectState;
}

interface OwnProps {
  onClose: Function;
}

type Props = StateProps & OwnProps;

function ProjectDeleteModal(props: Props) {
  const { t } = props;
  const navigate: NavigateFunction = useNavigate();

  const [isSaving, setIsSaving] = useState(false);

  function close() {
    props.onClose();
  }

  //Trigger the action with the backend
  async function deleteProjectConfirm() {
    setIsSaving(true);
    const response: any = await store.dispatch(
      projectDestroy(props.project.active.id)
    );
    setIsSaving(false);

    if (!response.error) {
      store.dispatch(projectRemove(props.project.active.id));
      navigate("/project/list");
    }
  }

  return (
    <Modal onClose={close} title={t("project_delete")}>
      <p>
        <b>
          {t("project_ask_delete", {
            project: props.project.active.name,
          })}
        </b>
      </p>

      <EnterCodeWidget
        onCancel={close}
        onNext={deleteProjectConfirm}
        status={isSaving ? STATUS_SAVING : STATUS_LOADED}
      />
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  project: state.project,
});

export default connect(mapStateToProps)(withTranslation()(ProjectDeleteModal));
