/**
 * ADD-ACCOUNT.MODAL
 * Add a new account
 */
import Modal from "@/components/modal";
import { connect } from "react-redux";

import { withTranslation, WithTranslation } from "react-i18next";
import { AccountState } from "@/redux/account.types";
import TextInput from "@/components/text-input";
import { store } from "@/index";
// import store from "@/core/store";
import {
  accountInsert_AsSupervisor,
  accountEdit,
  accountStatus,
} from "@/redux/account.actions";
import {
  STATUS_SAVED,
  STATUS_SAVE_ERROR,
  STATUS_SAVING,
} from "@/redux/_status.types";
import { NavigateFunction, useNavigate } from "react-router-dom";

interface StateProps extends WithTranslation {
  account: AccountState;
}

interface OwnProps {
  onClose: Function;
}

type Props = StateProps & OwnProps;

function AddAccountModal(props: Props) {
  const { t } = props;
  const navigate: NavigateFunction = useNavigate();

  //Save account
  //When done go to page /supervisor/account
  async function next() {
    store.dispatch(accountStatus(STATUS_SAVING));
    const response: any = await store.dispatch(
      accountInsert_AsSupervisor(props.account.active)
    );

    if (response.error) {
      store.dispatch(accountStatus(STATUS_SAVE_ERROR));
    } else {
      store.dispatch(accountStatus(STATUS_SAVED));
      navigate("/supervisor/account");
    }
  }

  return (
    <Modal
      isCloseButtonVisible
      status={props.account.status}
      onClose={() => props.onClose()}
      onNext={next}
      title={t("account_create")}
    >
      <TextInput
        autoFocus
        isFullWidth
        onChange={(e) => store.dispatch(accountEdit("name", e.value))}
        title={t("account_name")}
        value={props.account.active.name}
      />

      <div className="height-20" />
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  account: state.account,
});

export default connect(mapStateToProps)(withTranslation()(AddAccountModal));
