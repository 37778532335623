import { useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import UploadButtonWidget from "./upload-button.widget";
import mammoth from "mammoth";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import { SESSION_COLORS, Session } from "@/redux/_session.types";
import { connect } from "react-redux";
import { Topic } from "@/redux/topic.types";
import { v4 as uuid } from "uuid";
import { Question, QuestionsTopic, QuestionType } from "@/redux/question.types";
import i18n from "@/translate/i18n";
import { Proposition } from "@/redux/proposition.types";
import { groupBy } from "lodash";
import { Axis } from "@/redux/axis.types";
import { store } from "@/index";
// import store from "@/core/store";
import { topicGet } from "@/redux/topic.actions";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Space from "@/components/space";
import { questionGet } from "@/redux/question.actions";
import { axisGet, axisUpdateMultiple } from "@/redux/axis.actions";
import ReactMarkdown from "react-markdown";
import Button from "@/components/button";
import ConsentOpenaiWidget from "./consent-openai.widget";
import { SocketCreate, SocketEmit } from "@/utils/socketio.utils";
import { Socket } from "socket.io-client";

interface StateProps {
  _session: Session;
}

interface OwnProps {
  currentTemplateId: string;
}

type Props = StateProps & OwnProps & WithTranslation;

type JSONQuestion = {
  axisName: string;
  topicName: string;
  questionName: string;
  isPositive: boolean;
  questionType: QuestionType;
  Propositions: string[];
  isSecondary: boolean;
  triggerOn: string | null;
};

function TemplateGeneratorWidget(props: Props) {
  const { t } = props;

  const [answer, setAnswer] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function createTopics() {
    const jsonFile: JSON = getAnswerToJson();

    const currentLanguage: string = i18n.language;

    let axes: Axis[] = [];
    let topics: Topic[] = [];
    const questions: Question[] = [];

    function formateQuestion(question: JSONQuestion, axis: Axis) {
      const questionId: string = uuid();

      const propositions: Proposition[] = question.Propositions.map(
        (proposition: string) => {
          const propositionId: string = uuid();
          return new Proposition({
            id: propositionId,
            name: { [currentLanguage]: proposition },
            updated: true,
          });
        }
      );

      const newQuestion: Question = new Question({
        id: questionId,
        name: { [currentLanguage]: question.questionName },
        type: question.questionType,
        reverse: question.isPositive === false,
        draft: false,
        //fromSupervisor: true,
        updated: true,
        QuestionsTopic: new QuestionsTopic({
          QuestionId: questionId,
        }),
        propositionsOrder: propositions.map(
          (proposition: Proposition) => proposition.id
        ),
        Propositions: propositions,
      });

      if (question.isSecondary) {
        const oldTopic: Topic =
          topics.length > 0 ? topics[topics.length - 1] : new Topic();

        newQuestion.primaryId = questions[questions.length - 1].id;
        newQuestion.QuestionsTopic.TopicId = oldTopic.id;
        newQuestion.QuestionsTopic.triggerValue =
          question.triggerOn === "positive" ? ">" : "<";

        oldTopic.secondaryQuestionsOrder.push(newQuestion.id);
        oldTopic.Questions.push(newQuestion);

        questions.push(newQuestion);
        topics = topics.map((x: Topic) =>
          x.id === oldTopic.id ? oldTopic : x
        );
      } else {
        const topicId: string = uuid();

        newQuestion.QuestionsTopic.TopicId = topicId;

        const newTopic: Topic = new Topic({
          id: topicId,
          name: { [currentLanguage]: question.topicName },
          Axis: axis,
          AxisId: axis.id,
          Questions: [newQuestion],
          questionPrimaryId: questionId,
          updated: true,
        });

        questions.push(newQuestion);
        topics.push(newTopic);
      }
    }

    for (const [key, values] of Object.entries(groupBy(jsonFile, "axisName"))) {
      const axisId: string = uuid();

      const axis: Axis = new Axis({
        id: axisId,
        name: { [currentLanguage]: key },
        color:
          SESSION_COLORS[Math.floor(Math.random() * SESSION_COLORS.length)],
      });

      axes = [...axes, axis];

      values.forEach((question: any) => formateQuestion(question, axis));
    }

    const response: any = await store.dispatch(
      axisUpdateMultiple(axes, props.currentTemplateId)
    );

    if (!response.error) {
      /*
      store.dispatch(templateEdit("Axes", axes))
      store.dispatch(templateEdit("Topics", topics))
      store.dispatch(templateEdit("axisOrder", axes.map((axis: Axis) => axis.id)))
      store.dispatch(templateEdit("Topics", topics.map((topic: Topic) => topic.id)))
      */

      store.dispatch(
        axisGet(
          axes,
          axes.map((x: Axis) => x.id)
        )
      );

      store.dispatch(questionGet(questions));
      store.dispatch(topicGet(topics));
    }
  }

  function getAnswerToJson(): JSON {
    return JSON.parse(
      answer.substring(answer.indexOf("{"), answer.lastIndexOf("}") + 1)
    ).questions;
  }

  function generateJSON() {
    const jsonString = answer;
    const blob = new Blob([jsonString], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "template.json");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function onUpload(files: File[]) {
    const file: File = files[0];

    if (file) {
      const reader = new FileReader();
      const extension: string = file.name.substring(file.name.lastIndexOf("."));

      let documentContent: any = "";

      reader.onload = async (e: any) => {
        switch (extension) {
          case ".txt": {
            documentContent = e.target.result;
            break;
          }
          case ".json": {
            setAnswer(e.target.result);
            break;
          }
          case ".doc":
          case ".docx": {
            const docBuffer: any = e.target.result;

            const extractedText: any = await mammoth.extractRawText({
              arrayBuffer: docBuffer,
            });
            documentContent = extractedText.value;
            break;
          }
          case ".xls":
          case ".xlsx": {
            const xlsBuffer: any = e.target.result;

            const xls: any = XLSX.read(xlsBuffer, { type: "array" });
            const sheet = xls.Sheets[xls.SheetNames[0]];
            documentContent = XLSX.utils.sheet_to_json(sheet, { header: 1 });

            break;
          }
          default: {
            documentContent = null;
            toast(t("utils_error"), { type: "error" });
            break;
          }
        }

        if (documentContent) {
          if (documentContent.error) {
            toast(documentContent.error, { type: "error" });
          } else {
            setIsLoading(true);

            const socket: Socket = SocketCreate();

            SocketEmit(
              socket,
              "formate",
              { documentContent },
              undefined,
              (ans: string) => setAnswer(ans),
              () => setIsLoading(false),
              () => setIsLoading(false)
            );
          }
        }
      };

      if (extension === ".txt" || extension === ".json") {
        reader.readAsText(file);
      } else {
        reader.readAsArrayBuffer(file);
      }
    }
  }

  return (
    <ConsentOpenaiWidget>
      {isLoading && (
        <>
          {t("utils_loading") + "  "}
          <FontAwesomeIcon icon={faCircleNotch} spin />
        </>
      )}

      {answer.length > 0 ? (
        <div className="flex flex-dcol">
          <div style={{ height: 500, overflow: "auto" }}>
            <ReactMarkdown>{answer}</ReactMarkdown>
          </div>

          <div className="flex1 flex flex-dcol">
            <Space />
            <div className="flex">
              <Space />

              <Button className={"secondary"} onClick={generateJSON}>
                {"Télécharger en json"}
              </Button>

              <Button
                className={isLoading ? "light" : "primary"}
                onClick={isLoading ? undefined : createTopics}
                isLoading={isLoading}
              >
                {"Importer dans l'éditeur"}
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <UploadButtonWidget
          acceptedFiles=".json,.txt,.doc,.docx,.xls,.xlsx"
          maxSize={10000000}
          onUpload={onUpload}
        />
      )}
    </ConsentOpenaiWidget>
  );
}

const mapStateToProps = (state) => ({
  _session: state._session,
});

export default connect(mapStateToProps)(
  withTranslation()(TemplateGeneratorWidget)
);
