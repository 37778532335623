/**
 * IMAGE-PICKER
 * Select a picture to upload
 */
import { WithTranslation, withTranslation } from "react-i18next";
import { useState } from "react";
import env from "@/env";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import Link from "./link";
import { toast } from "react-toastify";
import Modal from "./modal";
import { upload } from "@/redux/_upload.actions";
import { store } from "@/index";
// import store from "@/core/store";
import { STATUS_LOADED, STATUS_SAVING } from "@/redux/_status.types";
import Space from "./space";
import { sessionEdit } from "@/redux/_session.actions";

interface OwnProps {
  backgroundColor?: string;
  id: string;
  imageId: string | null;
  model: string;
  onDelete: Function;
  onSave: Function;
  ratio?: number;
  text?: string;
  square?: boolean; //force image resize to be a square
}

type Props = OwnProps & WithTranslation;

//Max size of file
const FILE_MAX_SIZE: number = 1000000; //1 Mo

function ImagePicker(props: Props) {
  const { t } = props;

  const ratio: number = props.ratio ? props.ratio : 1 / 1;

  //Define state
  const [crop, setCrop] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [src, setSrc] = useState<any>(null);

  function closeCrop() {
    setCrop({});
    setSrc(null);
  }

  //Get image
  async function handleChange(files: any) {
    if (files.length) {
      const selectedFile = files[0];

      if (selectedFile.size > FILE_MAX_SIZE) {
        toast(t("image_picker_too_heavy"), { type: "error" });
      } else {
        //Read file
        const reader = new FileReader();
        reader.onload = (e: any) => {
          if (e.target) {
            //resize to square
            if (props.square) {
              const image: any = document.createElement("img");
              image.onload = () => {
                const canvas: any = document.createElement("canvas");
                const ctx: any = canvas.getContext("2d");

                let x: number = 0;
                let y: number = 0;
                let length: number = 0;

                if (image.naturalWidth > image.naturalHeight) {
                  length = image.naturalWidth;
                  y = (image.naturalWidth - image.naturalHeight) / 2;
                } else {
                  length = image.naturalHeight;
                  x = (image.naturalHeight - image.naturalWidth) / 2;
                }

                canvas.height = length;
                canvas.width = length;

                ctx.drawImage(image, x, y);

                setSrc(canvas.toDataURL("image/png"));
              };

              image.src = e.target.result;
            }

            //keep default dimensions
            else {
              setSrc(e.target.result);
            }
          }
        };

        reader.readAsDataURL(selectedFile);
      }
    }
  }

  //Save image to server
  async function saveCrop() {
    setIsLoading(true);
    const image: any = document.getElementsByClassName("ReactCrop__image")[0];
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx: any = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    const base64Image = canvas.toDataURL("image/png");

    //Update preview
    const response: any = await store.dispatch(
      upload(props.id, base64Image, props.model, "image/png")
    );
    setIsLoading(false);

    if (!response.error) {
      store.dispatch(sessionEdit("logoLastUpdate", new Date().getTime()));
      closeCrop();
      props.onSave();
    }
  }

  //Initial crop
  function setCropInitial(image: any) {
    const size = Math.min(image.width, image.height);

    setCrop({
      unit: "px",
      x: 0,
      y: 0,
      width: size * ratio,
      height: size,
      aspect: ratio,
    });

    return false;
  }

  return (
    <div>
      <div className="import-img">
        {src && (
          <Modal
            isCloseButtonVisible
            disableClickOutside
            disableKeyEvents
            status={isLoading ? STATUS_SAVING : STATUS_LOADED}
            onClose={closeCrop}
            onNext={saveCrop}
          >
            <div className="flex">
              <Space />
              <p className="grey-t">{t("image_ask_crop")}</p>
              <Space />
            </div>

            <div className="flex">
              <Space />
              <div
                style={{
                  maxWidth: "300px",
                  backgroundColor: props.backgroundColor,
                }}
              >
                <ReactCrop
                  src={src}
                  crop={crop}
                  onImageLoaded={(image) => setCropInitial(image)}
                  onChange={(newCrop) => setCrop(newCrop)}
                ></ReactCrop>
              </div>
              <Space />
            </div>
          </Modal>
        )}

        <div className="grey-t">
          <div className="flex">
            <Space />

            {props.imageId ? (
              <Link onClick={() => props.onDelete()} isWithoutMargin>
                {t("utils_delete")}
              </Link>
            ) : (
              <label htmlFor="avatar">
                <Link isWithoutMargin>
                  {props.text ? props.text : t("image_load")}
                </Link>
              </label>
            )}

            <Space />
          </div>
          <input
            type="file"
            onChange={(e: any) => handleChange(e.target.files)}
            id="avatar"
            name="avatar"
            accept="image/png,image/jpg"
          ></input>
        </div>

        <label htmlFor="avatar">
          <img
            alt={props.model}
            className="import-img-circle _hover"
            id="import-img-container"
            style={{
              backgroundColor: props.imageId ? props.backgroundColor : "",
            }}
            src={
              props.imageId
                ? env.REACT_APP_URL_SPACE.concat(
                    "/",
                    props.model,
                    "/",
                    props.imageId,
                    ".png?" + new Date().getTime()
                  )
                : require("@/assets/add.png")
            }
          ></img>
        </label>

        <p style={{ fontSize: "12px" }} className="grey-t">
          {t("image_load_format")}
        </p>
      </div>
    </div>
  );
}

export default withTranslation()(ImagePicker);
