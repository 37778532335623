/**
 * DELETE-ACCOUNT.MODAL
 * Delete an account
 */
import Modal from "@/components/modal";
import { connect } from "react-redux";

import { withTranslation, WithTranslation } from "react-i18next";
import { store } from "@/index";
// import store from "@/core/store";
import {
  STATUS_SAVED,
  STATUS_SAVE_ERROR,
  STATUS_SAVING,
} from "@/redux/_status.types";
import { AccountState } from "@/redux/account.types";
import {
  accountDestroy,
  accountDestroy_AsSupervisor,
  accountRemove,
  accountStatus,
} from "@/redux/account.actions";
import { Session } from "@/redux/_session.types";
import EnterCodeWidget from "@/widgets/enter-code.widget";
import { toast } from "react-toastify";
import { NavigateFunction, useNavigate } from "react-router-dom";

interface StateProps extends WithTranslation {
  _session: Session;
  account: AccountState;
}

interface OwnProps {
  accountId: string;
  accountName?: string;
  isAsSuperadmin?: boolean;
  onClose: Function;
  onDelete?: Function; //Specific behavior on delete
}

type Props = StateProps & OwnProps;

function DeleteAccountModal(props: Props) {
  const { t } = props;
  const navigate: NavigateFunction = useNavigate();

  //Destroy the account
  //Look for the function depending if the user is connecter as superadmin or client
  //Redirection on login page if not superadmin
  async function next() {
    const deleteFunction =
      props._session.interfaceType === "SUPERVISOR"
        ? accountDestroy_AsSupervisor
        : accountDestroy;
    store.dispatch(accountStatus(STATUS_SAVING));
    const response: any = await store.dispatch(deleteFunction(props.accountId));

    if (response.error) {
      store.dispatch(accountStatus(STATUS_SAVE_ERROR));
      toast(t(response.error), { type: "error" });
    } else {
      store.dispatch(accountStatus(STATUS_SAVED));
      toast(t("account_deleted"));

      if (props._session.interfaceType === "SUPERVISOR") {
        navigate("/supervisor/accounts");
      } else if (props.isAsSuperadmin) {
        store.dispatch(accountRemove(props.account.active.id));
        if (props.onDelete) {
          props.onDelete();
        } else {
          props.onClose();
        }
      } else {
        navigate("/login/app?message=account_deleted");
      }
    }
  }

  return (
    <Modal onClose={props.onClose} title={t("account_delete")}>
      <p>
        {t("account_delete_warning", {
          account: props.accountName ? props.accountName : "",
        })}
      </p>

      <EnterCodeWidget
        onCancel={props.onClose}
        onNext={next}
        status={props.account.status}
      />
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  _session: state._session,
  account: state.account,
});

export default connect(mapStateToProps)(withTranslation()(DeleteAccountModal));
