/**
 * PROJECT-ADD.MODAL
 * Create new projet from a template
 */
import Modal from "@/components/modal";
import TextInput from "@/components/text-input";
import { store } from "@/index";
// import store from "@/core/store";
import { useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { Project } from "@/redux/project.types";
import { templateDuplicate } from "@/redux/template.actions";
import { Template, TemplateState } from "@/redux/template.types";
import { Session } from "@/redux/_session.types";
import LoadingModal from "./loading.modal";
import i18n from "@/translate/i18n";
import { projectActivate, projectUpdate } from "@/redux/project.actions";
import { Email } from "@/redux/email.types";
import { emailUpdate } from "@/redux/email.actions";

interface StateProps extends WithTranslation {
  _session: Session;
  template: TemplateState;
}

interface OwnProps {
  isWithoutTemplate?: boolean; //Select from project page without defined template
  onClose: Function;
}

type Props = StateProps & OwnProps;

function ProjectAddModal(props: Props) {
  const { t } = props;
  const navigate: NavigateFunction = useNavigate();

  const [projectName, setProjectName] = useState(
    props.isWithoutTemplate
      ? t("project_new")
      : props.template.active.getDuplicateName(props._session.accountName, true)
  );
  const [isLoading, setIsLoading] = useState(false);

  //Click on confirm
  //If this is an idtree template duplicate it before
  async function next() {
    if (props.isWithoutTemplate) {
      insert(null);
    } else if (props.template.active.fromSupervisor) {
      setIsLoading(true);
      const templateResponse: any = await store.dispatch(
        templateDuplicate(props.template.active.id, projectName)
      );

      if (!templateResponse.error) {
        insert(templateResponse);
      } else {
        setIsLoading(true);
      }
    } else {
      const template = new Template(props.template.active);
      template.name = { [props._session.language]: projectName };
      insert(template);
    }
  }

  //Insert in database and go to edit page
  async function insert(template) {
    //Init project template from template informations
    const accountName = props._session.accountName;
    const project = new Project();
    project.id = uuid();
    project.languages = [i18n.language];
    project.name = projectName;
    project.messageOptions = project.getInitialMessageOptions(
      accountName,
      projectName
    );

    if (template) {
      project.TemplateId = template.id;
    }

    //Update project in the database
    await store.dispatch(projectUpdate(project));

    //Add emails from template
    const email = new Email();
    const emails: Email[] = [
      email.getFromMessage(
        t("email_template_0"),
        project.messageOptions,
        accountName
      ),
    ].concat(
      email
        .getList(
          accountName,
          project.name,
          project.Template
            .label /*.length > 0 ? project.Template.label : t("template_tag_qvt")*/
        )
        .slice(1)
    );

    //Update in database (emails)
    for (let i = 0; i < emails.length; i++) {
      await store.dispatch(emailUpdate(project.id, emails[i]));
    }

    //Set order of the emails
    project.emailsOrder = emails.map((x) => x.id);

    //Activate project
    store.dispatch(projectActivate(project));

    //Go to project page
    navigate("/project/edit");
  }

  return (
    <Modal
      isCloseButtonVisible
      title={t("template_send")}
      onClose={() => props.onClose()}
      onNext={next}
    >
      {isLoading && <LoadingModal />}

      <div className="flex">
        <TextInput
          autoFocus
          onChange={(e) => setProjectName(e.value)}
          title={t("project_add_name")}
          value={projectName}
          help={t("project_add_name_help")}
        />

        <div className="width-40" />

        <img
          alt="send"
          src={require("@/assets/project-status/draft.png")}
          width="150"
          height="150"
        />
      </div>

      <div className="height-20" />
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  _session: state._session,
  template: state.template,
});

export default connect(mapStateToProps)(withTranslation()(ProjectAddModal));
