/**
 * DASHBOARD-RPS-TEAMS.WIDGET
 * Repartition of users for a survey
 */
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import Card from "@/components/card";
import { useEffect, useState } from "react";
import { DashboardFilters } from "@/redux/filter.types";
import { store } from "@/index";
// import store from "@/core/store";
import {
  INSUFFICIENT_PARTICIPATION,
  STATUS_LOADED,
  STATUS_LOADING,
} from "@/redux/_status.types";
import { fetchRpsOverview } from "@/redux/_rps.actions";
import NoResultsWidget from "./no-results.widget";
import { SurveyRps, SurveyState } from "@/redux/survey.types";
import { surveyEdit } from "@/redux/survey.actions";
import DashboardNumber from "@/components/dashboard-number";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import QuestionSpecialHelp from "@/help/question-special.help";
import RpsHelp from "@/help/rps.help";
import { loadParticipation } from "@/redux/_archive.actions";

interface StateProps {
  survey: SurveyState;
}

interface OwnProps {
  dashboardFilters: DashboardFilters;
}

type Props = StateProps & OwnProps & WithTranslation;

function DashboardRpsTeamsWidget(props: Props) {
  const { t } = props;

  //Display help
  const [currentHelp, setCurrentHelp] = useState<string | null>(null);

  //Legend for tag
  const [currentTag, setCurrentTag] = useState<string | null>(null);

  //Status of loading
  const [isLoading, setIsLoading] = useState(true);

  //Load data on every change on filters
  useEffect(() => {
    async function load() {
      if (!props.survey.active.randomData) {
        //Load data from server
        setIsLoading(true);
        const data: any = await store.dispatch(
          fetchRpsOverview(props.dashboardFilters)
        );
        setIsLoading(false);

        if (data.error || data.message === "no_data") {
          store.dispatch(surveyEdit("rps", new SurveyRps()));
        } else {
          store.dispatch(loadParticipation(props.dashboardFilters, false));

          const surveyRps = new SurveyRps({
            actives: data.countActive,
            passives: data.countPassive,
            relaxed: data.countRelaxed,
            isoStrain: data.countStressed,
            jobStrain: data.countJobStrain,
            total: data.countTotal,
          });

          store.dispatch(surveyEdit("rps", surveyRps));
        }
      } else {
        setIsLoading(false);
      }
    }

    load();
  }, [props.dashboardFilters, props.survey.active.randomData]);

  return props.survey.status === INSUFFICIENT_PARTICIPATION ? (
    <NoResultsWidget />
  ) : (
    <div>
      {currentHelp && (
        <RpsHelp
          currentHelp={currentHelp}
          onClose={() => setCurrentHelp(null)}
        />
      )}

      {currentTag && (
        <QuestionSpecialHelp onClose={() => setCurrentTag(null)} />
      )}

      <Card
        title={t("dashboard_overview")}
        exportName={t("dashboard_overview")}
        status={
          isLoading || props.survey.status === STATUS_LOADING
            ? STATUS_LOADING
            : STATUS_LOADED
        }
      >
        <div className="flex1 rps-container">
          <div className="flex flex-dcol">
            <div className="flex">
              <DashboardNumber
                color="#eb5a46"
                isInactive={props.survey.active.rps.isoStrain === 0}
                count={props.survey.active.rps.isoStrain}
                icon={faUserCircle}
                onInfo={() => setCurrentHelp("iso-strain")}
                header={t("rps")}
                title={t("rps_stressed")}
                tooltip={t("rps_help_text_3")}
              />

              <DashboardNumber
                color="#ff9500"
                isInactive={props.survey.active.rps.jobStrain === 0}
                count={props.survey.active.rps.jobStrain}
                icon={faUserCircle}
                onInfo={() => setCurrentHelp("job-strain")}
                header={t("rps")}
                title={t("rps_job_strain")}
                tooltip={t("rps_help_text_4")}
              />

              <DashboardNumber
                isInactive={props.survey.active.rps.actives === 0}
                count={props.survey.active.rps.actives}
                icon={faUserCircle}
                onInfo={() => setCurrentHelp("actives")}
                header={t("rps_monitor")}
                title={t("rps_active")}
                tooltip={t("rps_help_text_5")}
              />

              <DashboardNumber
                isInactive={props.survey.active.rps.passives === 0}
                count={props.survey.active.rps.passives}
                icon={faUserCircle}
                onInfo={() => setCurrentHelp("passives")}
                header={t("rps_monitor")}
                title={t("rps_passive")}
                tooltip={t("rps_help_text_6")}
              />

              <DashboardNumber
                isInactive
                count={props.survey.active.rps.relaxed}
                icon={faUserCircle}
                onInfo={() => setCurrentHelp("relaxed")}
                header={t("rps_ras")}
                title={t("rps_relaxed")}
                tooltip={t("rps_help_text_7")}
              />

              <DashboardNumber
                isInactive
                count={props.survey.active.rpsNotConcerned}
                icon={faUserCircle}
                onInfo={() => setCurrentHelp("nc")}
                header={t("rps")}
                title={t("rps_nc")}
                tooltip={t("rps_help_text_8")}
              />
            </div>

            <p className="grey-t">{t("rps_sumary")}</p>
          </div>
        </div>
      </Card>
    </div>
  );
}

const mapStateToProps = (state) => ({
  survey: state.survey,
});

export default connect(mapStateToProps)(
  withTranslation()(DashboardRpsTeamsWidget)
);
