/**
 * EDITOR-QUESTION
 * Edit a question in card mode
 */
import Button from "@/components/button";
import Dropdown from "@/components/dropdown";
import { WithTranslation, withTranslation } from "react-i18next";
import { Draggable, Droppable, DragDropContext } from "react-beautiful-dnd";
import { connect } from "react-redux";
import {
  Question,
  QUESTION_LIST_SPECIAL,
  QUESTION_LIST_TYPE,
} from "@/redux/question.types";
import { FadeIn, FlipInY } from "@/utils/animations.utils";
import Color from "color";
import UpgradeModal from "@/modals/upgrade.modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight,
  faChevronUp,
  faCommentDots,
  faCompress,
  faExpand,
  faPenNib,
  faPlus,
  faShieldAlt,
  faThumbsDown,
  faThumbsUp,
  faTimes,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import ButtonFloating from "@/components/button-floating";
import Space from "@/components/space";
import { store } from "@/index";
// import store from "@/core/store";
import { TopicState } from "@/redux/topic.types";
import {
  topicAddProposition,
  topicAddQuestion,
  topicEdit,
  topicEditDefaultScaleCount,
  topicEditDefaultScaleNoOpinion,
  topicEditName,
  topicEditProposition,
  topicEditQuestion,
  topicEditTrigger,
  topicRemoveProposition,
  topicSwapProposition,
} from "@/redux/topic.actions";
import TextInput from "@/components/text-input";
import Chip from "@/components/chip";
import { Session } from "@/redux/_session.types";
import { TemplateState } from "@/redux/template.types";
import { useEffect, useState } from "react";
import AxesDropdown from "@/dropdowns/axes.dropdown";
import ModalConfirm from "@/components/modal-confirm";
import ListButton from "@/components/list-button";
import DeleteQuestionModal from "@/modals/delete-question.modal";
import AnnotationsWidget from "./annotations.widget";
import { templateEdit, templateEditAndUpdate } from "@/redux/template.actions";
import SwitchLanguage from "@/components/switch-language";
import DuplicateTemplateAskModal from "@/modals/duplicate-template-ask.modal";
import Link from "@/components/link";
import EditAxisModal from "@/modals/edit-axis.modal";
import { axisActivate } from "@/redux/axis.actions";
import Checkbox from "@/components/checkbox";
import WizardOwlWidget from "./wizard-owl.widget";
import { predict, predictionSave } from "@/redux/_numind.actions";
import { sessionEditUserOptions } from "@/redux/_session.actions";
import { userUpdateOptions } from "@/redux/user.actions";
import LogoSvgUtils from "@/utils/logo-svg.utils";

interface StateProps extends WithTranslation {
  _session: Session;
  topic: TopicState;
  template: TemplateState;
}

interface OwnProps {
  isPrimaryQuestion?: boolean; //If the question is secondary > specify question Id of the primary question
  question: Question; //Question to display
  onHelp: Function; //Trigger function onHelp
}

type Props = StateProps & OwnProps;

const NUMIND_CEIL: number = 0.7; // numind prediction accuracy min to trigger actions (must be between 0.5 and 1)

const HELP_AXIS: string = "HELP_AXIS";
const HELP_TOPIC: string = "HELP_TOPIC";
const HELP_TYPE: string = "HELP_TYPE";
const HELP_SCORE: string = "HELP_SCORE";
const HELP_SPECIAL: string = "HELP_RPS";
const HELP_TRIGGER: string = "HELP_TRIGGER";

const MODAL_DELETE_QUESTION: string = "MODAL_DELETE_QUESTION"; //Delete question
const MODAL_DUPLICATE_ASK: string = "MODAL_DUPLICATE_ASK"; //Ask for duplicate
const MODAL_EDIT_QUESTION: string = "MODAL_EDIT_QUESTION"; //Edit an idtree question
const MODAL_EDIT_IMAGE: string = "MODAL_EDIT_IMAGE"; //Edit image
const MODAL_UPGRADE_NPS: string = "MODAL_UPGRADE_NPS"; //Access to NPS module
const MODAL_UPGRADE_RPS: string = "MODAL_UPGRADE_RPS"; //Access to RPS module

const WIZARD_EDIT_DEFAULT_SCALE_COUNT: string =
  "WIZARD_EDIT_DEFAULT_SCALE_COUNT";
const WIZARD_EDIT_DEFAULT_SCALE_NO_OPINION: string =
  "WIZARD_EDIT_DEFAULT_SCALE_NO_OPINION";
const WIZARD_NUMIND_CHECK: string = "WIZARD_NUMIND_CHECK";

function EditorQuestion(props: Props) {
  const { t } = props;

  //currentModal > current dialog to display (search, delete...)
  //openAnnotations > show annotations for one question ?
  //showPropositionSpecial > show context menu for burnout items
  const [currentModal, setCurrentModal] = useState<string | null>(null);
  const [currentWizard, setCurrentWizard] = useState<string | null>(null);
  const [openAnnotations, setOpenAnnotations] = useState<boolean>(false);
  const [isEdited, setIsEdited] = useState<boolean>(true);
  const [wizardText, setWizardText] = useState<string | null>(null);
  const [numindResponse, setNumindResponse] = useState<any>(null);
  const [flip, setFlip] = useState<boolean>(false);

  //Hide wizard if changing topic
  useEffect(() => {
    setWizardText(null);
    setCurrentWizard(null);
  }, [props.question.id]);

  //Add item for multiple choice question
  function addProposition() {
    if (isConfirmationRequired()) {
      askEdit();
    } else {
      store.dispatch(topicAddProposition(props.question.id));
    }
  }

  //Add secondary question
  function addQuestion() {
    store.dispatch(
      topicAddQuestion(
        props._session.interfaceType === "SUPERVISOR",
        false,
        props.question.QuestionsTopic.triggerValue
      )
    );
  }

  //Ask for edit
  //If template is idtree ask for duplicate
  //Else ask for unlock
  function askEdit() {
    setCurrentModal(
      props.template.active.fromSupervisor
        ? MODAL_DUPLICATE_ASK
        : MODAL_EDIT_QUESTION
    );
  }

  //Edit axis
  function editAxisImage() {
    if (props.topic.active.Axis) {
      store.dispatch(axisActivate(props.topic.active.Axis));
      setCurrentModal(MODAL_EDIT_IMAGE);
    }
  }

  //Confirm changes (when editing an idtree question)
  function editConfirm() {
    store.dispatch(topicEditQuestion(props.question.id, "super", false));
    setCurrentModal(null);
  }

  //Edit choice item
  function editProposition(propositionId, key, value) {
    if (isConfirmationRequired()) {
      askEdit();
    } else {
      store.dispatch(
        topicEditProposition(propositionId, props.question.id, key, value)
      );
    }
  }

  //Edit name for choice item
  function editPropositionName(proposition, value) {
    const name = proposition.name;
    name[props.template.active.language] = value;
    editProposition(proposition.id, "name", name);
  }

  //Edit a question
  //If it is a question of idtree, confirmation is required
  function editQuestion(key, value) {
    if (isConfirmationRequired()) {
      askEdit();
    } else {
      if (key === "reverse") {
        setFlip(true);
        setTimeout(() => {
          setFlip(false);
        }, 2000);
      }
      store.dispatch(topicEditQuestion(props.question.id, key, value));
    }
  }

  //Edit one of the attribute for a question
  function editQuestionAttribute(key, value) {
    editQuestion(key, value);
  }

  //Edit question name
  function editQuestionName(e: any) {
    const name: any = props.question.name;

    if (
      props.question.benchmarkAvailable &&
      props.template.active.language === "fr" &&
      name["fr"] !== e.target.value
    ) {
      store.dispatch(
        topicEditQuestion(props.question.id, "benchmarkAvailable", false)
      );
    }

    name[props.template.active.language] = e.target.value;

    editQuestion("name", name);
    if (!isEdited) {
      setIsEdited(true);
    }
  }

  //Edit type of quesiton
  function editQuestionType(type, count) {
    let upgrade = false;

    //Check if operation is possible
    if (count) {
      if (
        count.module &&
        !props._session.modules[count.module] &&
        props._session.supervisorLevel < 1
      ) {
        upgrade = true;
      }
    }

    if (upgrade) {
      setCurrentModal(MODAL_UPGRADE_NPS);
    } else {
      //Check if scale is likert
      if (type.id === "scale") {
        if (
          (count.id === 4 || count.id === 5) &&
          props.template.active.options.defaultScaleCount !== count.id
        ) {
          setWizardText(
            t("question_ask_switch", {
              count: count.id,
            })
          );
          setCurrentWizard(WIZARD_EDIT_DEFAULT_SCALE_COUNT);
        }
      }

      //Edit attribute
      editQuestion("type", type.id);

      //If count is change update value
      if (count) {
        if (type.id === "scale") {
          editQuestion("responseCount", count.id);
        } else if (type.id === "choice") {
          editQuestion("choiceCount", count.id);
        }
      }
    }
  }

  //edit tag for a question
  function editQuestionSpecial(value) {
    if (isRpsAvailable()) {
      editQuestionAttribute("special", value.id);
    }
  }

  //Edit withScore
  //Set reverse attribute at the same time
  function editQuestionWithScore(withScore, reverse) {
    editQuestionAttribute("withScore", withScore.id);
    if (reverse) {
      editQuestionAttribute("reverse", reverse.id);
    }
  }

  //Edit name of the topic
  function editTopic(value) {
    store.dispatch(topicEditName(props.template.active.language, value));
  }

  //Edit trigger for the question
  function editTriggerValue(e, type) {
    store.dispatch(
      topicEditTrigger(
        props.question.id,
        type === "scale" ? "triggerValue" : "triggerChoice",
        e.id
      )
    );
  }

  function editDisableNoOpinion(value: boolean) {
    store.dispatch(
      topicEditTrigger(props.question.id, "disableNoOpinion", value)
    );
    setWizardText(
      t(
        value
          ? "question_ask_no_opinion_disable"
          : "question_ask_no_opinion_apply"
      )
    );
    setCurrentWizard(WIZARD_EDIT_DEFAULT_SCALE_NO_OPINION);
  }

  //Get axes color
  function getAxisColor() {
    return props.topic.active.Axis ? props.topic.active.Axis.color : "#c1c1c1";
  }

  //Class for the card
  //If not full screen so vertical align (flex-dcol)
  function getClassName() {
    let className = "question flex";

    if (props.topic.active.fullScreen) {
      className += " question-fullscreen";
    } else {
      className += " flex-dcol";
    }

    return className;
  }

  //Get color
  function getColor(i) {
    if (
      props.question.type === "scale" &&
      props.question.responseCount === 10
    ) {
      if (props.question.reverse) i = 11 - i;
      if (i >= 9) {
        return "#20CA7E";
      } else if (i >= 7) {
        return "#f2d600";
      } else {
        return "#eb5a46";
      }
    } else if (props.question.withScore) {
      const axisColor = getAxisColor();
      const ratio = (4 - i) * 0.25;
      let color = Color(axisColor);
      color = color.mix(
        Color("#b5b5b5"),
        props.question.reverse ? 1 - ratio : ratio
      );
      return color.hex();
    } else {
      return "#b5b5b5";
    }
  }

  //Get label for question
  function getResponseLabel(i) {
    return props.template.active.getResponseLabel(
      props.question.responseCount,
      i + 1,
      "question_likert"
    );
  }

  //Check if feature is available according the level of the account
  //If not display dialog
  function isRpsAvailable() {
    if (
      props._session.interfaceType !== "SUPERVISOR" &&
      !props._session.modules.rps
    ) {
      setCurrentModal(MODAL_UPGRADE_RPS);
      return false;
    } else {
      return true;
    }
  }

  function getScale() {
    return (
      <div className="flex grey-t">
        {[...Array(props.question.responseCount)].map((e, i) => (
          <div className="flex1" style={{ position: "relative" }} key={i}>
            {i > 0 && (
              <div
                style={{
                  background:
                    "linear-gradient(to left, " +
                    getColor(i) +
                    ", " +
                    getColor(i - 1) +
                    ")",
                }}
                className="question-option-separator"
              ></div>
            )}

            <div
              data-tip={getResponseLabel(i)}
              className="question-option question-option-sm"
              style={{ backgroundColor: getColor(i) }}
            >
              {((i === props.question.responseCount - 1 &&
                !props.question.reverse) ||
                (i === 0 && props.question.reverse)) && (
                <span>
                  {props.question.withScore && (
                    <FontAwesomeIcon icon={faThumbsUp}></FontAwesomeIcon>
                  )}
                </span>
              )}

              {((i === props.question.responseCount - 1 &&
                props.question.reverse) ||
                (i === 0 && !props.question.reverse)) && (
                <span>
                  {props.question.withScore && (
                    <FontAwesomeIcon icon={faThumbsDown}></FontAwesomeIcon>
                  )}
                </span>
              )}
            </div>
          </div>
        ))}
      </div>
    );
  }

  function hideWizard() {
    setWizardText(t("nlp_wizard_show"));

    store.dispatch(sessionEditUserOptions("hideWizardEditor", true));

    setTimeout(() => {
      if (props._session.interfaceType !== "SUPERVISOR") {
        store.dispatch(
          userUpdateOptions(props._session.userId, props._session.userOptions)
        );
      }
    }, 200);
  }

  //Get axis image
  function isAxisImage() {
    return props.topic.active.Axis ? props.topic.active.Axis.image : false;
  }

  //Is the question editable
  //If not connected in supervisor interface and question of idtree
  function isConfirmationRequired() {
    return (
      props._session.interfaceType !== "SUPERVISOR" &&
      props.question.fromSupervisor
    );
  }

  async function numindCheck(text: string) {
    if (
      props.question.type === "scale" &&
      props.question.withScore &&
      text.length > 0 &&
      isEdited &&
      !props._session.userOptions.hideWizardEditor &&
      !isConfirmationRequired()
    ) {
      const response: any = await store.dispatch(predict(text));

      if (!response.error) {
        setNumindResponse(response);
        setIsEdited(false);
        if (
          props.question.reverse &&
          response.probabilities["POSITIVE"] > NUMIND_CEIL
        ) {
          setWizardText(t("question_reverse_cancel"));
        } else if (
          !props.question.reverse &&
          response.probabilities["NEGATIVE"] > 1 - NUMIND_CEIL
        ) {
          setWizardText(t("question_reverse_apply"));
        } else {
          setWizardText(null);
        }
      }

      setCurrentWizard(WIZARD_NUMIND_CHECK);
    }
  }

  //Drag and drop : change order for a choice item
  function onDragEndProposition(e) {
    if (e.destination) {
      store.dispatch(
        topicSwapProposition(
          props.question.id,
          e.draggableId,
          e.destination.droppableId
        )
      );
    }
  }

  //Remove choice
  function removeProposition(propositionId) {
    if (isConfirmationRequired()) {
      askEdit();
    } else {
      store.dispatch(topicRemoveProposition(props.question.id, propositionId));
    }
  }

  //if prediction was tagged as non relevant, save it for learning
  async function savePrediction() {
    setWizardText(t("nlp_wizard_reject"));
    const expectedValue: string =
      wizardText === t("question_reverse_cancel") ? "POSITIVE" : "NEGATIVE";
    await store.dispatch(
      predictionSave(
        expectedValue,
        numindResponse.probabilities,
        numindResponse.text,
        NUMIND_CEIL
      )
    );
  }

  //Change language
  function selectLanguage(lg) {
    store.dispatch(templateEdit("language", lg));
  }

  //Define order for choice
  function setPropositionOrder(propositionId, isUp, isInactive) {
    if (!isInactive) {
      if (isConfirmationRequired()) {
        askEdit();
      } else {
        const propositionIndex = props.question.Propositions.findIndex(
          (x) => x.id === propositionId
        );
        store.dispatch(
          topicSwapProposition(
            props.question.id,
            propositionId,
            props.question.Propositions[propositionIndex - (isUp ? 1 : -1)].id
          )
        );
      }
    }
  }

  //Do not show switch when discovering the template as client
  function showSwitchLanguage() {
    return !(
      props.template.active.fromSupervisor &&
      props._session.interfaceType !== "SUPERVISOR"
    );
  }

  function switchNoOpinion() {
    setWizardText(t("nlp_wizard_accept"));
    store.dispatch(
      topicEditDefaultScaleNoOpinion(
        props.question.QuestionsTopic.disableNoOpinion
      )
    );
  }

  //Switch all scale
  function switchScale() {
    setWizardText(t("nlp_wizard_accept"));
    store.dispatch(
      templateEditAndUpdate(
        "options",
        Object.assign({}, props.template.active.options, {
          defaultScaleCount: props.question.responseCount,
        })
      )
    );
    store.dispatch(topicEditDefaultScaleCount(props.question.responseCount));
  }

  //Header of the card
  function renderQuestionHeader() {
    return (
      <div className="question-header">
        {props.question.benchmarkAvailable && (
          <div
            className="abs flex"
            style={{ width: "100%" }}
            data-tip={t("question_idtree")}
          >
            <Space />

            <LogoSvgUtils color={props.topic.active.Axis?.color} size={25} />
          </div>
        )}

        <div className="flex question-axis-image">
          <Space />

          <div className="flex1 question-separator"></div>

          {
            /** image de l'axe */
            props.topic.active.Axis ? (
              <div className="_hover" onClick={editAxisImage}>
                {isAxisImage() ? (
                  <img alt="axis" src={props.topic.active.Axis.picture} />
                ) : (
                  <div
                    className="question-notopic"
                    style={{ color: getAxisColor() }}
                  >
                    <FontAwesomeIcon icon={faShieldAlt} />
                  </div>
                )}
              </div>
            ) : (
              <div className="question-notopic grey-t">
                <FontAwesomeIcon icon={faShieldAlt} />
              </div>
            )
          }

          <div className="flex1 question-separator"></div>
          <Space />
        </div>

        <div className="flex">
          <Space />

          <Chip isWithMargin color={props.isPrimaryQuestion ? "#8c8c8c" : null}>
            {props.topic.active.isAtStart
              ? t("question_introduction")
              : props.question.getPrimaryLabel(props.isPrimaryQuestion)}
          </Chip>

          <Space />
        </div>

        {props.template.active.languageAlt ? (
          <div className="question-other-language rel">
            {showSwitchLanguage() && (
              <SwitchLanguage
                language={props.template.active.language}
                languageAlt={props.template.active.languageAlt}
                languages={props.template.active.languages}
                text={props.question.name}
                onSelect={selectLanguage}
              />
            )}
          </div>
        ) : (
          <div className="question-other-language" />
        )}

        <div className="flex">
          <textarea
            autoFocus={props.isPrimaryQuestion}
            value={
              props.question.name[props.template.active.language]
                ? props.question.name[props.template.active.language]
                : ""
            }
            placeholder={t("question_new")}
            style={
              !props.question.isNumericAllowed
                ? { fontSize: "14px", textAlign: "initial", height: "58px" }
                : { marginBottom: "24px" }
            }
            onChange={editQuestionName}
            onBlur={(e: any) => numindCheck(e.target.value)}
            className="question-name"
            spellCheck="true"
          />

          <div
            className="rel"
            style={{
              left: 15,
              top:
                (props.topic.active.fullScreen ? 80 : 0) +
                (currentWizard === WIZARD_EDIT_DEFAULT_SCALE_NO_OPINION
                  ? 110
                  : 0),
            }}
          >
            {currentWizard === WIZARD_NUMIND_CHECK &&
              wizardText &&
              props.question.type === "scale" &&
              props.question.withScore &&
              !props._session.userOptions.hideWizardEditor && (
                <WizardOwlWidget
                  onClose={() => setCurrentWizard(null)}
                  onAccept={() => {
                    editQuestion("reverse", !props.question.reverse);
                  }}
                  onReject={savePrediction}
                  onHide={hideWizard}
                >
                  {wizardText}
                </WizardOwlWidget>
              )}

            {currentWizard === WIZARD_EDIT_DEFAULT_SCALE_COUNT &&
              wizardText &&
              props.question.type === "scale" &&
              (props.question.responseCount === 4 ||
                props.question.responseCount === 5) &&
              props.template.active.options.defaultScaleCount !==
                props.question.responseCount && (
                <WizardOwlWidget
                  onClose={() => setCurrentWizard(null)}
                  onReject={() =>
                    setWizardText(t("question_ask_switch_reject"))
                  }
                  onAccept={switchScale}
                >
                  {wizardText}
                </WizardOwlWidget>
              )}

            {currentWizard === WIZARD_EDIT_DEFAULT_SCALE_NO_OPINION &&
              wizardText &&
              //props.template.active.options.hideNoAnwsers !== props.question.QuestionsTopic.disableNoOpinion &&
              props.question.type === "scale" && (
                <WizardOwlWidget
                  onClose={() => setCurrentWizard(null)}
                  onReject={() =>
                    setWizardText(t("question_ask_switch_reject"))
                  }
                  onAccept={switchNoOpinion}
                >
                  {wizardText}
                </WizardOwlWidget>
              )}
          </div>
        </div>

        {props.question.type === "choice" && props.question.choiceCount && (
          <div className="question-choice-count grey-t">
            {t("question_type_choice_limit", {
              count: props.question.choiceCount,
              s: props.question.choiceCount > 1 ? "s" : "",
            })}
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="question-container flex">
      {openAnnotations && (
        <div className="abs" style={{ zIndex: 9, left: "-34px" }}>
          <AnnotationsWidget onClose={() => setOpenAnnotations(false)} />
        </div>
      )}

      {currentModal === MODAL_DELETE_QUESTION && (
        <DeleteQuestionModal
          onClose={() => setCurrentModal(null)}
          currentQuestion={props.question}
        />
      )}

      {currentModal === MODAL_DUPLICATE_ASK && (
        <DuplicateTemplateAskModal onClose={() => setCurrentModal(null)} />
      )}

      {currentModal === MODAL_EDIT_QUESTION && (
        <ModalConfirm
          onNo={() => setCurrentModal(null)}
          onYes={editConfirm}
          text={t("question_is_idtree")}
          textBold={t("question_ask_edit")}
        />
      )}

      {currentModal === MODAL_EDIT_IMAGE && (
        <EditAxisModal editImageOnly onClose={() => setCurrentModal(null)} />
      )}

      {currentModal === MODAL_UPGRADE_NPS && (
        <UpgradeModal feature="nps" onClose={() => setCurrentModal(null)} />
      )}

      {currentModal === MODAL_UPGRADE_RPS && (
        <UpgradeModal feature="rps" onClose={() => setCurrentModal(null)} />
      )}

      <FadeIn key={props.topic.active.id}>
        <div
          className={getClassName()}
          style={{
            background: props.question.draft
              ? "linear-gradient(45deg, white 30%, #f2d600 30%, #f2d600 70%, white 70%)"
              : "",
          }}
        >
          <div className="editor-question-floating-menu">
            <ButtonFloating
              isPrimary={props.question.draft}
              top="0px"
              icon={faPenNib}
              onClick={() => editQuestion("draft", !props.question.draft)}
              text={
                props.question.draft ? t("question_activate") : t("utils_draft")
              }
            />

            <ButtonFloating
              top="30px"
              icon={props.topic.active.fullScreen ? faCompress : faExpand}
              onClick={() =>
                store.dispatch(
                  topicEdit("fullScreen", !props.topic.active.fullScreen)
                )
              }
              text={
                props.topic.active.fullScreen
                  ? t("question_fullscreen_cancel")
                  : t("question_fullscreen_set")
              }
            />

            <ButtonFloating
              top="60px"
              icon={faTrash}
              onClick={() => setCurrentModal(MODAL_DELETE_QUESTION)}
              text={t("question_delete")}
            />

            {props.isPrimaryQuestion && !props.question.fromSupervisor && (
              <div>
                {props.topic.active.newAnnotations && (
                  <div className="abs list-button-dot red annotation-dot editor-question-dot" />
                )}

                <ButtonFloating
                  top="90px"
                  icon={faCommentDots}
                  onClick={() => setOpenAnnotations(true)}
                  text={t("annotation_get")}
                />
              </div>
            )}
          </div>

          {props.question.type !== "open" && !props.topic.active.isAtStart && (
            <ButtonFloating
              bottom="-16px"
              right="calc(50% - 16px)"
              icon={faPlus}
              onClick={addQuestion}
              text={t("question_add_secondary")}
            />
          )}

          {renderQuestionHeader()}

          {props.question.isNumericAllowed && (
            <div className="question-numeric">
              {props.question.type === "scale" &&
                props.question.responseCount > 2 &&
                props.question.responseCount < 6 && (
                  <div>
                    {flip ? (
                      <FlipInY>{getScale()}</FlipInY>
                    ) : (
                      <div>{getScale()}</div>
                    )}

                    <div className="flex grey-t question-option-label">
                      <div className="flex1">
                        {props.template.active.getResponseLabel(
                          5,
                          1,
                          "question_likert"
                        )}
                      </div>
                      <div className="flex1" />
                      <div className="flex1" style={{ textAlign: "right" }}>
                        {props.template.active.getResponseLabel(
                          5,
                          5,
                          "question_likert"
                        )}
                      </div>
                    </div>
                  </div>
                )}

              {props.question.type === "scale" &&
                props.question.responseCount < 3 && (
                  <div className="flex grey-t" style={{ paddingBottom: "8px" }}>
                    <div className="flex1"></div>
                    <div
                      className="question-option flex"
                      style={{ backgroundColor: getColor(0) }}
                    >
                      <div className="question-option-center">
                        {props.template.active.getResponseLabel(
                          2,
                          "no",
                          "utils"
                        )}
                      </div>
                    </div>
                    <div className="flex1"></div>
                    <div
                      className="question-option flex"
                      style={{ backgroundColor: getColor(4) }}
                    >
                      <div className="question-option-center">
                        {props.template.active.getResponseLabel(
                          2,
                          "yes",
                          "utils"
                        )}
                      </div>
                    </div>
                    <div className="flex1"></div>
                  </div>
                )}

              {props.question.type === "scale" &&
                props.question.responseCount === 10 && (
                  <div>
                    <div className="flex grey-t question-nps-label">
                      {props.template.active.getResponseLabel(
                        10,
                        props.question.reverse ? "happy" : "unhappy",
                        "question_nps"
                      )}
                      <div className="flex1 question-nps-separator" />
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        className="question-nps-chevron"
                      />
                      {props.template.active.getResponseLabel(
                        10,
                        props.question.reverse ? "unhappy" : "happy",
                        "question_nps"
                      )}
                    </div>

                    <div className="flex question-nps-container">
                      <Space />
                      {[...Array(10)].map((e, i) => (
                        <div
                          className="question-nps flex"
                          key={i}
                          style={{
                            backgroundColor: getColor(i + 1),
                            marginTop: i % 2 > 0 ? "26px" : "",
                          }}
                        >
                          <span>{i + 1}</span>
                        </div>
                      ))}
                      <Space />
                    </div>
                  </div>
                )}

              {!props.question.QuestionsTopic.disableNoOpinion && (
                <span className="flex grey-t question-nspp">
                  <div className="flex1" />

                  {props.template.active.options.showCircle && (
                    <div
                      className="_hover question-proposition-circle"
                      style={{ borderColor: props.question.color }}
                    />
                  )}

                  <div className="question-nspp-text">
                    {props.template.active.getResponseLabel(
                      0,
                      "nspp",
                      "question"
                    )}
                  </div>
                  <div className="flex1" />
                </span>
              )}
            </div>
          )}

          {
            /** liste des propositions */
            props.question.type === "choice" && (
              <div>
                <DragDropContext onDragEnd={onDragEndProposition}>
                  <div className="question-proposition-container">
                    {props.question.Propositions.map((proposition, i) => (
                      <Droppable
                        droppableId={proposition.id}
                        type="PROPOSITION"
                        key={proposition.id}
                      >
                        {(provided, snapshot) => (
                          <div
                            className="question-proposition"
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            style={{
                              border: snapshot.isDraggingOver
                                ? "1px dotted #8C8C8C"
                                : "",
                            }}
                          >
                            {provided.placeholder}

                            <Draggable draggableId={proposition.id} index={0}>
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <div className="flex grey-bor question-proposition-draggable">
                                    <div className="question-proposition-option"></div>

                                    <div
                                      className="flex1 flex"
                                      style={{ position: "relative" }}
                                    >
                                      <textarea
                                        value={
                                          proposition.name[
                                            props.template.active.language
                                          ]
                                            ? proposition.name[
                                                props.template.active.language
                                              ]
                                            : ""
                                        }
                                        onChange={(e) =>
                                          editPropositionName(
                                            proposition,
                                            e.target.value
                                          )
                                        }
                                        className="grey-t"
                                      ></textarea>

                                      <div className="question-proposition-order flex flex-dcol">
                                        <Space />

                                        <ListButton
                                          icon={faChevronUp}
                                          isInactive={i === 0}
                                          text={t("question_order_up")}
                                          onClick={() =>
                                            setPropositionOrder(
                                              proposition.id,
                                              true,
                                              i === 0
                                            )
                                          }
                                        />

                                        <Space />

                                        <ListButton
                                          icon={faChevronDown}
                                          isInactive={
                                            i ===
                                            props.question.Propositions.length -
                                              1
                                          }
                                          text={t("question_order_down")}
                                          onClick={() =>
                                            setPropositionOrder(
                                              proposition.id,
                                              false,
                                              i ===
                                                props.question.Propositions
                                                  .length -
                                                  1
                                            )
                                          }
                                        />

                                        <Space />
                                      </div>

                                      <div className="question-proposition-action flex1 flex flex-dcol">
                                        <Space />
                                        <ListButton
                                          icon={faTimes}
                                          text={t("utils_delete")}
                                          onClick={() =>
                                            removeProposition(proposition.id)
                                          }
                                        />
                                        <Space />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          </div>
                        )}
                      </Droppable>
                    ))}
                  </div>
                </DragDropContext>

                <div className="question-proposition-container">
                  {props.question.withMessage && (
                    <div className="flex grey-bor">
                      <div className="question-proposition-option"></div>

                      <div
                        className="flex1 flex"
                        style={{ opacity: 0.8, marginRight: 44 }}
                      >
                        <textarea
                          value={t("question_withmessage_preview")}
                          disabled
                          className="grey-t"
                        ></textarea>
                      </div>
                    </div>
                  )}

                  <div className="flex">
                    <Space />
                    <ListButton
                      icon={faPlus}
                      text={t("proposition_add")}
                      onClick={addProposition}
                    />
                  </div>
                </div>

                {!props.topic.active.isAtStart && (
                  <div className="question-proposition-checkbox grey-t">
                    <Checkbox
                      active={props.question.withMessage}
                      onClick={() =>
                        editQuestion("withMessage", !props.question.withMessage)
                      }
                      text={t("question_withmessage")}
                    />
                    <div className="height-10" />
                  </div>
                )}
              </div>
            )
          }

          {
            /** commentaire libre */
            props.question.type === "open" && (
              <div className="flex1 question-open grey-t flex">
                <div style={{ margin: "auto" }}>
                  <FontAwesomeIcon icon={faCommentDots} />
                  <div>{t("question_type_open_free")}</div>
                </div>
              </div>
            )
          }
        </div>
      </FadeIn>

      <div className="question-params">
        {props.isPrimaryQuestion &&
          (Object.keys(props.topic.active.name).length === 0 ? (
            <div className="editor-question-new-topic">
              <div>
                <b>{t("topic_undefined")}</b>
              </div>
              <Button className="primary" onClick={() => editTopic("")}>
                {t("topic_add_new")}
              </Button>
              {props.topic.active.getPrevious(
                props.topic.list,
                props.template.active.language
              ) && (
                <Link
                  isWithoutMargin
                  isWhite
                  onClick={() =>
                    editTopic(
                      props.topic.active.getPrevious(
                        props.topic.list,
                        props.template.active.language
                      )
                    )
                  }
                >
                  {t("topic_use_previous", {
                    name: props.topic.active.getPrevious(
                      props.topic.list,
                      props.template.active.language
                    ),
                  })}
                </Link>
              )}
            </div>
          ) : (
            <div>
              <TextInput
                isWhiteMode
                onChange={(e) => editTopic(e.value)}
                title={t("topic")}
                onHelp={() => props.onHelp(HELP_TOPIC)}
                value={
                  props.topic.active.name[props.template.active.language]
                    ? props.topic.active.name[props.template.active.language]
                    : ""
                }
                width={210}
              />

              {showSwitchLanguage() && (
                <SwitchLanguage
                  isWhiteMode
                  language={props.template.active.language}
                  languageAlt={props.template.active.languageAlt}
                  languages={props.template.active.languages}
                  text={props.topic.active.name}
                  onSelect={selectLanguage}
                />
              )}

              {!props.topic.active.isAtStart && (
                <AxesDropdown
                  advancedEditor
                  onHelp={() => props.onHelp(HELP_AXIS)}
                  currentTopic={props.topic.active}
                />
              )}
            </div>
          ))}

        {props.topic.active.isAtStart ? (
          <div className="white-t">
            <div className="height-40" />

            <div style={{ textAlign: "center" }}>
              <h3>{t("question_introduction")}</h3>

              <p style={{ fontSize: "12px" }}>
                {t("question_introduction_text", {
                  filter: props.topic.active.label,
                  populations: props.question.Propositions.map(
                    (x) => x.label
                  ).join(", "),
                })}
              </p>
            </div>
          </div>
        ) : (
          <div>
            {!props.isPrimaryQuestion && (
              <div>
                {props.topic.active.questionPrimary?.type !== "open" && (
                  <div>
                    <Dropdown
                      displayField="name"
                      warning={
                        props.question.getTriggerLabel(
                          props.template.active,
                          props.topic.active.questionPrimary,
                          props.template.active.language
                        ) === ""
                      }
                      active={props.question.getTrigger(
                        props.topic.active.questionPrimary
                      )}
                      value={props.question.getTriggerValue(
                        props.topic.active.questionPrimary,
                        props.template.active.language
                      )}
                      isWhiteMode
                      onHelp={() => props.onHelp(HELP_TRIGGER)}
                      list={props.question.getTriggerList(
                        props.topic.active.questionPrimary,
                        props.template.active.language
                      )}
                      onSelect={(item) =>
                        editTriggerValue(
                          item,
                          props.topic.active.questionPrimary?.type
                        )
                      }
                      title={t("question_trigger")}
                    />

                    {props.question.getTriggerValue(
                      props.topic.active.questionPrimary,
                      props.template.active.language
                    ) !== "" && (
                      <div className="question-trigger">
                        {t("question_answer_at")}&nbsp;
                        <b>
                          {props.question.getTriggerLabel(
                            props.template.active,
                            props.topic.active.questionPrimary,
                            props.template.active.language
                          )}
                        </b>
                        &nbsp;
                        {t("question_answer_at_question_name", {
                          question:
                            props.topic.active.questionPrimary?.name[
                              props.template.active.language
                            ],
                        })}
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}

            <Dropdown
              active={props.question.type}
              activeValue={
                props.question.type === "choice"
                  ? props.question.choiceCount
                  : props.question.responseCount
              }
              displayField="name"
              isWhiteMode
              onHelp={() => props.onHelp(HELP_TYPE)}
              list={QUESTION_LIST_TYPE}
              onSelect={editQuestionType}
              title={t("question_type")}
              value={props.question.typeLabelWithCount}
            />

            <Dropdown
              active={props.question.withScore}
              activeValue={props.question.reverse}
              isDisabled={!props.question.isNumericAllowed}
              displayField="name"
              isWhiteMode
              list={props.question.listWithScore}
              onSelect={editQuestionWithScore}
              onHelp={() => props.onHelp(HELP_SCORE)}
              title={t("question_score")}
              tag={
                props.question.reverse ? t("question_reverse_tag") : undefined
              }
              tagColor="#0079bf"
              value={props.question.numericLabel}
            />

            <Dropdown
              active={props.question.special}
              displayField="name"
              isDisabled={props.question.type !== "scale"}
              isContextMenuTop
              isWhiteMode
              onHelp={() => props.onHelp(HELP_SPECIAL)}
              list={QUESTION_LIST_SPECIAL}
              onEdit={() => alert("WARNING")}
              onSelect={(item) => editQuestionSpecial(item)}
              title={t("question_rps")}
              tag={props.question.special ? t("utils_yes") : undefined}
              tagColor="#ff9500"
              value={props.question.specialLabel}
            />

            {props.question.type === "scale" && (
              <div className="editor-question-hide-nspp">
                <div className="height-10" />
                <Checkbox
                  onClick={(e: { id: any; value: boolean }) =>
                    editDisableNoOpinion(e.value)
                  }
                  active={props.question.QuestionsTopic.disableNoOpinion}
                  text={t("template_options_anwsers_required")}
                />
              </div>
            )}
          </div>
        )}
      </div>

      <ReactTooltip />
    </div>
  );
}

const mapStateToProps = (state) => ({
  _session: state._session,
  topic: state.topic,
  template: state.template,
});

export default connect(mapStateToProps)(withTranslation()(EditorQuestion));
