import cookie from "react-cookies";
import axios from "axios";
import { store } from "@/index";
// import store from "@/core/store";
import { sessionGet } from "@/redux/_session.actions";
import { Session } from "@/redux/_session.types";

//Déconnexion de l'application
//Supprimer cookies et informations utilisateur
//Redirection vers login
const logout = (expired: boolean) => {
  cookie.remove("firebaseToken", { path: "/" });
  cookie.remove("idtreeToken", { path: "/" });
  axios.defaults.headers.common["firebaseToken"] = null;
  axios.defaults.headers.common["idtreeToken"] = null;
  store.dispatch(sessionGet(new Session()));
  window.location.href =
    window.location.origin +
    "/login/app" +
    (expired ? "?message=session_expired" : "");
};

export default logout;
