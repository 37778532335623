/**
 * ACCOUNT-OPTIONS.WIDGET
 * Customize account options
 * Can be save for user options as for account options for the display settings
 */

import { connect } from "react-redux";
import { store } from "@/index";
// import store from "@/core/store";
import { withTranslation, WithTranslation } from "react-i18next";
import Space from "@/components/space";
import { Session, SESSION_COLORS } from "@/redux/_session.types";
import {
  sessionEdit,
  sessionEditAccountOptions,
} from "@/redux/_session.actions";
import ColorPickerWidget from "@/widgets/color-picker.widget";
import MultiRangeSlider from "multi-range-slider-react";
import Card from "@/components/card";
import ListItem from "@/components/list-item";
import Checkbox from "@/components/checkbox";
import Chip from "@/components/chip";
import { useState } from "react";
import Dropdown from "@/components/dropdown";
import getUserFilterDates, {
  UserFilterDates,
} from "@/utils/get-user-filter-dates.utils";
import TextInput from "@/components/text-input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import UpgradeWidget from "./upgrade.widget";
import ToggleButton from "@/components/toggle-button";
import Button from "@/components/button";
import { AccountOptions, AccountState } from "@/redux/account.types";
import { UserOptions } from "@/redux/user.types";
import Tabs from "@/components/tabs";
import { STATUS_SAVED, STATUS_SAVING } from "@/redux/_status.types";
import { accountStatus, accountUpdateOptions } from "@/redux/account.actions";
import { userUpdateOptions } from "@/redux/user.actions";
import Range from "@/components/range";

interface StateProps extends WithTranslation {
  account: AccountState;
  _session: Session;
}

interface OwnProps {
  isSmall?: boolean;
}

type Props = StateProps & OwnProps;

const SCORE_DISPLAY_MODE: any = [
  { id: "note", name: "score_display_note" },
  { id: "satisfaction", name: "score_display_satisfaction" },
];

const MIN_CEILS: number = 1;
const MAX_CEILS: number = 10;
const CEIL_BIRTHDATE_MIN: number = 18;
const CEIL_BIRTHDATE_MAX: number = 80;
const CEIL_COMPANYWELCOMEDATE_MIN: number = 2;
const CEIL_COMPANYWELCOMEDATE_MAX: number = 300;
const TAB_DISPLAY_ACCOUNT = "TAB_DISPLAY_ACCOUNT";
const TAB_DISPLAY_USER = "TAB_DISPLAY_USER";

function AccountOptionsWidget(props: Props) {
  const { t } = props;

  //Current tab for the display card
  //By default it is user options that can be displayed
  const [currentDisplayTab, setCurrentDisplayTab] = useState(
    props._session.userRole === "ADMIN" ? TAB_DISPLAY_ACCOUNT : TAB_DISPLAY_USER
  );

  //Show save buttons
  const [isEdited, setIsEdited] = useState(false);

  //Account options that will be edited
  const [accountOptions, setAccountOptions] = useState<AccountOptions>(
    props._session.accountOptions
  );

  //Idem for user options
  const [userOptions, setUserOptions] = useState(props._session.userOptions);

  //Change global options for the account
  async function updateOptions(key, value) {
    const response: any = store.dispatch(
      accountUpdateOptions(
        new AccountOptions({
          ...props._session.accountOptions,
          [key]: value,
        })
      )
    );

    if (!response.error) {
      store.dispatch(sessionEditAccountOptions(key, value));
    }
  }

  //On click cancel
  //Reset all parameters from the store
  function cancel() {
    setIsEdited(false);
    setUserOptions(props._session.userOptions);
    setAccountOptions(props._session.accountOptions);
  }

  //Edit settings for the account
  //Specific case for ceilsRepartition
  function edit(key: string, value: any) {
    const options = new AccountOptions(accountOptions);
    options[key] = value;
    setIsEdited(true);
    setAccountOptions(options);
  }

  //Edit settings for the display
  //According the tab, even the userOptions or
  function editDisplay(key: string, value: any) {
    if (currentDisplayTab === TAB_DISPLAY_USER) {
      const options = new UserOptions(userOptions);
      setUserOptions(getDisplayOptions(key, value, options));
    } else {
      const options = new AccountOptions(accountOptions);
      setAccountOptions(getDisplayOptions(key, value, options));
    }

    setIsEdited(true);

    function getDisplayOptions(key: string, value: any, options: any) {
      if (key === "ceilsRepartition") {
        options.ceilsRepartition = [value.minValue, value.maxValue];
      } else if (key === "dashboardNightmareMode") {
        options[key + "Min"] = value.minValue;
        options[key + "Max"] = value.maxValue;
      } else {
        options[key] = value;
      }
      return options;
    }
  }

  function changeBirthDateIntervals(values: number[]) {
    edit("ceilsBirthDate", values);
  }

  function changeCompanyWelcomeDateIntervals(values: number[]) {
    edit("ceilsCompanyWelcomeDate", values);
  }

  function addBirthDateCeil() {
    const array: number[] = accountOptions.ceilsBirthDate;

    let random: number = -1;
    do {
      random =
        Math.floor(
          Math.random() * (CEIL_BIRTHDATE_MAX - CEIL_BIRTHDATE_MIN + 1)
        ) + CEIL_BIRTHDATE_MIN;
    } while (array.includes(random)); // will return false if random isn't asigned

    array.push(random);

    changeBirthDateIntervals(array.sort((a: number, b: number) => a - b));
  }

  function addCompanyWelcomeDateCeil() {
    const array: number[] = accountOptions.ceilsCompanyWelcomeDate;

    let random: number = -1;
    do {
      random =
        Math.floor(
          Math.random() *
            (CEIL_COMPANYWELCOMEDATE_MAX - CEIL_COMPANYWELCOMEDATE_MIN + 1)
        ) + CEIL_COMPANYWELCOMEDATE_MIN;
    } while (array.includes(random)); // will return false if random isn't asigned

    array.push(random);

    changeCompanyWelcomeDateIntervals(
      array.sort((a: number, b: number) => a - b)
    );
  }

  function getBirthDateMinAllowed(index: number): number {
    return index === 0
      ? CEIL_BIRTHDATE_MIN
      : accountOptions.ceilsBirthDate[index - 1] + 1;
  }

  function getBirthDateMaxAllowed(index: number): number {
    return index === accountOptions.ceilsBirthDate.length - 1
      ? CEIL_BIRTHDATE_MAX
      : accountOptions.ceilsBirthDate[index + 1] - 1;
  }

  function getCompanyWelcomeDateMinAllowed(index: number): number {
    return index === 0
      ? CEIL_COMPANYWELCOMEDATE_MIN
      : accountOptions.ceilsCompanyWelcomeDate[index - 1] + 1;
  }

  function getCompanyWelcomeDateMaxAllowed(index: number): number {
    return index === accountOptions.ceilsCompanyWelcomeDate.length - 1
      ? CEIL_COMPANYWELCOMEDATE_MAX
      : accountOptions.ceilsCompanyWelcomeDate[index + 1] - 1;
  }

  function getPreferencesDisplay() {
    const options =
      currentDisplayTab === TAB_DISPLAY_USER ? userOptions : accountOptions;

    return (
      <div>
        {currentDisplayTab === TAB_DISPLAY_USER && (
          <ListItem>
            <Checkbox
              active={!userOptions.dashboardApplyUserSettings}
              text={t("account_settings_use_account")}
              onClick={() =>
                editDisplay(
                  "dashboardApplyUserSettings",
                  !userOptions.dashboardApplyUserSettings
                )
              }
            />
            <Space />
          </ListItem>
        )}

        <ListItem>
          <Checkbox
            active={options.dashboardNightmareModeDisplay}
            text={t("dashboard_nigthtmare_label")}
            onClick={
              currentDisplayTab === TAB_DISPLAY_ACCOUNT ||
              userOptions.dashboardApplyUserSettings
                ? () =>
                    editDisplay(
                      "dashboardNightmareModeDisplay",
                      !options.dashboardNightmareModeDisplay
                    )
                : null
            }
          />
          <Space />
        </ListItem>

        <ListItem>
          <Checkbox
            active={options.dashboardNightmareModeHeatmap}
            text={t("dashboard_nigthtmare_heatmap_label")}
            onClick={
              currentDisplayTab === TAB_DISPLAY_ACCOUNT ||
              userOptions.dashboardApplyUserSettings
                ? () =>
                    editDisplay(
                      "dashboardNightmareModeHeatmap",
                      !options.dashboardNightmareModeHeatmap
                    )
                : null
            }
          />
          <Space />
        </ListItem>

        <ListItem>
          <div className="flex1">
            <div>{t("account_ceil_nightmare_note")}</div>
            <div className="height-20" />
            <MultiRangeSlider
              min={5}
              max={95}
              step={1}
              ruler={false}
              minValue={options.dashboardNightmareModeMin}
              maxValue={options.dashboardNightmareModeMax}
              onInput={(e: any) => editDisplay("dashboardNightmareMode", e)}
            />
          </div>
        </ListItem>

        {(options.dashboardNightmareModeDisplay ||
          options.dashboardNightmareModeHeatmap) && (
          <ListItem>
            <div className="flex1">
              <div className="flex flex-wrap">
                <ColorPickerWidget
                  colors={SESSION_COLORS}
                  isCustom
                  title={t("score_below_color", {
                    score: options.dashboardNightmareModeMin,
                  })}
                  selectedColor={options.dashboardNightmareColor1}
                  onSelect={(color: string) =>
                    editDisplay("dashboardNightmareColor1", color)
                  }
                />

                <Space />

                <ColorPickerWidget
                  colors={SESSION_COLORS}
                  isCustom
                  title={t("score_between_color", {
                    score1: options.dashboardNightmareModeMin,
                    score2: options.dashboardNightmareModeMax,
                  })}
                  selectedColor={options.dashboardNightmareColor2}
                  onSelect={(color: string) =>
                    editDisplay("dashboardNightmareColor2", color)
                  }
                />

                <Space />

                <ColorPickerWidget
                  colors={SESSION_COLORS}
                  isCustom
                  title={t("score_above_color", {
                    score: options.dashboardNightmareModeMax,
                  })}
                  selectedColor={options.dashboardNightmareColor3}
                  onSelect={(color: string) =>
                    editDisplay("dashboardNightmareColor3", color)
                  }
                />
              </div>
            </div>
          </ListItem>
        )}

        <ListItem>
          <Dropdown
            displayField="name"
            isDisabled={
              currentDisplayTab === TAB_DISPLAY_USER &&
              !userOptions.dashboardApplyUserSettings
            }
            title={t("score_display_mode")}
            list={SCORE_DISPLAY_MODE}
            value={t("score_display_" + options.dashboardDisplayMode)}
            onSelect={(e) => editDisplay("dashboardDisplayMode", e.id)}
          />
        </ListItem>

        <ListItem>
          <Checkbox
            active={options.hideNoAnswers}
            onClick={
              currentDisplayTab === TAB_DISPLAY_ACCOUNT ||
              userOptions.dashboardApplyUserSettings
                ? (e) => editDisplay("hideNoAnswers", e.value)
                : null
            }
            text={t("account_hide_no_answers")}
          />
          <Space />
        </ListItem>

        <ListItem>
          <Checkbox
            active={options.hideStartedForParticipation}
            onClick={
              currentDisplayTab === TAB_DISPLAY_ACCOUNT ||
              userOptions.dashboardApplyUserSettings
                ? (e) => editDisplay("hideStartedForParticipation", e.value)
                : null
            }
            text={t("account_hide_started_for_participation")}
          />
          <Space />
        </ListItem>

        <ListItem>
          {currentDisplayTab === TAB_DISPLAY_USER &&
            !userOptions.dashboardApplyUserSettings && (
              <div
                className="abs"
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#ffffff7a",
                  zIndex: 9,
                  top: 0,
                  left: 0,
                }}
              />
            )}

          <div className="flex1">
            <div>{t("account_ceil_repartition")}</div>
            <MultiRangeSlider
              min={4}
              max={9}
              step={1}
              ruler={false}
              minValue={options.ceilsRepartition[0]}
              maxValue={options.ceilsRepartition[1]}
              onInput={(e: any) => editDisplay("ceilsRepartition", e)}
            />
          </div>
        </ListItem>

        {currentDisplayTab === TAB_DISPLAY_ACCOUNT && (
          <div>
            <ListItem>
              <ToggleButton
                isActive={accountOptions.allowObserverSettings}
                onToogle={() =>
                  edit(
                    "allowObserverSettings",
                    !accountOptions.allowObserverSettings
                  )
                }
              />
              <span style={{ margin: "2px 8px" }}>
                {t("account_settings_observer")}
              </span>
            </ListItem>

            {/*

          <ListItem>
            <div>
              <Checkbox active={accountOptions.pastResults} onClick={() => { edit("pastResults", !accountOptions.pastResults) }} text={t("account_settings_past_results")}/>
            </div>
          </ListItem>

          <ListItem>
            <div>
              <Checkbox active={accountOptions.pastResults && accountOptions.pastResultsEmail} onClick={accountOptions.pastResults ? () => edit("pastResultsEmail", !accountOptions.pastResultsEmail) : null} text={t("account_settings_past_results_email")}/>
            </div>
          </ListItem>

        */}
          </div>
        )}

        {props._session.modules.assistant && (
          <ListItem>
            <Checkbox
              active={props._session.accountOptions.allowAssistant}
              text={t("consent_ai")}
              onClick={(e) => updateOptions("allowAssistant", e.value)}
            />
            <Space />
          </ListItem>
        )}
      </div>
    );
  }

  function getSaveButton() {
    if (isEdited) {
      return (
        <ListItem>
          <Space />
          <Button onClick={cancel}>{t("utils_cancel")}</Button>
          <Button
            className="primary"
            isLoading={props.account.status === STATUS_SAVING}
            onClick={save}
          >
            {t("utils_save")}
          </Button>
        </ListItem>
      );
    } else {
      return null;
    }
  }

  async function save() {
    if (
      accountOptions.ceilsBirthDate.some(
        (ceil: number, index: number) =>
          !ceil ||
          ceil < getBirthDateMinAllowed(index) ||
          ceil > getBirthDateMaxAllowed(index)
      ) ||
      accountOptions.ceilsCompanyWelcomeDate.some(
        (ceil: number, index: number) =>
          !ceil ||
          ceil < getCompanyWelcomeDateMinAllowed(index) ||
          ceil > getCompanyWelcomeDateMaxAllowed(index)
      )
    ) {
      toast(t("ceil_date_error"), { type: "error" });
    } else {
      store.dispatch(accountStatus(STATUS_SAVING));
      store.dispatch(sessionEdit("accountOptions", accountOptions));
      store.dispatch(sessionEdit("userOptions", userOptions));
      await store.dispatch(accountUpdateOptions(accountOptions));
      await store.dispatch(
        userUpdateOptions(props._session.userId, userOptions)
      );
      store.dispatch(accountStatus(STATUS_SAVED));
      setIsEdited(false);
    }
  }

  return props._session.modules.settings ? (
    <div>
      <Card isWithoutPadding>
        <ListItem>
          <div>
            {!props.isSmall && (
              <h2>{t("account_settings_preferences_display")}</h2>
            )}

            {props._session.userRole === "ADMIN" && (
              <Tabs
                tabs={{
                  TAB_DISPLAY_ACCOUNT: t(
                    "account_settings_preferences_account"
                  ),
                  TAB_DISPLAY_USER: t("account_settings_preferences_user"),
                }}
                onClick={(tab) => setCurrentDisplayTab(tab)}
                active={currentDisplayTab}
              />
            )}
          </div>
        </ListItem>

        {getPreferencesDisplay()}
        {getSaveButton()}
      </Card>

      {!props.isSmall && (
        <Card isWithoutPadding>
          <ListItem>
            <h2>{t("account_settings_preferences_dashboard")}</h2>
          </ListItem>

          <ListItem>
            <div className="flex1">
              <p>{t("ceils_birth_date_ceil_configure")}</p>

              <div className="flex flex-wrap">
                <div style={{ width: props.isSmall ? 300 : 500 }}>
                  <p style={{ fontSize: 12 }} className="grey-t">
                    {t("account_ceil_birth_date_title")}
                  </p>

                  {accountOptions.ceilsBirthDate.map(
                    (ceil: number, index: number) => (
                      <div key={index} className="flex">
                        <TextInput
                          min={getBirthDateMinAllowed(index)}
                          max={getBirthDateMaxAllowed(index)}
                          title={t("ceil_birth_date") + " " + (index + 1)}
                          error={
                            !ceil ||
                            ceil < getBirthDateMinAllowed(index) ||
                            ceil > getBirthDateMaxAllowed(index)
                          }
                          type={"number"}
                          value={ceil}
                          onChange={(event: any) =>
                            changeBirthDateIntervals(
                              accountOptions.ceilsBirthDate.map(
                                (x: number, i: number) =>
                                  i === index ? parseInt(event.value, 10) : x
                              )
                            )
                          }
                        />

                        <div className="width-10" />

                        <div
                          className="_hover flex flex-dcol"
                          onClick={() =>
                            changeBirthDateIntervals(
                              accountOptions.ceilsBirthDate.filter(
                                (x: number, i: number) => i !== index
                              )
                            )
                          }
                        >
                          <div style={{ height: "37px" }} />
                          <Space />
                          {accountOptions.ceilsBirthDate.length > MIN_CEILS && (
                            <FontAwesomeIcon
                              icon={faTimes}
                              color="#111C2B"
                              data-tip={t("ceil_birth_date_remove")}
                            />
                          )}
                          <Space />
                        </div>
                      </div>
                    )
                  )}

                  {accountOptions.ceilsBirthDate.length < MAX_CEILS && (
                    <u className="_hover grey-t" onClick={addBirthDateCeil}>
                      {"+ " + t("ceil_birth_date_add")}
                    </u>
                  )}
                </div>

                {!props.isSmall && <div className="width-100" />}

                {!props.isSmall && (
                  <div className="flex1">
                    <div className="flex">
                      <p className="grey-t">
                        {t("account_ceil_birth_date_preview")}
                      </p>
                      <Space />
                    </div>

                    <div style={{ maxWidth: "180px" }}>
                      {getUserFilterDates("BIRTH_DATE", new Date()).map(
                        (item: UserFilterDates) => (
                          <Chip key={item.id} isWithMargin>
                            <span>
                              {item.name + " "}
                              <FontAwesomeIcon
                                icon={faInfoCircle}
                                data-tip={item.description}
                              />
                            </span>
                          </Chip>
                        )
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </ListItem>

          <ListItem>
            <div className="flex1">
              <p>{t("ceils_company_welcome_date_ceil_configure")}</p>

              <div className="flex flex-wrap">
                <div style={{ width: props.isSmall ? 300 : 500 }}>
                  <p style={{ fontSize: 12 }} className="grey-t">
                    {t("account_ceil_company_welcome_date_title")}
                  </p>

                  {accountOptions.ceilsCompanyWelcomeDate.map(
                    (ceil: number, index: number) => (
                      <div key={index} className="flex">
                        <TextInput
                          min={getCompanyWelcomeDateMinAllowed(index)}
                          max={getCompanyWelcomeDateMaxAllowed(index)}
                          step={1}
                          title={
                            t("ceil_company_welcome_date") + " " + (index + 1)
                          }
                          error={
                            !ceil ||
                            ceil < getCompanyWelcomeDateMinAllowed(index) ||
                            ceil > getCompanyWelcomeDateMaxAllowed(index)
                          }
                          type={"number"}
                          value={ceil}
                          onChange={(event: any) =>
                            changeCompanyWelcomeDateIntervals(
                              accountOptions.ceilsCompanyWelcomeDate.map(
                                (x: number, i: number) =>
                                  i === index ? parseInt(event.value, 10) : x
                              )
                            )
                          }
                        />

                        <div className="width-10" />

                        <div
                          className="_hover flex flex-dcol"
                          onClick={() =>
                            changeCompanyWelcomeDateIntervals(
                              accountOptions.ceilsCompanyWelcomeDate.filter(
                                (x: number, i: number) => i !== index
                              )
                            )
                          }
                        >
                          <div style={{ height: "37px" }} />
                          <Space />
                          {accountOptions.ceilsCompanyWelcomeDate.length >
                            MIN_CEILS && (
                            <FontAwesomeIcon
                              icon={faTimes}
                              color="#111C2B"
                              data-tip={t("ceil_company_welcome_date_remove")}
                            />
                          )}
                          <Space />
                        </div>
                      </div>
                    )
                  )}

                  {accountOptions.ceilsCompanyWelcomeDate.length <
                    MAX_CEILS && (
                    <u
                      className="_hover grey-t"
                      onClick={addCompanyWelcomeDateCeil}
                    >
                      {"+ " + t("ceil_company_welcome_date_add")}
                    </u>
                  )}
                </div>

                {!props.isSmall && <div className="width-100" />}

                {!props.isSmall && (
                  <div className="flex1">
                    <div className="flex">
                      <p className="grey-t">
                        {t("account_ceil_company_welcome_date_preview")}
                      </p>
                      <Space />
                    </div>

                    <div style={{ maxWidth: "180px" }}>
                      {getUserFilterDates(
                        "COMPANY_WELCOME_DATE",
                        new Date()
                      ).map((item: UserFilterDates) => (
                        <Chip key={item.id} isWithMargin>
                          <span>
                            {item.name + " "}
                            <FontAwesomeIcon
                              icon={faInfoCircle}
                              data-tip={item.description}
                            />
                          </span>
                        </Chip>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </ListItem>

          <ListItem>
            <div className="flex1">
              <p>{t("topic_repartition_ceil")}</p>
              <Range
                onChange={(value) =>
                  editDisplay("topicRepartitionCeil", value * 10)
                }
                min={0}
                max={10}
                step={0.5}
                value={accountOptions.topicRepartitionCeil / 10}
              />
            </div>
          </ListItem>

          <ListItem>
            <Checkbox
              active={props._session.accountOptions.isObserverNotRestricted}
              text={t("admin_observer_restricted")}
              onClick={(e) => updateOptions("isObserverNotRestricted", e.value)}
              status={props.account.status}
            />
            <Space />
          </ListItem>

          {getSaveButton()}
        </Card>
      )}
    </div>
  ) : (
    <div
      style={
        props.isSmall
          ? {
              padding: "0px 40px",
              marginBottom: -40,
            }
          : {}
      }
    >
      <UpgradeWidget feature="settings" withNoImages={props.isSmall} />
    </div>
  );
}

const mapStateToProps = (state) => ({
  account: state.account,
  _session: state._session,
});

export default connect(mapStateToProps)(
  withTranslation()(AccountOptionsWidget)
);
