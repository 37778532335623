/**
 * ACCOUNTS-LIST.WIDGET
 * Seach accounts in the database for a superadmin
 */

import { store } from "@/index";
// import store from "@/core/store";
import { withTranslation, WithTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import {
  accountActivate,
  accountEdit,
  accountFetchCount_AsSuperadmin,
  accountFetch_AsSuperadmin,
  accountGet,
  accountGetCount,
  accountGetCountSearch,
  accountOrder,
  accountStatus,
  accountUpdateTemplateRules,
} from "@/redux/account.actions";
import {
  Account,
  AccountState,
  AccountTemplateRule,
} from "@/redux/account.types";
import {
  STATUS_LOADED,
  STATUS_LOADING,
  STATUS_LOAD_ERROR,
  STATUS_SAVED,
  STATUS_SAVING,
  STATUS_SEARCHING,
} from "@/redux/_status.types";
import ListContainer from "@/components/list-container";
import ListFields from "@/components/list-fields";
import { connect } from "react-redux";
import ListItem from "@/components/list-item";
import ListButton from "@/components/list-button";
import ListCell from "@/components/list-cell";
import {
  faShieldAlt,
  faPencilAlt,
  faPlayCircle,
} from "@fortawesome/free-solid-svg-icons";
import Space from "@/components/space";
import { Session } from "@/redux/_session.types";
import ListIcon from "@/components/list-icon";
import env from "@/env";
import EditAccountModal from "@/modals/edit-account.modal";
import PageLoader from "@/components/page-loader";
import Modal from "@/components/modal";
import LoginModal from "@/modals/login.modal";
import Chip from "@/components/chip";
import PagePlaceholder from "@/components/page-placeholder";
import Card from "@/components/card";
import { orderBy } from "lodash";
import { getIconUrl } from "@/utils/default-icons.utils";
import { userGet } from "@/redux/user.actions";
import ContextMenu from "@/components/context-menu";
import SaveIcon from "@/components/save-icon";
import EditTemplateRulesModal from "@/modals/edit-template-rules.modal";
import AdmEditChildrenModal from "@/modals/adm-edit-children.modal";

interface StateProps extends WithTranslation {
  _session: Session;
  account: AccountState;
}

interface OwnProps {
  editTemplateRulesOnly?: boolean;
}

type Props = StateProps & OwnProps;

const MODAL_ADD: string = "MODAL_ADD";
const MODAL_ADD_ADMIN: string = "MODAL_ADD_ADMIN";
const MODAL_EDIT: string = "MODAL_EDIT";
const MODAL_AUTH_CONFIRM: string = "MODAL_AUTH_CONFIRM";
const MODAL_TEMPLATES: string = "MODAL_TEMPLATES";

const TEMPLATE_RULE_LIST: AccountTemplateRule[] = [
  "all",
  "account",
  "public",
  "list",
  "blocked",
];

type AccountFields = "id" | "name" | "dateStart" | "Users";

function AccountsListWidget(props: Props) {
  const { t } = props;

  const [currentAccountId, setCurrentAccountId] = useState<string | null>(null);
  const [currentSearch, setCurrentSearch] = useState<string>("");
  const [currentModal, setCurrentModal] = useState<string | null>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [showRuleContext, setShowRuleContext] = useState(false);

  useEffect(() => {
    loadAccounts();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    sortAccounts(props.account.list);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.account.ascOrDesc, props.account.order]);

  function addAccount() {
    store.dispatch(userGet([]));
    setCurrentModal(MODAL_ADD);
  }

  function addAdmin(account) {
    store.dispatch(accountActivate(account));
    store.dispatch(userGet(account.Users));
    setCurrentModal(MODAL_ADD_ADMIN);
  }

  function closeTemplateModal(templateIds) {
    setCurrentModal(null);
    editRule("list", templateIds);
  }

  function editAccount(account: Account) {
    store.dispatch(accountActivate(account));
    store.dispatch(userGet(account.Users));
    setCurrentModal(MODAL_EDIT);
  }

  //Click on "connect to account"
  //Open dialog to confirm
  function clickAccount(account: Account) {
    store.dispatch(accountActivate(account));
    setCurrentModal(MODAL_AUTH_CONFIRM);
  }

  //click on a list cell, if already current reverse order else set as current order ASC
  function clickListCell(fieldname: AccountFields) {
    if (props.account.order === fieldname) {
      const reverse: "asc" | "desc" =
        props.account.ascOrDesc === "asc" ? "desc" : "asc";
      store.dispatch(accountOrder(reverse, props.account.order));
    } else {
      store.dispatch(accountOrder(props.account.ascOrDesc, fieldname));
    }
  }

  //when closing edit account modal
  async function close(withReload: boolean) {
    if (withReload) {
      await loadAccounts();
    }
    setCurrentModal(null);
  }

  function clickRule(e, rule: AccountTemplateRule) {
    e.stopPropagation();
    setShowRuleContext(false);
    if (rule === "list") {
      setCurrentModal(MODAL_TEMPLATES);
    } else {
      editRule(rule, []);
    }
  }

  async function editRule(rule: AccountTemplateRule, templateIds: string[]) {
    store.dispatch(accountEdit("templateRule", rule));
    store.dispatch(accountEdit("allowedTemplates", templateIds));
    store.dispatch(accountStatus(STATUS_SAVING));
    await store.dispatch(
      accountUpdateTemplateRules(props.account.active.id, rule, templateIds)
    );
    store.dispatch(accountStatus(STATUS_SAVED));
  }

  function editTemplateList(account) {
    store.dispatch(accountActivate(account));
    setCurrentModal(MODAL_TEMPLATES);
  }

  //Get color according rule
  function getRuleColor(rule: AccountTemplateRule) {
    switch (rule) {
      case "all":
        return "#20CA7E";
      case "list":
        return props._session.accountColors.brandPrimary;
      case "blocked":
        return "#eb5a46";
      default:
        return "";
    }
  }

  //show arrow of list cell
  function getOrder(fieldname: AccountFields) {
    if (props.account.order === fieldname) {
      return props.account.ascOrDesc;
    } else {
      return undefined;
    }
  }

  function getRuleName(rule: AccountTemplateRule) {
    return t("template_rule_" + rule.toLocaleLowerCase(), {
      name: props._session.accountName,
    });
  }

  //Fetch accounts from backend and get count
  //If there is a search update count
  async function loadAccounts() {
    store.dispatch(accountStatus(STATUS_SEARCHING));

    //Do query (get list and count)
    const accounts: any = await store.dispatch(accountFetch_AsSuperadmin());
    const accountsCount: any = await store.dispatch(
      accountFetchCount_AsSuperadmin(/*currentSearch*/ "")
    );

    setIsLoaded(true);

    if (accounts.error || accountsCount.error) {
      store.dispatch(accountStatus(STATUS_LOAD_ERROR));
    } else {
      //Update list
      store.dispatch(accountStatus(STATUS_LOADED));
      sortAccounts(accounts);

      //Update store
      store.dispatch(accountGetCountSearch(accountsCount.count));
      if (!currentSearch.length)
        store.dispatch(accountGetCount(accountsCount.count));
    }
  }

  function openRuleContext(account) {
    store.dispatch(accountActivate(account));
    setShowRuleContext(true);
  }

  function searchAccount(search: string) {
    setCurrentSearch(search);
  }

  function sortAccounts(accountList: Account[]) {
    if (props.account.order) {
      store.dispatch(
        accountGet(
          orderBy(accountList, props.account.order, props.account.ascOrDesc)
        )
      );
    }
  }

  return (
    <div>
      {currentAccountId && (
        <LoginModal
          authLevel={2}
          onClose={() => setCurrentAccountId(null)}
          accountIdForSuperadmin={currentAccountId}
          email={props._session.email}
          isSupervisor={props._session.connectedAsSupervisor}
        />
      )}

      {currentModal === MODAL_ADD && (
        <EditAccountModal
          isNew
          onClose={(withReload: boolean) => close(withReload)}
        />
      )}

      {currentModal === MODAL_EDIT && (
        <EditAccountModal
          isNew={false}
          onClose={(withReload: boolean) => close(withReload)}
        />
      )}

      {currentModal === MODAL_ADD_ADMIN && (
        <AdmEditChildrenModal isNew onClose={() => setCurrentModal(null)} />
      )}

      {currentModal === MODAL_TEMPLATES && (
        <EditTemplateRulesModal onClose={closeTemplateModal} />
      )}

      {currentModal === MODAL_AUTH_CONFIRM && (
        <Modal
          isCloseButtonVisible
          onClose={() => setCurrentModal(null)}
          onNext={() => {
            setCurrentModal(null);
            setCurrentAccountId(props.account.active.id);
          }}
        >
          <p>
            {t("account_connect")} : <b>{props.account.active.name}</b>
          </p>
        </Modal>
      )}

      <PageLoader status={isLoaded ? STATUS_LOADED : STATUS_LOADING}>
        {props.account.count > 0 ? (
          <Card isWithoutPadding>
            <ListContainer
              countSearch={props.account.countSearch}
              onAddPrimary
              onAddTitle={t("account_add")}
              onAdd={
                !props.editTemplateRulesOnly ? () => addAccount() : undefined
              }
              onSearch={searchAccount}
              status={props.account.status}
            >
              <ListFields>
                <ListCell />
                <ListCell
                  width={200}
                  text={t("user_lastname")}
                  order={getOrder("name")}
                  onClick={() => clickListCell("name")}
                />
                {props.editTemplateRulesOnly ? (
                  <ListCell width={300} text={t("utils_permissions")} />
                ) : (
                  <ListCell
                    width={150}
                    text={t("user_admins")}
                    order={getOrder("Users")}
                    onClick={() => clickListCell("Users")}
                  />
                )}
                <Space />
                <ListCell
                  width={150}
                  text={t("account_date_start")}
                  order={getOrder("dateStart")}
                  onClick={() => clickListCell("dateStart")}
                />
                <ListCell width={props.editTemplateRulesOnly ? 118 : 88} />
              </ListFields>

              <div
                style={{
                  minHeight: "380px",
                }}
              >
                {props.account.list
                  .filter((account: Account) =>
                    account.name.toLowerCase().includes(currentSearch)
                  )
                  .map((account: Account, i: number) => (
                    <ListItem
                      isEditable
                      onClick={() => clickAccount(account)}
                      key={account.id}
                    >
                      <ListIcon
                        fallbackIcon={faShieldAlt}
                        iconColor={account.colors.brandPrimary}
                        image={
                          account.options.image
                            ? env.REACT_APP_URL_SPACE.concat(
                                "/Accounts/",
                                account.id,
                                ".png"
                              )
                            : getIconUrl(account.options.defaultIcon)
                        }
                      />

                      <ListCell width={200} text={account.name} />

                      {props.editTemplateRulesOnly ? (
                        <ListCell width={300}>
                          <div className="rel">
                            <Chip
                              onClick={() => openRuleContext(account)}
                              onInfo={
                                account.templateRule === "list"
                                  ? () => editTemplateList(account)
                                  : undefined
                              }
                              color={getRuleColor(account.templateRule)}
                            >
                              {getRuleName(account.templateRule)}
                              {account.templateRule === "list" && (
                                <span>&nbsp;</span>
                              )}
                            </Chip>

                            {showRuleContext &&
                              props.account.active.id === account.id && (
                                <div className="rel" style={{ zIndex: 1 }}>
                                  <ContextMenu
                                    onClose={() => setShowRuleContext(false)}
                                    positionBottom={i > 2}
                                  >
                                    {TEMPLATE_RULE_LIST.map((rule) => (
                                      <ListItem
                                        key={rule}
                                        isSmall
                                        isEditable
                                        onClick={(e) => clickRule(e, rule)}
                                        isActive={rule === account.templateRule}
                                      >
                                        {getRuleName(rule)}
                                      </ListItem>
                                    ))}
                                  </ContextMenu>
                                </div>
                              )}
                          </div>
                        </ListCell>
                      ) : (
                        <ListCell width={150}>
                          <Chip
                            onClick={() => addAdmin(account)}
                            color={account.Users.length === 0 ? "#eb5a46" : ""}
                          >
                            {account.Users.length}
                          </Chip>
                        </ListCell>
                      )}

                      <Space />

                      <ListCell width={150} text={account.dateStartLabel} />

                      {props.editTemplateRulesOnly ? (
                        <SaveIcon
                          status={
                            props.account.active.id === account.id
                              ? props.account.status
                              : null
                          }
                        />
                      ) : (
                        <ListButton
                          icon={faPencilAlt}
                          onClick={() => editAccount(account)}
                          text={t("account_edit")}
                        />
                      )}

                      <ListButton
                        icon={faPlayCircle}
                        onClick={() => clickAccount(account)}
                        isPrimary
                        text={t("utils_connect")}
                      />
                    </ListItem>
                  ))}
              </div>
            </ListContainer>
          </Card>
        ) : (
          <PagePlaceholder
            image="home-adm"
            button={t("account_add")}
            onClick={() => addAccount()}
            title={t("account_empty")}
          />
        )}
      </PageLoader>
    </div>
  );
}

const mapStateToProps = (state) => ({
  _session: state._session,
  account: state.account,
});

export default connect(mapStateToProps)(withTranslation()(AccountsListWidget));
