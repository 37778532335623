/**
 * LOGIN-SUPERVISOR
 * Connexion as supervisor
 */

import { useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import TextInput from "@/components/text-input";
import Button from "@/components/button";
import Card from "@/components/card";
import NavLogin from "@/nav/nav.login";
import { SupervisorState } from "@/redux/supervisor.types";
import { STATUS_SAVING } from "@/redux/_status.types";
import LoginIconWidget from "@/widgets/login-icon.widget";
import Space from "@/components/space";
import Link from "@/components/link";
import LoginModal from "@/modals/login.modal";
import { Session } from "@/redux/_session.types";
import { store } from "@/index";
// import store from "@/core/store";
import { sessionGet } from "@/redux/_session.actions";
import { NavigateFunction, useNavigate } from "react-router-dom";

interface Props extends WithTranslation {
  supervisor: SupervisorState;
}

const MODAL_LOGIN = "MODAL_LOGIN";

function LoginSupervisor(props: Props) {
  const { t } = props;
  const navigate: NavigateFunction = useNavigate();

  const [currentModal, setCurrentModal] = useState<string | null>(null);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  //Submit form
  function handleSubmit(event: any) {
    event.preventDefault();
    if (validateForm()) {
      //Save email adress in session
      const _session = new Session();
      _session.email = email;
      store.dispatch(sessionGet(_session));

      //Launch login
      setCurrentModal(MODAL_LOGIN);
    }
  }

  //Validation of the form (detect if no informations provided)
  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  return (
    <NavLogin>
      {currentModal === MODAL_LOGIN && (
        <LoginModal
          authLevel={2}
          email={email}
          isSupervisor
          toSupervisor
          password={password}
          onClose={() => setCurrentModal(null)}
        />
      )}

      <LoginIconWidget />

      <div className="flex">
        <Space />

        <div>
          <Card title={t("utils_connect_idtree")}>
            <div>
              <form className="flex flex-dcol" onSubmit={handleSubmit}>
                <TextInput
                  title={t("user_email")}
                  id="email"
                  isFullWidth
                  value={email}
                  onChange={(event: any) => setEmail(event.value)}
                  type="email"
                  autoFocus
                />

                <TextInput
                  autoComplete="current-password"
                  title={t("utils_password")}
                  id="password"
                  isFullWidth
                  value={password}
                  onChange={(event: any) => setPassword(event.value)}
                  type="password"
                  autoFocus
                />

                <div className="height-20" />

                <Button
                  type="submit"
                  isLarge
                  isLoading={props.supervisor.status === STATUS_SAVING}
                  className="primary"
                  isFullWidth
                >
                  {t("utils_connect")}
                </Button>

                <p className="red-t" style={{ width: "240px" }}>
                  <b>{t("supervisor_connect")}</b>
                </p>
              </form>
            </div>
          </Card>

          {/** link à the bottom */}
          <div className="flex">
            <Space />
            <Link isWhite onClick={() => navigate("/login/home")}>
              {t("supervisor_connect_account")}
            </Link>
            <Space />
          </div>
        </div>
        <div className="flex1"></div>
      </div>

      <div className="flex1" />
    </NavLogin>
  );
}

const mapStateToProps = (state) => ({
  supervisor: state.supervisor,
});

export default connect(mapStateToProps)(withTranslation()(LoginSupervisor));
