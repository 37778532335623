/**
 * TEMPLATE-PREVIEW.HELP
 * Aperçu du tableau de bord
 */
import HelpModal from "@/components/modal-help";
import { store } from "@/index";
// import store from "@/core/store";
import { sum } from "lodash";
import { useEffect, useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { AxisState } from "@/redux/axis.types";
import { FilterState } from "@/redux/filter.types";
import { surveyActivate } from "@/redux/survey.actions";
import { Survey, SurveyState } from "@/redux/survey.types";
import { TemplateState } from "@/redux/template.types";
import { TopicState } from "@/redux/topic.types";
import DashboardResultsWidget from "@/widgets/dashboard-results.widget";

interface StateProps extends WithTranslation {
  axis: AxisState;
  filter: FilterState;
  template: TemplateState;
  topic: TopicState;
  survey: SurveyState;
}

interface OwnProps {
  onClose: Function;
}

type Props = StateProps & OwnProps;

function TemplatePreviewHelp(props: Props) {
  const { t } = props;

  //Show widget (delay after survey activate)
  const [showWidget, setShowWidget] = useState(false);

  //Get count of secondary questions
  const secondaryCount = sum(
    props.topic.list.map((x) => x.Questions.length - 1)
  );

  useEffect(() => {
    function init() {
      const survey = new Survey({
        randomData: true,
        template: props.template.active,
        /*topics : props.topic.list*/
      });

      store.dispatch(surveyActivate(survey));

      setTimeout(() => {
        setShowWidget(true);
      }, 200);
    }

    init();

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <HelpModal onClose={props.onClose} title={t("dashboard_preview")}>
      <div className="flex grey-t" style={{ fontSize: "12px" }}>
        <div className="flex1">
          {t("questions_primary")} :{" "}
          <b>{props.template.active.topicsOrder.length}</b>
        </div>

        <div className="flex1">
          {t("questions_secondary")} : <b>{secondaryCount}</b>
        </div>
      </div>

      <div className="height-20" />

      {showWidget && (
        <DashboardResultsWidget
          exportName=""
          currentAxes={props.axis.list}
          currentSurvey={props.survey.active}
          currentTopics={props.topic.list}
          dashboardFilters={props.filter.dashboard}
          isPreview
        />
      )}
    </HelpModal>
  );
}

const mapStateToProps = (state) => ({
  axis: state.axis,
  filter: state.filter,
  template: state.template,
  topic: state.topic,
  survey: state.survey,
});

export default connect(mapStateToProps)(withTranslation()(TemplatePreviewHelp));
