/**
 * EDITOR-AXES-OPTIONS
 * Options for editing an axis
 */
import { withTranslation, WithTranslation } from "react-i18next";
import AddAxisModal from "@/modals/add-axis.modal";
import EditAxisModal from "@/modals/edit-axis.modal";
import ListButton from "@/components/list-button";
import {
  faChevronDown,
  faChevronUp,
  faEdit,
  faEllipsisV,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { Axis, AxisState } from "@/redux/axis.types";
import { store } from "@/index";
// import store from "@/core/store";
import { axisActivate, axisSwapAndUpdateTemplate } from "@/redux/axis.actions";
import { connect } from "react-redux";
import { Session } from "@/redux/_session.types";
import DeleteAxisModal from "@/modals/delete-axis.modal";

interface StateProps extends WithTranslation {
  _session: Session;
  axis: AxisState;
}

interface OwnProps extends WithTranslation {
  currentAxis: Axis;
}

type Props = StateProps & OwnProps;

const ACTION_AXIS_ADD = "ACTION_AXIS_ADD";
const ACTION_AXIS_EDIT = "ACTION_AXIS_EDIT";
const ACTION_AXIS_DELETE = "ACTION_AXIS_DELETE";
const ACTION_AXIS_DOWN = "ACTION_DOWN_AXIS";
const ACTION_AXIS_UP = "ACTION_UP_AXIS";
const MODAL_ADD_AXIS = "MODAL_ADD_AXIS";
const MODAL_EDIT_AXIS = "MODAL_EDIT_AXIS";
const MODAL_DELETE_AXIS = "MODAL_DELETE_AXIS";

function EditorAxesOptions(props: Props) {
  const { t } = props;

  //Current dialog active
  const [currentModal, setCurrentModal] = useState<string | null>(null);

  //Click on one of the options
  //Save axisIndex (for up or down) and activate axis
  function clickAction(action) {
    const axisIndex = getAxisIndex();
    store.dispatch(axisActivate(props.currentAxis));

    switch (action) {
      case ACTION_AXIS_ADD:
        return setCurrentModal(MODAL_ADD_AXIS);
      case ACTION_AXIS_EDIT:
        return setCurrentModal(MODAL_EDIT_AXIS);
      case ACTION_AXIS_UP:
        return swapAxis(axisIndex - 1);
      case ACTION_AXIS_DOWN:
        return swapAxis(axisIndex + 1);
      case ACTION_AXIS_DELETE:
        return setCurrentModal(MODAL_DELETE_AXIS);
    }
  }

  //List of action possibles
  //show "up" if it's not the first
  //show "down" if it's not the last
  function getAxesActions() {
    const axisIndex = getAxisIndex();

    return [
      { id: ACTION_AXIS_ADD, label: t("axis_add"), icon: faPlus },
      { id: ACTION_AXIS_EDIT, label: t("axis_edit"), icon: faEdit },
      {
        id: ACTION_AXIS_UP,
        label: t("axis_up"),
        icon: faChevronUp,
        isInactive: axisIndex === 0,
      },
      {
        id: ACTION_AXIS_DOWN,
        label: t("axis_down"),
        icon: faChevronDown,
        isInactive: axisIndex === props.axis.list.length - 1,
      },
      { id: ACTION_AXIS_DELETE, label: t("axis_delete"), icon: faTrash },
    ];
  }

  //Get axis index
  function getAxisIndex() {
    return props.axis.list.findIndex((x) => x.id === props.currentAxis?.id);
  }

  //Swap axis and save template (axesOrder column)
  function swapAxis(newIndex) {
    if (props.currentAxis) {
      store.dispatch(
        axisSwapAndUpdateTemplate(
          props.currentAxis.id,
          props.axis.list[newIndex].id
        )
      );
    }
  }

  return (
    <div>
      {currentModal === MODAL_ADD_AXIS && (
        <AddAxisModal onClose={() => setCurrentModal(null)} />
      )}

      {currentModal === MODAL_EDIT_AXIS && (
        <EditAxisModal onClose={() => setCurrentModal(null)} />
      )}

      {currentModal === MODAL_DELETE_AXIS && (
        <DeleteAxisModal onClose={() => setCurrentModal(null)} />
      )}

      <ListButton
        icon={faEllipsisV}
        actions={getAxesActions()}
        onAction={(actionId) => clickAction(actionId)}
        text={t("utils_options")}
      />
    </div>
  );
}
const mapStateToProps = (state) => ({
  _session: state._session,
  axis: state.axis,
});

export default connect(mapStateToProps)(withTranslation()(EditorAxesOptions));
