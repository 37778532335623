/**
 * REPORT-SCREENSHOT-AXES.WIDGET.TSX
 * Preview of the slide
 */
import { connect } from "react-redux";
import { Session } from "@/redux/_session.types";
import { Survey } from "@/redux/survey.types";
import { useEffect, useState } from "react";
import DashboardAxesOverviewWidget from "./dashboard-axes-overview.widget";
import { Axis, AxisState } from "@/redux/axis.types";
import formatAxisResults from "@/utils/format-axis-results.utils";
import { store } from "@/index";
// import store from "@/core/store";
import { fetchAxes, fetchTopics } from "@/redux/_archive.actions";
import i18n from "@/translate/i18n";
import { pageInitDashboardFilters } from "@/redux/page.actions";
import { Topic, TopicState } from "@/redux/topic.types";
import getArchiveTopic from "@/utils/get-archive-topic.utils";
import PageLoader from "@/components/page-loader";
import { STATUS_LOADED, STATUS_LOADING } from "@/redux/_status.types";
import Space from "@/components/space";
import { Page, PageState } from "@/redux/page.types";

interface StateProps {
  _session: Session;
  axis: AxisState;
  topic: TopicState;
  page: PageState;
}

interface OwnProps {
  currentSurvey: Survey;
  isFullWidth?: boolean;
  currentPage: Page;
}

type Props = StateProps & OwnProps;

function ReportScreenshotAxesWidget(props: Props) {
  const [axes, setAxes] = useState<Axis[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function loadData() {
      setIsLoading(true);

      const dashboardFilters = pageInitDashboardFilters(
        props.currentPage.options.populations
      );

      const topicResponse = await store.dispatch(
        fetchTopics(
          dashboardFilters,
          props._session.accountOptions.topicRepartitionCeil,
          props.currentSurvey.id,
          props.currentSurvey.dateStart
        )
      );

      //Fetch axes
      const axesResponse: any = await store.dispatch(
        fetchAxes(
          dashboardFilters,
          i18n.language,
          props._session.dashboardDisplaySettings.dashboardDisplayMode ===
            "satisfaction",
          props.currentSurvey.id,
          props.currentSurvey.dateStart
        )
      );

      //Init new topics for survey topics
      //And update note for topics
      const newTopics: Topic[] = Array.from(props.topic.list, (topic: Topic) =>
        getArchiveTopic(topic, topicResponse)
      );

      //Update axes with note (calculte from topic list)
      const newAxes: Axis[] = Array.from(props.axis.list, (axis: Axis) =>
        formatAxisResults(
          axis,
          newTopics,
          axesResponse,
          props._session.accountId,
          props.currentSurvey.randomData
        )
      );

      //Update axis list
      setAxes(newAxes.map((x: Axis) => new Axis(x)));
      setIsLoading(false);
    }

    if (props.currentPage.id === props.page.active.id || isLoading) {
      loadData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.page.active.options.populations,
    props.currentPage.id,
    props.page.active.id,
  ]);

  return (
    <div
      className="flex"
      style={{
        transform: "scale(0.90)",
        transformOrigin: "top left",
      }}
    >
      <Space />
      <PageLoader status={isLoading ? STATUS_LOADING : STATUS_LOADED}>
        <DashboardAxesOverviewWidget
          axes={axes}
          isFullWidth={props.isFullWidth}
          isPreview
        />
      </PageLoader>
      <Space />
    </div>
  );
}
const mapStateToProps = (state) => ({
  _session: state._session,
  axis: state.axis,
  topic: state.topic,
  page: state.page,
});

export default connect(mapStateToProps)(ReportScreenshotAxesWidget);
