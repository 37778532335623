/**
 * DASHBOARD-QUESTION-OPEN.WIDGET
 * Display open question with messages
 */

import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import PageLoader from "@/components/page-loader";
import { Message, MESSAGE_CEIL_COUNT_SUMMARY } from "@/redux/message.types";
import { STATUS_LOADED, STATUS_LOADING } from "@/redux/_status.types";
import { Question } from "@/redux/question.types";
import Card from "@/components/card";
import ListButton from "@/components/list-button";
import {
  faCaretDown,
  faCaretRight,
  faCircleNotch,
  faComment,
  faPen,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import Space from "@/components/space";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Topic } from "@/redux/topic.types";
import { orderBy } from "lodash";
import { Session } from "@/redux/_session.types";
import TextInput from "@/components/text-input";
import makeInsensitive from "@/utils/make-insensitive.utils";
import DashboardMessageWidget from "./dashboard-message.widget";
import Tabs from "@/components/tabs";
import { store } from "@/index";
// import store from "@/core/store";
import { FilterState } from "@/redux/filter.types";
import { fetchMessages } from "@/redux/_archive.actions";
import { SurveyState } from "@/redux/survey.types";
import { generateMessages } from "@/redux/_generate.actions";
import { messageUpdateColor } from "@/redux/message.actions";
import ReactTooltip from "react-tooltip";
import { Tag } from "@/redux/account.types";
import ListItem from "@/components/list-item";
import EditTagsModal from "@/modals/edit-tags.modal";
import { accountUpdateColors } from "@/redux/account.actions";
import { sessionEditAccountColors } from "@/redux/_session.actions";
import Chip from "@/components/chip";
import DashboardMessagesCloudWidget from "./dashboard-messages-cloud.widget";
import DashboardSummarizeWidget from "./dashboard-summarize.widget";
import UpgradeWidget from "./upgrade.widget";
import { Population } from "@/redux/population.types";
import { getSegmentationPopulations } from "@/utils/get-segmentation-populations.utils";
import Link from "@/components/link";
import Modal from "@/components/modal";
import Checkbox from "@/components/checkbox";
import { questionUpdatePrompt } from "@/redux/question.actions";

interface StateProps extends WithTranslation {
  _session: Session;
  filter: FilterState;
  survey: SurveyState;
}

interface OwnProps {
  currentQuestion: Question;
  currentTopic: Topic;
  count: number;
  isOpenByDefault?: boolean;
  showSegmentation?: boolean;
}

type Props = StateProps & OwnProps;

const TAB_SUMMARY: string = "TAB_SUMMARY";
const TAB_ALL: string = "TAB_ALL";

const MODAL_EDIT_PROMPT: string = "MODAL_EDIT_PROMPT";
const MODAL_EDIT_TAGS: string = "MODAL_EDIT_TAGS";

function DashboardQuestionOpenWidget(props: Props) {
  const { t } = props;

  //Current modal
  const [currentModal, setCurrentModal] = useState<string | null>(null);

  //Current tab (summary or all). Il not enough comment, TAB_ALL only is active
  const [currentTab, setCurrentTab] = useState<string>(TAB_ALL);

  //List of message to display for the question
  const [messages, setMessages] = useState<Message[]>([]);

  //Open question
  const [open, setOpen] = useState<boolean>(
    props.isOpenByDefault ? true : false
  );

  //Custom prompt
  const [prompt, setPrompt] = useState(props.currentQuestion.prompt);
  const [isPromptCustom, setIsPromptCustom] = useState(
    props.currentQuestion.isPromptCustom
  );

  //Text for the search (for filtering in messages)
  const [search, setSearch] = useState<string>("");

  //Selected color for a filter (show only message with a specific color tag)
  const [selectedTag, setSelectedTag] = useState<Tag | null>(null);

  //Status loading
  const [status, setStatus] = useState<string>(STATUS_LOADED);

  //List of messages
  const [displayedMessages, setDisplayedMessages] = useState<Message[]>([]);

  //Update messages to display
  useEffect(() => {
    if (selectedTag) {
      setDisplayedMessages(
        messages.filter((x) => x.color === selectedTag.color)
      );
    } else if (search.length) {
      const insensitiveSearch = makeInsensitive(search);
      setDisplayedMessages(
        messages.filter(
          (x) => makeInsensitive(x.text).indexOf(insensitiveSearch) > -1
        )
      );
    } else {
      setDisplayedMessages(messages);
    }
  }, [messages, selectedTag, search]);

  //Load messages
  //- when question is open
  //- when tab is switched
  //- when filters are changed
  useEffect(() => {
    async function load() {
      if (open) {
        setStatus(STATUS_LOADING);

        //Fetch all messages
        if (currentTab === TAB_ALL) {
          const response: any = props.survey.active.randomData
            ? await store.dispatch(
                generateMessages(props.filter.dashboard, false)
              )
            : await store.dispatch(
                fetchMessages(
                  props.filter.dashboard,
                  props.currentQuestion.aid,
                  props.currentTopic.aid
                )
              );

          if (!response.error) {
            setMessages(
              orderBy(
                response.map((x) => new Message(x)),
                "color"
              )
            );
          }
        }

        //End loading
        setStatus(STATUS_LOADED);
      }
    }

    load();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab, open, props.filter.dashboard]);

  //click on caret in order to open question
  function clickOpen() {
    setOpen(!open);
  }

  function savePrompt() {
    store.dispatch(
      questionUpdatePrompt(props.currentQuestion.aid, isPromptCustom, prompt)
    );
    setCurrentModal(null);
  }

  function getCount(color: string) {
    return messages.filter(
      (message: Message) =>
        message.color && message.color.toUpperCase() === color
    ).length;
  }

  function displaySummaryTab() {
    return (
      props.count > MESSAGE_CEIL_COUNT_SUMMARY &&
      props._session.interfaceType !== "SUPERADMIN"
    );
  }

  async function saveColors(tags: Tag[]) {
    const response: any = await store.dispatch(
      accountUpdateColors({ ...props._session.accountColors, tags })
    );
    if (!response.error) {
      store.dispatch(sessionEditAccountColors("tags", tags));
    }
  }

  //Set color for a message
  function setColor(message: Message, color: string) {
    message.color = color;
    setMessages(
      messages.map((x: Message) => (x.aid === message.aid ? message : x))
    );

    if (props._session.authLevel > 0 && !props.survey.active.randomData) {
      store.dispatch(messageUpdateColor(message.aid, color));
    }
  }

  //Set color in order to filter by tag
  //If click again : disable color
  function setColorFilter(tag: Tag | null) {
    setSelectedTag(
      !tag || (selectedTag && tag.color === selectedTag.color) ? null : tag
    );
  }

  return props._session.accountId === "6654c17d-5678-4277-a4ff-04d43b255fa5" &&
    props._session.userRole === "OBSERVER" ? null : (
    <Card isWithoutPadding exportName={props.currentQuestion.label}>
      {currentModal === MODAL_EDIT_PROMPT && (
        <Modal
          onClose={() => setCurrentModal(null)}
          onNext={() => savePrompt()}
          isCloseButtonVisible
        >
          <Checkbox
            active={isPromptCustom}
            text="Utiliser un prompt personnalisé."
            onClick={() => {
              if (!isPromptCustom && prompt === "") {
                setPrompt(t("dashboard_question_custom_prompt_default"));
              }
              setIsPromptCustom(!isPromptCustom);
            }}
          />

          {isPromptCustom && (
            <div style={{ marginTop: 24 }}>
              <p className="grey-t">Intitulé du prompt</p>

              <textarea
                className="text-area"
                style={{ height: "120px" }}
                onChange={(e) => setPrompt(e.target.value)}
                name="description"
                value={prompt}
              ></textarea>
            </div>
          )}
        </Modal>
      )}

      {currentModal === MODAL_EDIT_TAGS && (
        <EditTagsModal
          tags={props._session.accountColors.tags}
          onClose={() => setCurrentModal(null)}
          onSave={saveColors}
        />
      )}

      <div
        className="_hover"
        onClick={clickOpen}
        style={{ padding: "20px 30px" }}
      >
        <div className="flex">
          <ListButton
            icon={open ? faCaretDown : faCaretRight}
            onClick={clickOpen}
          />

          <div>
            {props.currentQuestion.primaryId && (
              <div className="grey-t" style={{ marginBottom: "6px" }}>
                {t("question_primary")} :{" "}
                {
                  props.currentQuestion.getQuestionPrimary(
                    props.currentTopic.Questions
                  ).label
                }
              </div>
            )}

            <b>{props.currentQuestion.label}</b>
          </div>

          <Space />

          {props.count > 0 && (
            <div className="grey-t flex" style={{ minWidth: 48 }}>
              <Space />
              <FontAwesomeIcon icon={faComment} />
              &nbsp;
              {props.count}
            </div>
          )}
        </div>

        <div
          className="flex message-axis"
          style={{ color: props.currentTopic.Axis?.color }}
        >
          {props.currentTopic.label}
        </div>

        <div className="grey-t message-axis" style={{ fontSize: 12 }}>
          {props.showSegmentation &&
            getSegmentationPopulations(props.currentTopic.AxisId).map(
              (population: Population) =>
                `${population.filterName}: ${population.name}`
            )}
        </div>
      </div>

      {open && (
        <>
          {displaySummaryTab() && (
            <div style={{ padding: "0px 30px" }} className="flex">
              <Tabs
                active={currentTab}
                onClick={(tab: any) =>
                  currentTab !== tab ? setCurrentTab(tab) : {}
                }
                tabs={{
                  TAB_ALL: t("message_all"),
                  TAB_SUMMARY: t("message_synthesis"),
                }}
              />
              <Space />

              {props._session.connectedAsSupervisor &&
                currentTab === "TAB_SUMMARY" && (
                  <Link onClick={() => setCurrentModal(MODAL_EDIT_PROMPT)}>
                    Modifier le prompt (Gestionnaire IDTree uniquement)
                  </Link>
                )}
            </div>
          )}

          {currentTab === TAB_SUMMARY ? (
            /*ChatGPT*/
            props._session.modules.assistant ? (
              <div style={{ padding: "10px 40px" }}>
                {status === STATUS_LOADING ? (
                  <div className="flex">
                    <Space />
                    <FontAwesomeIcon icon={faCircleNotch} spin />
                    <Space />
                  </div>
                ) : (
                  <>
                    {messages.length < MESSAGE_CEIL_COUNT_SUMMARY ? (
                      <div>
                        {t("message_synthesis_insufficient", {
                          min: MESSAGE_CEIL_COUNT_SUMMARY,
                        })}
                      </div>
                    ) : (
                      <DashboardSummarizeWidget
                        comments={messages.map(
                          (message: Message) => message.text
                        )}
                        isPromptCustom={props.currentQuestion.isPromptCustom}
                        prompt={props.currentQuestion.prompt}
                        questionLabel={props.currentQuestion.label}
                      />
                    )}
                  </>
                )}

                <div className="height-20" />
              </div>
            ) : (
              <UpgradeWidget feature="assistant" />
            )
          ) : (
            <div style={{ marginBottom: "20px" }}>
              <DashboardMessagesCloudWidget
                active={search}
                currentQuestionAid={props.currentQuestion.aid!}
                currentTopicAid={props.currentTopic.aid!}
                onClick={(word: string) => setSearch(word)}
              />

              <PageLoader status={status}>
                <div
                  className="flex"
                  style={{
                    marginLeft: "50px",
                    height: "110px",
                  }}
                >
                  <TextInput
                    value={search}
                    onChange={(e) => setSearch(e.value)}
                    title={t("utils_search")}
                  />

                  <Space />

                  <div
                    style={{
                      paddingRight: "30px",
                    }}
                  >
                    <p className="grey-t">{t("message_filter")}</p>

                    <div className="flex">
                      {props._session.accountColors.tags
                        .filter((tag: Tag) => getCount(tag.color) > 0)
                        .map((tag: Tag) => (
                          <div
                            key={tag.color}
                            data-tip={tag.name}
                            className="messages-colors _hover"
                            onClick={() => setColorFilter(tag)}
                            style={{ backgroundColor: tag.color }}
                          >
                            {selectedTag && selectedTag.color === tag.color ? (
                              <div className="messages-colors-active" />
                            ) : (
                              <div
                                style={{
                                  textAlign: "center",
                                  color: "white",
                                  marginTop: 2,
                                }}
                              >
                                {getCount(tag.color)}
                              </div>
                            )}
                          </div>
                        ))}

                      {selectedTag ? (
                        <div
                          className="messages-colors _hover flex"
                          onClick={() => setColorFilter(null)}
                          style={{ border: "1px solid #8C8C8C" }}
                        >
                          <FontAwesomeIcon icon={faTimes} />
                        </div>
                      ) : (
                        <div
                          className="messages-colors _hover flex"
                          onClick={() => {
                            setCurrentModal(MODAL_EDIT_TAGS);
                          }}
                          style={{ border: "1px solid #8C8C8C" }}
                        >
                          <FontAwesomeIcon icon={faPen} />
                        </div>
                      )}
                    </div>

                    <ReactTooltip />
                  </div>
                </div>

                {selectedTag && (
                  <ListItem>
                    <Chip
                      color={selectedTag.color}
                      count={getCount(selectedTag.color)}
                    >
                      {selectedTag.name}
                    </Chip>
                  </ListItem>
                )}

                {displayedMessages.map((message: Message, i: number) => (
                  <DashboardMessageWidget
                    key={i}
                    search={search}
                    id={(i + 1).toString()}
                    currentMessage={message}
                    currentQuestion={props.currentQuestion}
                    currentTopic={props.currentTopic}
                    onSetColor={(color) => setColor(message, color)}
                  />
                ))}
              </PageLoader>
            </div>
          )}
        </>
      )}
    </Card>
  );
}

const mapStateToProps = (state) => ({
  _session: state._session,
  filter: state.filter,
  survey: state.survey,
});

export default connect(mapStateToProps)(
  withTranslation()(DashboardQuestionOpenWidget)
);
