/**
 * ADM-SELF
 * Edit options for the connected user
 */
import { useEffect, useRef, useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Session, SESSION_LANGUAGES } from "@/redux/_session.types";
import PageHeader from "@/components/page-header";
import Dropdown from "@/components/dropdown";
import NavFocus from "@/nav/nav.focus";
import TextInput from "@/components/text-input";
import ImagePicker from "@/components/image-picker";
import Card from "@/components/card";
import { store } from "@/index";
// import store from "@/core/store";
import { sessionEdit, sessionEditUserOptions } from "@/redux/_session.actions";
import {
  userStatus,
  userUpdate,
  userUpdateLanguage,
  userUpdateOptions,
  userUpdate_AsEmployee,
} from "@/redux/user.actions";
import {
  STATUS_SAVED,
  STATUS_SAVE_ERROR,
  STATUS_SAVING,
} from "@/redux/_status.types";
import { UserState } from "@/redux/user.types";
import { firebaseAppAuth } from "@/App";
import { toast } from "react-toastify";
import SetPasswordModal from "@/modals/set-password.modal";
import Space from "@/components/space";
import i18n from "@/translate/i18n";
import { sendPasswordResetEmail } from "firebase/auth";

interface StateProps extends WithTranslation {
  user: UserState;
  _session: Session;
}

const MODAL_SET_PASSWORD = "MODAL_SET_PASSWORD";

function AdmSelfRoute(props: StateProps) {
  const { t } = props;

  //Current modal displayed
  const [currentModal, setCurrentModal] = useState<string | null>(null);

  //Detect if component is load
  const isLoad = useRef(false);

  //Save options
  //Not on component load but when image is edited
  useEffect(() => {
    if (isLoad.current) {
      saveOptions();
    } else {
      isLoad.current = true;
    }

    async function saveOptions() {
      store.dispatch(userStatus(STATUS_SAVING));
      const response: any = await store.dispatch(
        userUpdateOptions(props._session.userId, props._session.userOptions)
      );
      store.dispatch(
        userStatus(response.error ? STATUS_SAVE_ERROR : STATUS_SAVED)
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props._session.userId, props._session.userOptions.image]);

  function handleChanges(event) {
    store.dispatch(sessionEdit(event.id, event.value));
  }

  function resetPassword() {
    sendPasswordResetEmail(firebaseAppAuth, props._session.email)
      .then(() => {
        toast(t("self_password_send", { email: props._session.email }));
      })
      .catch((error) => {
        toast(t(error.code), { type: "error" });
      });
  }

  //Save user data
  //Créer a user object as form element to submit
  async function save() {
    const user = {
      id: props._session.userId,
      email: props._session.email,
      firstname: props._session.userFirstname,
      lastname: props._session.userLastname,
    };

    store.dispatch(userStatus(STATUS_SAVING));
    const response: any = props._session.userRole
      ? await store.dispatch(userUpdate(user, false))
      : await store.dispatch(userUpdate_AsEmployee(user));
    store.dispatch(
      userStatus(response.error ? STATUS_SAVE_ERROR : STATUS_SAVED)
    );
  }

  //On save image
  function saveImage() {
    store.dispatch(sessionEditUserOptions("image", true));
  }

  //Save language
  //Update translate param and save BDD
  async function saveLanguage(event) {
    store.dispatch(sessionEdit("language", event.id));
    i18n.changeLanguage(event.id);

    store.dispatch(userStatus(STATUS_SAVING));
    const response: any = await store.dispatch(userUpdateLanguage(event.id));
    store.dispatch(
      userStatus(response.error ? STATUS_SAVE_ERROR : STATUS_SAVED)
    );
  }

  return (
    <NavFocus>
      <PageHeader title={t("self_params")} />

      {currentModal === MODAL_SET_PASSWORD && (
        <SetPasswordModal onClose={() => setCurrentModal(null)} />
      )}

      <Card>
        <div className="flex">
          <div className="flex1">
            <TextInput
              id="userFirstname"
              onBlur={save}
              onChange={handleChanges}
              status={props.user.status}
              title={t("user_firstname")}
              value={props._session.userFirstname}
            />

            <TextInput
              id="userLastname"
              onBlur={save}
              onChange={handleChanges}
              status={props.user.status}
              title={t("user_lastname")}
              value={props._session.userLastname}
            />

            <TextInput
              disabled={props._session.connexionMode === "GOOGLE"}
              id="email"
              onBlur={save}
              onChange={handleChanges}
              status={props.user.status}
              title={t("user_email")}
              value={props._session.email}
            />

            <Dropdown
              active={props._session.language}
              displayField="value"
              list={SESSION_LANGUAGES}
              title={t("utils_language")}
              onSelect={saveLanguage}
              status={props.user.status}
              value={props._session.language.toUpperCase()}
            />
          </div>

          <div className="flex1">
            {props._session.connexionMode !== "GOOGLE" && (
              <div className="text-input-title grey-t">
                {t("utils_password")}
              </div>
            )}

            {props._session.connexionMode !== "GOOGLE" && (
              <div style={{ marginTop: "18px" }}>
                {
                  /** if never connected, it is possible to create password here */
                  props._session.userPasswordProvided ? (
                    <div
                      className="signup-rgpd-link"
                      style={{ marginTop: "12px" }}
                      onClick={resetPassword}
                    >
                      {t("self_password_update")}
                    </div>
                  ) : (
                    <div
                      className="signup-rgpd-link"
                      onClick={() => setCurrentModal(MODAL_SET_PASSWORD)}
                    >
                      {t("self_password_create")}
                    </div>
                  )
                }
              </div>
            )}

            <div className="height-40" />

            <div className="flex">
              <ImagePicker
                id={props._session.userId}
                square
                imageId={
                  props._session.userOptions.image
                    ? props._session.userId
                    : null
                }
                model="Users"
                onDelete={() =>
                  store.dispatch(sessionEditUserOptions("image", false))
                }
                onSave={() => saveImage()}
              />
              <Space />
            </div>
          </div>
        </div>
      </Card>
    </NavFocus>
  );
}

const mapStateToProps = (state) => ({
  _session: state._session,
  user: state.user,
});

export default connect(mapStateToProps)(withTranslation()(AdmSelfRoute));
