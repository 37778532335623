/**
 * RENAME-SURVEY.MODAL
 * Rename survey
 */
import Modal from "@/components/modal";
import { useState } from "react";
import { connect } from "react-redux";
import { withTranslation, WithTranslation } from "react-i18next";
import TextInput from "@/components/text-input";
import { store } from "@/index";
// import store from "@/core/store";
import {
  STATUS_SAVED,
  STATUS_SAVE_ERROR,
  STATUS_SAVING,
} from "@/redux/_status.types";
import { Session } from "@/redux/_session.types";
import { SurveyState } from "@/redux/survey.types";
import {
  surveyEditOne,
  surveyStatus,
  surveyUpdateName,
} from "@/redux/survey.actions";

interface StateProps extends WithTranslation {
  _session: Session;
  survey: SurveyState;
}

interface OwnProps {
  onClose: Function;
}

type Props = StateProps & OwnProps;

function RenameSurveyModal(props: Props) {
  const { t } = props;

  //Name of the survey to change
  const [surveyName, setSurveyName] = useState(props.survey.active.name);

  //Save the survey
  //Edit the store with the new name and update the BDD
  async function next() {
    store.dispatch(surveyStatus(STATUS_SAVING));
    store.dispatch(surveyEditOne(props.survey.active.id, "name", surveyName));
    const response: any = await store.dispatch(
      surveyUpdateName(props.survey.active.id, surveyName)
    );

    if (response.error) {
      store.dispatch(surveyStatus(STATUS_SAVE_ERROR));
    } else {
      store.dispatch(surveyStatus(STATUS_SAVED));
      props.onClose();
    }
  }

  return (
    <Modal
      isCloseButtonVisible
      status={props.survey.status}
      onClose={() => props.onClose()}
      onNext={next}
      title={t("utils_rename")}
    >
      <TextInput
        autoFocus
        isFullWidth
        onChange={(e) => setSurveyName(e.value)}
        title={t("survey_name")}
        value={surveyName}
      />

      <div className="height-20" />
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  _session: state._session,
  survey: state.survey,
});

export default connect(mapStateToProps)(withTranslation()(RenameSurveyModal));
