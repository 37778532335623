/**
 * REPORT-STORE-DATA.MODAL
 * Save all the data in the store from the survey for the reporting module
 * Fetch missing data if needed
 */

import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useEffect } from "react";
import LoadingModal from "./loading.modal";
import { Page } from "@/redux/page.types";
import {
  pageActivate,
  pageFetch,
  pageFetch_AsSupervisor,
  pageGet,
  pageInitDashboardFilters,
} from "@/redux/page.actions";
import { ReportState } from "@/redux/report.types";
import { store } from "@/index";
// import store from "@/core/store";
import {
  reportEdit,
  reportGetAllTexts,
  reportGetTagsFromTexts,
  reportUpdateTagsData,
} from "@/redux/report.actions";
import { orderBy } from "lodash";
import { filterReplaceDashboard } from "@/redux/filter.actions";
import { Session } from "@/redux/_session.types";

interface StateProps extends WithTranslation {
  _session: Session;
  report: ReportState;
}

interface OwnProps {
  onClose: Function;
}

type Props = StateProps & OwnProps;

function ReportGetDataModal(props: Props) {
  //On load update all tags data
  useEffect(() => {
    async function loadData() {
      //Update dashboard filters according population
      store.dispatch(filterReplaceDashboard(pageInitDashboardFilters([])));

      //Fetch page data
      const fetchFunction =
        props._session.interfaceType === "SUPERVISOR"
          ? pageFetch_AsSupervisor
          : pageFetch;
      const response: any = await store.dispatch(
        fetchFunction(props.report.active.id)
      );

      if (!response.error) {
        const pages = orderBy(response, (item: Page) =>
          props.report.active.pagesOrder.indexOf(item.id)
        ).map(
          (x: Page) =>
            new Page({
              ...x,
              status: "saved",
            })
        );

        //Extract all tags from the report
        const texts = reportGetAllTexts(pages);
        const tags = reportGetTagsFromTexts(texts);

        //Update the store
        store.dispatch(
          reportEdit("tagsData", await reportUpdateTagsData(tags))
        );
        store.dispatch(pageGet(pages));
        store.dispatch(pageActivate(pages.length > 0 ? pages[0] : new Page()));

        //Close the modal
        props.onClose();
      } else {
        store.dispatch(pageGet([]));
      }
    }

    loadData();

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <LoadingModal />;
}

const mapStateToProps = (state) => ({
  _session: state._session,
  report: state.report,
});

export default connect(mapStateToProps)(withTranslation()(ReportGetDataModal));
