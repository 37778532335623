/**
 * PROJECT-CALENDAR.WIDGET
 * Set all dates of invitations for a project on the calendar
 */

import { connect } from "react-redux";
import { withTranslation, WithTranslation } from "react-i18next";
import { Calendar, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import moment from "moment";
import "moment/locale/fr";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import Space from "@/components/space";
import {
  faCheck,
  faChevronLeft,
  faChevronRight,
  faInfoCircle,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import ListButton from "@/components/list-button";
import { store } from "@/index";
// import store from "@/core/store";
import { toast } from "react-toastify";
import { Sending, SendingState } from "@/redux/sending.types";
import {
  ProjectCalendarEvent,
  ProjectCalendarEventItem,
  ProjectState,
} from "@/redux/project.types";
import {
  sendingDestroy,
  sendingGet,
  sendingReplace,
  sendingUpdate,
} from "@/redux/sending.actions";
import { STATUS_SAVED, STATUS_SAVING } from "@/redux/_status.types";
import { Session } from "@/redux/_session.types";
import React, { Children, useCallback, useEffect, useState } from "react";
import { isToday, updateDate } from "@/utils/format-date.utils";
import { projectCheckCalendar, projectStatus } from "@/redux/project.actions";
import { EmailState } from "@/redux/email.types";
import ConfirmButtons from "@/components/confirm-buttons";
import Chip from "@/components/chip";
import {
  Reminder,
  ReminderState,
  ReminderTag,
  REMINDER_TAGS,
} from "@/redux/reminder.types";
import ProjectEditReminderModal from "@/modals/project-edit-reminder.modal";
import {
  reminderDestroy,
  reminderGet,
  reminderReplace,
  reminderUpdate,
} from "@/redux/reminder.actions";
import ProjectEditSendingModal from "@/modals/project-edit-sending.modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "@/components/modal";
import ListItem from "@/components/list-item";
import ListCell from "@/components/list-cell";
import ProjectStepErrorsWidget from "./project-step-errors.widget";
import ContextMenu from "@/components/context-menu";

interface StateProps extends WithTranslation {
  _session: Session;
  email: EmailState;
  project: ProjectState;
  reminder: ReminderState;
  sending: SendingState;
}

interface OwnProps {
  onNext?: Function;
}

type Props = StateProps & OwnProps;

//Calendar date cell with title and Event
interface CalendarDate {
  event: ProjectCalendarEvent;
}

//Type of item to drag (sending or reminder)
type ItemDragged = "sending" | "reminder";

const MODAL_ADD_REMINDER = "MODAL_ADD_REMINDER";
const MODAL_ADD_SENDING = "MODAL_ADD_SENDING";
const MODAL_EDIT_REMINDER = "MODAL_EDIT_REMINDER";
const MODAL_EDIT_SENDING = "MODAL_EDIT_SENDING";
const MODAL_SELECT_EVENT = "MODAL_SELECT_EVENT";
const MODAL_SELECT_SLOT = "MODAL_SELECT_SLOT";

//Context
const CONTEXT_DESCRIPTION: string = "CONTEXT_DESCRIPTION";

//Init localization for time event
const localizer = momentLocalizer(moment);

//Init drag and drop calendar
const DragAndDropCalendar = withDragAndDrop(Calendar);

//Different color for cell if date is past
const ColoredDateCellWrapper = ({ children, value }) =>
  React.cloneElement(Children.only(children), {
    style: {
      ...children.style,

      //past days...
      backgroundImage:
        value < new Date() && !isToday(value)
          ? `url(${require("@/assets/check-cross.png")})`
          : "",
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      backgroundPosition: "bottom",
    },
  });

function ProjectCalendarWidget(props: Props) {
  const { t } = props;

  //Current date selected in the calendar
  const [date, setDate] = useState(new Date());

  //Current event that is dragged
  const [currentTag, setCurrentTag] = useState<ReminderTag>(REMINDER_TAGS[0]);

  //Save current date for the calendar when item is dropped
  const [currentCalendarDate, setCurrentCalendarDate] = useState(new Date());

  //Type of item that will be dragger (sending or reminder)
  const [currentItemDragged, setCurrentItemDragged] =
    useState<ItemDragged>("sending");

  //Current modal to display
  const [currentModal, setCurrentModal] = useState<string | null>(null);

  //Current reminded to edit
  const [currentReminder, setCurrentReminder] = useState(new Reminder());

  //Current reminded to edit
  const [currentSending, setCurrentSending] = useState(new Sending());

  //Display action menu if many event in one day
  const [openedCalendarDate, setOpenedCalendarDate] =
    useState<CalendarDate | null>(null);

  //Detect drop from outside the calendar
  //Open modal to create an event
  const onDropFromOutside = useCallback(
    ({ start }) => {
      setCurrentCalendarDate(new Date(start));

      if (currentItemDragged === "reminder") {
        setCurrentModal(MODAL_ADD_REMINDER);
      } else if (currentItemDragged === "sending") {
        setCurrentModal(MODAL_ADD_SENDING);
      }
    },
    [currentItemDragged]
  );

  //Detect when day is open in order to select event between many
  useEffect(() => {
    if (openedCalendarDate) {
      setCurrentModal(MODAL_SELECT_EVENT);
    }
  }, [openedCalendarDate]);

  //Detect if new sendings
  useEffect(() => {
    store.dispatch(
      projectCheckCalendar(
        props.project.active.diffusionMode,
        props.sending.list
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.project.active.diffusionMode, props.sending.list.length]);

  //Reset
  async function cancel() {
    for (let i = 0; i < props.sending.list.length; i++) {
      const sending = props.sending.list[i];
      await store.dispatch(sendingDestroy(sending.id));
    }

    for (let i = 0; i < props.reminder.list.length; i++) {
      const reminder = props.reminder.list[i];
      await store.dispatch(reminderDestroy(reminder.id));
    }

    store.dispatch(sendingGet([]));
    store.dispatch(reminderGet([]));

    next(false, false);
  }

  //Component to display for an event
  //Display date in bold
  function getEventComponent(date: CalendarDate) {
    const isMultipleItem = date.event.items.length > 1;
    return (
      <div
        className="flex flex-dcol"
        onClick={() =>
          isMultipleItem
            ? setOpenedCalendarDate(date)
            : openEventItem(date.event.items[0])
        }
        style={{ height: "100%" }}
      >
        <div style={{ fontSize: 14 }} className="rel">
          {date.event.icon && (
            <div
              className="abs"
              style={{
                top: 2,
              }}
            >
              <FontAwesomeIcon icon={date.event.icon} />
            </div>
          )}

          <b>{date.event.start.getUTCDate()}</b>
        </div>

        <Space />

        {date.event.items.map((item) => (
          <div
            key={item.id}
            className="rel"
            style={{
              textAlign: "initial",
              overflow: "hidden",
              paddingBottom: 8,
            }}
          >
            <b>{item.date.toTimeString().slice(0, 5)}</b>
            &nbsp;
            {item.name}
          </div>
        ))}

        <Space />
      </div>
    );
  }

  //Style of the event
  //Color depend of the type
  function getEventsStyle(event: ProjectCalendarEvent) {
    let backgroundColor = event.backgroundColor;
    const color = event.color;
    const lastEvent = event.items[event.items.length - 1].date;

    if (lastEvent < new Date()) {
      backgroundColor = "lightgrey";
    }

    return {
      style: {
        backgroundColor,
        color,
      },
    };
  }

  //Header of the calendar
  //Current month and navigation
  function getToolbarComponent(item) {
    return (
      <div className="flex" style={{ padding: "18px 12px", height: "56px" }}>
        <Space />

        <ListButton
          icon={faChevronLeft}
          onClick={() =>
            setDate(new Date(item.date.setMonth(item.date.getMonth() - 1)))
          }
        />

        <b>{item.label}</b>

        <ListButton
          icon={faChevronRight}
          onClick={() =>
            setDate(new Date(item.date.setMonth(item.date.getMonth() + 1)))
          }
        />

        <Space />
      </div>
    );
  }

  //Change date on drop
  async function dropEvent(dropEvent) {
    store.dispatch(projectStatus(STATUS_SAVING));

    if (currentReminder.id) {
      const reminder = Object.assign({}, currentReminder, {
        date: updateDate(currentReminder.date, dropEvent.start, true),
      });
      store.dispatch(reminderReplace(reminder));
      await store.dispatch(reminderUpdate(reminder, props.project.active.id));
    } else if (currentSending.id) {
      const sending = Object.assign({}, currentSending, {
        date: updateDate(currentSending.date, dropEvent.start, false),
      });
      store.dispatch(sendingReplace(sending));
      await store.dispatch(
        sendingUpdate(sending, props.project.active.id, false)
      );
    } else {
      toast(t("project_calendar_multiple_events"), { type: "error" });
    }

    store.dispatch(projectStatus(STATUS_SAVED));
  }

  //Open event item
  async function openEventItem(item: ProjectCalendarEventItem) {
    if (item.reminder) {
      setCurrentReminder(item.reminder);
      setCurrentModal(MODAL_EDIT_REMINDER);
    } else if (item.sending) {
      setCurrentSending(item.sending);
      setCurrentModal(MODAL_EDIT_SENDING);
    }
  }

  //Start to drag event
  //Possible only there is only one event
  //select current Reminder or Sending
  function dragEvent(date: CalendarDate) {
    let reminder = new Reminder();
    let sending = new Sending();

    if (date.event.items.length === 1) {
      const eventItem: ProjectCalendarEventItem = date.event.items[0];

      if (eventItem.reminder) {
        reminder = eventItem.reminder;
      } else if (eventItem.sending) {
        sending = eventItem.sending;
      }
    }

    setCurrentReminder(reminder);
    setCurrentSending(sending);
  }

  //Clic on calendar date
  //Open context menu
  function selectSlot(event) {
    setCurrentCalendarDate(new Date(event.start));
    setCurrentModal(MODAL_SELECT_SLOT);
  }

  function next(isVisited: boolean, isConfirmed: boolean) {
    if (props.onNext) {
      props.onNext(isVisited, isConfirmed);
    }
  }

  return (
    <div>
      {currentModal === MODAL_ADD_REMINDER && (
        <ProjectEditReminderModal
          onClose={() => setCurrentModal(null)}
          date={currentCalendarDate}
          tag={currentTag}
        />
      )}

      {currentModal === MODAL_EDIT_REMINDER && (
        <ProjectEditReminderModal
          onClose={() => setCurrentModal(null)}
          editedReminded={currentReminder}
        />
      )}

      {currentModal === MODAL_ADD_SENDING && (
        <ProjectEditSendingModal
          onClose={() => setCurrentModal(null)}
          date={currentCalendarDate}
        />
      )}

      {currentModal === MODAL_EDIT_SENDING && (
        <ProjectEditSendingModal
          onClose={() => setCurrentModal(null)}
          editedSending={currentSending}
        />
      )}

      {currentModal === MODAL_SELECT_EVENT && (
        <Modal
          onClose={() => setCurrentModal(null)}
          title={t("calendar_select_multiple")}
        >
          {openedCalendarDate?.event.items.map((item) => (
            <ListItem
              key={item.id}
              isEditable
              onClick={() => openEventItem(item)}
            >
              <ListCell
                width={100}
                text={item.date.toTimeString().slice(0, 5)}
              />
              <span className="grey-t">{item.name}</span>
            </ListItem>
          ))}
        </Modal>
      )}

      {currentModal === MODAL_SELECT_SLOT && (
        <Modal onClose={() => setCurrentModal(null)} title={t("reminder_add")}>
          {props.project.active.diffusionMode.email && (
            <ListItem
              isEditable
              onClick={() => setCurrentModal(MODAL_ADD_SENDING)}
            >
              <span
                style={{
                  color: props.sending.list.length > 0 ? "#ff9500" : "#eb5a46",
                }}
              >
                {props.sending.list.length > 0
                  ? t("sending_relaunch")
                  : t("sending_launch")}
              </span>
            </ListItem>
          )}

          {REMINDER_TAGS.map((tag) => (
            <ListItem
              key={tag}
              isEditable
              onClick={() => {
                setCurrentTag(tag);
                setCurrentModal(MODAL_ADD_REMINDER);
              }}
            >
              <span style={{ color: "#00c2e0" }}>
                {t("reminder_tag_" + tag)}
              </span>
            </ListItem>
          ))}

          <ListItem
            isEditable
            onClick={() => {
              setCurrentTag("custom");
              setCurrentModal(MODAL_ADD_REMINDER);
            }}
          >
            <span style={{ color: "#0082A0" }}>{t("reminder_tag_custom")}</span>
          </ListItem>
        </Modal>
      )}

      <ListItem>
        <div className="setup-card-help">
          <p>{t("project_calendar_help")}</p>
          <p className="grey-t setup-card-help-content">
            {t("project_calendar_help_1")}
          </p>
          <ul className="grey-t setup-card-help-content">
            <li>{t("project_calendar_help_2")}</li>
            <li>{t("project_calendar_help_3")}</li>
            <li>{t("project_calendar_help_4")}</li>
          </ul>
          <p className="grey-t setup-card-help-content">
            {t("project_calendar_help_5")}
          </p>
        </div>
      </ListItem>

      <div className="flex" style={{ minWidth: 1000 }}>
        <div
          style={{
            margin: "10px 32px",
            minWidth: 300,
            maxWidth: 300,
          }}
        >
          <div className="height-20" />

          {props.project.active.diffusionMode.email && (
            <div className="flex" style={{ marginBottom: 20 }}>
              <div
                className="flex project-calendar-chip"
                draggable="true"
                onDragStart={() => setCurrentItemDragged("sending")}
              >
                <Chip
                  icon={faPaperPlane}
                  color={props.sending.list.length > 0 ? "#ff9500" : "#eb5a46"}
                >
                  {(props.sending.list.length > 0
                    ? t("sending_relaunch")
                    : t("sending_launch")) + " "}
                </Chip>
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  className="grey-t _hover"
                  onClick={() => {
                    setCurrentItemDragged("sending");
                    setCurrentModal(CONTEXT_DESCRIPTION);
                  }}
                />
              </div>

              {currentModal === CONTEXT_DESCRIPTION &&
                currentItemDragged === "sending" && (
                  <div className="rel" style={{ zIndex: 100 }}>
                    <div className="height-20" />
                    <ContextMenu onClose={() => setCurrentModal(null)}>
                      <ListItem>{t("sending_help_1")}</ListItem>
                    </ContextMenu>
                  </div>
                )}
            </div>
          )}

          {REMINDER_TAGS.map((tag) => (
            <div className="flex" key={tag}>
              <div
                draggable="true"
                onDragStart={() => {
                  setCurrentTag(tag);
                  setCurrentItemDragged("reminder");
                }}
                className="project-calendar-chip flex"
              >
                <Chip
                  isOutlineColored
                  icon={
                    props.reminder.list.findIndex((x) => x.tag === tag) > -1
                      ? faCheck
                      : undefined
                  }
                  color="#00c2e0"
                >
                  {t("reminder_tag_" + tag) + " "}
                </Chip>
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  className="grey-t _hover"
                  onClick={() => {
                    setCurrentTag(tag);
                    setCurrentItemDragged("reminder");
                    setCurrentModal(CONTEXT_DESCRIPTION);
                  }}
                />
              </div>

              {currentModal === CONTEXT_DESCRIPTION &&
                currentTag === tag &&
                currentItemDragged === "reminder" && (
                  <div className="rel" style={{ zIndex: 100 }}>
                    <div className="height-20" />
                    <ContextMenu onClose={() => setCurrentModal(null)}>
                      <ListItem>{t("reminder_description_" + tag)}</ListItem>
                    </ContextMenu>
                  </div>
                )}
            </div>
          ))}

          <div className="height-20" />

          <div className="flex">
            <div
              className="flex project-calendar-chip"
              draggable="true"
              onDragStart={() => {
                setCurrentTag("custom");
                setCurrentItemDragged("reminder");
              }}
            >
              <Chip isOutlineColored color="#0082A0">
                {t("reminder_tag_custom") + " "}
              </Chip>
              <FontAwesomeIcon
                icon={faInfoCircle}
                className="grey-t _hover"
                onClick={() => {
                  setCurrentTag("custom");
                  setCurrentItemDragged("reminder");
                  setCurrentModal(CONTEXT_DESCRIPTION);
                }}
              />
            </div>

            {currentModal === CONTEXT_DESCRIPTION &&
              currentTag === "custom" &&
              currentItemDragged === "reminder" && (
                <div className="rel" style={{ zIndex: 100 }}>
                  <div className="height-20" />
                  <ContextMenu onClose={() => setCurrentModal(null)}>
                    <div style={{ margin: 10 }}>
                      {t("reminder_description_custom")}
                    </div>
                  </ContextMenu>
                </div>
              )}
          </div>
        </div>

        <DragAndDropCalendar
          components={{
            event: getEventComponent,
            toolbar: (item) => getToolbarComponent(item),
            dateCellWrapper: ColoredDateCellWrapper,
          }}
          date={date}
          endAccessor="end"
          eventPropGetter={getEventsStyle}
          events={props.project.active.getEvents(
            props.sending.list,
            props.reminder.list,
            props.email.list
          )}
          localizer={localizer}
          onDragStart={(e) => setTimeout(() => dragEvent(e), 200)}
          onDropFromOutside={onDropFromOutside}
          onEventDrop={dropEvent}
          onSelectSlot={selectSlot}
          onNavigate={() => {}}
          selectable
          startAccessor="start"
          style={{ height: 500, width: "100%" }}
          views={["month"]}
        />
      </div>

      {props.onNext && <div className="height-20" />}

      <ProjectStepErrorsWidget
        alert={props.project.active.alerts.calendar}
        step="calendar"
      />

      {props.onNext && (
        <ConfirmButtons
          onCancel={() => cancel()}
          onConfirm={() => next(true, true)}
          onConfirmLater={() => next(true, false)}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  _session: state._session,
  email: state.email,
  project: state.project,
  reminder: state.reminder,
  sending: state.sending,
});

export default connect(mapStateToProps)(
  withTranslation()(ProjectCalendarWidget)
);
