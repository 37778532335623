/**
 * UPGRADE.WIDGET
 * Text to explain feature to unlock
 */

import { useEffect, useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import Space from "@/components/space";
import { store } from "@/index";
// import store from "@/core/store";
import { generateTranslation } from "@/redux/_generate.actions";
import Dropdown from "@/components/dropdown";
import languages from "@/translate/lang.json";
import Button from "@/components/button";
import SaveIcon from "@/components/save-icon";
import { STATUS_LOADED, STATUS_LOADING } from "@/redux/_status.types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExchangeAlt,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import Chip from "@/components/chip";
import { Language } from "@/redux/language.types";
import { flatten } from "lodash";

interface OwnProps extends WithTranslation {
  sourceLang?: string; //default source language
  targetLang?: string; //default target language
  onSourceChange?: Function; //function to trigger on source language change
  onTargetChange?: Function; //function to trigger on target language change
  onTranslate: Function; //action to do after translate (set the translated text as function param)
  languages?: string[]; //array must contain at least 2 languages codes
  sourceTexts: any[]; //texts to translate
  disableSource?: boolean; //disable dropdown selection for source language
  disableTarget?: boolean; //disable dropdown selection for target language
}

const CHUNK_SIZE: number = 100;

const deeplCodeLanguages: string[] = [
  "ar",
  "bg",
  "cs",
  "da",
  "de",
  "el",
  "en",
  "es",
  "et",
  "fi",
  "fr",
  "hu",
  "id",
  "it",
  "ja",
  "lt",
  "lv",
  "nl",
  "pl",
  "pt",
  "ro",
  "ru",
  "sk",
  "sl",
  "sv",
  "tr",
  "uk",
  "zh",
];
const deeplLangAvailable: Language[] = languages
  .filter((x: any) => deeplCodeLanguages.indexOf(x.code) > -1)
  .map((lang: any) => new Language(lang));

function TranslatorWidget(props: OwnProps) {
  const { t } = props;

  const langAvailable: Language[] =
    props.languages && props.languages.length > 0
      ? deeplLangAvailable.filter(
          (x: Language) => props.languages!.indexOf(x.code) > -1
        )
      : deeplLangAvailable;

  //display dropdown for source language
  const [editSource, setEditSource] = useState<boolean>(
    props.disableSource ? false : true
  );

  //display dropdown for target language
  const [editTarget, setEditTarget] = useState<boolean>(
    props.disableTarget ? false : true
  );

  //loader while translating
  const [isLoading, setIsLoading] = useState<boolean>(false);

  //selected source language (must be the same as the text to translate)
  const [sourceLang, setSourceLang] = useState<Language>(
    props.sourceLang ? findLanguage(props.sourceLang) : langAvailable[0]
  );

  //selected target language
  const [targetLang, setTargetLang] = useState<Language>(
    props.targetLang ? findLanguage(props.targetLang) : langAvailable[0]
  );

  useEffect(() => {
    if (props.sourceLang) {
      setSourceLang(findLanguage(props.sourceLang));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.sourceLang]);

  useEffect(() => {
    if (props.targetLang) {
      setTargetLang(findLanguage(props.targetLang));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.targetLang]);

  function changeSourceLang(lang: Language) {
    const newLang: Language = new Language(lang);

    setSourceLang(newLang);
    if (props.onSourceChange) {
      props.onSourceChange(newLang);
    }
  }

  function changeTargetLang(lang: Language) {
    const newLang: Language = new Language(lang);

    setTargetLang(newLang);
    if (props.onTargetChange) {
      props.onTargetChange(newLang);
    }
  }

  function reverseLanguages(source: Language, target: Language) {
    changeSourceLang(target);
    changeTargetLang(source);
  }

  function findLanguage(langCode: string): Language {
    const language: Language = deeplLangAvailable.find(
      (x: Language) => x.code === langCode
    )!;
    return language ? language : langAvailable[0];
  }

  function isError() {
    return sourceLang.code === targetLang.code;
  }

  async function translate() {
    setIsLoading(true);

    const lang: string =
      targetLang.code === "en"
        ? "en-GB"
        : targetLang.code === "pt"
        ? "pt-PT"
        : targetLang.code;

    const sourceTexts: string[] = props.sourceTexts.map(
      (x: any) => x[sourceLang.code]
    );
    let response: string[] = [];

    for (let i: number = 0; i < sourceTexts.length; i += CHUNK_SIZE) {
      const result: any = await store.dispatch(
        generateTranslation(
          sourceTexts.slice(i, i + CHUNK_SIZE),
          sourceLang.code,
          lang
        )
      );
      response = [...response, result ? result : "translation_error"];
    }

    setIsLoading(false);

    props.onTranslate(flatten(response), targetLang);
  }

  return (
    <div>
      <div
        className="flex"
        onBlur={editSource ? () => setEditSource(false) : undefined}
      >
        <Space />
        {editSource ? (
          <Dropdown
            displayField="name"
            list={langAvailable.map((lang: Language) => {
              return { ...lang, id: lang.code };
            })}
            error={isError()}
            onSelect={changeSourceLang}
            title={t("language_source")}
            active={sourceLang.code}
            value={sourceLang.name}
            isDisabled={props.disableSource}
          />
        ) : (
          <Chip
            isWithMargin
            onClick={
              !props.disableSource ? () => setEditSource(true) : undefined
            }
          >
            {sourceLang.flag && (
              <img
                src={require("@/assets/default_icons/flags/" +
                  sourceLang.flag +
                  ".png")}
                alt={sourceLang.code}
                width="14"
                height="14"
                style={{ margin: "0px 6px -2px 0px" }}
              />
            )}

            {sourceLang.fullName}
          </Chip>
        )}
        <Space />
      </div>

      <div className="flex _hover">
        <Space />
        <FontAwesomeIcon
          onClick={() => reverseLanguages(sourceLang, targetLang)}
          color={"#AAA"}
          icon={faExchangeAlt}
          rotation={90}
          style={{ margin: "0 10px" }}
        />
        <Space />
      </div>

      <div
        className="flex"
        onBlur={editTarget ? () => setEditTarget(false) : undefined}
      >
        <Space />
        {editTarget ? (
          <Dropdown
            displayField="name"
            list={langAvailable.map((lang: Language) => {
              return { ...lang, id: lang.code };
            })}
            error={isError()}
            onSelect={changeTargetLang}
            title={t("language_target")}
            active={targetLang.code}
            value={targetLang.name}
            isDisabled={props.disableTarget}
          />
        ) : (
          <Chip
            isWithMargin
            onClick={
              !props.disableTarget ? () => setEditTarget(true) : undefined
            }
          >
            {targetLang.flag && (
              <img
                src={require("@/assets/default_icons/flags/" +
                  targetLang.flag +
                  ".png")}
                alt={targetLang.code}
                width="14"
                height="14"
                style={{ margin: "0px 6px -2px 0px" }}
              />
            )}

            {targetLang.fullName}
          </Chip>
        )}
        <Space />
      </div>

      {isError() && (
        <div className="red-t">
          <FontAwesomeIcon color={"#AAA"} icon={faExclamationTriangle} />
          {" " + t("same_languages")}
        </div>
      )}

      <div className="height-20" />

      <div className="flex">
        <Space />

        <SaveIcon status={isLoading ? STATUS_LOADING : STATUS_LOADED} />

        <Button
          className={isError() ? "light" : "primary"}
          onClick={isError() || isLoading ? undefined : translate}
          isLoading={isLoading}
        >
          {t("@/translate")}
        </Button>
      </div>
    </div>
  );
}

export default withTranslation()(TranslatorWidget);
