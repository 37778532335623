/**
 * TASKS-CARD.WIDGET
 * Get task that you can edit
 */
import Card from "@/components/card";
import { Draggable } from "react-beautiful-dnd";
import { store } from "@/index";
// import store from "@/core/store";
import { useState } from "react";
import { connect } from "react-redux";
import { ActionPlanState, ActionPlanTask } from "@/redux/action-plan.types";
import { topicActivate } from "@/redux/topic.actions";
import { TopicState } from "@/redux/topic.types";
import { Session } from "@/redux/_session.types";
import ListButton from "@/components/list-button";
import {
  faComment,
  faPencilAlt,
  faStickyNote,
} from "@fortawesome/free-solid-svg-icons";
import { WithTranslation, withTranslation } from "react-i18next";
import TasksTopicLabelWidget from "./tasks-topic-label.widget";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TasksEditModal from "@/modals/tasks-edit.modal";
import { getAssociatedLearning } from "@/utils/learning-topic-correspondance";
import { learningActivate } from "@/redux/learning.actions";
import { Learning } from "@/redux/learning.types";

interface StateProps extends WithTranslation {
  _session: Session;
  actionPlan: ActionPlanState;
  topic: TopicState;
}

interface OwnProps {
  draggableIndex: number; //Required for react dnd
  isDraggable?: boolean;
  currentTask: ActionPlanTask;
}

type Props = StateProps & OwnProps;

const MODAL_EDIT = "MODAL_EDIT";
const MODAL_FOLLOW = "MODAL_FOLLOW";

function TasksCardWidget(props: Props) {
  const { t } = props;

  //Display modal
  const [currentModal, setCurrentModal] = useState<string | null>(null);

  //Open modal to edit task
  function editTask(fromEditButton: boolean) {
    store.dispatch(topicActivate(props.currentTask.getTopic(props.topic.list)));

    if (props.actionPlan.active.status === "closed") {
      setCurrentModal(MODAL_FOLLOW);
    } else if (fromEditButton || props.actionPlan.active.status !== "active") {
      setCurrentModal(MODAL_EDIT);
    } else {
      setCurrentModal(MODAL_FOLLOW);
    }
  }

  //Return react content
  function getCardContent() {
    return (
      <Card className="_hover" onClick={() => editTask(false)}>
        {props.currentTask.topicAid && (
          <TasksTopicLabelWidget currentTask={props.currentTask} />
        )}

        <div className="rel">
          <div>{props.currentTask.text}</div>

          {props.currentTask.messages.length > 0 && (
            <div
              className="grey-t"
              style={{
                fontSize: 12,
                marginTop: 12,
              }}
            >
              <FontAwesomeIcon icon={faComment} />
              &nbsp;
              {t("tasks_messages_count", {
                count: props.currentTask.messages.length,
                s: props.currentTask.messages.length > 0 ? "s" : "",
              })}
            </div>
          )}

          <div className="height-20" />

          {props._session.modules.learning &&
            getAssociatedLearning(props.currentTask.topicAid!) && (
              <div
                className="abs"
                style={{
                  bottom: -10,
                  right: 15,
                }}
              >
                <ListButton
                  icon={faStickyNote}
                  onClick={() =>
                    store.dispatch(
                      learningActivate(
                        new Learning({
                          id: getAssociatedLearning(
                            props.currentTask.topicAid!
                          )!,
                        })
                      )
                    )
                  }
                  text={t("utils_edit")}
                />
              </div>
            )}

          {props.actionPlan.active.status === "active" &&
            props._session.userRole === "ADMIN" && (
              <div
                className="abs"
                style={{
                  bottom: -10,
                  right: -30,
                }}
              >
                <ListButton
                  icon={faPencilAlt}
                  onClick={() => editTask(true)}
                  text={t("utils_edit")}
                />
              </div>
            )}
        </div>
      </Card>
    );
  }

  return (
    <div>
      {currentModal === MODAL_EDIT && (
        <TasksEditModal
          currentTask={props.currentTask}
          onClose={() => setCurrentModal(null)}
        />
      )}

      {currentModal === MODAL_FOLLOW && (
        <TasksEditModal
          isModeActive
          currentTask={props.currentTask}
          onClose={() => setCurrentModal(null)}
        />
      )}

      {props.isDraggable ? (
        <Draggable
          draggableId={props.currentTask.id}
          index={props.draggableIndex}
        >
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              {getCardContent()}
            </div>
          )}
        </Draggable>
      ) : (
        getCardContent()
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  _session: state._session,
  actionPlan: state.actionPlan,
  topic: state.topic,
});

export default connect(mapStateToProps)(withTranslation()(TasksCardWidget));
