/**
 * SELECT-POPULATIONS.MODAL
 * Select list of populations
 */
import Modal from "@/components/modal";
import { connect } from "react-redux";
import { withTranslation, WithTranslation } from "react-i18next";
import {
  DashboardFilters,
  Filter,
  FilterPopulation,
  FilterState,
} from "@/redux/filter.types";
import { useEffect, useState } from "react";
import PageLoader from "@/components/page-loader";
import { STATUS_LOADED, STATUS_LOADING } from "@/redux/_status.types";
import { store } from "@/index";
// import store from "@/core/store";
import { filterActivate, filterFetch, filterGet } from "@/redux/filter.actions";
import Chip from "@/components/chip";
import { Session } from "@/redux/_session.types";
import ModalConfirm from "@/components/modal-confirm";
import GetPopulationUsersModal from "./get-population-users.modal";
import getUserFilterDates, {
  UserFilterDates,
} from "@/utils/get-user-filter-dates.utils";
import { topicFetch } from "@/redux/topic.actions";
import { TemplateState } from "@/redux/template.types";
import { Topic } from "@/redux/topic.types";
import { Population } from "@/redux/population.types";
import { fetchAttributes, fetchFilters } from "@/redux/_archive.actions";
import { surveyEditOne, surveyFetch, surveyGet } from "@/redux/survey.actions";
import Space from "@/components/space";
import Dropdown from "@/components/dropdown";
import { Survey, SurveyState } from "@/redux/survey.types";
import Scroller from "@/components/scroller";
import makeInsensitive from "@/utils/make-insensitive.utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExchangeAlt,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import ListButton from "@/components/list-button";
import { sortBy } from "lodash";

interface StateProps extends WithTranslation {
  _session: Session;
  filter: FilterState;
  survey: SurveyState;
  template: TemplateState;
}

interface OwnProps {
  excludedFilters?: string[]; //Possible to hide filters
  isSurveyFiltersIncluded?: boolean; //Search filters from the previous surveys
  isAccountSelectable?: boolean; //Possible to select whole account
  isSurveyActiveSelected?: boolean; //Get filters for the current survey
  isTemplateFilterLoaded?: boolean; //Load filters from the template
  isWithoutDefaultFilters?: boolean; //Don't load default filters (gender, birth date)
  onClose?: Function; //Trigger on close
  onNext: Function; //Trigger on confirm
  selectedPopulations: Population[]; //List of selected populations
  subtitle?: string; //Display secondary title
  title?: string; //Display custom title
}

type Props = StateProps & OwnProps;

const MODAL_ASK_SAVE = "MODAL_ASK_SAVE";
const MODAL_POPULATION_USERS = "MODAL_POPULATION_USERS";

function SelectPopulationsModal(props: Props) {
  const { t } = props;

  //Search
  const [currentSearch, setCurrentSearch] = useState("");

  //Current population to display
  const [currentPopulationName, setCurrentPopulationName] = useState("");

  //Current modal
  const [currentModal, setCurrentModal] = useState<string | null>(null);

  //Show hide button for the filters
  const [displayExcludeFilter] = useState(props.excludedFilters ? true : false);

  //List of excluded filters for the active user
  const [excludedFilters, setExcludedFilters] = useState(
    props.excludedFilters ? props.excludedFilters : []
  );

  //Component is loading
  const [isLoading, setIsLoading] = useState(true);

  //Save changes
  const [isEdited, setIsEdited] = useState(false);

  //Selected populations
  const [selectedPopulations, setSelectedPopulations] = useState<Population[]>(
    props.selectedPopulations
  );

  //Selected Filters
  const [selectedSurvey, setSelectedSurvey] = useState(getCustomerFilterName());
  const [selectedFilter, setSelectedFilter] = useState(
    new Filter(props.filter.list.find((x) => x.isManager))
  );

  //Search by filters
  const [searchByFilters, setSearchByFilters] = useState(
    props.filter.list.find((x) => x.isManager) ? true : false
  );

  //Change filter active
  useEffect(() => {
    loadAttributes();

    async function loadAttributes() {
      if (searchByFilters && selectedFilter.name.length) {
        setIsLoading(true);

        for (let i = 0; i < props.survey.list.length; i++) {
          const survey = props.survey.list[i];
          const attributes: any = await store.dispatch(
            fetchAttributes(
              new DashboardFilters(),
              selectedFilter.name,
              survey.id
            )
          );

          if (!attributes.error) {
            attributes.forEach((attribute) => {
              attribute.id = selectedFilter.name + "_" + attribute.name;
            });

            store.dispatch(
              surveyEditOne(
                survey.id,
                "attributesByFilter",
                sortBy(attributes, "name")
              )
            );
          } else {
            store.dispatch(surveyEditOne(survey.id, "attributesByFilter", []));
          }
        }

        setIsLoading(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilter.name, searchByFilters]);

  //Load survey if asked
  useEffect(() => {
    loadSurveys();

    async function loadSurveys() {
      if (props.isSurveyFiltersIncluded) {
        let surveys: any = [];
        surveys = await store.dispatch(surveyFetch());
        store.dispatch(surveyGet(surveys.error ? [] : surveys, [], false));
      }
    }
  }, [props.isSurveyFiltersIncluded]);

  //Load filters at start
  useEffect(() => {
    load();

    async function load() {
      //Init filters
      let filters: Filter[] = [];

      //Init loading
      setIsLoading(true);

      //Case when selected survey is not defined
      //Load filters that are in the database at this time
      if (selectedSurvey.id === "") {
        //Load filters
        const filterResponse: any = await store.dispatch(
          filterFetch(true, props.isWithoutDefaultFilters ? false : true)
        );
        filters = filterResponse.error
          ? []
          : filterResponse.map((x) => new Filter(x));

        //Update values for default filtres
        filters.forEach((filter: Filter) => {
          if (
            ["gender", "birthDate", "companyWelcomeDate"].indexOf(filter.id) >
            -1
          ) {
            filter = updateDefaultFilter(filter);
          }
        });

        //Load populations from template (if specified)
        if (props.isTemplateFilterLoaded) {
          const topicsResponse: any = await store.dispatch(
            topicFetch(props.template.active.id)
          );
          const topics: Topic[] = topicsResponse.error
            ? []
            : topicsResponse.map((x) => new Topic(x));

          //For each topic at start create filter
          topics
            .filter((x) => x.isAtStart && x.Questions.length)
            .forEach((topic: Topic) => {
              filters.unshift(
                new Filter({
                  id: topic.id,
                  isTemplateFilter: true,
                  name: topic.label,
                  populations: topic.Questions[0].Propositions.map(
                    (x) => new FilterPopulation({ name: x.label })
                  ),
                })
              );
            });
        }
      }
      //If survey id is defined load filters from the survey
      else {
        let surveyFilters: any = [];
        let surveyAttributes: any = [];

        //Load filters for the survey
        surveyFilters = await store.dispatch(
          fetchFilters(new DashboardFilters(), selectedSurvey.id)
        );
        surveyAttributes = await store.dispatch(
          fetchAttributes(new DashboardFilters(), null, selectedSurvey.id)
        );

        //Loop on filter
        if (!surveyFilters.error && !surveyAttributes.error) {
          surveyFilters.forEach((filter) => {
            filters.push(
              new Filter({
                id: filter.name,
                name: filter.name,
                populations: surveyAttributes
                  .filter((x) => x.filterName === filter.name)
                  .map(
                    (x) =>
                      new FilterPopulation({
                        name: x.name,
                        usersCount: x.usersCount,
                      })
                  ),
              })
            );
          });
        }
      }

      //Update population id
      filters.forEach((filter) => {
        filter.populations.forEach((population) => {
          population.id = filter.default
            ? filter.id + "_" + population.id
            : filter.name + "_" + population.name;
        });
      });

      //Update store
      store.dispatch(filterGet(filters));

      //End of load
      setIsLoading(false);
    }

    //For default filter update name of filters and population
    function updateDefaultFilter(filter: Filter) {
      filter.name = t(filter.name);
      filter.populations.forEach((i: FilterPopulation) => {
        i.id = i.name;
        if (filter.id === "gender") {
          i.name = t("user_gender_" + i.name);
        } else {
          const value: UserFilterDates | undefined = getUserFilterDates(
            filter.id === "birthDate" ? "BIRTH_DATE" : "COMPANY_WELCOME_DATE",
            null
          ).find((x) => x.id === i.name);
          i.name = value ? value.name : "";
        }
      });

      return filter;
    }
  }, [
    t,
    selectedSurvey,
    props._session.interfaceType,
    props.isTemplateFilterLoaded,
    props.isWithoutDefaultFilters,
    props.template.active.id,
  ]);

  //Close modal
  function closeModal() {
    if (isEdited) {
      setCurrentModal(MODAL_ASK_SAVE);
    } else {
      closeModalConfirm();
    }
  }

  //Confirm close
  function closeModalConfirm() {
    if (props.onClose) {
      props.onClose();
    }
  }

  //Get name for custom filter
  //If no survey selected go for filters_custom importer
  function getCustomerFilterName() {
    return props.isSurveyActiveSelected
      ? props.survey.active
      : new Survey({ name: t("filters_custom") });
  }

  //Get populations according search
  function getPopulations(list: FilterPopulation[]) {
    return currentSearch.length
      ? list.filter((x) => makeInsensitive(x.name).indexOf(currentSearch) > -1)
      : list;
  }

  //Detect if the filter is active for the user
  function isFilterActive(filterName: string) {
    return excludedFilters.indexOf(filterName) === -1;
  }

  //Click on chips to add or remove a population from the selected list
  function selectPopulation(
    filter: Filter,
    population: FilterPopulation,
    isTemplatePopulation: boolean
  ) {
    setIsEdited(true);
    if (isPopulationSelected(population.id)) {
      setSelectedPopulations(
        selectedPopulations.filter((x) => x.id !== population.id)
      );
    } else {
      setSelectedPopulations(
        selectedPopulations.concat([
          {
            filterName: filter.name,
            name: population.name,
            id: population.id,
            isTemplatePopulation,
          },
        ])
      );
    }
  }

  //Check if population is selected
  function isPopulationSelected(populationId: string) {
    return selectedPopulations.findIndex((x) => x.id === populationId) > -1;
  }

  //Send selected populations
  function next() {
    props.onNext(selectedPopulations, excludedFilters);
    closeModalConfirm();
  }

  //Search into list of populations
  function search(value) {
    setCurrentSearch(makeInsensitive(value));
  }

  //Open user modal
  function showUsers(filter: Filter, populationName: string) {
    store.dispatch(filterActivate(filter));
    setCurrentPopulationName(populationName);
    setCurrentModal(MODAL_POPULATION_USERS);
  }

  return (
    <Modal
      isCloseButtonVisible={props.onClose ? true : false}
      isLarge
      onSearch={search}
      onClose={closeModal}
      onNext={next}
      title={props.title ? props.title : t("populations_select")}
    >
      {props.subtitle && (
        <p className="grey-t">
          <b>{props.subtitle}</b>
        </p>
      )}

      {currentModal === MODAL_ASK_SAVE && (
        <ModalConfirm
          onNo={() => closeModalConfirm()}
          onYes={next}
          textBold={t("utils_ask_confirm_edits")}
        />
      )}

      {currentModal === MODAL_POPULATION_USERS && (
        <GetPopulationUsersModal
          onClose={() => setCurrentModal(null)}
          populationName={currentPopulationName}
        />
      )}

      <div className="flex">
        {props.isAccountSelectable && (
          <div>
            <h3>{t("account_placeholder")}</h3>

            <div className="flex">
              <Chip
                onClick={() => setSelectedPopulations([])}
                isWithMargin
                color={
                  !selectedPopulations.length
                    ? props._session.accountColors.active
                    : ""
                }
              >
                {props._session.accountName}
              </Chip>
            </div>

            <div className="height-20" />
          </div>
        )}

        <Space />

        {props.isSurveyFiltersIncluded &&
          (searchByFilters ? (
            <Dropdown
              title={t("filters")}
              displayField="name"
              active={selectedFilter.id}
              value={selectedFilter.name}
              list={props.filter.list}
              onSelect={(item) => setSelectedFilter(item)}
            />
          ) : (
            <Dropdown
              title={t("filters")}
              displayField="name"
              active={selectedSurvey.id}
              value={selectedSurvey.name}
              list={[getCustomerFilterName()].concat(props.survey.list)}
              onSelect={(item) => setSelectedSurvey(item)}
            />
          ))}

        {props.isSurveyFiltersIncluded && (
          <div style={{ marginTop: 54 }}>
            <ListButton
              onClick={() => setSearchByFilters(!searchByFilters)}
              icon={faExchangeAlt}
              text={t("filter_switch_search")}
            />
          </div>
        )}
      </div>

      <Scroller style={{ height: "calc(100vh - 300px)" }} isModalScroller>
        <PageLoader status={isLoading ? STATUS_LOADING : STATUS_LOADED}>
          {searchByFilters ? (
            //Displey all filters by survey
            <div>
              {props.survey.list
                .filter((x) => x.attributesByFilter.length)
                .map((survey) => (
                  <div key={survey.id}>
                    <h3>{survey.name}</h3>

                    <div className="flex flex-wrap">
                      {survey.attributesByFilter.map((attribute) => (
                        <Chip
                          key={attribute.id}
                          count={attribute.usersCount}
                          countWarning={
                            attribute.usersCount <
                            props._session.participationMin
                          }
                          isWithMargin
                          onClick={() =>
                            selectPopulation(selectedFilter, attribute, false)
                          }
                          color={
                            isPopulationSelected(attribute.id)
                              ? props._session.accountColors.active
                              : ""
                          }
                        >
                          {attribute.name}
                        </Chip>
                      ))}
                    </div>
                  </div>
                ))}
            </div>
          ) : (
            //Display by filters
            <div>
              {props.filter.list.map(
                (filter) =>
                  getPopulations(filter.populations).length > 0 && (
                    <div key={filter.id}>
                      <div className="flex">
                        <h3>
                          <span
                            className={
                              isFilterActive(filter.name) ? "" : "medgrey-t"
                            }
                          >
                            {filter.name}
                          </span>

                          {filter.isTemplateFilter && (
                            <span className="grey-t">
                              &nbsp; ({t("question_introduction")})
                            </span>
                          )}

                          {displayExcludeFilter && (
                            <span className="grey-t">
                              &nbsp;
                              <FontAwesomeIcon
                                className="_hover"
                                style={{
                                  fontSize: 18,
                                  margin: "-2px 4px",
                                }}
                                icon={
                                  isFilterActive(filter.name)
                                    ? faEye
                                    : faEyeSlash
                                }
                                onClick={() =>
                                  setExcludedFilters(
                                    isFilterActive(filter.name)
                                      ? excludedFilters.concat(filter.name)
                                      : excludedFilters.filter(
                                          (x) => x !== filter.name
                                        )
                                  )
                                }
                              />
                            </span>
                          )}

                          {!isFilterActive(filter.name) && (
                            <span className="grey-t" style={{ fontSize: 12 }}>
                              &nbsp;
                              {t("user_excluded_filter", {
                                filter: filter.name,
                              })}
                            </span>
                          )}
                        </h3>
                      </div>

                      <div className="flex flex-wrap">
                        {getPopulations(filter.populations).map(
                          (population, i) => (
                            <Chip
                              key={i}
                              onInfo={
                                filter.isTemplateFilter ||
                                selectedSurvey.id !== ""
                                  ? undefined
                                  : () => showUsers(filter, population.name)
                              }
                              count={
                                filter.isTemplateFilter
                                  ? undefined
                                  : population.usersCount
                              }
                              countWarning={
                                population.usersCount <
                                props._session.participationMin
                              }
                              isWithMargin
                              onClick={() =>
                                selectPopulation(
                                  filter,
                                  population,
                                  filter.isTemplateFilter ? true : false
                                )
                              }
                              color={
                                isPopulationSelected(population.id)
                                  ? props._session.accountColors.active
                                  : ""
                              }
                            >
                              {population.name}
                            </Chip>
                          )
                        )}
                      </div>

                      <div className="height-20" />
                    </div>
                  )
              )}
            </div>
          )}
        </PageLoader>
      </Scroller>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  _session: state._session,
  filter: state.filter,
  survey: state.survey,
  template: state.template,
});

export default connect(mapStateToProps)(
  withTranslation()(SelectPopulationsModal)
);
