/**
 * GET-USER-FILTER-DATES
 * Generate list of range for date filters in the dashboard
 * Date depends of account params
 */

import { store } from "@/index";
// import store from "@/core/store";
import { AccountOptions } from "@/redux/account.types";

import { dateFromMonths } from "./date-months-to-range.utils";
import { dateFromYears } from "./date-years-to-range.utils";
import { formatDate } from "./format-date.utils";
import { t } from "@/translate/t";

//Type of lists (birth date or company welcome date)
type UserFilterType = "BIRTH_DATE" | "COMPANY_WELCOME_DATE";
type DateType = "year" | "month";

//Output type
export interface UserFilterDates {
  id: string;
  name: string;
  description: string;
}

const getUserFilterDates = (
  type: UserFilterType,
  refDate: Date | null
): UserFilterDates[] => {
  //Get params for store
  const options: AccountOptions = store.getState()._session.accountOptions;

  //Init array
  const list: UserFilterDates[] = [];

  //Init ceils array
  //Add a max value
  let ceils: number[] =
    type === "BIRTH_DATE"
      ? options.ceilsBirthDate
      : options.ceilsCompanyWelcomeDate;
  ceils = ceils.concat([10000]);

  //Loop on values
  ceils.forEach((max: number, i: number) => {
    let min = ceils[i - 1] ? ceils[i - 1] : 0;

    //Get index for text
    let textIndex: string = "2";
    if (i === 0) textIndex = "1";
    else if (i + 1 === ceils.length) textIndex = "3";

    //Update values for companyWelcome date
    //If less than 12 month display month values
    let minType: DateType = "year";
    let maxType: DateType = "year";
    let nameCode: string = "";
    let description: string = "";
    let params: any = {};

    //CASE COMPANY WELCOME DATE
    if (type === "COMPANY_WELCOME_DATE") {
      if (textIndex === "2") {
        params = {
          ceilMin: formatDate(dateFromMonths(refDate, min), false, false),
          ceilMax: formatDate(dateFromMonths(refDate, max), false, false),
        };

        //display 1 month less for superior ceils in intermediate classes
        max--;
      } else {
        params = {
          ceil: formatDate(
            dateFromMonths(refDate, i === 0 ? max : min),
            false,
            false
          ),
        };
      }

      minType = min < 24 ? "month" : "year";
      maxType = max < 24 ? "month" : "year";
      if (minType === "year") min = Math.round(min / 12);
      if (maxType === "year") max = Math.round(max / 12);

      //Define nameCode
      //Depend of the type
      //For welcome date, make conversion in month if param is less than 12 months

      nameCode =
        "user_company_welcome_date_" +
        textIndex +
        "_" +
        (i === 0 ? maxType : minType);
      if (minType === "month" && maxType === "year" && textIndex === "2") {
        nameCode = "user_company_welcome_date_2_both";
      }

      description = t(
        "user_company_welcome_date_description" + textIndex,
        params
      );
    } else {
      if (textIndex === "2") {
        params = {
          ceilMin: formatDate(dateFromYears(refDate, min), false, false),
          ceilMax: formatDate(dateFromYears(refDate, max), false, false),
        };

        //display 1 year less for superior ceils in intermediate classes
        max--;
      } else {
        params = {
          ceil: formatDate(
            dateFromYears(refDate, i === 0 ? max : min),
            false,
            false
          ),
        };
      }

      nameCode = "user_birth_date_" + textIndex;
      description = t("user_birth_date_description" + textIndex, params);
    }

    //Define name (with max and min params to display)
    const name = t(nameCode, {
      min,
      max,
      s1: min > 1 ? "s" : "",
      s2: max > 1 ? "s" : "",
    });

    //Build list
    list.push({
      id: i.toString(),
      name,
      description,
    });
  });

  //Return result
  return list;
};

export default getUserFilterDates;
