/**
 * PROJECT-TEST-ONBOARDING.MODAL
 * Create a test survey in order to test the tool
 */
import { useEffect, useState } from "react";
import Modal from "@/components/modal";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Session } from "@/redux/_session.types";
import { Project } from "@/redux/project.types";
import { v4 as uuid } from "uuid";
import { Email } from "@/redux/email.types";
import i18n from "@/translate/i18n";
import { Config } from "@/redux/_config.types";
import LoadingModal from "./loading.modal";
import { store } from "@/index";
// import store from "@/core/store";
import { projectLaunch, projectUpdate } from "@/redux/project.actions";
import { emailUpdate } from "@/redux/email.actions";
import CongratulationModal from "./congratulation.modal";
import { invitationFetch } from "@/redux/invitation.actions";
import { Invitation } from "@/redux/invitation.types";
import env from "@/env";
import { NavigateFunction, useNavigate } from "react-router-dom";

interface StateProps extends WithTranslation {
  _session: Session;
}

interface OwnProps {
  onClose: Function;
  onNext: Function;
}

type Props = StateProps & OwnProps;

const MODAL_LAUNCH = "MODAL_LAUNCH";
const MODAL_SAVING = "MODAL_SAVING";
const MODAL_CONFIRM = "MODAL_CONFIRM";

function ProjectTestOnboardingModal(props: Props) {
  const { t } = props;
  const navigate: NavigateFunction = useNavigate();

  const [currentModal, setCurrentModal] = useState(MODAL_LAUNCH);

  const [project, setProject] = useState(new Project());
  const [email, setEmail] = useState(new Email());
  const [invitation, setInvitation] = useState(new Invitation());

  useEffect(() => {
    initProject();

    function initProject() {
      //Init project template from template informations
      const accountName = props._session.accountName;
      const project = new Project();
      project.id = uuid();
      project.languages = [i18n.language];
      project.name = accountName + " - " + t("survey_test");
      project.messageOptions = project.getInitialMessageOptions(
        accountName,
        project.name
      );
      project.TemplateId = new Config().welcomeTemplateId;
      project.usersForTest = [props._session.userId];

      //Add emails from template
      const email = new Email().getFromMessage(
        t("email_template_0"),
        project.messageOptions,
        accountName
      );
      project.emailsOrder = [email.id];

      //Set values in the state
      setProject(project);
      setEmail(email);
    }
  }, [props._session.accountName, props._session.userId]);

  //Get invitation when survey test is launch
  async function getInvitations(surveyId: string) {
    const invitationsResponse: any = await store.dispatch(
      invitationFetch(surveyId, "", 1, 0)
    );
    if (invitationsResponse.error) {
      setCurrentModal(MODAL_LAUNCH);
    } else {
      if (invitationsResponse.length > 0) {
        setInvitation(new Invitation(invitationsResponse[0]));
      }

      setCurrentModal(MODAL_CONFIRM);
    }
  }

  //Click on next
  //> Update project in the database
  //> Update email for the project
  //> Launch the project as test
  async function launch() {
    setCurrentModal(MODAL_SAVING);
    const projectResponse: any = await store.dispatch(projectUpdate(project));
    const emailResponse: any = await store.dispatch(
      emailUpdate(project.id, email)
    );
    const surveyResponse: any = await store.dispatch(
      projectLaunch(project.id, true, project.name, i18n.language, email.id)
    );

    if (projectResponse.error || emailResponse.error || surveyResponse.error) {
      setCurrentModal(MODAL_LAUNCH);
    } else {
      setTimeout(() => {
        getInvitations(surveyResponse.id);
      }, 2000);
    }
  }

  //Go to results page
  //If click on the qv button open the quickvote page
  function toResults(openQuickvote: boolean) {
    if (openQuickvote) {
      window.open(
        env.REACT_APP_URL_QUICKVOTE + "/" + invitation.code,
        "_blank"
      );
    }

    navigate("/dashboard/home");
  }

  return (
    <div>
      {currentModal === MODAL_LAUNCH && (
        <Modal
          isCloseButtonVisible
          title={t("home_welcome_project")}
          onNext={launch}
          onClose={() => props.onClose()}
        >
          <div style={{ paddingTop: 20 }}>
            <p>
              <b>
                {t("project_onboarding_1", { email: props._session.email })}
              </b>
            </p>
            <p>{t("project_onboarding_2")}</p>
          </div>
        </Modal>
      )}

      {currentModal === MODAL_SAVING && <LoadingModal />}

      {currentModal === MODAL_CONFIRM && (
        <CongratulationModal
          text={t("survey_created")}
          onNext={invitation.aid ? () => toResults(false) : undefined}
          onClose={() => toResults(true)}
          onCloseText={t("utils_close")}
          onNextText={t("project_onboarding_quickvote")}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  _session: state._session,
});

export default connect(mapStateToProps)(
  withTranslation()(ProjectTestOnboardingModal)
);
