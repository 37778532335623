/**
 * EDIT-AXIS.MODAL
 * Edit axis of the questionnaire
 */

import Modal from "@/components/modal";
import { connect } from "react-redux";
import { withTranslation, WithTranslation } from "react-i18next";
import TextInput from "@/components/text-input";
import { AxisState } from "@/redux/axis.types";
import { store } from "@/index";
// import store from "@/core/store";
import {
  axisEdit,
  axisEditName,
  axisStatus,
  axisUpdate,
  axisUpdate_AsSupervisor,
} from "@/redux/axis.actions";
import { TemplateState } from "@/redux/template.types";
import { useState } from "react";
import Tabs from "@/components/tabs";
import {
  STATUS_SAVED,
  STATUS_SAVE_ERROR,
  STATUS_SAVING,
} from "@/redux/_status.types";
import { topicEditAxes } from "@/redux/topic.actions";
import { Session, SESSION_COLORS } from "@/redux/_session.types";
import ColorPickerWidget from "@/widgets/color-picker.widget";
import DeleteAxisModal from "./delete-axis.modal";
import DuplicateTemplateAskModal from "./duplicate-template-ask.modal";
import EditorEditAxisImageWidget from "@/widgets/editor-edit-axis-image.widget";

interface StateProps extends WithTranslation {
  _session: Session;
  axis: AxisState;
  template: TemplateState;
}

interface OwnProps {
  onClose: Function;
  editImageOnly?: boolean;
}

type Props = StateProps & OwnProps;

const MODAL_DELETE = "MODAL_DELETE"; //Confirm delete axe

function EditAxisModal(props: Props) {
  const { t } = props;

  //Language for the tabs
  const [currentLanguage, setCurrentLanguage] = useState(
    props.template.active.languages[0]
  );
  const [currentModal, setCurrentModal] = useState<string | null>(null);

  //Remove axis
  function removeConfirm() {
    setCurrentModal(MODAL_DELETE);
  }

  //Get tabs from the languages
  function getTabs() {
    const tab = {};
    props.template.active.languages.forEach((lg) => {
      tab[lg] = lg.toUpperCase();
    });
    return tab;
  }

  //Clic next
  //Save the new axis in the BDD (differentes routes if connected as supervisor or not)
  //Si save OK
  //> copy axis into the list of axis
  //> update all topics with this axis
  async function next() {
    const updateFunction =
      props._session.interfaceType === "SUPERVISOR"
        ? axisUpdate_AsSupervisor
        : axisUpdate;
    store.dispatch(axisStatus(STATUS_SAVING));
    const response: any = await store.dispatch(
      updateFunction(props.axis.active, props.template.active.id)
    );
    if (response.error) {
      store.dispatch(axisStatus(STATUS_SAVE_ERROR));
    } else {
      store.dispatch(axisStatus(STATUS_SAVED));
      store.dispatch(topicEditAxes(props.axis.active));
      props.onClose();
    }
  }

  return props.template.active.fromSupervisor &&
    props._session.interfaceType !== "SUPERVISOR" ? (
    <DuplicateTemplateAskModal onClose={() => props.onClose()} />
  ) : props.editImageOnly ? (
    <Modal
      isCloseButtonVisible
      title={t("axis_image")}
      onClose={() => props.onClose()}
      onNext={next}
    >
      <EditorEditAxisImageWidget />
    </Modal>
  ) : (
    <Modal
      isCloseButtonVisible
      disableKeyEvents
      status={props.axis.status}
      onClose={() => props.onClose()}
      onDelete={removeConfirm}
      onNext={next}
      title={t("axis_edit")}
    >
      {
        /** Confirmer la suppression */
        currentModal === MODAL_DELETE && (
          <DeleteAxisModal onClose={props.onClose} />
        )
      }

      {
        /** si plusieurs langues alors onglets */
        props.template.active.languages.length > 1 && (
          <Tabs
            active={currentLanguage}
            tabs={getTabs()}
            onClick={(tab) => setCurrentLanguage(tab)}
          />
        )
      }

      <div className="flex">
        <TextInput
          autoFocus
          onChange={(e) =>
            store.dispatch(axisEditName(currentLanguage, e.value))
          }
          title={t("axis_name")}
          value={
            props.axis.active.name[currentLanguage]
              ? props.axis.active.name[currentLanguage]
              : ""
          }
          help={t("axis_help")}
        />

        <div className="width-20" />

        <ColorPickerWidget
          selectedColor={props.axis.active.color}
          colors={SESSION_COLORS}
          isAlignedLeft
          isCustom
          onSelect={(color) => store.dispatch(axisEdit("color", color))}
        />

        <div className="width-100" />
      </div>

      <EditorEditAxisImageWidget />

      <div className="height-40" />
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  _session: state._session,
  axis: state.axis,
  template: state.template,
});

export default connect(mapStateToProps)(withTranslation()(EditAxisModal));
