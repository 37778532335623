/**
 * DASHBOARD-INEQUALITIES-DISPLAY.WIDGET
 * Gini
 */

import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { SurveyState } from "@/redux/survey.types";
import { Session } from "@/redux/_session.types";
import { useEffect, useState } from "react";
import getUserFilterDates, {
  UserFilterDates,
} from "@/utils/get-user-filter-dates.utils";
import { orderBy } from "lodash";
import Space from "@/components/space";
import ListCell from "@/components/list-cell";
import DashboardRepartitionBarsWidget from "./dashboard-repartition-bars.widget";
import Chip from "@/components/chip";
import ContextMenu from "@/components/context-menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { fetchDisparity, fetchRepartition } from "@/redux/_archive.actions";
import { store } from "@/index";
// import store from "@/core/store";
import { FilterState } from "@/redux/filter.types";
import {
  DISPARITY_VERY_LOW,
  getDisparityLevel,
} from "@/utils/get-disparity-level.utils";
import Card from "@/components/card";
import { STATUS_LOADED, STATUS_LOADING } from "@/redux/_status.types";
import { dateYearsToRange } from "@/utils/date-years-to-range.utils";
import { dateMonthsToRange } from "@/utils/date-months-to-range.utils";

interface StateProps {
  _session: Session;
  filter: FilterState;
  survey: SurveyState;
}

interface OwnProps {
  rowData: InequalityData;
  filterName?: string;
  topicColor?: string;
  reverse?: boolean;
  responseCount: number;
  nbDisplay?: number;
  isReverse?: boolean;
}

type Props = StateProps & OwnProps & WithTranslation;

type InequalityAttributeData = {
  aid: string;
  name?: string;
  note?: number;
  users: number;
  sd?: number;
};

export type InequalityData = {
  attributes: InequalityAttributeData[];
  fkQuestion: string;
  note?: number;
  users: number;
  sd?: number;
};

export const INEQUALITY_COLORS: string[] = [
  "#fff",
  "#e9e9e9",
  "#03273f44",
  "#03273f88",
  "#03273fbb",
  "#03273fff",
];

const NB_DISPLAY: number = 3;

const CEIL_DISPLAY_CHIPS: number = DISPARITY_VERY_LOW;

function DashboardInequalitiesDisplayWidget(props: Props) {
  const { t } = props;

  const nbDisplay = props.nbDisplay ? props.nbDisplay : NB_DISPLAY;

  const birthDatePopulations: UserFilterDates[] = getUserFilterDates(
    "BIRTH_DATE",
    props.survey.active.dateStart
  );
  const companyWelcomeDatePopulations: UserFilterDates[] = getUserFilterDates(
    "COMPANY_WELCOME_DATE",
    props.survey.active.dateStart
  );

  const [rowData, setRowData] = useState<InequalityData>(props.rowData);

  const [filterName, setFilterName] = useState<string | undefined>(
    props.filterName
  );

  const [showAll, setShowAll] = useState<boolean>(false);

  const [openContextAid, setOpenContextAid] = useState<string | undefined>(
    undefined
  );

  const [repartition, setRepartition] = useState<number[]>([]);

  const [isContextLoading, setIsContextLoading] = useState<boolean>(false);

  const [isRepartitionLoading, setIsRepartitionLoading] =
    useState<boolean>(true);

  const [defaultRepartition, setDefaultRepartition] = useState<number[]>([]);

  useEffect(() => {
    setRowData(props.rowData);
  }, [props.rowData]);

  useEffect(() => {
    setFilterName(props.filterName);
  }, [props.filterName]);

  useEffect(() => {
    async function getDefaultRepartition() {
      setIsRepartitionLoading(true);
      const response: any = await store.dispatch(
        fetchRepartition(props.filter.dashboard, false, null, null)
      );

      if (response.error) {
        setDefaultRepartition([]);
      } else {
        setDefaultRepartition(response.values);
      }

      setIsRepartitionLoading(false);
    }

    if (rowData.fkQuestion === "_") {
      getDefaultRepartition();
    }
  }, [props.filter.dashboard, rowData.fkQuestion]);

  useEffect(() => {
    async function loadRepartition() {
      setIsContextLoading(true);

      let attributeAid: string | undefined = openContextAid;

      if (
        attributeAid &&
        attributeAid !== "other" &&
        (filterName === "birth_date" || filterName === "company_welcome_date")
      ) {
        if (filterName === "birth_date") {
          attributeAid = dateYearsToRange(
            props.survey.active.dateStart,
            props._session.accountOptions.ceilsBirthDate,
            attributeAid
          );
        } else {
          attributeAid = dateMonthsToRange(
            props.survey.active.dateStart,
            props._session.accountOptions.ceilsCompanyWelcomeDate,
            attributeAid
          );
        }
      }

      const response: any = await store.dispatch(
        rowData.fkQuestion === "_"
          ? fetchRepartition(
              props.filter.dashboard,
              false,
              !filterName || filterName === "company" ? null : filterName,
              !attributeAid || attributeAid === "_" ? null : attributeAid
            )
          : fetchDisparity(
              props.filter.dashboard,
              filterName,
              rowData.fkQuestion,
              attributeAid,
              props.responseCount
            )
      );

      if (response.error) {
        setRepartition([]);
      } else {
        setRepartition(
          rowData.fkQuestion === "_" ? response.values : response.repartition
        );
      }

      setIsContextLoading(false);
    }

    if (openContextAid) {
      loadRepartition();
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.filter.dashboard,
    filterName,
    rowData.fkQuestion,
    openContextAid,
    props.responseCount,
  ]);

  function displayScores(
    inequality: number,
    note?: number,
    noteColor?: string
  ) {
    return (
      <>
        <div className="flex" style={{ textAlign: "center" }}>
          <div className="flex1">{t("utils_mean")}</div>

          <div className="flex1">{t("disparity")}</div>
        </div>

        <div className="flex" style={{ textAlign: "center" }}>
          <div
            className="flex1 flex flex-dcol"
            style={{
              color: noteColor ? noteColor : "#8C8C8C",
              fontSize: 32,
              fontWeight: 700,
            }}
          >
            <Space />
            {note ? note / 10 : t("utils_undefined")}
            <Space />
          </div>

          <div
            className="flex1 flex flex-dcol"
            style={{
              color: INEQUALITY_COLORS[inequality],
              fontSize: 20,
              fontWeight: 700,
            }}
          >
            <Space />
            {t("disparity_level_" + inequality)}
            <Space />
          </div>
        </div>
      </>
    );
  }

  function getColumnName(key: string | null): {
    name: string;
    description?: string;
  } {
    if (!key || key === "other") {
      const name: string = t("utils_others");
      return { name };
    } else if (
      filterName === "birth_date" ||
      filterName === "company_welcome_date"
    ) {
      const datePopulations: UserFilterDates[] =
        filterName === "birth_date"
          ? birthDatePopulations
          : companyWelcomeDatePopulations;
      const { name, description } = key.match(/^-?\d+$/)
        ? datePopulations[parseInt(key, 10)]
        : { name: key, description: key };
      return { name, description };
    } else if (filterName === "gender") {
      const name: string = t("user_gender_" + key);
      return { name };
    } else {
      const name: string = key;
      return { name };
    }
  }

  function renderContextMenu(
    populationName: string,
    note: number | undefined,
    users: number,
    inequality: number,
    noteColor?: string
  ): JSX.Element {
    return (
      <div style={{ padding: "10px 30px" }}>
        <div className="flex">
          <div>
            <b>{populationName}</b>
          </div>

          <div className="width-20" />
          <Space />

          <div className="flex width-100">
            <Space />
            {users + " " + t(users > 1 ? "propositions" : "proposition")}
          </div>
        </div>

        <>
          <div className="height-20" />

          {displayScores(inequality, note, noteColor)}

          <div className="height-20" />

          {isContextLoading ? (
            <div className="flex">
              <Space />
              <FontAwesomeIcon icon={faCircleNotch} spin />
              <Space />
            </div>
          ) : (
            <>
              {repartition.length > 0 ? (
                <>
                  <div className="height-20" />

                  <div>{t("survey_repartition")}</div>

                  <div className="height-20" />

                  <DashboardRepartitionBarsWidget
                    classicColors={props.rowData.fkQuestion !== "_"}
                    values={repartition}
                    isReverse={props.isReverse}
                    showSubtext={props.rowData.fkQuestion !== "_"}
                    showCeils={props.rowData.fkQuestion === "_"}
                  />
                </>
              ) : (
                <div style={{ marginTop: 20 }}>
                  {t("survey_no_results_help", {
                    count: props._session.participationMin,
                  })}
                </div>
              )}
            </>
          )}

          <div className="height-20" />
        </>
      </div>
    );
  }

  return (
    <>
      {rowData.fkQuestion === "_" ? (
        <>
          <ListCell width={472}>
            <Card
              isFullWidth
              status={isRepartitionLoading ? STATUS_LOADING : STATUS_LOADED}
            >
              <div className="height-20" />

              <div className="flex">
                <Space />

                <div className="repartition">
                  <DashboardRepartitionBarsWidget
                    values={defaultRepartition}
                    showSubtext={props.rowData.fkQuestion !== "_"}
                    showCeils={props.rowData.fkQuestion === "_"}
                  />
                </div>

                <Space />
              </div>

              <div className="height-20" />
            </Card>
          </ListCell>

          <ListCell width={60} />
        </>
      ) : (
        <ListCell width={60}>
          <div>
            <div className="_hover" onClick={() => setOpenContextAid("_")}>
              <div
                style={{
                  backgroundColor:
                    INEQUALITY_COLORS[getDisparityLevel(rowData.sd)],
                  border:
                    "2px solid " +
                    (openContextAid === "_"
                      ? props._session.accountColors.active
                      : "transparent"),
                  padding: 2,
                  borderRadius: 30,
                  height: 30,
                  width: 30,
                }}
              />
            </div>

            {openContextAid === "_" && (
              <div className="rel" style={{ zIndex: 100 }}>
                <ContextMenu
                  isLarge
                  onClose={() => setOpenContextAid(undefined)}
                >
                  {renderContextMenu(
                    props._session.accountName.toLocaleUpperCase(),
                    rowData.note,
                    rowData.users,
                    getDisparityLevel(rowData.sd),
                    props.topicColor
                  )}
                </ContextMenu>
              </div>
            )}
          </div>
        </ListCell>
      )}

      <ListCell width={400}>
        {rowData.attributes.some(
          (attribute: InequalityAttributeData) =>
            attribute.sd && attribute.sd > CEIL_DISPLAY_CHIPS
        ) ? (
          <div className="flex flex-dcol">
            {rowData.fkQuestion === "_" && (
              <h3>{t("disparities_populations")}</h3>
            )}

            <div style={{ margin: "auto" }} className="flex flex-wrap">
              {orderBy(
                rowData.attributes.filter(
                  (attribute: InequalityAttributeData) =>
                    attribute.sd && attribute.sd > CEIL_DISPLAY_CHIPS
                ),
                (attribute: InequalityAttributeData) => attribute.sd,
                ["desc"]
              )
                .slice(0, showAll ? rowData.attributes.length : nbDisplay)
                .map((attribute: InequalityAttributeData) => (
                  <div key={attribute.aid}>
                    <Chip
                      isWithMargin
                      icon={
                        openContextAid === attribute.aid
                          ? faCaretDown
                          : undefined
                      }
                      color={INEQUALITY_COLORS[getDisparityLevel(attribute.sd)]}
                      onClick={() => setOpenContextAid(attribute.aid)}
                    >
                      {
                        getColumnName(
                          attribute.name ? attribute.name : attribute.aid
                        ).name
                      }
                    </Chip>

                    {openContextAid === attribute.aid && (
                      <div className="rel" style={{ zIndex: 100 }}>
                        <ContextMenu
                          isLarge
                          positionRight
                          onClose={() => setOpenContextAid(undefined)}
                        >
                          {renderContextMenu(
                            getColumnName(
                              attribute.name ? attribute.name : attribute.aid
                            ).name,
                            attribute.note,
                            attribute.users,
                            getDisparityLevel(attribute.sd),
                            props.topicColor
                          )}
                        </ContextMenu>
                      </div>
                    )}
                  </div>
                ))}
            </div>

            {rowData.attributes.filter(
              (attribute: InequalityAttributeData) =>
                attribute.sd && attribute.sd > CEIL_DISPLAY_CHIPS
            ).length > nbDisplay && (
              <div style={{ paddingLeft: 10, fontSize: 12, marginTop: -10 }}>
                <div className="height-20" />
                {showAll ? (
                  <u
                    className="_hover grey-t"
                    onClick={() => setShowAll(false)}
                  >
                    {t("utils_hide")}
                  </u>
                ) : (
                  <u className="_hover grey-t" onClick={() => setShowAll(true)}>
                    {t("utils_show")}
                  </u>
                )}
              </div>
            )}

            <Space />
          </div>
        ) : (
          <div />
        )}
      </ListCell>
    </>
  );
}

const mapStateToProps = (state) => ({
  _session: state._session,
  filter: state.filter,
  survey: state.survey,
  topic: state.topic,
});

export default connect(mapStateToProps)(
  withTranslation()(DashboardInequalitiesDisplayWidget)
);
