/**
 * ADM-IMPORT-MAPPING.WIDGET
 * Proceed to mapping
 */
import { useEffect, useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import Button from "@/components/button";
import { User } from "@/redux/user.types";
import { toast } from "react-toastify";
import { store } from "@/index";
// import store from "@/core/store";
import Space from "@/components/space";
import languages from "@/translate/lang.json";
import {
  faCrown,
  faExclamationTriangle,
  faLink,
  faPlusCircle,
  faTimes,
  faUnlink,
} from "@fortawesome/free-solid-svg-icons";
import ListFields from "@/components/list-fields";
import ListItem from "@/components/list-item";
import { connect } from "react-redux";
import { Filter, FilterState } from "@/redux/filter.types";
import Dropdown from "@/components/dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Session } from "@/redux/_session.types";
import i18n from "@/translate/i18n";
import Modal from "@/components/modal";
import TextInput from "@/components/text-input";
import { v4 as uuid } from "uuid";
import {
  filterActivateAndEdit,
  filterFetch,
  filterGet,
} from "@/redux/filter.actions";
import { STATUS_LOADED, STATUS_LOADING } from "@/redux/_status.types";
import PageLoader from "@/components/page-loader";
import ImportModal from "@/modals/import.modal";
import ListButton from "@/components/list-button";
import ListCell from "@/components/list-cell";
import Chip from "@/components/chip";
import UserFilterPictureWidget from "./user-filter-picture.widget";
import { compact, flatten, pullAll, uniq } from "lodash";
import { Population } from "@/redux/population.types";

interface StateProps extends WithTranslation {
  _session: Session;
  filter: FilterState;
}

interface OwnProps {
  onNext: Function;
}

type Props = StateProps & OwnProps;

interface DefaultAttribute {
  id: string | null;
  name: string;
}

interface FileColumnError {
  birthDate: boolean;
  companyWelcomeDate: boolean;
  email: boolean;
  gender: boolean;
}

interface FileColumn {
  name: string;
  sample: string[];
  items: string[];
  filterId: string | null;
  error: FileColumnError;
  isManager: boolean;
}

const MODAL_ADD = "MODAL_ADD";
const MODAL_IMPORT = "MODAL_IMPORT";

const LANGUAGES: any = languages.map((x) => x.code);

function AdmImportMappingWidget(props: Props) {
  const { t } = props;

  //Init default attributes for dropdown
  const [defaultAttributes] = useState<DefaultAttribute[]>([
    { id: null, name: t("utils_undefined") },
    { id: "email", name: t("user_email") },
    { id: "firstname", name: t("user_firstname") },
    { id: "lastname", name: t("user_lastname") },
    { id: "gender", name: t("user_gender") },
    { id: "birthDate", name: t("user_birth_date") },
    { id: "companyWelcomeDate", name: t("user_company_welcome_date") },
    { id: "language", name: t("user_language") },
    { id: "phone", name: t("user_phone") },
    { id: "address", name: t("user_address") },
  ]);

  //Init file columns from response after upload file
  const [fileColumns, setFileColumns] = useState<FileColumn[]>(
    props.filter.importResponse.columnsImport
  );

  //Filter name to edit
  const [filterName, setFilterName] = useState("");

  //Current modal to display
  const [currentModal, setCurrentModal] = useState<string | null>(null);

  //Loading is pending
  const [isLoading, setIsLoading] = useState(true);

  //Column of the file
  const [selectedColumn, setSelectedColumn] = useState<any>({
    name: "",
    items: [],
    sample: [],
  });

  //Future users
  const [usersToImport, setUsersToImport] = useState<User[]>([]);

  //Manager where no mapping was found
  const [notAssociatedManagers, setNotAssociatedManagers] = useState<string[]>(
    []
  );

  //Onload
  //Fetch filters for server and update columns with already mapped filters
  useEffect(() => {
    load();

    async function load() {
      //Load data
      const filtersResponse: any = await store.dispatch(
        filterFetch(false, false)
      );
      const filters: Filter[] = filtersResponse.error ? [] : filtersResponse;
      store.dispatch(filterGet(filters));

      //Update filter according importName
      const newFileColumns: FileColumn[] = [];
      props.filter.importResponse.columnsImport.forEach(
        (column: FileColumn) => {
          const currentFilter = filters.find(
            (x) => x.importName === column.name
          );
          if (currentFilter) column.filterId = currentFilter.id;
          newFileColumns.push(column);
        }
      );

      //Update state
      setFileColumns(newFileColumns);
      setIsLoading(false);
    }
  }, [props.filter.importResponse.columnsImport]);

  //Add new filter for the column
  //On click sur add button on the context menu
  function addFilter() {
    const filterId = uuid();

    const filter = new Filter({
      id: filterId,
      importName: selectedColumn.name,
      name: filterName,
    });

    store.dispatch(filterGet(props.filter.list.concat([filter])));
    selectItem(selectedColumn, filterId);
    setCurrentModal(null);
  }

  //Detect if there are errors in the mapping
  //for example email link to a field without email adresses
  function checkErrors() {
    let errors: number = 0;
    fileColumns.forEach((column) => {
      if (isColumnInError(column)) errors++;
    });
    return errors;
  }

  //clic sur le + pour ajouter un filtre personnalisé
  function createFilter(column: FileColumn) {
    setCurrentModal(MODAL_ADD);
    setSelectedColumn(column);
    setFilterName(column.name);
  }

  //Display error message if data type does not match with expected column
  function getError(filterId) {
    switch (filterId) {
      case "email":
        return t("error_email");
      case "birthDate":
        return t("error_numeric");
      case "companyWelcomeDate":
        return t("error_numeric");
      case "gender":
        return t("error_gender");
      default:
        return t("error_mapping");
    }
  }

  //Format date to display
  function formatDate(value: number) {
    if (value < 100) {
      return new Date(new Date().getFullYear() - value, 0);
    } else if (value) {
      let dateString = value.toString();
      const dateParts = dateString.split("/");
      if (dateParts[2]) {
        if (dateParts[2].length === 4) {
          dateString = dateParts[2] + "/" + dateParts[1] + "/" + dateParts[0];
        }
      }

      const date = new Date(dateString);
      return date.toString() !== "Invalid Date" ? new Date(dateString) : null;
    } else {
      return null;
    }
  }

  //Format gender
  function formatGender(gender: string) {
    const f: string[] = [
      "f",
      "F",
      "femme",
      "Femme",
      "Féminin",
      "féminin",
      "Female",
    ];
    const m: string[] = [
      "m",
      "M",
      "H",
      "h",
      "homme",
      "Homme",
      "Masculin",
      "masculin",
      "Male",
    ];
    if (f.indexOf(gender) > -1) {
      return "f";
    } else if (m.indexOf(gender) > -1) {
      return "m";
    } else {
      return null;
    }
  }

  //Format language
  function formatLanguage(language: string) {
    const lang: string = language ? language.toLowerCase() : "empty";
    if (LANGUAGES.indexOf(lang) > -1) {
      return lang;
    } else {
      return i18n.language;
    }
  }

  //Get custom filter
  function getCustomFilter(column: FileColumn) {
    return props.filter.list.find((x) => x.id === column.filterId);
  }

  //Get attribute
  function getFilter(column: FileColumn) {
    const defaultAttribute = defaultAttributes.find(
      (x) => x.id === column.filterId
    );
    if (defaultAttribute) {
      return defaultAttribute;
    } else {
      return getCustomFilter(column);
    }
  }

  //Icon si m(b)appé ou non
  function getIcon(column) {
    if (isColumnInError(column)) {
      return (
        <div className="adm-mapping-icon red-t">
          <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
        </div>
      );
    } else if (column.filterId) {
      return (
        <div
          className="adm-mapping-icon"
          style={{ color: props._session.accountColors.active }}
        >
          <FontAwesomeIcon icon={faLink}></FontAwesomeIcon>
        </div>
      );
    } else {
      return (
        <div className="adm-mapping-icon grey-t">
          <FontAwesomeIcon icon={faUnlink}></FontAwesomeIcon>
        </div>
      );
    }
  }

  //Get other count for filter add modal
  function getOtherPopulationsCount() {
    return selectedColumn.items.length - selectedColumn.sample.length;
  }

  //Return value for the column
  //Detect default attribute then custom filters
  function getFilterName(column: FileColumn) {
    const filter = getFilter(column);
    return filter ? filter.name : null;
  }

  //Detect if there is an error for the column
  function isColumnInError(column: FileColumn) {
    if (column.filterId) {
      return column.error[column.filterId] ? true : false;
    } else {
      return false;
    }
  }

  //Select item from the list
  async function selectItem(column: FileColumn, filterId: string) {
    const newFileColumns: FileColumn[] = [];

    //Remove old associations
    fileColumns.forEach((oldColumn) => {
      if (oldColumn.filterId === filterId) {
        oldColumn.filterId = null;
      }

      newFileColumns.push(oldColumn);
    });

    //Add new association
    newFileColumns.forEach((newColumn) => {
      if (newColumn.name === column.name) {
        newColumn.filterId = filterId;
      }
    });

    //Update state
    setFileColumns(newFileColumns);
  }

  //Define column as "isManager column"
  async function setIsManager(column: FileColumn) {
    const filter = props.filter.list.find((x) => x.id === column.filterId);
    if (filter)
      store.dispatch(
        filterActivateAndEdit(filter, "isManager", !column.isManager)
      );
    setFileColumns(
      fileColumns.map((x) =>
        x.name === column.name
          ? Object.assign({}, x, { isManager: !x.isManager })
          : x
      )
    );
  }

  //On click on "import data" button
  //> check errors
  //> prepare filters
  //> prepare users
  //> redirection to adm import
  async function storeData() {
    const errors: number = checkErrors();

    //Check other erreurs (incompatible associations)
    if (errors > 0) {
      toast(t("field_bad_association") + " (" + errors + ")", {
        type: "error",
      });
    } else {
      //Remove unsued filters
      const filterIds = fileColumns.map((x) => x.filterId);
      store.dispatch(
        filterGet(props.filter.list.filter((x) => filterIds.indexOf(x.id) > -1))
      );

      //Init array of users to import
      let users: User[] = [];

      //Loop on all attributes
      props.filter.importResponse.users.forEach((importUser) => {
        const user: User = new User({ language: i18n.language });

        fileColumns.forEach((column) => {
          const currentFilter = props.filter.list.find(
            (x) => x.id === column.filterId
          );

          if (currentFilter?.importName) {
            user.attributes[currentFilter.name] =
              importUser.attributes[currentFilter.importName];
          } else if (column.filterId) {
            if (column.filterId === "gender") {
              user.gender = formatGender(importUser.attributes[column.name]);
            } else if (column.filterId === "birthDate") {
              user.birthDate = formatDate(importUser.attributes[column.name]);
            } else if (column.filterId === "companyWelcomeDate") {
              user.companyWelcomeDate = formatDate(
                importUser.attributes[column.name]
              );
            } else if (column.filterId === "language") {
              user.language = formatLanguage(
                importUser.attributes[column.name]
              );
            } else {
              user[column.filterId] = importUser.attributes[column.name];
            }
          }
        });

        if (user.email) {
          user.email = user.email.toLowerCase();
        }

        if (user.email || user.firstname || user.lastname) {
          if (user.lastname || user.firstname) {
            user.username = user.firstname + " " + user.lastname;
          } else if (user.email) {
            user.username = user.email.split("@")[0].replace(".", " ");
          } else {
            user.username = "";
          }

          users.push(user);
        }
      });

      //For each manager Filter
      //Update the populationsObserver attribute for the user matching populations of this filter
      const managerFilters = props.filter.list.filter((x) => x.isManager);
      const managers: string[] = [];
      managerFilters.forEach((managerFilter) => {
        const populations: string[] = uniq(
          compact(users.map((x) => x.attributes[managerFilter.name]))
        );

        populations.forEach((population) => {
          managers.push(population);

          users = users.map((x) =>
            x.username === population ||
            x.lastname + " " + x.firstname === population ||
            x.lastname === population
              ? new User(
                  Object.assign({}, x, {
                    role: "OBSERVER",
                    populationsObserver: x.populationsObserver.concat([
                      new Population({
                        id: managerFilter.name + "_" + population,
                        name: population,
                        filterName: managerFilter.name,
                      }),
                    ]),
                  })
                )
              : x
          );
        });
      });

      //Detect errors of manager importation
      const associatedManagers = flatten(
        users.map((x) => x.populationsObserver.map((x) => x.name))
      );
      setNotAssociatedManagers(pullAll(managers, associatedManagers));

      //Update list of users
      setUsersToImport(users);
      setCurrentModal(MODAL_IMPORT);
    }
  }

  return (
    <PageLoader status={isLoading ? STATUS_LOADING : STATUS_LOADED}>
      {currentModal === MODAL_IMPORT && (
        <ImportModal
          filters={props.filter.list}
          notAssociatedManagers={notAssociatedManagers}
          users={usersToImport}
          onClose={() => setCurrentModal(null)}
          onNext={() => props.onNext()}
        />
      )}

      {
        /**
         * add new filter
         */
        currentModal === MODAL_ADD && (
          <Modal
            isCloseButtonVisible
            isLarge
            onClose={() => setCurrentModal(null)}
            onNext={() => addFilter()}
            title={t("filter_add")}
          >
            <div className="flex">
              <div className="flex1">
                <TextInput
                  title={t("filter_name")}
                  help={t("filters_help")}
                  value={filterName}
                  onChange={(e) => setFilterName(e.value)}
                />

                <div style={{ marginLeft: 60 }}>
                  <p className="grey-t">{t("sample")}</p>

                  {selectedColumn.sample.map((item, i) => (
                    <div className="flex" key={i}>
                      <Chip isWithMargin>{item}</Chip>
                      <Space />
                    </div>
                  ))}

                  {getOtherPopulationsCount() > 0 && (
                    <div className="grey-t" style={{ marginTop: 12 }}>
                      {t("utils_show_others", {
                        count: getOtherPopulationsCount(),
                        s: getOtherPopulationsCount() > 1 ? "s" : "",
                      })}
                    </div>
                  )}

                  <div className="height-20" />

                  {selectedColumn.items.length > 30 && (
                    <div className="orange-t">
                      <FontAwesomeIcon icon={faExclamationTriangle} />
                      &nbsp;
                      {t("filter_warning", {
                        count: selectedColumn.items.length,
                      })}
                    </div>
                  )}
                </div>
              </div>

              <div className="flex1 rel">
                <div className="text-input-title flex">
                  <Space />
                  <b>{t("import_filter_example")}</b>
                  <Space />
                </div>

                <div className="height-10" />

                <img
                  alt="arrow"
                  className="abs"
                  src={require("@/assets/onboarding/arrow-alt.png")}
                  style={{
                    opacity: 0.6,
                    width: 230,
                    height: 80,
                    left: -230,
                    top: 132,
                  }}
                />

                <UserFilterPictureWidget />
              </div>
            </div>
          </Modal>
        )
      }

      <ListFields>
        <div className="flex flex1 width-100">{t("spreadsheet_columns")}</div>
        <div className="width-20" />
        <div className="flex flex1 width-100">{t("sample")}</div>
        <div className="width-20" />
        <div className="flex flex1 width-100">{t("attribute_idtree")}</div>
        <div style={{ width: "90px" }} />
      </ListFields>

      {fileColumns.map((column) => (
        <ListItem key={column.name}>
          <div className="flex flex1">
            <div className="flex1 width-100" style={{ margin: "auto" }}>
              <div className="flex1">{column.name}</div>
            </div>

            <div className="width-20" />

            {column.sample.length ? (
              <div
                className="flex1 grey-t width-100"
                style={{ margin: "auto" }}
              >
                {column.sample.map((sample, i) => (
                  <div key={i} className="adm-mapping-sample">
                    {sample}
                  </div>
                ))}
              </div>
            ) : (
              <div
                className="flex1 grey-t width-100"
                style={{ margin: "auto" }}
              >
                {t("column_empty")}
              </div>
            )}

            <div className="width-20" />

            <div className="flex1 width-100">
              <Dropdown
                error={isColumnInError(column)}
                warning={!column.filterId ? true : false}
                active={column.filterId}
                displayField="name"
                list={defaultAttributes}
                onSelect={(defaultAttribute) =>
                  selectItem(column, defaultAttribute.id)
                }
                value={getFilterName(column)}
              >
                <div className="flex" style={{ margin: "4px 0px 18px 0px" }}>
                  <Space />
                  <Button
                    onClick={() => createFilter(column)}
                    className="primary"
                  >
                    {t("filter_add")}
                  </Button>
                  <Space />
                </div>
              </Dropdown>

              <div className="red-t">
                {isColumnInError(column) && getError(column.filterId)}
              </div>
            </div>

            {!column.filterId ? (
              <div style={{ margin: "16px 62px 16px -62px" }}>
                <ListButton
                  icon={faPlusCircle}
                  onClick={() => createFilter(column)}
                  isPrimary
                  text={t("filter_add")}
                />
              </div>
            ) : getCustomFilter(column)?.id &&
              props._session.connectedAsSupervisor ? (
              <div style={{ margin: "16px 62px 16px -62px" }}>
                <ListButton
                  icon={faCrown}
                  onClick={() => setIsManager(column)}
                  isInactive={!column.isManager}
                  text={t("filter_is_manager")}
                />
              </div>
            ) : (
              <ListCell />
            )}

            <div style={{ marginTop: "16px" }}>{getIcon(column)}</div>
          </div>
        </ListItem>
      ))}

      <div className="flex" style={{ padding: "20px 30px" }}>
        <Space />

        {/* bouton pour importer */}
        <Button className="primary" onClick={() => storeData()}>
          {t("utils_next")}
        </Button>
      </div>
    </PageLoader>
  );
}

const mapStateToProps = (state) => ({
  _session: state._session,
  filter: state.filter,
});

export default connect(mapStateToProps)(
  withTranslation()(AdmImportMappingWidget)
);
