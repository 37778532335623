/**
 * DASHBOARD-AXIS.WIDGET
 * Display Axis' topics and question details
 */

import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { store } from "@/index";
// import store from "@/core/store";
import { Topic, TopicState } from "@/redux/topic.types";
import { Axis } from "@/redux/axis.types";
import env from "@/env";
import ListItem from "@/components/list-item";
import { topicActivate, topicEdit, topicOpen } from "@/redux/topic.actions";
import { sortBy } from "lodash";
import { STATUS_LOADED, STATUS_LOADING } from "@/redux/_status.types";
import { Session } from "@/redux/_session.types";
import Space from "@/components/space";
import Button from "@/components/button";
import DashboardTopicWidget from "./dashboard-topic.widget";
import { axisActivate, axisEdit, axisStatus } from "@/redux/axis.actions";
import { getRepartitionRatio } from "@/utils/satisfaction.utils";
import { getSegmentationPopulations } from "@/utils/get-segmentation-populations.utils";
import { Population } from "@/redux/population.types";

interface StateProps {
  _session: Session;
  topic: TopicState;
}

interface OwnProps {
  axis: Axis;
  isAxisOpenable?: boolean;
}

type Props = StateProps & OwnProps & WithTranslation;

function DashboardAxisWidget(props: Props) {
  const { t } = props;

  const [axis, setAxis] = useState<Axis>(props.axis);

  useEffect(() => {
    setAxis(props.axis);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.axis.id, props.axis.note]);

  //Clic to open all topics for an axis
  async function displayFullAxis() {
    store.dispatch(axisStatus(STATUS_LOADING));

    for (let i = 0; i < props.topic.list.length; i++) {
      const topic = props.topic.list[i];
      store.dispatch(topicActivate(topic));
      store.dispatch(
        topicEdit("open", axis.open ? false : topic.AxisId === axis.id)
      );
    }

    store.dispatch(axisActivate(axis));
    setAxis(new Axis({ ...axis, open: !axis.open }));

    setTimeout(() => {
      store.dispatch(axisEdit("open", !axis.open));
      store.dispatch(axisStatus(STATUS_LOADED));
      document.querySelector("#details-axis-" + axis.id)?.scrollIntoView({
        behavior: "smooth",
      });
    }, 1000);
  }

  //Get all topics for an axis
  function getTopics(axisId) {
    return sortBy(
      props.topic.list.filter((x) => x.AxisId === axisId),
      props._session.dashboardDisplaySettings.dashboardDisplayMode
    ).reverse();
  }

  return (
    <>
      <div className="flex details-axis" id={"details-axis-" + axis.id}>
        <div className="flex">
          <div className="flex1"></div>

          {axis.image && (
            <img
              alt="axis"
              src={env.REACT_APP_URL_SPACE.concat("/Axes/", axis.image, ".png")}
            ></img>
          )}
        </div>

        <div className="flex1 flex">
          <div>
            {axis.id && (
              <div className="details-axis-note" style={{ color: axis.color }}>
                <b>
                  {axis.note ? (
                    axis.getNoteLabel(
                      props._session.dashboardDisplaySettings
                        .dashboardDisplayMode
                    )
                  ) : (
                    <span
                      style={{
                        fontSize: "initial",
                      }}
                    >
                      {t("question_score_false")}
                    </span>
                  )}
                </b>
              </div>
            )}

            <div className="details-axis-title">
              {axis.id ? axis.label : t("questions_without_axis")}

              <br></br>

              <span className="grey-t">
                {getSegmentationPopulations(axis.id).length > 0 &&
                  t("axis_attributes")}
              </span>
            </div>

            <div className="grey-t">
              {getSegmentationPopulations(axis.id).map(
                (population: Population) =>
                  `${population.filterName}: ${population.name}`
              )}
            </div>
          </div>

          <Space />

          {props.isAxisOpenable && (
            <Button onClick={() => displayFullAxis()}>
              {axis.open
                ? t("axis_hide_all_topics")
                : t("axis_display_all_topics")}
            </Button>
          )}
        </div>
      </div>

      {getTopics(axis.id).map((topic: Topic, i) => (
        <div id={"details-topic-" + topic.id} key={i}>
          {i === 0 && topic.repartition.length > 1 && (
            <div className="grey-t details-help">
              {t("topic_repartition_help", {
                ratio: getRepartitionRatio(topic.repartition[1], [
                  ...topic.repartition,
                  topic.noAnswers,
                ]).toFixed(0),
                topic: topic.label,
              })}
            </div>
          )}

          <ListItem
            isEditable
            onClick={
              !topic.open ? () => store.dispatch(topicOpen(topic)) : undefined
            }
          >
            <div className="flex1">
              <DashboardTopicWidget
                currentTopic={topic}
                currentAxis={axis}
                isEditable
                onOpen={() => store.dispatch(topicOpen(topic))}
              />
            </div>
          </ListItem>
        </div>
      ))}
    </>
  );
}

const mapStateToProps = (state) => ({
  _session: state._session,
  topic: state.topic,
});

export default connect(mapStateToProps)(withTranslation()(DashboardAxisWidget));
