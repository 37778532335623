/**
 * ADM-IMPORT-MANUAL.WIDGET
 * Add users one by one
 */
import { connect } from "react-redux";
import { Session } from "../redux/_session.types";
import { useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import Button from "@/components/button";
import { User, UserState } from "../redux/user.types";
import { validateEmail } from "@/utils/validate-email.utils";
import { toast } from "react-toastify";
import {
  userAdd,
  userInsertList,
  userRemove,
  userStatus,
} from "@/redux/user.actions";
import { store } from "@/index";
// import store from "@/core/store";
import {
  STATUS_SAVED,
  STATUS_SAVE_ERROR,
  STATUS_SAVING,
} from "@/redux/_status.types";
import ListItem from "@/components/list-item";
import Space from "@/components/space";
import ListButton from "@/components/list-button";
import {
  faArrowCircleRight,
  faLock,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "@/components/modal";
import UserFilterInfoWidget from "./user-filter-info.widget";
import UserGeneralInfoWidget from "./user-general-info.widget";
import UserOptionalInfoWidget from "./user-optional-info.widget";
import Card from "@/components/card";

interface StateProps extends WithTranslation {
  _session: Session;
  user: UserState;
}

interface OwnProps {
  onNext: Function;
}

type Props = StateProps & OwnProps;

const MODAL_NEXT = "MODAL_NEXT";

function AdmImportManualWidget(props: Props) {
  const { t } = props;

  //Number of users that are really imported
  const [count, setCount] = useState(0);

  //Display modal
  const [currentModal, setCurrentModal] = useState<string | null>(null);

  //display optional info
  const [showOptional, setShowOptional] = useState<boolean>(false);

  //User to edit
  const [user, setUser] = useState<User>(new User());

  //Errors after import
  const [errors, setErrors] = useState<any[]>([]);

  //Click on the arrow button (or "Enter") to add the user to the list
  //Message if error
  //If ok add the new user to the list
  function addUser() {
    if (!isUserValid()) {
      toast(t("import_user_invalid"), { type: "error" });
    } else if (
      props.user.list.some(
        (x) => x.email === user.email && validateEmail(user.email!)
      )
    ) {
      toast(t("utils_email_already_exist"), { type: "error" });
    } else {
      store.dispatch(userAdd(user));
      setUser(new User());
    }
  }

  //Remove user from the list
  function deleteUser(id: string) {
    store.dispatch(userRemove(id));
  }

  //Edit user
  function edit(key: string, value: any) {
    setUser(new User(Object.assign({}, user, { [key]: value })));
  }

  //Edit attributes
  function editAttributes(key, value) {
    const newAttributes: any = Object.assign({}, user.attributes, {
      [key]: value,
    });
    edit("attributes", newAttributes);
  }

  //Check if form is valid
  function isUserValid() {
    let isValid = true;

    if (user.email && !validateEmail(user.email)) {
      isValid = false;
    }

    if (!user.email && !user.firstname.length && !user.lastname.length) {
      isValid = false;
    }

    return isValid;
  }

  //Click on the CTA
  //When it's done show modal to go next
  async function importUsers() {
    if (props.user.list.length > 0) {
      store.dispatch(userStatus(STATUS_SAVING));
      const response: any = await store.dispatch(
        userInsertList(props.user.list)
      );
      if (response.error) {
        store.dispatch(userStatus(STATUS_SAVE_ERROR));
      } else {
        store.dispatch(userStatus(STATUS_SAVED));
        setCurrentModal(MODAL_NEXT);
        setCount(response.count);
        setErrors(response.errors);
      }
    } else {
      toast(t("project_error_import_no_user"), { type: "error" });
    }
  }

  return (
    <div className="flex1" style={{ padding: "16px 32px" }}>
      {currentModal === MODAL_NEXT && (
        <Modal onNext={() => props.onNext()}>
          {count > 0 && (
            <div>
              {t("user_import_manual_done_" + (count > 1 ? "2" : "1"), {
                count,
              })}
            </div>
          )}

          {errors.map((error: any, i: number) => (
            <div key={i} className="red-t">
              <p>
                {t(error.message, {
                  email: error.user.email,
                })}
              </p>
            </div>
          ))}
        </Modal>
      )}

      <div className="flex">
        <div className="flex1">
          <div>
            <UserGeneralInfoWidget
              userId={""}
              onEdit={edit}
              email={user.email ? user.email : ""}
              emailCanBeNull
              firstname={user.firstname}
              lastname={user.lastname}
            />

            <div className="height-20" />

            <UserFilterInfoWidget
              attributes={user.attributes}
              username={user.username}
              isEditable
              onEdit={editAttributes}
              withInfo
            />

            <div className="height-20" />

            {showOptional ? (
              <div>
                <UserOptionalInfoWidget
                  birthDate={user.birthDate}
                  companyWelcomeDate={user.companyWelcomeDate}
                  phone={user.phone}
                  address={user.address}
                  language={user.language}
                  gender={user.gender}
                  genderLabel={user.genderLabel}
                  onEdit={edit}
                />

                <div className="height-40" />

                <div
                  className="_hover grey-t"
                  onClick={() => setShowOptional(false)}
                >
                  <u>{t("user_info_optional_hide")}</u>
                </div>
              </div>
            ) : (
              <div
                className="_hover grey-t"
                onClick={() => setShowOptional(true)}
              >
                <u>{t("user_info_optional_show")}</u>
              </div>
            )}

            <div className="height-60" />
          </div>
        </div>

        <div className="width-60" />

        <Card className="medgrey-b flex1 rel flex flex-dcol">
          <div
            className="survey-users-add abs"
            onClick={addUser}
            style={{
              borderRadius: "40px",
              zIndex: 2,
              color: isUserValid()
                ? props._session.accountColors.cta
                : "#CCCCCC",
            }}
          >
            <FontAwesomeIcon className="_hover" icon={faArrowCircleRight} />
          </div>

          <p>
            <b>{t("user_import_manual_list")}</b>
          </p>

          <div className="survey-users-list">
            {props.user.list.length === 0 && count === 0 && (
              <ListItem>
                <span className="grey-t">
                  {t("user_import_manual_list_empty")}
                </span>
              </ListItem>
            )}

            {props.user.list.map((user) => (
              <ListItem key={user.id}>
                {user.username}
                <Space />
                <ListButton
                  icon={faTimes}
                  onClick={() => deleteUser(user.id)}
                  text={t("utils_delete")}
                />
              </ListItem>
            ))}
          </div>

          <div className="flex1 flex flex-dcol">
            <Space />

            <div
              className="flex"
              style={
                props.user.list.length > 0
                  ? { bottom: "0px", position: "sticky" }
                  : {}
              }
            >
              <Space />
              <Button
                onClick={importUsers}
                isWithBorder
                isFullWidth
                isLoading={props.user.status === STATUS_SAVING}
                icon={props.user.list.length === 0 ? faLock : undefined}
                className={props.user.list.length ? "primary" : "light"}
              >
                {t("user_import_manual_btn")}
              </Button>
              <Space />
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  _session: state._session,
  user: state.user,
});

export default connect(mapStateToProps)(
  withTranslation()(AdmImportManualWidget)
);
