import { store } from "@/index";
// import store from "@/core/store";
import { useState } from "react";
import { connect } from "react-redux";
import {
  sessionEditAccountOptions,
  sessionEditUserOptions,
} from "@/redux/_session.actions";
import { Session } from "@/redux/_session.types";
import { accountUpdateOptions } from "@/redux/account.actions";
import { AccountOptions } from "@/redux/account.types";
import { FilterState } from "@/redux/filter.types";
import { userUpdateOptions } from "@/redux/user.actions";

/**dnd */
import SortableWidget from "./sortable.widget";
import {
  closestCorners,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";

interface StateProps {
  _session: Session;
  filter: FilterState;
}

type Props = StateProps;

function AdmFiltersOrder(props: Props) {
  const [activeFilterId, setActiveFilterId] = useState<string | null>(null);
  const [filterOrderId, setFilterOrderId] = useState<string[]>(
    props._session.connectedAsSupervisor ||
      props._session.userOptions.filterOrder.length < 1
      ? props._session.accountOptions.filterOrder
      : props._session.userOptions.filterOrder
  );

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function handleDragStart(event) {
    const { active } = event;

    setActiveFilterId(active.id);
  }

  function handleDragEnd(event: any) {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = props._session.accountOptions.filterOrder.indexOf(
        active.id
      );
      const newIndex = props._session.accountOptions.filterOrder.indexOf(
        over.id
      );

      const newFilterOrder: string[] = arrayMove(
        props._session.accountOptions.filterOrder,
        oldIndex,
        newIndex
      );

      const newAccountOptions: AccountOptions = new AccountOptions({
        ...props._session.accountOptions,
        filterOrder: newFilterOrder,
      });

      if (props._session.connectedAsSupervisor) {
        store.dispatch(accountUpdateOptions(newAccountOptions));
        store.dispatch(
          sessionEditAccountOptions("filterOrder", newFilterOrder)
        );
      } else {
        store.dispatch(
          userUpdateOptions(props._session.userId, newAccountOptions)
        );
        store.dispatch(sessionEditUserOptions("filterOrder", newFilterOrder));
      }

      setFilterOrderId(newFilterOrder);
    }

    setActiveFilterId(null);
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCorners}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={filterOrderId} strategy={rectSortingStrategy}>
        <div className="flex flex-wrap">
          {filterOrderId.map((filterId: string) => (
            <SortableWidget id={filterId} key={filterId} withHandle>
              <div className="filters-item">{filterId}</div>
            </SortableWidget>
          ))}
        </div>
      </SortableContext>

      {
        <DragOverlay>
          {activeFilterId ? (
            <div className="filters-item" style={{ opacity: 0.5 }}>
              {activeFilterId}
            </div>
          ) : null}
        </DragOverlay>
      }
    </DndContext>
  );
}

const mapStateToProps = (state) => ({
  _session: state._session,
  filter: state.filter,
});

export default connect(mapStateToProps)(AdmFiltersOrder);
