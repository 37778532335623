/**
 * ADD-AXIS.MODAL
 * Add axis to existing questionnaire
 */
import Modal from "@/components/modal";
import { connect } from "react-redux";
import { withTranslation, WithTranslation } from "react-i18next";
import TextInput from "@/components/text-input";
import { AxisState } from "@/redux/axis.types";
import { store } from "@/index";
// import store from "@/core/store";
import {
  axisAdd,
  axisCopy,
  axisEditName,
  axisOpen,
  axisStatus,
  axisUpdate,
  axisUpdate_AsSupervisor,
} from "@/redux/axis.actions";
import {
  STATUS_SAVED,
  STATUS_SAVE_ERROR,
  STATUS_SAVING,
} from "@/redux/_status.types";
import { TemplateState } from "@/redux/template.types";
import { topicAdd, topicEdit } from "@/redux/topic.actions";
import { Session } from "@/redux/_session.types";
import { useEffect } from "react";
import DuplicateTemplateAskModal from "./duplicate-template-ask.modal";

interface StateProps extends WithTranslation {
  _session: Session;
  axis: AxisState;
  template: TemplateState;
}

interface OwnProps {
  onClose: Function;
  updateTopic?: boolean;
}

type Props = StateProps & OwnProps;

function AddAxisModal(props: Props) {
  const { t } = props;

  //Create Axe on load
  useEffect(() => {
    store.dispatch(axisAdd(props.template.active.language));
  }, [props.template.active.language]);

  //Click on next button to save the new axis in the BDD
  //If OK
  //> copy axis created to the list of axis for the template
  //> set active axis for the card
  async function next() {
    const updateFunction =
      props._session.interfaceType === "SUPERVISOR"
        ? axisUpdate_AsSupervisor
        : axisUpdate;
    store.dispatch(axisStatus(STATUS_SAVING));
    const response: any = await store.dispatch(
      updateFunction(props.axis.active, props.template.active.id)
    );
    if (response.error) {
      store.dispatch(axisStatus(STATUS_SAVE_ERROR));
    } else {
      store.dispatch(axisStatus(STATUS_SAVED));
      store.dispatch(axisCopy());

      if (props.updateTopic) {
        store.dispatch(topicEdit("Axis", props.axis.active));
      } else {
        store.dispatch(
          topicAdd(
            props._session.interfaceType === "SUPERVISOR",
            props.axis.active,
            true,
            false,
            !props._session.userOptions.activateQuestions
          )
        );
      }

      //Before close scroll to axis
      //Open only selected axis
      setTimeout(() => {
        props.axis.list.forEach((axis) => {
          if (axis.open) {
            store.dispatch(axisOpen(axis.id));
          }
        });

        setTimeout(() => {
          store.dispatch(axisOpen(response.id));

          document.querySelector("#axis-" + response.id)?.scrollIntoView({
            behavior: "smooth",
          });

          props.onClose();
        }, 200);
      }, 200);
    }
  }

  return props.template.active.fromSupervisor &&
    props._session.interfaceType !== "SUPERVISOR" ? (
    <DuplicateTemplateAskModal onClose={() => props.onClose()} />
  ) : (
    <Modal
      isCloseButtonVisible
      status={props.axis.status}
      onClose={() => props.onClose()}
      onNext={next}
      title={t("axis_add")}
    >
      <TextInput
        autoFocus
        onChange={(e) =>
          store.dispatch(axisEditName(props.template.active.language, e.value))
        }
        title={t("axis_name")}
        placeholder={t("axis_new")}
        value={props.axis.active.name[props.template.active.language]}
        help={t("axis_help")}
      />

      <div className="height-20" />
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  _session: state._session,
  axis: state.axis,
  template: state.template,
});

export default connect(mapStateToProps)(withTranslation()(AddAxisModal));
