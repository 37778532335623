/**
 * DASHBOARD-TOPIC.WIDGET
 * Display results for a topic
 */

import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import Card from "@/components/card";
import { Question } from "@/redux/question.types";
import { Topic } from "@/redux/topic.types";
import { Axis } from "@/redux/axis.types";
import ListItem from "@/components/list-item";
import { FadeIn } from "@/utils/animations.utils";
import { sortBy } from "lodash";
import { SurveyState } from "@/redux/survey.types";
import Chip from "@/components/chip";
import { Session } from "@/redux/_session.types";
import Space from "@/components/space";
import DashboardQuestionOpenWidget from "./dashboard-question-open.widget";
import { Proposition } from "@/redux/proposition.types";
import DashboardTopicHeaderWidget from "./dashboard-topic-header.widget";
import DashboardPropositionWidget from "./dashboard-proposition.widget";
import Link from "@/components/link";
import { useEffect, useState } from "react";
import DashboardEditAfterLaunchWidget from "./dashboard-edit-after-launch.widget";
import { store } from "@/index";
// import store from "@/core/store";
import { questionActivate } from "@/redux/question.actions";
import AiAssistantButton from "@/components/ai-assistant-button";
import TopicRepartition from "@/components/topic-repartition";

interface StateProps extends WithTranslation {
  _session: Session;
  survey: SurveyState;
}

interface OwnProps {
  currentAxis: Axis;
  currentTopic: Topic;
  isEditable?: boolean; //show option to reverse question (only for supervisors)
  isOpen?: boolean;
  onOpen?: Function;
}

type Props = StateProps & OwnProps;

function DashboardTopicWidget(props: Props) {
  const { t } = props;

  //Display option to edit the question once the survey is launch
  //For superadmin only
  const [showEditAfterLaunch, setShowEditAfterLaunch] = useState(false);

  //Axis
  const [axis, setAxis] = useState<Axis>(props.currentAxis);

  //Topic
  const [topic, setTopic] = useState<Topic>(props.currentTopic);

  useEffect(() => {
    setTopic(props.currentTopic);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentTopic.id, props.currentTopic.open, props.currentTopic.note]);

  useEffect(() => {
    setAxis(props.currentAxis);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentAxis.id, props.currentAxis.open, props.currentAxis.note]);

  //Open topic
  //Detect primary question in the list and update it if needed
  async function openTopic() {
    if (props.onOpen) {
      props.onOpen();
    }
  }

  return !topic.open ? (
    <DashboardTopicHeaderWidget
      onOpen={props.onOpen}
      currentTopic={topic}
      currentAxis={axis}
    />
  ) : (
    <FadeIn>
      <div className="detail-topic-card" id={"details-topic-card-" + topic.id}>
        <Card isWithoutPadding exportName={topic.label}>
          <ListItem key={topic.aid} isEditable onClick={() => openTopic()}>
            <div className="flex1">
              <DashboardTopicHeaderWidget
                onOpen={props.onOpen}
                hideProgressBar={
                  props._session.accountOptions.dashboardDisplayMode ===
                  "satisfaction"
                }
                currentTopic={topic}
                currentAxis={axis}
              />
            </div>
          </ListItem>

          {topic.hide ? (
            <ListItem>
              <div className="grey-t">
                {topic.usersCount +
                  " " +
                  t(topic.usersCount > 1 ? "propositions" : "proposition") +
                  ". " +
                  t("survey_no_results_help", {
                    count: props._session.participationMin,
                  })}
              </div>
            </ListItem>
          ) : (
            topic.Questions.map((question, i) =>
              question.type === "open" ? (
                <DashboardQuestionOpenWidget
                  key={question.id}
                  count={question.messagesCount}
                  currentTopic={topic}
                  currentQuestion={question}
                />
              ) : (
                <ListItem key={question.id}>
                  <div className="flex1">
                    <div className="flex">
                      {topic.Questions.length > 1 && (
                        <Chip color={!question.primaryId ? axis.color : ""}>
                          {t(
                            "question_" +
                              (!question.primaryId ? "primary" : "secondary")
                          )}
                        </Chip>
                      )}

                      {topic.Questions.length > 1 && (
                        <div className="width-20" />
                      )}

                      <span className={question.primaryId ? "grey-t" : ""}>
                        {question.label}
                      </span>

                      {
                        //On graduation-cap button click
                        //use chat gpt to get advices on the question
                        //should animate if (props._session.modules.assistant || credits remaining as free user) && !props._session.accountOptions.allowAssistant
                        //true &&
                        !question.primaryId &&
                          props._session.modules.assistant && (
                            <div style={{ minWidth: 150 }} className="flex">
                              <Space />
                              <div className="flex flex-dcol">
                                <AiAssistantButton
                                  animationDuration={600000} //10min
                                  onClick={() =>
                                    store.dispatch(
                                      questionActivate(new Question(question))
                                    )
                                  }
                                />
                                <Space />
                              </div>
                            </div>
                          )
                      }

                      <Space />

                      <div className="width-20" />

                      {question.type === "scale" && question.usersCount > 0 && (
                        <span className="grey-t" style={{ minWidth: 90 }}>
                          {question.usersCount}{" "}
                          {question.usersCount > 1
                            ? t("propositions")
                            : t("proposition")}
                        </span>
                      )}
                    </div>

                    {
                      /** show text if trigger */
                      question.getTrigger(
                        question.getQuestionPrimary(topic.Questions)
                      ) &&
                        question.primaryId && (
                          <div className="details-question-trigger grey-t">
                            <i>
                              {t("question_answer_at_alt")}&nbsp;
                              <b>
                                {question.getTriggerLabel(
                                  props.survey.active.template,
                                  question.getQuestionPrimary(topic.Questions),
                                  props._session.language
                                )}
                              </b>
                              &nbsp;
                              {t("question_answer_at_question_name", {
                                question: question.getQuestionPrimary(
                                  topic.Questions
                                ).label,
                              })}
                            </i>
                          </div>
                        )
                    }

                    {question.usersCount === 0 && question.type === "scale" && (
                      <p style={{ margin: "22px 0px" }} className="grey-t">
                        <b>
                          {t("questions_no_results", {
                            count: props._session.participationMin,
                          })}
                        </b>
                      </p>
                    )}

                    {question.type === "choice" && (
                      <div className="flex">
                        <Space />
                        <div className="details-proposition-container">
                          {sortBy(question.Propositions, "count")
                            .reverse()
                            .map((proposition) => (
                              <DashboardPropositionWidget
                                key={proposition.id}
                                currentAxis={axis}
                                currentProposition={proposition}
                              />
                            ))}

                          {question.withMessage && (
                            <div>
                              <DashboardPropositionWidget
                                currentAxis={axis}
                                currentProposition={
                                  new Proposition({
                                    id: "others",
                                    count: question.messagesCount,
                                  })
                                }
                              />

                              <div className="height-20" />

                              {question.Messages.length > 0 && (
                                <p>
                                  {props._session.accountId ===
                                    "6654c17d-5678-4277-a4ff-04d43b255fa5" &&
                                  props._session.userRole ===
                                    "OBSERVER" ? null : (
                                    <b>
                                      {t("utils_details")} ({t("utils_others")})
                                    </b>
                                  )}
                                </p>
                              )}

                              <div className="height-20" />

                              <DashboardQuestionOpenWidget
                                key={question.id}
                                count={question.messagesCount}
                                currentTopic={topic}
                                currentQuestion={question}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    {question.type !== "choice" &&
                      question.repartition.length > 0 && (
                        <TopicRepartition
                          id={question.id}
                          repartition={question.repartition}
                          noAnswers={question.noAnswers}
                          reverse={question.reverse}
                          isLarge={i === 0}
                        />
                      )}

                    {props.isEditable &&
                      props._session.connectedAsSupervisor &&
                      question.type === "scale" && (
                        <div className="flex">
                          <Space />
                          <Link
                            onClick={() =>
                              setShowEditAfterLaunch(!showEditAfterLaunch)
                            }
                          >
                            <span style={{ fontSize: 12 }}>
                              Modifier après enquête (Gestionnaire IDTree
                              uniquement)
                            </span>
                          </Link>
                        </div>
                      )}

                    {showEditAfterLaunch && (
                      <DashboardEditAfterLaunchWidget
                        currentQuestion={question}
                      />
                    )}
                  </div>
                </ListItem>
              )
            )
          )}
        </Card>
      </div>
    </FadeIn>
  );
}

const mapStateToProps = (state) => ({
  _session: state._session,
  survey: state.survey,
});

export default connect(mapStateToProps)(
  withTranslation()(DashboardTopicWidget)
);
