/**
 * REPORT-SCREENSHOT-HEATMAP.WIDGET.TSX
 * Preview of the slide
 */
import { connect } from "react-redux";
import { Session } from "@/redux/_session.types";
import { AxisState } from "@/redux/axis.types";
import { TopicState } from "@/redux/topic.types";
import DashboardHeatmapWidget from "./dashboard-heatmap.widget";
import { AccountHeatmapResult } from "@/redux/account.types";
import { PAGE_HEATMAP_LIMIT, PageState } from "@/redux/page.types";

interface StateProps {
  _session: Session;
  axis: AxisState;
  page: PageState;
  topic: TopicState;
}

interface OwnProps {
  filterName: string;
  resultsType: AccountHeatmapResult;
}

type Props = StateProps & OwnProps;

function ReportScreenshotHeatmapWidget(props: Props) {
  return (
    <div
      className="flex"
      style={{
        transform: "scale(0.4)",
        transformOrigin: "top left",
      }}
    >
      <DashboardHeatmapWidget
        isPreview
        offset={props.page.active.heatmapOffset}
        limit={PAGE_HEATMAP_LIMIT}
        filterName={props.filterName}
        resultsType={props.resultsType}
      />
    </div>
  );
}
const mapStateToProps = (state) => ({
  _session: state._session,
  axis: state.axis,
  page: state.page,
  topic: state.topic,
});

export default connect(mapStateToProps)(ReportScreenshotHeatmapWidget);
