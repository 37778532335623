// le but de ce fichier est de créer des sélecteurs afin de récupérer plus facilement la donnée dans les composants
// il a pour vocation d'être pourquoi pas réagencé, splité en plusieurs fichier, renommé peu importe on en parle on se met d'accord
// https://redux-toolkit.js.org
// Le package Redux Toolkit est destiné à être le moyen standard d’écrire la logique Redux.
// Il a été créé à l'origine pour aider à répondre à trois préoccupations courantes concernant Redux :
// "Configurer une boutique Redux est trop compliqué"
// "Je dois ajouter beaucoup de packages pour que Redux fasse quelque chose d'utile"
// "Redux nécessite trop de code passe-partout"

import { createSelector } from "@reduxjs/toolkit";
import { Session } from "../_session.types";
import { FilterState } from "../filter.types";
import { AccountState } from "../account.types";
import { RpsSliceState } from "@/core/slice/rpsSlice";

const gollacState = (state: { rps: RpsSliceState }) => state;
const sessionState = (state: { _session: Session }) => state;
const filterState = (state: { filter: FilterState }) => state;
const accountState = (state: { account: AccountState }) => state;

export const selectSession = createSelector(
  sessionState,
  (state) => state._session
);

export const selectFilter = createSelector(
  filterState,
  (state) => state.filter
);

export const selectAccount = createSelector(
  accountState,
  (state) => state.account
);

export const selectGollac = createSelector(gollacState, (state) => {
  console.log(state);
  return state.rps;
});
