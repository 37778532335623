/**
 * EDITOR-ADVANCED
 * View in card mode for the editor of questionnaire
 */
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TopicState } from "@/redux/topic.types";
import EditorQuestionWidget from "@/widgets/editor-question.widget";
import Space from "@/components/space";
import { store } from "@/index";
// import store from "@/core/store";
import {
  topicActivate,
  topicAddQuestion,
  topicRemove,
} from "@/redux/topic.actions";
import Button from "@/components/button";
import { Session } from "@/redux/_session.types";
import { useEffect, useState, useRef } from "react";
import { SlideInRight } from "@/utils/animations.utils";

import EditorNavWidget from "./editor-nav.widget";
import AddAxisModal from "@/modals/add-axis.modal";

interface StateProps extends WithTranslation {
  _session: Session;
  topic: TopicState;
}

interface OwnProps {
  onHelp: Function; //Trigger function onHelp
}

type Props = StateProps & OwnProps;

const MODAL_AXIS_ADD = "MODAL_AXIS_ADD";

function EditorAdvanced(props: Props) {
  const { t } = props;

  //Define container (force back to top)
  const containerElement = useRef<HTMLDivElement>(null);

  //Modal to display
  const [currentModal, setCurrentModal] = useState<string | null>(null);

  //When topic is change
  //Force scroll to top of the page
  useEffect(() => {
    if (containerElement.current) {
      containerElement.current.scrollTo(0, 0);
    }
  }, [props.topic.active.id]);

  //Get color of the axis
  //If card is without axis no color
  function getAxisColor() {
    if (props.topic.active.Axis) {
      return props.topic.active.Axis.color;
    } else {
      return "#c1c1c1";
    }
  }

  //Go to next or previous card
  //Search index of the card
  function getTopicNext(next) {
    const newTopic =
      props.topic.list.findIndex((x) => x.id === props.topic.active.id) +
      (next ? 1 : -1);
    store.dispatch(topicActivate(props.topic.list[newTopic]));
  }

  return (
    <div>
      {currentModal === MODAL_AXIS_ADD && (
        <AddAxisModal onClose={() => setCurrentModal(null)} />
      )}

      <SlideInRight
        className="survey-viewer-container flex flex-dcol"
        id="survey-viewer-container"
        ref={containerElement}
        style={{ backgroundColor: getAxisColor() }}
      >
        <Space />

        <div className="flex">
          <div className="flex1">
            {
              /** show button for previous card */
              props.topic.list.findIndex(
                (x) => x.id === props.topic.active.id
              ) > 0 &&
                !props.topic.active.fullScreen && (
                  <div
                    className="survey-viewer-chevron"
                    onClick={() => getTopicNext(false)}
                    style={{ marginLeft: "auto" }}
                  >
                    <FontAwesomeIcon icon={faChevronLeft}></FontAwesomeIcon>
                    <div className="survey-viewer-chevron-label">
                      {t("question_back")}
                    </div>
                  </div>
                )
            }
          </div>

          {props.topic.active.questionPrimary && (
            <EditorQuestionWidget
              isPrimaryQuestion
              onHelp={props.onHelp}
              question={props.topic.active.questionPrimary}
            />
          )}

          {!props.topic.active.questionPrimary &&
            props.topic.list.length > 0 && (
              <div className="question question-empty">
                <div className="height-40" />
                <h1>{t("Oups !")}</h1>
                <div style={{ padding: "20px" }}>
                  <p>{t("question_primary_empty")}</p>
                </div>
                <div className="height-40" />
                <Button
                  className="primary"
                  onClick={() =>
                    store.dispatch(
                      topicAddQuestion(
                        props._session.interfaceType === "SUPERVISOR",
                        true,
                        null
                      )
                    )
                  }
                >
                  {t("question_add")}
                </Button>
                <Button
                  onClick={() =>
                    store.dispatch(topicRemove(props.topic.active.id))
                  }
                >
                  {t("topic_delete")}
                </Button>
              </div>
            )}

          <div className="flex1">
            {
              /** show button for next question */
              props.topic.list.findIndex(
                (x) => x.id === props.topic.active.id
              ) <
                props.topic.list.length - 1 &&
                !props.topic.active.fullScreen && (
                  <div
                    className="survey-viewer-chevron"
                    onClick={() => getTopicNext(true)}
                    style={{ marginRight: "auto" }}
                  >
                    <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
                    <div className="survey-viewer-chevron-label">
                      {t("question_next")}
                    </div>
                  </div>
                )
            }
          </div>
        </div>

        {
          /** show secondary questions */
          props.topic.active.questionsSecondary.length > 0 && (
            <div>
              {
                /** questions with trigger superior than */
                props.topic.active.questionsSecondaryRight.map((question) => (
                  <div className="flex rel" key={question.id}>
                    <Space />
                    <div className="survey-viewer-separator-right" />

                    {props.topic.active.questionsSecondaryLeft.length > 0 && (
                      <div className="survey-viewer-separator-left" />
                    )}

                    <EditorQuestionWidget
                      question={question}
                      onHelp={props.onHelp}
                    />

                    <Space />
                  </div>
                ))
              }

              {
                /** questions with trigger inferior than */
                props.topic.active.questionsSecondaryLeft.map((question) => (
                  <div className="flex rel" key={question.id}>
                    <Space />
                    <div className="survey-viewer-separator-middle-left" />
                    <EditorQuestionWidget
                      question={question}
                      onHelp={props.onHelp}
                    />
                    <Space />
                  </div>
                ))
              }

              {
                /** questions with trigger false */
                props.topic.active.questionsSecondary
                  .filter((x) => !x.QuestionsTopic.triggerValue)
                  .map((question) => (
                    <div className="flex" key={question.id}>
                      <Space />
                      <EditorQuestionWidget
                        question={question}
                        onHelp={props.onHelp}
                      />
                      <Space />
                    </div>
                  ))
              }
            </div>
          )
        }

        <Space />
      </SlideInRight>

      <EditorNavWidget onAddAxis={() => setCurrentModal(MODAL_AXIS_ADD)} />
    </div>
  );
}

const mapStateToProps = (state) => ({
  _session: state._session,
  topic: state.topic,
});

export default connect(mapStateToProps)(withTranslation()(EditorAdvanced));
