/***
 * PROJECT-QRCODE.WIDGET
 * Edit content for the QRCode
 */

import { connect } from "react-redux";
import { WithTranslation, withTranslation } from "react-i18next";
import { ProjectState } from "@/redux/project.types";
import { useEffect, useState } from "react";
import { store } from "@/index";
// import store from "@/core/store";
import {
  projectCheckQrcode,
  projectEditQrcodeOptions,
} from "@/redux/project.actions";
import { Session } from "@/redux/_session.types";
import Checkbox from "@/components/checkbox";
import TextInput from "@/components/text-input";
import { Language } from "@/redux/language.types";
import QRCode from "react-qr-code";
import { createRoot, Root } from "react-dom/client";
import ReactMarkdown from "react-markdown";
import { TemplateState } from "@/redux/template.types";
import ConfirmButtons from "@/components/confirm-buttons";
import Space from "@/components/space";
import ContextMenu from "@/components/context-menu";
import TranslatorWidget from "./translator.widget";
import LanguageSelection from "@/components/language-selection";
import ProjectStepErrorsWidget from "./project-step-errors.widget";
import Dropdown from "@/components/dropdown";
import { FilterState } from "@/redux/filter.types";

interface StateProps extends WithTranslation {
  _session: Session;
  filter: FilterState;
  project: ProjectState;
  template: TemplateState;
}

interface OwnProps {
  onNext?: Function;
  onEdit: Function;
}

type Props = StateProps & OwnProps;

const CONTEXT_AUTO_TRANSLATION: string = "CONTEXT_AUTO_TRANSLATION";

function ProjectQRCodeWidget(props: Props) {
  const { t } = props;

  const [currentLanguage, setCurrentLanguage] = useState<string>(
    props.project.active.languages[0]
  );

  const [currentContext, setCurrentContext] = useState<string | null>(null);

  //Create QR code to test the template
  useEffect(() => {
    // Get the DOM element where you want to mount the React component
    const rootElement: HTMLElement | null =
      document.getElementById("div_qrcode");

    if (rootElement) {
      // Create a root for rendering
      const root: Root = createRoot(rootElement);

      // Conditionally render the component
      root.render(
        props.project.active.qrcodeOptions.hideCode ? (
          <div />
        ) : (
          <QRCode size={140} value={props.project.active.testTemplateLink} />
        )
      );
    }
  }, [
    props.project.active.testTemplateLink,
    props.project.active.qrcodeOptions.hideCode,
  ]);

  function edit() {
    store.dispatch(
      projectCheckQrcode(
        props.project.active.languages,
        props.project.active.qrcodeOptions
      )
    );
    props.onEdit();
  }

  function editQrcodeContent(languageCode: string, value: string) {
    editQrcodeOptions(
      "content",
      Object.assign({}, props.project.active.qrcodeOptions.content, {
        [languageCode]: value,
      })
    );
  }

  function editQrcodeOptions(key: string, value: any) {
    store.dispatch(projectEditQrcodeOptions(key, value));
  }

  function next(isVisited, isConfirmed) {
    if (props.onNext) {
      props.onNext(isVisited, isConfirmed);
    }
  }

  return (
    <div style={{ padding: "10px 32px" }}>
      <div className="height-10" />

      <h3>{t("project_invitation_qrcode_options")}</h3>

      <div className="height-10" />

      {props.project.active.languages.length > 1 && (
        <div className="flex">
          <div>
            <p className="grey-t">{t("utils_translations")}</p>

            <LanguageSelection
              languages={props.project.active.languages}
              activeLang={currentLanguage}
              onClick={(language: Language) =>
                setCurrentLanguage(language.code)
              }
            />
          </div>

          <Space />

          <div className="flex flex-dcol">
            <Space />

            <u
              className="_hover grey-t"
              onClick={() => setCurrentContext(CONTEXT_AUTO_TRANSLATION)}
            >
              {t("auto_translate")}
            </u>

            <div className="rel">
              {currentContext === CONTEXT_AUTO_TRANSLATION && (
                <ContextMenu
                  positionRight
                  onClose={() => setCurrentContext(null)}
                >
                  <div style={{ padding: "10px 20px" }}>
                    <p>{t("auto_translate")}</p>

                    <TranslatorWidget
                      targetLang={currentLanguage}
                      onTranslate={(texts: string[], language: Language) => {
                        editQrcodeContent(language.code, texts[0]);
                        edit();
                        setCurrentContext(null);
                      }}
                      languages={props.project.active.languages}
                      sourceTexts={[props.project.active.qrcodeOptions.content]}
                      onTargetChange={(lang: Language) =>
                        setCurrentLanguage(lang.code)
                      }
                    />
                  </div>
                </ContextMenu>
              )}
            </div>
          </div>
        </div>
      )}

      <div className="height-10" />

      <div className="flex">
        <div className="flex2">
          <TextInput
            title={t("project_invitation_qrcode_sender_name")}
            placeholder={props.project.active.qrcodeOptions.senderName}
            value={props.project.active.qrcodeOptions.senderName}
            onBlur={edit}
            onChange={(e) => editQrcodeOptions("senderName", e.value)}
            isFullWidth
          />

          <TextInput
            title={t("project_invitation_qrcode_sender_address")}
            value={props.project.active.qrcodeOptions.senderAddress}
            help={t("project_invitation_qrcode_sender_address_help")}
            onBlur={edit}
            onChange={(e) => editQrcodeOptions("senderAddress", e.value)}
            isFullWidth
          />

          <Checkbox
            onClick={() => {
              editQrcodeOptions(
                "hideAddress",
                !props.project.active.qrcodeOptions.hideAddress
              );
              edit();
            }}
            active={props.project.active.qrcodeOptions.hideAddress}
            text={t("project_invitation_qrcode_address_display")}
          />

          <Checkbox
            onClick={() => {
              editQrcodeOptions(
                "hideCode",
                !props.project.active.qrcodeOptions.hideCode
              );
              edit();
            }}
            active={props.project.active.qrcodeOptions.hideCode}
            text={t("project_invitation_qrcode_sender_display")}
          />

          <Checkbox
            onClick={() => {
              editQrcodeOptions(
                "displayAttribute",
                !props.project.active.qrcodeOptions.displayAttribute
              );
              edit();
            }}
            active={props.project.active.qrcodeOptions.displayAttribute}
            text={t("project_invitation_qrcode_attribute")}
          />

          {props.project.active.qrcodeOptions.displayAttribute && (
            <Dropdown
              displayField="name"
              isNullAllowed
              list={props.filter.list}
              onSelect={(e) => {
                editQrcodeOptions("attributeToDisplay", e.id ? e.name : null);
                edit();
              }}
              title={t("project_invitation_qrcode_attribute_name")}
              value={props.project.active.qrcodeOptions.attributeToDisplay}
            />
          )}

          {
            // Display a second attribute for the QR Code
            props.project.active.qrcodeOptions.displayAttribute && (
              <Checkbox
                onClick={() => {
                  editQrcodeOptions(
                    "displayAttribute2",
                    !props.project.active.qrcodeOptions.displayAttribute2
                  );
                  edit();
                }}
                active={props.project.active.qrcodeOptions.displayAttribute2}
                text={t("project_invitation_qrcode_attribute_2")}
              />
            )
          }

          {props.project.active.qrcodeOptions.displayAttribute2 && (
            <Dropdown
              displayField="name"
              isNullAllowed
              list={props.filter.list}
              onSelect={(e) => {
                editQrcodeOptions("attributeToDisplay2", e.id ? e.name : null);
                edit();
              }}
              title={t("project_invitation_qrcode_attribute_name")}
              value={props.project.active.qrcodeOptions.attributeToDisplay2}
            />
          )}

          <div className="height-20" />

          <div className="grey-t">{t("project_invitation_qrcode_content")}</div>

          <textarea
            style={{
              resize: "none",
              height: "300px",
              width: "calc(100% - 20px)",
              border: "none",
              padding: "6px 10px",
              backgroundColor: "#eaeaea",
              borderRadius: "8px",
              margin: "12px 0px",
            }}
            onChange={(e) => editQrcodeContent(currentLanguage, e.target.value)}
            onBlur={edit}
            value={props.project.active.qrcodeOptions.content[currentLanguage]}
          ></textarea>
        </div>

        <div
          className="flex3 rel"
          style={{
            fontFamily: "sans-serif",
            minWidth: "570px",
          }}
        >
          <img src={require("@/assets/qrcode.png")} width="100%" alt="qrcode" />

          <div
            className="abs"
            style={{
              backgroundColor: "white",
              width: "200px",
              height: "100px",
              left: "68px",
              fontSize: "10px",
              top: "100px",
            }}
          >
            <div>{props.project.active.qrcodeOptions.senderName}</div>

            {props.project.active.qrcodeOptions.senderAddresses.map((x, i) => (
              <div key={i}>{x}</div>
            ))}
          </div>

          <div
            className="abs"
            style={{
              backgroundColor: "white",
              width: "200px",
              height: "100px",
              left: "300px",
              fontSize: "10px",
              fontWeight: 700,
              top: "180px",
            }}
          >
            <div>{`${props._session.userName}`}</div>

            {!props.project.active.qrcodeOptions.hideAddress && (
              <div>
                <div>{`1 Boulevard XXX`}</div>
                <div>{`44100 Nantes`}</div>
              </div>
            )}
          </div>

          <div className="abs project-invitation-body">
            <ReactMarkdown>
              {props.project.active.qrcodeOptions.content[currentLanguage]}
            </ReactMarkdown>
          </div>

          <img
            className="abs"
            src={require("@/assets/invitation_instructions.png")}
            width="83%"
            alt="invitation_instructions"
            style={{
              top: 486,
              left: 47,
            }}
          />

          {props.project.active.qrcodeOptions.hideCode && (
            <div
              className="abs"
              style={{
                width: 200,
                height: 100,
                backgroundColor: "#fff",
                top: 580,
                left: 290,
              }}
            />
          )}
        </div>
      </div>

      <ProjectStepErrorsWidget
        alert={props.project.active.alerts.qrcode}
        step="qrcode"
      />

      {props.onNext && (
        <ConfirmButtons
          onCancel={() => next(false, false)}
          onConfirm={() => next(true, true)}
          onConfirmLater={() => next(true, false)}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  _session: state._session,
  filter: state.filter,
  project: state.project,
  template: state.template,
});

export default connect(mapStateToProps)(withTranslation()(ProjectQRCodeWidget));
