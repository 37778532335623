/**
 * TASKS-ARCHIVE.MODAL
 * Archive action plan
 */

import { useState } from "react";
import { connect } from "react-redux";
import { withTranslation, WithTranslation } from "react-i18next";
import { STATUS_SAVED, STATUS_SAVING } from "@/redux/_status.types";
import { ActionPlanState } from "@/redux/action-plan.types";
import { actionPlanArchive } from "@/redux/action-plan.actions";
import { store } from "@/index";
// import store from "@/core/store";
import ModalConfirm from "@/components/modal-confirm";
import { NavigateFunction, useNavigate } from "react-router-dom";

interface StateProps extends WithTranslation {
  actionPlan: ActionPlanState;
}

interface OwnProps {
  onClose: Function;
}

type Props = StateProps & OwnProps;

function TaskArchiveModal(props: Props) {
  const { t } = props;
  const navigate: NavigateFunction = useNavigate();

  const [isSaving, setIsSaving] = useState(false);

  //Trigger the action with the backend
  async function archiveConfirm() {
    setIsSaving(true);
    const response: any = await store.dispatch(
      actionPlanArchive(props.actionPlan.active.id)
    );
    setIsSaving(false);

    if (!response.error) {
      navigate("/tasks/home");
    }
  }

  return (
    <ModalConfirm
      status={isSaving ? STATUS_SAVING : STATUS_SAVED}
      textBold={t("tasks_archive_ask")}
      onNo={() => props.onClose()}
      onYes={() => archiveConfirm()}
    ></ModalConfirm>
  );
}

const mapStateToProps = (state) => ({
  actionPlan: state.actionPlan,
});

export default connect(mapStateToProps)(withTranslation()(TaskArchiveModal));
