/**
 * AXES.DROPDOWN
 * Get all axes used by a questionnaire
 */
import Dropdown from "@/components/dropdown";
import ListDropdown from "@/components/list-dropdown";
import { connect } from "react-redux";
import { WithTranslation, withTranslation } from "react-i18next";
import { Axis, AxisState } from "@/redux/axis.types";
import { Topic } from "@/redux/topic.types";
import { useState } from "react";
import AddAxisModal from "@/modals/add-axis.modal";
import { store } from "@/index";
// import store from "@/core/store";
import { axisActivate } from "@/redux/axis.actions";
import { topicActivate, topicEdit } from "@/redux/topic.actions";
import EditAxisModal from "@/modals/edit-axis.modal";
import { TemplateState } from "@/redux/template.types";
import SwitchLanguage from "@/components/switch-language";
import { templateEdit } from "@/redux/template.actions";
import { Session } from "@/redux/_session.types";

interface StateProps extends WithTranslation {
  _session: Session;
  axis: AxisState;
  template: TemplateState;
}

interface OwnProps {
  advancedEditor?: boolean;
  currentTopic: Topic;
  onHelp?: Function;
}

type Props = StateProps & OwnProps;

const MODAL_ADD = "MODAL_ADD"; //Add axis
const MODAL_EDIT = "MODAL_EDIT"; //Edit axis

function AxesDropdown(props: Props) {
  const { t } = props;

  const [currentModal, setCurrentModal] = useState<string | null>(null);

  function addAxis() {
    setCurrentModal(MODAL_ADD);
  }

  function editAxis(axis) {
    const selectedAxis = props.axis.list.find((x) => x.id === axis.id);
    store.dispatch(axisActivate(new Axis(selectedAxis)));
    setCurrentModal(MODAL_EDIT);
  }

  //Get list of axes
  //Format according the selected language
  function getAxes() {
    const axes: any[] = [];

    axes.push({
      id: null,
      name: t("utils_undefined"),
    });

    props.axis.list.forEach((axis: any) => {
      if (axis.name) {
        axes.push({
          id: axis.id,
          name: axis.name[props.template.active.language],
        });
      }
    });

    return axes;
  }

  //Get id of selected axis
  function getAxisId() {
    return props.currentTopic.Axis ? props.currentTopic.Axis.id : null;
  }

  //Get label to display
  function getAxisValue() {
    return props.currentTopic.Axis
      ? props.currentTopic.Axis.name[props.template.active.language]
      : null;
  }

  //Select an axis and update current topic
  function selectAxis(axis) {
    const selectedAxis = props.axis.list.find((x) => x.id === axis.id);
    store.dispatch(
      topicEdit("Axis", selectedAxis ? new Axis(selectedAxis) : null)
    );
  }

  //Edit language for template
  function selectLanguage(lg) {
    store.dispatch(templateEdit("language", lg));
  }

  //Do not show switch when discovering the template as client
  function showSwitchLanguage() {
    return (
      !(
        props.template.active.fromSupervisor &&
        props._session.interfaceType !== "SUPERVISOR"
      ) && props.advancedEditor
    );
  }

  return (
    <div>
      {currentModal === MODAL_ADD && (
        <AddAxisModal onClose={() => setCurrentModal(null)} updateTopic />
      )}

      {currentModal === MODAL_EDIT && (
        <EditAxisModal onClose={() => setCurrentModal(null)} />
      )}

      {props.advancedEditor ? (
        <Dropdown
          active={getAxisId()}
          displayField="name"
          isWhiteMode
          list={getAxes()}
          onAdd={addAxis}
          onEdit={editAxis}
          onHelp={props.onHelp}
          onSelect={selectAxis}
          title={t("axis")}
          value={getAxisValue()}
        />
      ) : (
        <ListDropdown
          active={getAxisId()}
          onClick={() => store.dispatch(topicActivate(props.currentTopic))}
          color={props.currentTopic.Axis ? props.currentTopic.Axis.color : ""}
          onAdd={addAxis}
          onEdit={editAxis}
          onSelect={selectAxis}
          value={getAxisValue()}
          values={getAxes()}
        />
      )}

      {showSwitchLanguage() && (
        <SwitchLanguage
          isWhiteMode
          language={props.template.active.language}
          languageAlt={props.template.active.languageAlt}
          languages={props.template.active.languages}
          text={props.currentTopic.Axis ? props.currentTopic.Axis.name : {}}
          onSelect={selectLanguage}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  _session: state._session,
  axis: state.axis,
  template: state.template,
});

export default connect(mapStateToProps)(withTranslation()(AxesDropdown));
