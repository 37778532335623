/**
 * SURVEY-REOPEN.MODAL
 * Open existing survey that is closed
 */
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ProjectState } from "@/redux/project.types";
import ModalConfirm from "@/components/modal-confirm";
import { store } from "@/index";
// import store from "@/core/store";
import { projectEdit, projectStatus } from "@/redux/project.actions";
import { STATUS_SAVED, STATUS_SAVING } from "@/redux/_status.types";
import { surveyReopen } from "@/redux/survey.actions";
import { toast } from "react-toastify";
import { NavigateFunction, useNavigate } from "react-router-dom";

interface StateProps extends WithTranslation {
  project: ProjectState;
}

interface OwnProps {
  onClose: Function;
}

type Props = StateProps & OwnProps;

function SurveyReopenModal(props: Props) {
  const { t } = props;
  const navigate: NavigateFunction = useNavigate();

  async function reopen() {
    if (props.project.active.lastSurvey) {
      store.dispatch(projectStatus(STATUS_SAVING));
      const response: any = await store.dispatch(
        surveyReopen(props.project.active.lastSurvey?.id)
      );
      store.dispatch(projectStatus(STATUS_SAVED));

      if (!response.error) {
        store.dispatch(projectEdit("isClosed", false));
        navigate("/project/list");
      }
    } else {
      toast("survey_do_not_exist", { type: "error" });
    }
  }

  return (
    <ModalConfirm
      status={props.project.status}
      onNo={() => props.onClose()}
      onYes={() => reopen()}
      textBold={t("utils_next_ask")}
      text={t("survey_reopen_text")}
    />
  );
}

const mapStateToProps = (state) => ({
  project: state.project,
});

export default connect(mapStateToProps)(withTranslation()(SurveyReopenModal));
