/**
 * TASKS-EDIT.WIDGET
 * Edit action plan
 */

import { connect } from "react-redux";
import { ActionPlan, ActionPlanState } from "@/redux/action-plan.types";
import TasksListWidget from "./tasks-list.widget";
import TasksTopicsWidget from "./tasks-topics.widget";
import { useEffect, useState } from "react";
import {
  STATUS_LOADED,
  STATUS_LOADING,
  STATUS_SAVING,
} from "@/redux/_status.types";
import { store } from "@/index";
// import store from "@/core/store";
import {
  actionPlanActivate,
  actionPlanEdit,
  actionPlanFetchOne,
  actionPlanFetchSelf,
  actionPlanUpdateAndStatus,
} from "@/redux/action-plan.actions";
import PageLoader from "@/components/page-loader";
import { TopicState } from "@/redux/topic.types";
import AdmUserPopulationsWidget from "./adm-user-populations.widget";
import PageHeader from "@/components/page-header";
import { UserState } from "@/redux/user.types";
import { WithTranslation, withTranslation } from "react-i18next";
import { loadSurveyAndTopics } from "@/redux/_archive.actions";
import { Session } from "@/redux/_session.types";
import { FilterState } from "@/redux/filter.types";
import PagePlaceholder from "@/components/page-placeholder";
import { surveyActivate, surveyFetch, surveyGet } from "@/redux/survey.actions";
import { Survey } from "@/redux/survey.types";

interface StateProps extends WithTranslation {
  _session: Session;
  actionPlan: ActionPlanState;
  filter: FilterState;
  topic: TopicState;
  user: UserState;
}

function TasksEditWidget(props: StateProps) {
  const { t } = props;

  //Set is empty
  //> for an observer if there is no action plan defined
  const [isEmpty, setIsEmpty] = useState(false);

  //Set is loading
  const [isLoading, setIsLoading] = useState(true);

  //For OBSERVER
  //> if no action plan set as empty
  useEffect(() => {
    if (
      props._session.userRole === "OBSERVER" &&
      props.actionPlan.active.status === "draft"
    ) {
      setIsEmpty(true);
    }
  }, [props.actionPlan.active.status, props._session.userRole]);

  //Load data
  useEffect(() => {
    load();

    async function load() {
      //is new
      const isNew = new URLSearchParams(window.location.search).get("isNew");

      //Load action plan
      let actionPlan = new ActionPlan(props.actionPlan.active);

      if (props._session.userRole === "ADMIN" && !isNew) {
        const actionPlanResponse: any = await store.dispatch(
          actionPlanFetchOne(props.actionPlan.active.id)
        );
        if (!actionPlanResponse.error)
          actionPlan = new ActionPlan(actionPlanResponse);
      } else {
        const actionPlans: any = await store.dispatch(actionPlanFetchSelf());
        if (!actionPlans.error && actionPlans.length)
          actionPlan = new ActionPlan(actionPlans[0]);
      }

      //Load surveys
      const response: any = await store.dispatch(surveyFetch());

      if (response.error) {
        store.dispatch(surveyGet([], [], true));
        store.dispatch(surveyActivate(new Survey()));
      } else {
        store.dispatch(surveyGet(response, [], false));

        //Detect if a survey is defined
        //If not search the last one
        const actionPlanSurvey = response.find(
          (x) => x.id === actionPlan.referenceSurveyId
        );
        const referenceSurvey = response.find(
          (x) => x.id === props.actionPlan.referenceSurveyId
        );

        //If one survey is defined
        let survey = new Survey();
        if (actionPlanSurvey) {
          survey = actionPlanSurvey;
        } else if (referenceSurvey) {
          survey = referenceSurvey;
          store.dispatch(
            actionPlanEdit("referenceSurveyId", referenceSurvey.id, false)
          );
        }

        //Load topics
        store.dispatch(surveyActivate(survey));
        store.dispatch(loadSurveyAndTopics(survey.id));
      }

      //Activate actionPlan
      if (!isNew) {
        store.dispatch(actionPlanActivate(actionPlan));
      }
    }

    //Add delay before ending loading
    setTimeout(() => {
      setIsLoading(false);
    }, 500);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props._session.userRole, props.actionPlan.referenceSurveyId]);

  //Detect changes in order to save
  useEffect(() => {
    if (props.actionPlan.status === STATUS_SAVING) {
      update();
    }

    async function update() {
      store.dispatch(actionPlanUpdateAndStatus());
    }
  }, [props.actionPlan.status]);

  return (
    <div>
      <PageHeader
        title={t("tasks_new_admin", { name: props.user.active.username })}
      />

      <div
        style={{
          marginTop: -32,
          marginBottom: 28,
        }}
      >
        <AdmUserPopulationsWidget currentUser={props.user.active} />
      </div>

      <PageLoader
        status={
          isLoading || props.topic.status === STATUS_LOADING
            ? STATUS_LOADING
            : STATUS_LOADED
        }
      >
        {isEmpty ? (
          <PagePlaceholder title={t("tasks_empty_manager")} image="sherlock" />
        ) : (
          <div className="flex">
            {props.actionPlan.active.status === "draft" &&
              !props._session.isSuperAccount && <TasksTopicsWidget />}

            <TasksListWidget />
          </div>
        )}
      </PageLoader>
    </div>
  );
}

const mapStateToProps = (state) => ({
  _session: state._session,
  actionPlan: state.actionPlan,
  filter: state.filter,
  topic: state.topic,
  user: state.user,
});

export default connect(mapStateToProps)(withTranslation()(TasksEditWidget));
