/**
 * SEARCH-USER
 * Context menu to search user
 */
import { useCallback, useEffect, useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { faPlus, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { STATUS_LOADED, STATUS_SEARCHING } from "@/redux/_status.types";
import ContextMenu from "./context-menu";
import { User } from "@/redux/user.types";
import SearchBar from "./search-bar";
import ListItem from "./list-item";
import ListIcon from "./list-icon";
import { store } from "@/index";
// import store from "@/core/store";
import { userFetch, userFetch_AsSupervisor } from "@/redux/user.actions";
import { Session } from "@/redux/_session.types";
import { connect } from "react-redux";

interface StateProps extends WithTranslation {
  _session: Session;
}

interface OwnProps {
  activeUsers?: User[];
  onAdd?: Function;
  onClose: Function;
  onSelect: Function;
}

type Props = StateProps & OwnProps;

//Limit of users displayed for search
const LIMIT_SEARCH: number = 5;

function SearchUser(props: Props) {
  const { t } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [searchedUsers, setSearchedUsers] = useState<User[]>([]);

  //Load recipients data (count and list of users for preview)
  const search = useCallback(
    async (value: string) => {
      const users: any =
        props._session.interfaceType === "SUPERVISOR"
          ? await store.dispatch(
              userFetch_AsSupervisor(value, LIMIT_SEARCH, 0, null)
            )
          : await store.dispatch(
              userFetch(value, LIMIT_SEARCH, 0, null, "id,role")
            );
      setSearchedUsers(users.error ? [] : users.map((x) => new User(x)));
      setIsLoading(false);
    },
    [props._session.interfaceType]
  );

  //Search on start
  useEffect(() => {
    search("");
  }, [search]);

  function close() {
    props.onClose();
  }

  //Trigger add function
  function add() {
    if (props.onAdd) {
      props.onAdd();
    }
  }

  //Detect if user is active when already present in list of users
  function isActive(user: User) {
    return props.activeUsers
      ? props.activeUsers.some((x: User) => x.id === user.id)
      : user.role;
  }

  //Select user and close modal
  function select(user) {
    props.onSelect(user);
    close();
  }

  return (
    <div>
      <ContextMenu
        footer={
          searchedUsers.length >= LIMIT_SEARCH
            ? t("utils_total_displayed", {
                count: LIMIT_SEARCH,
                s: LIMIT_SEARCH > 1 ? "s" : "",
              })
            : null
        }
        positionRight
        onClose={close}
      >
        <SearchBar
          status={isLoading ? STATUS_SEARCHING : STATUS_LOADED}
          onSearchStart={() => setIsLoading(true)}
          onSearch={search}
        />

        {searchedUsers.length > 0 && <div style={{ height: "12px" }} />}

        {props.onAdd && (
          <ListItem isSmall onClick={add} isEditable>
            <ListIcon fallbackIcon={faPlus} />
            <b>{t("user_add")}</b>
          </ListItem>
        )}

        {searchedUsers.map((user) =>
          isActive(user) ? (
            <ListItem key={user.id} isActive isSmall>
              <ListIcon fallbackIcon={faUserCircle} image={user.imageUrl} />
              {user.username}
            </ListItem>
          ) : (
            <ListItem
              key={user.id}
              onClick={() => select(user)}
              isEditable
              isSmall
            >
              <ListIcon fallbackIcon={faUserCircle} image={user.imageUrl} />
              {user.username}
            </ListItem>
          )
        )}
      </ContextMenu>
    </div>
  );
}

const mapStateToProps = (state) => ({
  _session: state._session,
});

export default connect(mapStateToProps)(withTranslation()(SearchUser));
