/**
 * DASHBOARD-HOME.WIDGET
 * Get list of current survey and closed
 */
import { connect } from "react-redux";
import { withTranslation, WithTranslation } from "react-i18next";
import { SurveyState } from "@/redux/survey.types";
import "moment/locale/fr";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import Space from "@/components/space";
import { useEffect, useState } from "react";
import { store } from "@/index";
// import store from "@/core/store";
import { surveyActivate, surveyFetch, surveyGet } from "@/redux/survey.actions";
import DeleteSurveyModal from "@/modals/delete-survey.modal";
import PageLoader from "@/components/page-loader";
import Card from "@/components/card";
import ListFields from "@/components/list-fields";
import ListCell from "@/components/list-cell";
import ListItem from "@/components/list-item";
import { templateActivate } from "@/redux/template.actions";
import Chip from "@/components/chip";
import ListButton from "@/components/list-button";
import {
  faCircleNotch,
  faEdit,
  faEllipsisV,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { STATUS_LOADED, STATUS_LOADING } from "@/redux/_status.types";
import GetSurveyResultsModal from "@/modals/get-survey-results.modal";
import { Session } from "@/redux/_session.types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PageHeader from "@/components/page-header";
import { surveyFetch_Archive } from "@/redux/_archive.actions";
import RenameSurveyModal from "@/modals/rename-survey.modal";

interface StateProps extends WithTranslation {
  _session: Session;
  survey: SurveyState;
}

//Actions
const ACTION_GET_RESULTS = "ACTION_GET_RESULTS"; //Go to results (for launch or closed)
const ACTION_DELETE = "ACTION_DELETE"; //Delete survey
const ACTION_RENAME = "ACTION_RENAME"; //Rename the survey

//Modals
const MODAL_DELETE = "MODAL_DELETE";
const MODAL_DEMO = "MODAL_DEMO";
const MODAL_GET_RESULTS = "MODAL_GET_RESULTS";
const MODAL_RENAME = "MODAL_RENAME";

function DashboardHomeWidget(props: StateProps) {
  const { t } = props;

  //Current modal to display
  const [currentModal, setCurrentModal] = useState<string | null>(null);

  //Loading in progress
  const [isLoading, setIsLoading] = useState(true);

  //Load surveys at start
  //Load also from archive database to get participation data
  useEffect(() => {
    let timer;

    loadSurveys();

    async function loadSurveys() {
      const surveys: any = await store.dispatch(surveyFetch());
      const surveysArchive: any = await store.dispatch(surveyFetch_Archive());

      if (surveys.error || surveysArchive.error) {
        store.dispatch(surveyGet([], [], false));
      } else {
        store.dispatch(surveyGet(surveys, surveysArchive, true));
      }

      setIsLoading(false);
    }

    return () => {
      clearTimeout(timer);
    };
  }, []);

  //Click on the survey
  function clickSurvey(survey, action) {
    store.dispatch(surveyActivate(survey));
    store.dispatch(templateActivate(survey.Template));

    switch (action) {
      case ACTION_DELETE:
        return setCurrentModal(MODAL_DELETE);
      case ACTION_GET_RESULTS:
        return setCurrentModal(MODAL_GET_RESULTS);
      case ACTION_RENAME:
        return setCurrentModal(MODAL_RENAME);
    }
  }

  return (
    <div>
      {currentModal === MODAL_DEMO && (
        <GetSurveyResultsModal
          isDemo
          onClose={() => setCurrentModal(null)}
          destination="/dashboard/overview"
        />
      )}

      {currentModal === MODAL_GET_RESULTS && (
        <GetSurveyResultsModal
          onClose={() => setCurrentModal(null)}
          destination="/dashboard/overview"
        />
      )}

      {currentModal === MODAL_DELETE && (
        <DeleteSurveyModal onClose={() => setCurrentModal(null)} />
      )}

      {currentModal === MODAL_RENAME && (
        <RenameSurveyModal onClose={() => setCurrentModal(null)} />
      )}

      <PageHeader
        title={t("dashboard_results")}
        subtitle={t("dashboard_results_help")}
        warning={
          props.survey.list.length === 0
            ? t("surveys_pending_empty")
            : undefined
        }
      ></PageHeader>

      <PageLoader status={isLoading ? STATUS_LOADING : STATUS_LOADED}>
        <Card isWithoutPadding>
          <ListFields>
            <ListCell width={250}>{t("survey")}</ListCell>
            <Space />
            <ListCell width={120}>{t("survey_participation")}</ListCell>
            <ListCell width={180}>{t("utils_status")}</ListCell>
            <ListCell width={80} />
            <ListCell />
          </ListFields>

          {props.survey.list.map((survey) => (
            <ListItem
              key={survey.id}
              isEditable
              onClick={() => clickSurvey(survey, ACTION_GET_RESULTS)}
            >
              <div className="flex1" style={{ width: 210 }}>
                <div className="flex" style={{ fontSize: 18 }}>
                  {survey.name}
                </div>
                <div className="height-10" />
                {survey.isInvitationsInProgress ? (
                  <div style={{ marginTop: "8px" }} className="flex">
                    <Chip color={props._session.accountColors.active}>
                      {t("survey_launch_pending")}
                      &nbsp;
                      <FontAwesomeIcon icon={faCircleNotch} spin />
                    </Chip>
                    <Space />
                  </div>
                ) : (
                  <div className="grey-t">{survey.dateStartLabel}</div>
                )}
              </div>

              <div className="width-40" />

              <ListCell width={120}>
                <div>
                  <div>
                    {survey.getParticipationRate(
                      props._session.dashboardDisplaySettings
                        .hideStartedForParticipation
                    )}{" "}
                    %
                  </div>
                  <div className="grey-t">
                    {survey.getParticipationCount(
                      props._session.dashboardDisplaySettings
                        .hideStartedForParticipation
                    )}{" "}
                    / {survey.participationSend}
                  </div>
                </div>
              </ListCell>

              <ListCell width={180}>
                <Chip color={survey.statusColor}>{survey.statusLabel}</Chip>
              </ListCell>

              <ListCell width={80}>
                {survey.isTest && <Chip>{t("utils_test")}</Chip>}
              </ListCell>

              <ListButton
                onAction={(action: string) => clickSurvey(survey, action)}
                icon={faEllipsisV}
                actions={[
                  { id: ACTION_RENAME, label: t("utils_rename"), icon: faEdit },
                  {
                    id: ACTION_DELETE,
                    label: t("utils_delete"),
                    icon: faTrash,
                  },
                ]}
              />
            </ListItem>
          ))}

          <ListItem isEditable onClick={() => setCurrentModal(MODAL_DEMO)}>
            <div
              className="flex1"
              style={{
                width: 210,
                opacity: 0.6,
              }}
            >
              <div className="flex" style={{ fontSize: 18 }}>
                {t("dashboard_demo")}
              </div>
              <div className="height-10" />
              <div className="grey-t">{t("dashboard_demo_help")}</div>
            </div>

            <div className="width-40" />

            <ListCell width={120}>
              <div style={{ opacity: 0.6 }}>
                <div>97 %</div>
                <div className="grey-t">111 / 115</div>
              </div>
            </ListCell>

            <ListCell width={180}>
              <div style={{ opacity: 0.6 }}>
                <Chip color="#CCCCCC">{t("survey_closed")}</Chip>
              </div>
            </ListCell>

            <ListCell width={80} />

            <ListCell />
          </ListItem>
        </Card>

        <div className="height-100" />
      </PageLoader>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  _session: state._session,
  survey: state.survey,
});

export default connect(mapStateToProps)(withTranslation()(DashboardHomeWidget));
