/**
 * ERROR-MESSAGE
 * Display error message on the bottom of the screen
 */
import { store } from "@/index";
// import store from "@/core/store";
import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { sessionEdit } from "@/redux/_session.actions";
import { Session } from "@/redux/_session.types";
import Space from "./space";
import { SlideInTop, SlideOutDown } from "@/utils/animations.utils";
import { WithTranslation, withTranslation } from "react-i18next";

interface StateProps extends WithTranslation {
  _session: Session;
}

function ErrorMessage(props: StateProps) {
  const { t } = props;

  //Display slide out when the message is not displayed anymore
  const [showSlideOut, setShowSlideOut] = useState(false);

  //Function to hide the message
  //Activate slide out
  const hideMessage = useCallback(() => {
    setShowSlideOut(true);
    store.dispatch(sessionEdit("errorMessage", null));
    setTimeout(() => {
      setShowSlideOut(false);
    }, 500);
  }, []);

  //Detect when the message is fired
  //Auto hide after 10 seconds
  useEffect(() => {
    if (props._session.errorMessage) {
      setTimeout(() => {
        hideMessage();
      }, 10000);
    }
  }, [hideMessage, props._session.errorMessage]);

  return props._session.errorMessage ? (
    <SlideInTop className="error-message red-t flex">
      <Space />

      <div className="flex-auto">
        <b>{t("error_warning")} :</b>
        &nbsp;
        {props._session.errorMessage}
      </div>

      <div
        className="_hover flex-auto"
        onClick={() => hideMessage()}
        style={{
          fontSize: 12,
          marginLeft: 24,
        }}
      >
        <u>{t("error_close")}</u>
      </div>

      <Space />
    </SlideInTop>
  ) : (
    <div>
      {showSlideOut && <SlideOutDown className="error-message red-t flex" />}
    </div>
  );
}

const mapStateToProps = (state) => ({
  _session: state._session,
});

export default connect(mapStateToProps)(withTranslation()(ErrorMessage));
