/***
 * ANNOTATIONS.WIDGET
 * List of annotations for one question
 */
import { connect } from "react-redux";
import { WithTranslation, withTranslation } from "react-i18next";
import { Annotation } from "@/redux/annotation.types";
import { v4 as uuid } from "uuid";
import ContextMenu from "@/components/context-menu";
import Space from "@/components/space";
import Button from "@/components/button";
import { useState } from "react";
import { Session } from "@/redux/_session.types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { store } from "@/index";
// import store from "@/core/store";
import { topicEdit, topicEditAnnotations } from "@/redux/topic.actions";
import { TopicState } from "@/redux/topic.types";

interface StateProps extends WithTranslation {
  _session: Session;
  topic: TopicState;
}

interface OwnProps {
  onClose: Function;
}

type Props = StateProps & OwnProps;

function Annotations(props: Props) {
  const { t } = props;

  //Text for the new annotation to enter
  const [annotationText, setAnnotationText] = useState("");

  //Add new annotation
  //Save BDD then add to current list
  async function add() {
    let author =
      props._session.userFirstname +
      " " +
      props._session.userLastname.toUpperCase();
    if (!author.length) author = props._session.email;

    const annotation = new Annotation({
      id: uuid(),
      text: annotationText,
      author,
      authorId: props._session.userId,
      updated: true,
      createdAt: new Date(),
    });

    store.dispatch(
      topicEdit(
        "Annotations",
        props.topic.active.Annotations.concat([annotation])
      )
    );
    setAnnotationText("");
  }

  //Close widget
  function close() {
    store.dispatch(topicEditAnnotations(props.topic.active.Annotations));
    props.onClose();
  }

  //Style is different if messages are from the connected user
  function getStyle(authorId) {
    let backgroundColor;
    let color;

    if (authorId !== props._session.userId) {
      color = "white";
      backgroundColor = props._session.accountColors.active;
    }

    return {
      backgroundColor,
      color,
    };
  }

  //Confirm delete
  function remove(annotationId) {
    store.dispatch(
      topicEdit(
        "Annotations",
        props.topic.active.Annotations.filter((x) => x.id !== annotationId)
      )
    );
    store.dispatch(
      topicEdit(
        "annotationsDeleted",
        props.topic.active.annotationsDeleted.concat([annotationId])
      )
    );
  }

  return (
    <div
      className="annotations rel"
      onMouseLeave={!annotationText.length ? close : () => {}}
    >
      <ContextMenu onClose={close}>
        {props.topic.active.Annotations.map((annotation) => (
          <div
            key={annotation.id}
            className="annotation rel"
            style={getStyle(annotation.authorId)}
          >
            {annotation.isNew && (
              <div className="abs list-button-dot red annotation-dot" />
            )}

            <div className="flex">
              <b>{annotation.text}</b>

              <Space />

              {annotation.authorId === props._session.userId && (
                <div
                  className="grey-t annotation-delete"
                  onClick={() => remove(annotation.id)}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </div>
              )}
            </div>

            <div className="flex">
              <Space />
              {annotation.author} - {annotation.createdAtLabel}
            </div>
          </div>
        ))}

        <textarea
          value={annotationText}
          onChange={(e) => setAnnotationText(e.target.value)}
          placeholder={t("annotation_add")}
        ></textarea>

        <div className="flex">
          <Space />
          <Button onClick={close}>{t("utils_close")}</Button>
          {annotationText.length > 0 && (
            <Button onClick={add} className="primary">
              {t("utils_add")}
            </Button>
          )}
        </div>
      </ContextMenu>
    </div>
  );
}

const mapStateToProps = (state) => ({
  _session: state._session,
  topic: state.topic,
});

export default connect(mapStateToProps)(withTranslation()(Annotations));
