/**
 * DASHBOARD-MESSAGES.MINI
 * Count of message for a survey
 */

import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { faComments } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import { FilterState } from "@/redux/filter.types";
import { Config } from "@/redux/_config.types";
import { loadMessagesCount } from "@/redux/_archive.actions";
import { store } from "@/index";
// import store from "@/core/store";
import { MessageState } from "@/redux/message.types";
import { SurveyState } from "@/redux/survey.types";
import Card from "@/components/card";
import DashboardNumber from "@/components/dashboard-number";
import { STATUS_LOADING } from "@/redux/_status.types";
import { NavigateFunction, useNavigate } from "react-router-dom";

interface StateProps extends WithTranslation {
  _config: Config;
  filter: FilterState;
  message: MessageState;
  survey: SurveyState;
}

interface OwnProps {
  isPreview?: boolean;
}

type Props = StateProps & OwnProps;

function DashboardMessagesMiniWidget(props: Props) {
  const navigate: NavigateFunction = useNavigate();

  //Load count of message every times filters are changed
  //If random data : two random messages
  useEffect(() => {
    store.dispatch(loadMessagesCount(props.filter.dashboard));
  }, [props.filter.dashboard, props.survey.active.randomData]);

  const { t } = props;
  return (
    <Card
      className="_hover flex1"
      isWithoutPadding
      onClick={() => navigate("/dashboard/messages")}
    >
      <div className="height-10" />
      <DashboardNumber
        isLoading={props.message.status === STATUS_LOADING}
        count={props.isPreview ? 0 : props.message.count}
        icon={faComments}
        title={t("dashboard_messages")}
      />
    </Card>
  );
}

const mapStateToProps = (state) => ({
  _config: state._config,
  filter: state.filter,
  message: state.message,
  survey: state.survey,
});

export default connect(mapStateToProps)(
  withTranslation()(DashboardMessagesMiniWidget)
);
