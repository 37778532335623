/**
 * DASHBOARD-RPS.MINI
 * Count of RPS for a survey
 */

import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  faExclamationTriangle,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { FilterState } from "@/redux/filter.types";
import { Config } from "@/redux/_config.types";
import { Session } from "@/redux/_session.types";
import { store } from "@/index";
// import store from "@/core/store";
import { filterStatus } from "@/redux/filter.actions";
import {
  STATUS_LOADED,
  STATUS_LOADING,
  STATUS_LOAD_ERROR,
} from "@/redux/_status.types";
import { SurveyState } from "@/redux/survey.types";
import Card from "@/components/card";
import DashboardNumber from "@/components/dashboard-number";
import { fetchRpsOverview } from "@/redux/_rps.actions";
import { NavigateFunction, useNavigate } from "react-router-dom";

interface StateProps extends WithTranslation {
  _config: Config;
  _session: Session;
  filter: FilterState;
  survey: SurveyState;
}

function DashboardRpsMiniWidget(props: StateProps) {
  const { t } = props;
  const navigate: NavigateFunction = useNavigate();

  const [countStressed, setCountStressed] = useState(0);
  const [showData, setShowData] = useState(props._session.modules.rps);

  //Load count of message every times filters are changed
  //All level should be greater than 2
  useEffect(() => {
    async function load() {
      if (props._session.modules.rps && !props.survey.active.randomData) {
        store.dispatch(filterStatus(STATUS_LOADING));
        const response: any = await store.dispatch(
          fetchRpsOverview(props.filter.dashboard)
        );
        store.dispatch(
          filterStatus(response.error ? STATUS_LOAD_ERROR : STATUS_LOADED)
        );
        if (response.error) {
          setShowData(false);
        } else {
          if (response.message === "no_data") {
            setShowData(false);
          } else {
            setCountStressed(response.countStressed);
          }
        }
      }
    }

    load();
  }, [
    props._session.modules.rps,
    props.survey.active.randomData,
    props.filter.dashboard,
  ]);

  return (
    <Card
      className="_hover flex1"
      isWithoutPadding
      onClick={() => navigate("/dashboard/rps")}
    >
      {showData ? (
        <div className="flex" style={{ margin: "0px 20px" }}>
          <DashboardNumber
            color="#eb5a46"
            isActive={countStressed > 0}
            isInactive={countStressed === 0}
            isLoading={props.filter.status === STATUS_LOADING}
            count={countStressed}
            icon={faUserCircle}
            header={t("rps")}
            title={t("rps_stressed")}
          />
        </div>
      ) : (
        <DashboardNumber
          isInactive
          count={t("utils_unavailable")}
          icon={faExclamationTriangle}
          header={t("rps_header")}
          title={t("rps")}
          fontSize={12}
        />
      )}
    </Card>
  );
}

const mapStateToProps = (state) => ({
  _config: state._config,
  _session: state._session,
  filter: state.filter,
  survey: state.survey,
});

export default connect(mapStateToProps)(
  withTranslation()(DashboardRpsMiniWidget)
);
