/***
 * DASHBOARD-RANDOMIZE
 * Option to generate random data for a survey
 */
import { connect } from "react-redux";
import { WithTranslation, withTranslation } from "react-i18next";
import { SurveyState } from "@/redux/survey.types";
import { store } from "@/index";
// import store from "@/core/store";
import Checkbox from "@/components/checkbox";
import { surveyEdit } from "@/redux/survey.actions";
import { useState } from "react";
import GetSurveyResultsModal from "@/modals/get-survey-results.modal";
import { filterReplaceDashboard } from "@/redux/filter.actions";
import { DashboardFilters } from "@/redux/filter.types";
import { Session } from "@/redux/_session.types";

interface StateProps extends WithTranslation {
  _session: Session;
  survey: SurveyState;
}

interface OwnProps {
  hideRandomize?: boolean;
}

type Props = StateProps & OwnProps;

//Call getresults modal when generate data is turned off
const MODAL_RELOAD = "MODAL_RELOAD";

function DashboardRandomizeWidget(props: Props) {
  const { t } = props;

  //Current modal
  const [currentModal, setCurrentModal] = useState<string | null>(null);

  //Generate or not random data
  function toogleRandomData() {
    store.dispatch(surveyEdit("randomData", !props.survey.active.randomData));
    store.dispatch(filterReplaceDashboard(new DashboardFilters()));
  }

  return (
    <div>
      {currentModal === MODAL_RELOAD && (
        <GetSurveyResultsModal
          onClose={() => setCurrentModal(null)}
          destination={window.location.pathname}
        />
      )}

      {props.survey.active.isTest &&
        props.survey.active.id.length > 0 &&
        !props.hideRandomize && (
          <Checkbox
            text={t("dashboard_test")}
            active={props.survey.active.randomData}
            onClick={toogleRandomData}
          />
        )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  _session: state._session,
  survey: state.survey,
});

export default connect(mapStateToProps)(
  withTranslation()(DashboardRandomizeWidget)
);
