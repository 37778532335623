/**
 * ADM-ROLES-AFFECT.WIDGET
 * Automatic affect roles
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Space from "@/components/space";
import { Session } from "@/redux/_session.types";
import Link from "@/components/link";
import Modal from "@/components/modal";
import { FilterState } from "@/redux/filter.types";
import { store } from "@/index";
// import store from "@/core/store";
import { filterActivate, filterFetchAndGet } from "@/redux/filter.actions";
import Dropdown from "@/components/dropdown";
import { userFetchObservers, userUpdateObservers } from "@/redux/user.actions";
import LoadingModal from "@/modals/loading.modal";
import ListItem from "@/components/list-item";
import ListCell from "@/components/list-cell";
import Scroller from "@/components/scroller";
import ListFields from "@/components/list-fields";
import ListIcon from "@/components/list-icon";
import {
  faCheck,
  faPlus,
  faTimes,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import ListButton from "@/components/list-button";
import { v4 as uuid } from "uuid";
import ListInput from "@/components/list-input";

interface StateProps {
  _session: Session;
  filter: FilterState;
}

interface Observer {
  name: string;
  id: string;
  username: string;
  email: string | null;
}

//Modales
const MODAL_AFFECT = "MODAL_AFFECT"; //Affect managers
const MODAL_LOADING = "MODAL_LOADING";
const MODAL_SELECT_FILTER = "MODAL_SELECT_FILTER"; //Select filter

function AdmRolesAffectWidget(props: StateProps) {
  //Current modal to display
  const [currentModal, setCurrentModal] = useState<string | null>(null);

  //List of observers candidats
  const [observers, setObservers] = useState<Observer[]>([]);

  //Load filters
  useEffect(() => {
    store.dispatch(filterFetchAndGet());
  }, []);

  function addObserver(observer: Observer) {
    editObserver(observer, "id", uuid());
    editObserver(observer, "username", observer.name);
    editObserver(observer, "email", "");
  }

  //Assign observers
  async function assignObservers() {
    setCurrentModal(MODAL_LOADING);
    const observersResponse: any = await store.dispatch(
      userFetchObservers(props.filter.active.name)
    );
    if (observersResponse.error) {
      setCurrentModal(null);
    } else {
      setObservers(observersResponse);
      setCurrentModal(MODAL_AFFECT);
    }
  }

  function editObserver(observer: Observer, key, value) {
    observer[key] = value;
    setObservers(
      observers.map((x) => (x.name === observer.name ? observer : x))
    );
  }

  function removeObserver(observer: Observer) {
    editObserver(observer, "id", null);
    editObserver(observer, "email", null);
    editObserver(observer, "username", null);
  }

  //Save changes
  async function save() {
    setCurrentModal(MODAL_LOADING);
    const response: any = await store.dispatch(
      userUpdateObservers(
        observers.filter((x) => x.id),
        props.filter.active.name
      )
    );
    if (response.error) {
      setCurrentModal(MODAL_AFFECT);
    } else {
      window.location.reload();
    }
  }

  return props._session.supervisorLevel > 0 ? (
    <div className="flex">
      {currentModal === MODAL_LOADING && <LoadingModal />}

      {currentModal === MODAL_SELECT_FILTER && (
        <Modal
          onClose={() => setCurrentModal(null)}
          title="Affecter les managers"
          isCloseButtonVisible
          onNext={props.filter.active.id ? () => assignObservers() : undefined}
          disableOverflow
        >
          <Dropdown
            active={props.filter.active.id}
            displayField="name"
            list={props.filter.list}
            onSelect={(e) => {
              store.dispatch(filterActivate(e));
            }}
            title="Filtre correspondant aux managers"
            value={props.filter.active.name}
          />
        </Modal>
      )}

      {currentModal === MODAL_AFFECT && (
        <Modal
          disableClickOutside
          disableKeyEvents
          isCloseButtonVisible
          title="Affecter les managers"
          isLarge
          onClose={() => setCurrentModal(null)}
          onNext={() => save()}
        >
          <ListFields>
            <ListCell />
            <ListCell width={200}>Valeur pour le filtre</ListCell>
            <Space />
            <ListCell width={200}>Nom du manager</ListCell>
            <ListCell width={140}>Email</ListCell>
            <ListCell />
          </ListFields>

          <Scroller isModalScroller>
            {observers.map((observer, i) => (
              <ListItem key={i}>
                <ListIcon
                  iconColor={observer.id ? "#20CA7E" : "#ff9500"}
                  fallbackIcon={observer.id ? faCheck : faTimes}
                />
                <ListCell width={200}>{observer.name}</ListCell>
                <Space />
                <ListCell width={200}>
                  <span className="grey-t">{observer.username}</span>
                </ListCell>
                <ListCell width={140}>
                  {observer.id && (
                    <ListInput
                      value={observer.email}
                      onEditEnd={(value) =>
                        editObserver(observer, "email", value)
                      }
                    />
                  )}
                </ListCell>
                {observer.id ? (
                  <ListButton
                    icon={faTrash}
                    onClick={() => removeObserver(observer)}
                  />
                ) : (
                  <ListButton
                    icon={faPlus}
                    isPrimary
                    onClick={() => addObserver(observer)}
                  />
                )}
              </ListItem>
            ))}
          </Scroller>
        </Modal>
      )}

      <Space />

      <Link onClick={() => setCurrentModal(MODAL_SELECT_FILTER)}>
        Automatiquement affecter les managers (superadmin uniquement)
      </Link>
    </div>
  ) : null;
}

const mapStateToProps = (state) => ({
  _session: state._session,
  filter: state.filter,
});

export default connect(mapStateToProps)(AdmRolesAffectWidget);
