/**
 * DELETE-QUESTION.MODAL
 * Delete a question
 */
import { withTranslation, WithTranslation } from "react-i18next";
import ModalConfirm from "@/components/modal-confirm";
import { store } from "@/index";
// import store from "@/core/store";
import { topicRemoveQuestion } from "@/redux/topic.actions";
import { TopicState } from "@/redux/topic.types";
import { connect } from "react-redux";
import { questionDestroyAndRemove } from "@/redux/question.actions";
import { Question, QuestionState } from "@/redux/question.types";
import { Session } from "@/redux/_session.types";

interface StateProps extends WithTranslation {
  _session: Session;
  question: QuestionState;
  topic: TopicState;
}

interface OwnProps {
  outsideTemplate?: boolean; //delete question outside template editor
  onClose: Function;
  currentQuestion: Question;
}

type Props = StateProps & OwnProps;

function DeleteQuestionModal(props: Props) {
  const { t } = props;

  function close() {
    props.onClose();
  }

  function deleteConfirm() {
    if (props.outsideTemplate) {
      store.dispatch(questionDestroyAndRemove(props.question.active));
    } else {
      store.dispatch(
        topicRemoveQuestion(props.currentQuestion.id, props.topic.active.id)
      );
    }
    close();
  }

  return (
    <ModalConfirm
      onNo={close}
      onYes={deleteConfirm}
      text={t("question_delete_confirm", {
        question: props.currentQuestion.name[props._session.language],
      })}
      textBold={
        props.topic.active.questionPrimaryId === props.currentQuestion.id &&
        props.topic.active.Questions.length > 1
          ? t("question_delete_primary")
          : ""
      }
    />
  );
}

const mapStateToProps = (state) => ({
  _session: state._session,
  question: state.question,
  topic: state.topic,
});

export default connect(mapStateToProps)(withTranslation()(DeleteQuestionModal));
