/**
 * REPORT-SCREENSHOT-GLOBAL-RESULTS.WIDGET.TSX
 * Preview of the slide
 */
import { connect } from "react-redux";
import { WithTranslation, withTranslation } from "react-i18next";
import { Session } from "@/redux/_session.types";
import { Topic, TopicState } from "@/redux/topic.types";
import { Survey } from "@/redux/survey.types";
import { pageInitDashboardFilters } from "@/redux/page.actions";
import DashboardTopicsPodiumWidget from "./dashboard-topics-podium.widget";
import { useEffect, useState } from "react";
import { store } from "@/index";
// import store from "@/core/store";
import { fetchTopics } from "@/redux/_archive.actions";
import getArchiveTopic from "@/utils/get-archive-topic.utils";
import PageLoader from "@/components/page-loader";
import { STATUS_LOADING } from "@/redux/_status.types";

interface StateProps {
  _session: Session;
  topic: TopicState;
}

interface OwnProps {
  currentSurvey: Survey;
  displayQuestions: boolean;
  isFullWidth: boolean;
  //pagePopulations : Population[]
}

type Props = StateProps & OwnProps & WithTranslation;

function ReportScreenshotPodiumWidget(props: Props) {
  const { t } = props;

  const [topics, setTopics] = useState<Topic[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function loadData() {
      const dashboardFilters = pageInitDashboardFilters(
        [] /*props.pagePopulations*/
      );

      const topicResponse = await store.dispatch(
        fetchTopics(
          dashboardFilters,
          props._session.accountOptions.topicRepartitionCeil,
          props.currentSurvey.id,
          props.currentSurvey.dateStart
        )
      );

      setTopics(
        Array.from(props.topic.list, (topic: Topic) =>
          getArchiveTopic(topic, topicResponse)
        )
      );
      setIsLoading(false);
    }

    loadData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isLoading ? (
    <PageLoader status={STATUS_LOADING} />
  ) : (
    <div
      className={
        "flex " +
        (props.isFullWidth ? "" : " flex-dcol report-screenshot-podium-sm")
      }
      style={{
        paddingLeft: 82,
        transform: "scale(0.75)",
        transformOrigin: "top left",
      }}
    >
      <div>
        <div className="report-screenshot-podium green white-t">
          <b>{t("rank_top", { number: 5 })}</b>
        </div>

        <DashboardTopicsPodiumWidget
          currentTopics={topics}
          nbToDisplay={5}
          isPreview
          displayQuestions={props.displayQuestions}
        />
      </div>

      <div className="width-100" />

      <div className="height-20" />

      <div>
        <div className="report-screenshot-podium red white-t">
          <b>{t("rank_worst", { number: 5 })}</b>
        </div>

        <DashboardTopicsPodiumWidget
          currentTopics={topics}
          reverse={true}
          nbToDisplay={5}
          isPreview
          displayQuestions={props.displayQuestions}
        />
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  _session: state._session,
  topic: state.topic,
});

export default connect(mapStateToProps)(
  withTranslation()(ReportScreenshotPodiumWidget)
);
