/**
 * EDIT-TEMPLATE-RULES.MODAL
 * Select template for an account
 */
import Checkbox from "@/components/checkbox";
import Chip from "@/components/chip";
import ListItem from "@/components/list-item";
import Modal from "@/components/modal";
import PageLoader from "@/components/page-loader";
import Space from "@/components/space";
import { store } from "@/index";
// import store from "@/core/store";
import { useEffect, useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { AccountState } from "@/redux/account.types";
import { templateFetchAndGet } from "@/redux/template.actions";
import { TemplateState } from "@/redux/template.types";
import { Session } from "@/redux/_session.types";
import { STATUS_LOADED, STATUS_LOADING } from "@/redux/_status.types";

interface StateProps extends WithTranslation {
  _session: Session;
  account: AccountState;
  template: TemplateState;
}

type Props = StateProps & OwnProps;

interface OwnProps extends WithTranslation {
  onClose: Function;
  onSelect?: Function;
  switchForSurvey?: boolean;
}

function EditTemplateRulesModal(props: Props) {
  const { t } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [templateIds, setTemplateIds] = useState(
    props.account.active.allowedTemplates
  );

  //On load fetch templates
  useEffect(() => {
    store.dispatch(templateFetchAndGet(true, false, true, true));
    setTimeout(() => {
      setIsLoading(false);
    }, 150);
  }, [props._session.accountIsChildren, props._session.language]);

  function isTemplateActive(templateId) {
    return templateIds.indexOf(templateId) > -1;
  }

  //Select template to add to permission list
  function selectTemplate(templateId) {
    if (isTemplateActive(templateId)) {
      setTemplateIds(templateIds.filter((x) => x !== templateId));
    } else {
      setTemplateIds(templateIds.concat([templateId]));
    }
  }

  return (
    <Modal
      onClose={() => props.onClose(templateIds)}
      isCloseButtonVisible
      isLarge
      title={t("template_permissions_select", {
        name: props.account.active.name,
      })}
    >
      <div
        style={{
          width: "750px",
          minHeight: "100vh",
        }}
      >
        <PageLoader
          status={
            isLoading || props.template.status === STATUS_LOADING
              ? STATUS_LOADING
              : STATUS_LOADED
          }
        >
          <div style={{ margin: "0px -40px" }}>
            {props.template.list.map((template) => (
              <ListItem key={template.id} isSmall>
                <Checkbox
                  active={isTemplateActive(template.id)}
                  onClick={() => selectTemplate(template.id)}
                  text={template.label}
                />
                <Space />
                {template.AccountId === props._session.accountId && (
                  <Chip>{props._session.accountName}</Chip>
                )}
              </ListItem>
            ))}
          </div>
        </PageLoader>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  _session: state._session,
  account: state.account,
  template: state.template,
});

export default connect(mapStateToProps)(
  withTranslation()(EditTemplateRulesModal)
);
