/**
 * SEARCH-QUESTION.MODAL
 * Search a question in the database
 */
import Modal from "@/components/modal";
import { connect } from "react-redux";
import { v4 as uuid } from "uuid";
import { withTranslation, WithTranslation } from "react-i18next";
import { Template, TemplateState } from "@/redux/template.types";
import { Session } from "@/redux/_session.types";
import { useEffect, useState } from "react";
import { store } from "@/index";
// import store from "@/core/store";
import {
  templateFetch,
  templateFetchPublic,
  templateStatus,
} from "@/redux/template.actions";
import {
  STATUS_LOADED,
  STATUS_LOADING,
  STATUS_LOAD_ERROR,
  STATUS_SAVED,
  STATUS_SAVE_ERROR,
  STATUS_SAVING,
  STATUS_SEARCHING,
} from "@/redux/_status.types";
import ListContainer from "@/components/list-container";
import ListItem from "@/components/list-item";
import ListButton from "@/components/list-button";
import {
  faChevronDown,
  faChevronRight,
  faCircleNotch,
} from "@fortawesome/free-solid-svg-icons";
import {
  topicAddAndReplaceQuestion,
  topicCopy,
  topicCopyAndCopyQuestion,
  topicFetch,
  topicFetchOne,
  topicFetchPublic,
} from "@/redux/topic.actions";
import {
  axisActivate,
  axisCopy,
  axisFetch,
  axisFetchOne,
  axisFetchPublic,
  axisFetch_AsSupervisor,
  axisUpdate,
  axisUpdate_AsSupervisor,
} from "@/redux/axis.actions";

import ListCell from "@/components/list-cell";
import Checkbox from "@/components/checkbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Space from "@/components/space";
import Chip from "@/components/chip";
import { Question, QuestionState } from "@/redux/question.types";
import {
  questionGet,
  questionStatus,
  questionFetch,
  questionFetchCount,
  questionFetchOne,
  questionFetchCount_AsSupervisor,
  questionFetch_AsSupervisor,
} from "@/redux/question.actions";
import Link from "@/components/link";
import ModalConfirm from "@/components/modal-confirm";
import { Axis, AxisState } from "@/redux/axis.types";
import { Topic, TopicState } from "@/redux/topic.types";
import DuplicateTemplateAskModal from "./duplicate-template-ask.modal";
import { Proposition } from "@/redux/proposition.types";

interface StateProps extends WithTranslation {
  _session: Session;
  axis: AxisState;
  question: QuestionState;
  template: TemplateState;
  topic: TopicState;
}

interface OwnProps {
  onClose: Function;
  language: string;
}

type Props = StateProps & OwnProps;

const LIMIT = 8; //Count of results of search to display
const MODAL_CONFIRM = "MODAL_CONFIRM"; //Confirm import
const MODAL_DUPLICATE_ASK = "MODAL_DUPLICATE_ASK"; //Duplicate template

function SearchQuestionModal(props: Props) {
  const { t } = props;

  const [currentModal, setCurrentModal] = useState<string | null>(null);
  const [currentSearch, setCurrentSearch] = useState(""); //Text to search
  const [currentOffset, setCurrentOffset] = useState(0); //Offset for query
  const [templates, setTemplates] = useState<Template[]>([]); //Array of template to browse
  const [selectedAxes, setSelectedAxes] = useState<string[]>([]); //List of axes selected
  const [selectedTopics, setSelectedTopics] = useState<string[]>([]); //List of topics selected
  const [selectedQuestions, setSelectedQuestions] = useState<string[]>([]); //List of questions selected
  const [resultsCount, setResultsCount] = useState<number>(0);

  //Load template at beginning
  useEffect(() => {
    async function getTemplates() {
      store.dispatch(templateStatus(STATUS_LOADING));
      const templatesPublic: any = await store.dispatch(
        templateFetchPublic(props._session.language)
      );
      const templateAccount: any =
        props._session.interfaceType === "SUPERVISOR" ||
        props._session.authLevel === 0
          ? []
          : await store.dispatch(templateFetch());

      if (templatesPublic.error || templateAccount.error) {
        store.dispatch(templateStatus(STATUS_LOAD_ERROR));
        setTemplates([]);
      } else {
        const templates = templateAccount
          .concat(templatesPublic)
          .map((x: any) => (x = new Template(x)));
        store.dispatch(templateStatus(STATUS_LOADED));
        setTemplates(
          templates.filter((x) => x.id !== props.template.active.id)
        );
      }
    }

    getTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function close() {
    props.onClose();
  }

  //Copy axis from search
  async function copyAxis(axis) {
    axis.id = uuid();
    store.dispatch(axisActivate(axis));
    const updateFunction =
      props._session.interfaceType === "SUPERVISOR"
        ? axisUpdate_AsSupervisor
        : axisUpdate;
    await store.dispatch(updateFunction(axis, props.template.active.id));
    store.dispatch(axisCopy());
    return axis;
  }

  //Copy topic from search
  function copyTopic(topic: Topic, axis) {
    topic.id = uuid();
    topic.Axis = axis;
    topic.AxisId = axis ? axis.id : null;

    //Change associations with the question
    topic.Questions.forEach((question: Question) => {
      const questionId: string = uuid();
      const originalQuestionId: string = question.originalQuestionId
        ? question.originalQuestionId
        : question.id;

      if (topic.questionPrimaryId === question.id) {
        topic.questionPrimaryId = questionId;
      }

      question.id = questionId;
      question.fromSupervisor = false;
      question.QuestionsTopic.TopicId = topic.id;
      question.QuestionsTopic.QuestionId = questionId;
      question.originalQuestionId = originalQuestionId;
      question.updated = true;

      question.Propositions.forEach((proposition: Proposition) => {
        proposition.id = uuid();
        proposition.updated = true;
      });
    });

    store.dispatch(topicCopy(topic, axis));
  }

  //Edit axis
  async function editAxis(template, axis) {
    editTemplate(template.Axes.map((x: Axis) => (x.id === axis.id ? axis : x)));
  }

  //Edit template
  async function editTemplate(template) {
    setTemplates(
      templates.map((x: Template) =>
        x.id === template.id ? new Template(template) : new Template(x)
      )
    );
  }

  //Text for confirmation
  //If there are no topics in the template do not ask for confirm
  function getConfirmText() {
    if (selectedAxes.length && selectedTopics.length) {
      return t("question_ask_import_with_axes", {
        count1: selectedTopics.length,
        s1: selectedAxes.length > 1 ? "s" : "",
        count2: selectedTopics.length,
        s2: selectedQuestions.length > 1 ? "s" : "",
      });
    } else if (selectedTopics.length) {
      return t("topic_ask_import", {
        count: selectedTopics.length,
        s: selectedTopics.length > 1 ? "s" : "",
      });
    } else if (selectedAxes.length) {
      return t("axis_ask_import", {
        count: selectedAxes.length,
        s: selectedAxes.length > 1 ? "s" : "",
      });
    } else {
      return t("question_ask_import", {
        count: selectedQuestions.length,
      });
    }
  }

  //Check if axis is active
  function isAxisActive(axisId) {
    return selectedAxes.indexOf(axisId) > -1;
  }

  //Check if topic is active
  function isTopicActive(topicId) {
    return selectedTopics.indexOf(topicId) > -1;
  }

  //Load axes from a template
  //Function super / public or account depending if the user is connected
  async function loadAxes(templateId) {
    let fetchAxesFunction;
    if (props._session.interfaceType === "SUPERVISOR") {
      fetchAxesFunction = axisFetch_AsSupervisor;
    } else if (props._session.authLevel === 0) {
      fetchAxesFunction = axisFetchPublic;
    } else {
      fetchAxesFunction = axisFetch;
    }

    const axes: any = await store.dispatch(fetchAxesFunction(templateId, null));
    return axes.error ? [] : axes;
  }

  //Load topics from template
  async function loadTopics(templateId) {
    let fetchTopicsFunction;
    if (
      props._session.authLevel === 0 ||
      props._session.interfaceType === "SUPERVISOR"
    ) {
      fetchTopicsFunction = topicFetchPublic;
    } else {
      fetchTopicsFunction = topicFetch;
    }

    const topics: any = await store.dispatch(fetchTopicsFunction(templateId));
    return topics.error ? [] : topics;
  }

  //Open axis
  async function openAxis(template, axis) {
    axis.open = !axis.open;
    editAxis(template, axis);
  }

  //Open template => get axes and topics
  async function openTemplate(template) {
    if (template.open) {
      template.Axes = [];
      template.Topics = [];
    } else {
      template.loading = true;
      editTemplate(template);

      template.Axes = await loadAxes(template.id);
      template.Topics = await loadTopics(template.id);
    }

    template.open = !template.open;
    template.loading = false;
    editTemplate(template);
  }

  //Search a quesiton
  //Reset current selection
  async function search(value) {
    setSelectedAxes([]);
    setSelectedTopics([]);
    setSelectedQuestions([]);
    setCurrentOffset(0);

    if (value.length) {
      let fetchCountFunction: Function = questionFetchCount;
      let fetchFunction: Function = questionFetch;

      if (props._session.interfaceType === "SUPERVISOR") {
        fetchCountFunction = questionFetchCount_AsSupervisor;
        fetchFunction = questionFetch_AsSupervisor;
      }

      const count: any = await store.dispatch(
        fetchCountFunction(value, props._session.language, true)
      );
      const response: any = await store.dispatch(
        fetchFunction(
          value,
          LIMIT,
          currentOffset,
          props._session.language,
          true
        )
      );

      setResultsCount(count.error ? 0 : count.count);

      if (response.error) {
        store.dispatch(questionStatus(STATUS_LOAD_ERROR));
        store.dispatch(questionGet([]));
      } else {
        store.dispatch(questionStatus(STATUS_LOADED));
        store.dispatch(questionGet(response));
      }
    } else {
      store.dispatch(questionStatus(STATUS_LOADED));
    }

    setCurrentSearch(value);
  }

  //Get next questions
  //add at the end of the list
  async function searchNext() {
    const offset: number = currentOffset + LIMIT;
    setCurrentOffset(offset);
    store.dispatch(questionStatus(STATUS_LOADING));

    let fetchFunction: Function = questionFetch;

    if (props._session.interfaceType === "SUPERVISOR") {
      fetchFunction = questionFetch_AsSupervisor;
    }

    const response: any = await store.dispatch(
      fetchFunction(currentSearch, LIMIT, offset, props._session.language, true)
    );
    store.dispatch(
      questionStatus(response.error ? STATUS_LOAD_ERROR : STATUS_LOADED)
    );
    store.dispatch(
      questionGet(response.error ? [] : props.question.list.concat(response))
    );
  }

  //Confirm questions to import
  async function next() {
    //If template of idtree and not supervisor
    //Ask for duplicate
    if (
      props._session.interfaceType !== "SUPERVISOR" &&
      props.template.active.fromSupervisor
    ) {
      setCurrentModal(MODAL_DUPLICATE_ASK);
    } else {
      //If only one question selected, procedd to copy
      if (
        selectedQuestions.length === 1 &&
        selectedAxes.length === 0 &&
        selectedTopics.length === 0
      ) {
        store.dispatch(questionStatus(STATUS_SAVING));
        const response: any = await store.dispatch(
          questionFetchOne(selectedQuestions[0])
        );
        if (response.error) {
          store.dispatch(questionStatus(STATUS_SAVE_ERROR));
        } else {
          store.dispatch(questionStatus(STATUS_SAVED));
          store.dispatch(
            topicAddAndReplaceQuestion(
              props._session.interfaceType === "SUPERVISOR",
              response
            )
          );
          close();
        }
      } else if (props.topic.list.length === 0) {
        nextConfirm();
      } else {
        setCurrentModal(MODAL_CONFIRM);
      }
    }
  }

  //Import many questions
  async function nextConfirm() {
    //Get copy of axes
    const axes = props.axis.list.filter((x) => new Axis(x));

    if (selectedAxes.length) {
      for (let i = 0; i < selectedAxes.length; i++) {
        const selectedAxis = selectedAxes[i];
        const currentAxis: any = await store.dispatch(
          axisFetchOne(selectedAxis)
        );
        const topics: any = await loadTopics(currentAxis.TemplateId);

        //Create axis for the existing template
        const axis = await copyAxis(currentAxis);
        axes.push(axis);

        //Loop on topics the create them
        topics
          .filter((x) => x.AxisId === selectedAxis)
          .forEach((topic: Topic) => {
            copyTopic(topic, axis);
          });
      }
    }

    //Import topic
    if (selectedTopics.length) {
      for (let i = 0; i < selectedTopics.length; i++) {
        const selectedTopic = selectedTopics[i];
        const topic: any = await store.dispatch(topicFetchOne(selectedTopic));

        //Create axis if not exist
        if (topic.Axis) {
          const currentAxis = axes.find(
            (x) =>
              x.name[props._session.language] ===
              topic.Axis.name[props._session.language]
          );

          if (currentAxis) {
            copyTopic(topic, currentAxis);
          } else {
            const axis = await copyAxis(topic.Axis);
            axes.push(axis);
            copyTopic(topic, axis);
          }
        } else {
          copyTopic(topic, null);
        }
      }
    }

    //Import questions
    for (let i = 0; i < selectedQuestions.length; i++) {
      const selectedQuestion = selectedQuestions[i];
      const response: any = await store.dispatch(
        questionFetchOne(selectedQuestion)
      );
      if (!response.error) {
        store.dispatch(topicCopyAndCopyQuestion(response));
      }
    }

    store.dispatch(questionStatus(STATUS_SAVED));
    close();
  }

  //Select axis
  function selectAxis(axis) {
    if (isAxisActive(axis.id)) {
      setSelectedAxes(selectedAxes.filter((x) => x !== axis.id));
    } else {
      setSelectedAxes(selectedAxes.concat([axis.id]));
    }
  }

  //Select question
  function selectQuestion(questionId) {
    if (selectedQuestions.indexOf(questionId) > -1) {
      setSelectedQuestions(selectedQuestions.filter((x) => x !== questionId));
    } else {
      setSelectedQuestions(selectedQuestions.concat([questionId]));
    }
  }

  //Select topic
  function selectTopic(topic) {
    if (isTopicActive(topic.id)) {
      setSelectedTopics(selectedTopics.filter((x) => x !== topic.id));
    } else {
      setSelectedTopics(selectedTopics.concat([topic.id]));
    }
  }

  //Display question + propositions
  function renderQuestion(question, isPrimary) {
    return (
      <div className={isPrimary ? "flex" : "flex grey-t"}>
        {!isPrimary && (
          <div className="search-question-secondary">
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
        )}

        <div className="flex1">
          {!isPrimary && <span>{t("question_secondary")} :&nbsp;</span>}

          {question.name[props.language]}

          {question.Propositions.length > 0 && (
            <ul>
              {question.Propositions.map((proposition: Proposition) => (
                <li key={proposition.id}>{proposition.name[props.language]}</li>
              ))}
            </ul>
          )}
        </div>
      </div>
    );
  }

  return (
    <Modal
      isCloseButtonVisible
      isLarge
      onClose={close}
      onNext={
        selectedQuestions.length || selectedAxes.length || selectedTopics.length
          ? next
          : undefined
      }
      status={props.question.status}
    >
      {currentModal === MODAL_CONFIRM && (
        <ModalConfirm
          onNo={() => setCurrentModal(null)}
          onYes={nextConfirm}
          textBold={t("utils_next_ask")}
          text={getConfirmText()}
          status={props.question.status}
        />
      )}

      {currentModal === MODAL_DUPLICATE_ASK && (
        <DuplicateTemplateAskModal onClose={() => setCurrentModal(null)} />
      )}

      <div className="flex">
        <div>
          <p>
            <b>{t("editor_onboarding_search")}</b>
          </p>
          <p>{t("editor_onboarding_search_help")}</p>
        </div>
        <div className="width-100" />
        <div className="flex">
          <img
            src={require("@/assets/edit.png")}
            alt="edit"
            style={{
              width: "100px",
              height: "100px",
              margin: "auto",
            }}
          />
        </div>
      </div>

      <div className="search-question-container">
        <ListContainer
          onSearch={search}
          countSearch={resultsCount}
          onSearchStart={() => store.dispatch(questionStatus(STATUS_SEARCHING))}
          status={props.question.status}
        >
          {
            /** if current search display questions one by one */
            currentSearch.length ? (
              <div className="search-question">
                {props.question.list.map((question: Question) => (
                  <ListItem
                    key={question.id}
                    isEditable
                    onClick={() => selectQuestion(question.id)}
                    isSmall
                  >
                    <ListCell>
                      <Checkbox
                        active={selectedQuestions.indexOf(question.id) > -1}
                        onClick={() => selectQuestion(question.id)}
                      />
                    </ListCell>

                    {renderQuestion(question, true)}

                    <Space />

                    <ListCell width={20} />

                    <div style={{ textAlign: "right" }} className="grey-t">
                      {question.Topics.length > 0 &&
                        question.Topics[0].name[props.language]}
                    </div>
                  </ListItem>
                ))}

                {props.question.list.length < resultsCount && (
                  <div className="flex">
                    <Space />
                    <Link onClick={searchNext}>
                      {`${t("utils_show_more")} ${
                        props.question.list.length
                      }/${resultsCount}`}
                    </Link>
                    <Space />
                  </div>
                )}
              </div>
            ) : (
              /** browse in the template */
              <div className="search-question">
                {templates.map((template: Template) => (
                  <div key={template.id}>
                    <ListItem
                      isSmall
                      isEditable
                      onClick={() => openTemplate(template)}
                    >
                      <ListButton
                        icon={template.open ? faChevronDown : faChevronRight}
                        text={t("question_search_template")}
                        onClick={() => openTemplate(template)}
                      />
                      <b>{template.name[props.language]}</b>
                      &nbsp;
                      {template.loading && (
                        <FontAwesomeIcon icon={faCircleNotch} spin />
                      )}
                      <Space />
                      {!template.fromSupervisor && (
                        <Chip color={props._session.accountColors.brandPrimary}>
                          {props._session.accountName}
                        </Chip>
                      )}
                    </ListItem>

                    {template.Axes.map((axis: Axis) => (
                      <div key={axis.id}>
                        <ListItem isEditable isSmall>
                          <ListCell>
                            <Checkbox
                              active={isAxisActive(axis.id)}
                              onClick={() => selectAxis(axis)}
                            />
                          </ListCell>

                          <ListButton
                            icon={axis.open ? faChevronDown : faChevronRight}
                            text={t("question_search_questions")}
                            onClick={() => openAxis(template, axis)}
                          />

                          <b>
                            {t("question_search_axis", {
                              axis: axis.name[props.language],
                            })}
                          </b>
                        </ListItem>

                        {axis.open &&
                          template.Topics.filter(
                            (x) => x.Axis?.id === axis.id
                          ).map((topic: Topic) => (
                            <div key={topic.id}>
                              {topic.Questions.map((question, q) => (
                                <ListItem key={question.id} isEditable isSmall>
                                  {q === 0 ? (
                                    <ListCell>
                                      <Checkbox
                                        active={
                                          isTopicActive(topic.id) ||
                                          isAxisActive(axis.id)
                                        }
                                        onClick={
                                          !isAxisActive(axis.id)
                                            ? () => selectTopic(topic)
                                            : null
                                        }
                                      />
                                    </ListCell>
                                  ) : (
                                    <ListCell>
                                      <Checkbox
                                        active={
                                          isTopicActive(topic.id) ||
                                          isAxisActive(axis.id)
                                        }
                                        onClick={null}
                                      />
                                    </ListCell>
                                  )}

                                  <ListCell />

                                  {renderQuestion(question, q === 0)}
                                </ListItem>
                              ))}
                            </div>
                          ))}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            )
          }
        </ListContainer>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  _session: state._session,
  axis: state.axis,
  question: state.question,
  template: state.template,
  topic: state.topic,
});

export default connect(mapStateToProps)(withTranslation()(SearchQuestionModal));
