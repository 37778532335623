/**
 * IMPORT-USERS.MODAL
 * Pick a type of importation (list, integration, emails)
 */
import { useState } from "react";
import Modal from "@/components/modal";
import { WithTranslation, withTranslation } from "react-i18next";
import { faPen, faFile, faDownload } from "@fortawesome/free-solid-svg-icons";

import CardContainer from "@/components/card-container";
import CardButton from "@/components/card-button";
import Space from "@/components/space";
import { store } from "@/index";
// import store from "@/core/store";
import { userGet, userStatus } from "@/redux/user.actions";
import { connect } from "react-redux";
import { Session } from "@/redux/_session.types";
import UpgradeModal from "./upgrade.modal";
import AdmIntegrationModal from "./adm-integration.modal";
import { sortBy } from "lodash";
import { toast } from "react-toastify";
import AdmIntegrationLuccaModal from "./adm-integration-lucca.modal";
import Link from "@/components/link";
import { STATUS_LOADED } from "@/redux/_status.types";
import { NavigateFunction, useNavigate } from "react-router-dom";

interface StateProps extends WithTranslation {
  _session: Session;
}

interface OwnProps {
  onClose: Function;
  onNext?: Function;
  onSelect?: Function;
}

type Props = StateProps & OwnProps;

//Dialogs
const MODAL_INTEGRATION = "MODAL_INTEGRATION"; //Display list of integrations
const MODAL_INTEGRATION_LUCCA: string = "MODAL_INTEGRATION_LUCCA"; //Proceed to integration with Lucca
const MODAL_UPGRADE = "MODAL_UPGRADE"; //Upgrade modal (when feature is lock)

function ImportUsersModal(props: Props) {
  const { t } = props;
  const navigate: NavigateFunction = useNavigate();

  //List of options
  const CARD_ITEMS = [
    {
      id: "upload",
      link: "/adm/import-file",
      title: t("user_import_file"),
      icon: faFile,
      lock: props._session.accountLevel === "free",
    },
    {
      id: "emails",
      link: "/adm/import-manual",
      title: t("user_import_manual"),
      icon: faPen,
      lock: false,
    },
    {
      id: "integration",
      title: t("user_import_integration"),
      icon: faDownload,
      lock: !props._session.modules.integration,
    },
  ];

  const recommendation: string = props._session.modules.integration
    ? "integration"
    : props._session.accountLevel === "free"
    ? "emails"
    : "upload";

  //Current modal to display
  const [currentModal, setCurrentModal] = useState<string | null>(null);

  //Feature locked
  const [currentFeature, setCurrentFeature] = useState("");

  const [integrationDone, setIntegrationDone] = useState<boolean>(
    props._session.accountOptions.integration === "lucca" ? true : false
  );

  //Select one option
  function selectMethod(card) {
    if (card.lock) {
      setCurrentFeature(card.id);
      setCurrentModal(MODAL_UPGRADE);
    } else {
      //Reset store
      store.dispatch(userGet([]));
      store.dispatch(userStatus(STATUS_LOADED));

      if (card.id === "integration") {
        setCurrentModal(MODAL_INTEGRATION);
      } else {
        if (props.onSelect) {
          props.onSelect(card.id);
        } else {
          navigate(card.link);
        }
      }
    }
  }

  function sync() {
    switch (props._session.accountOptions.integration) {
      case "lucca":
        setCurrentModal(MODAL_INTEGRATION_LUCCA);
        break;
      case "peoplesphere":
        toast(t("utils_saved"));
        setCurrentModal(null);
        if (props.onNext) {
          props.onNext();
        } else {
          props.onClose();
        }
        break;
      default:
        toast(t("not_implemented"), { type: "error" });
        break;
    }
  }

  return (
    <Modal
      isCloseButtonVisible
      onClose={() => props.onClose()}
      onNext={integrationDone ? sync : undefined}
      title={t("user_import_title")}
    >
      <p>{t("user_import_help")}</p>

      {currentModal === MODAL_UPGRADE && (
        <UpgradeModal
          feature={currentFeature}
          isAppLevel={currentFeature === "upload"}
          onClose={() => setCurrentModal(null)}
        />
      )}

      {currentModal === MODAL_INTEGRATION && (
        <AdmIntegrationModal
          onClose={() => setCurrentModal(null)}
          onSave={sync}
        />
      )}

      {currentModal === MODAL_INTEGRATION_LUCCA && (
        <AdmIntegrationLuccaModal
          onClose={() => setCurrentModal(null)}
          onNext={props.onNext ? props.onNext : props.onClose}
        />
      )}

      {integrationDone ? (
        <div style={{ textAlign: "center" }}>
          <img
            src={require("@/assets/logo-" +
              props._session.accountOptions.integration?.toLowerCase() +
              ".png")}
            alt="integration"
            width="150px"
          />

          <p>
            {t("user_import_integration_exist", {
              name: props._session.accountOptions.integration,
            })}
          </p>

          <p>
            <b>{t("user_import_integration_exist_help")}</b>
          </p>

          <Link onClick={() => setIntegrationDone(false)}>
            {t("utils_other_method")}
          </Link>
        </div>
      ) : (
        <CardContainer>
          <Space />

          {sortBy(CARD_ITEMS, (card) => {
            return card.id !== recommendation;
          }).map((card) => (
            <CardButton
              subtitle={
                card.id === recommendation ? t("utils_recommended") : ""
              }
              isCta={card.id === recommendation}
              isLock={card.lock}
              key={card.id}
              icon={card.icon}
              title={card.title}
              onClick={() => selectMethod(card)}
            ></CardButton>
          ))}

          <Space />
        </CardContainer>
      )}
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  _session: state._session,
});

export default connect(mapStateToProps)(withTranslation()(ImportUsersModal));
