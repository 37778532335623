/**
 * SUPERVISOR.ACTIONS
 */

import { SUPERVISOR_ACTIVATE, SUPERVISOR_ADD, SUPERVISOR_EDIT, SUPERVISOR_GET, SUPERVISOR_INIT, SUPERVISOR_REMOVE, SUPERVISOR_STATUS, Supervisor } from "./supervisor.types";


//REDUX

export const supervisorActivate = (supervisor:Supervisor) => ({
  type : SUPERVISOR_ACTIVATE,
  payload : {
    supervisor
  }
})

export const supervisorAdd = () => ({
  type : SUPERVISOR_ADD
})

export const supervisorEdit = (key:string, value:any) => ({
  type : SUPERVISOR_EDIT,
  payload : {
    key,
    value
  }
})

export const supervisorGet = (supervisors:Supervisor[]) => ({
  type : SUPERVISOR_GET,
  payload : {
    supervisors
  }
})

export const supervisorInit = () => ({
  type : SUPERVISOR_INIT
})

export const supervisorRemove = (id:string) => ({
  type : SUPERVISOR_REMOVE,
  payload : {
    id
  }
})

export const supervisorStatus = (status:string) => ({
  type : SUPERVISOR_STATUS,
  payload : {
    status
  }
})


//API

export const supervisorDestroy = (id:string) => ({
  type : 'API',
  payload : {
    method : "DELETE",
    url : "/supervisor/supervisor/" + id
  }
})

export const supervisorFetch = (search:string) => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/supervisor/supervisors",
    data : {
      search
    }
  }
})

export const supervisorSendEmail = (email, sender, accountName, content) => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/supervisor/supervisor/send-email",
    data : {
      email,
      sender,
      accountName,
      content
    }
  }
})

export const supervisorUpdate = (supervisor:Supervisor) => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/supervisor/supervisor",
    data : {
      supervisor
    }
  }
})

export const supervisorUpdateLanguage = (language:string) => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/supervisor/supervisor/language",
    data : {
      language
    }
  }
})