/**
 * HOME-SURVEYS.WIDGET
 * Shortcut to last survey done
 */
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import Card from "@/components/card";
import ListFields from "@/components/list-fields";
import ListCell from "@/components/list-cell";
import ListItem from "@/components/list-item";
import { SurveyState } from "@/redux/survey.types";
import Space from "@/components/space";
import Button from "@/components/button";
import { useEffect, useState } from "react";
import { store } from "@/index";
// import store from "@/core/store";
import { Session } from "@/redux/_session.types";
import { STATUS_LOADED, STATUS_LOADING } from "@/redux/_status.types";
import { surveyActivate, surveyFetch, surveyGet } from "@/redux/survey.actions";
import Chip from "@/components/chip";
import DashboardNumber from "@/components/dashboard-number";
import {
  faEnvelopeOpen,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import GetSurveyResultsModal from "@/modals/get-survey-results.modal";
import { surveyFetch_Archive } from "@/redux/_archive.actions";
import Link from "@/components/link";
import { invitationFetchByEmail } from "@/redux/invitation.actions";
import env from "@/env";

interface StateProps extends WithTranslation {
  _session: Session;
  survey: SurveyState;
}

const MODAL_RESULTS = "MODAL_RESULTS";
const MODAL_DEMO = "MODAL_DEMO";

function HomeSurveysWidget(props: StateProps) {
  const { t } = props;

  const [currentModal, setCurrentModal] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [qvLink, setQvLink] = useState<string | null>(null); //Show link to vote if log with SSO as admin

  //Get surveys on load
  //If there is a survey set active
  useEffect(() => {
    async function load() {
      store.dispatch(surveyGet([], [], true));

      const surveys: any = await store.dispatch(surveyFetch());
      const surveysArchive: any = await store.dispatch(surveyFetch_Archive());

      if (!surveys.error && !surveysArchive.error) {
        store.dispatch(surveyGet(surveys, surveysArchive, true));

        //Search if vote is pending
        if (props._session.modules.sso && props._session.qvCode) {
          const qvInvitation: any = await store.dispatch(
            invitationFetchByEmail(props._session.email)
          );
          if (qvInvitation) {
            const qvSurvey = surveys.find(
              (x) => x.id === qvInvitation.fk_survey
            );
            if (!qvSurvey?.dateEnd && !qvInvitation.date_done) {
              setQvLink(qvInvitation.code);
            }
          }
        }
      }

      setIsLoading(false);
    }

    if (props._session.authLevel > 0) {
      load();
    } else {
      store.dispatch(surveyGet([], [], false));
      setIsLoading(false);
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props._session.authLevel, props._session.accountId]);

  //Select survey and open modal to get results
  function toSurvey(survey) {
    store.dispatch(surveyActivate(survey));
    setCurrentModal(MODAL_RESULTS);
  }

  function renderSurveyWidget() {
    return qvLink ? (
      <Card className="flex1" isWithMinHeight>
        <div style={{ textAlign: "center" }}>
          <div className="height-10" />
          <p className="grey-t">
            <b>Enquête en cours</b>
          </p>
          <h2>{props.survey.active.name}</h2>
          <div className="height-20" />
          <Button
            isLarge
            className="primary"
            onClick={() =>
              window.open(env.REACT_APP_URL_QUICKVOTE + "/" + qvLink)
            }
          >
            Participer
          </Button>
        </div>
      </Card>
    ) : (
      <Card
        className="flex1"
        isWithMinHeight
        status={isLoading ? STATUS_LOADING : STATUS_LOADED}
      >
        {currentModal === MODAL_DEMO && (
          <GetSurveyResultsModal
            isDemo
            onClose={() => setCurrentModal(null)}
            destination="/dashboard/overview"
          />
        )}

        {currentModal === MODAL_RESULTS && (
          <GetSurveyResultsModal
            onClose={() => setCurrentModal(null)}
            destination="/dashboard/overview"
          />
        )}

        {
          /**
           * IF THERE IS NO SURVEY DONE
           * text to say "no survey done"
           * lien to dashboard
           */
          props.survey.list.length === 0 ? (
            <div className="flex">
              <div className="flex flex-dcol">
                <Space />
                <img
                  src={require("@/assets/onboarding.png")}
                  height="180px"
                  alt="home-survey"
                />
                <Space />
              </div>

              <div
                className="flex1 flex flex-dcol"
                style={{
                  width: 180,
                  marginLeft: 40,
                  textAlign: "center",
                }}
              >
                <Space />

                <p>
                  <b>
                    {props._session.userRole === "ADMIN"
                      ? t("surveys_pending_empty")
                      : t("no_survey_active")}
                  </b>
                </p>

                <Space />

                {props._session.userRole === "ADMIN" && (
                  <div className="flex">
                    <Space />
                    <Link
                      onClick={() => setCurrentModal(MODAL_DEMO)}
                      isWithoutMargin
                    >
                      {t("onboarding_dashboard")}
                    </Link>
                    <Space />
                  </div>
                )}
              </div>
            </div>
          ) : (
            /**
             * IF THERE IS AT LEAST ONE SURVEY
             * si + de 1 alors bouton précédent et next
             * ligne 1 show survey name
             * ligne 2 show "lancée le xxx"
             * ligne 3 (big) => global note | participation
             * ligne 4 => lien vers le résultats
             */
            <div
              className="flex flex-dcol home-widget"
              style={{ width: "100%" }}
            >
              <div className="flex" style={{ width: 460 }}>
                <b>{props.survey.active.name}</b>
                <Space />
                <div className="width-20" />
                <Chip
                  isWithMargin
                  isOutlineColored
                  color={props.survey.active.statusColor}
                >
                  {props.survey.active.statusLabel}
                </Chip>
              </div>

              <Space />

              <div className="flex">
                <DashboardNumber
                  count={
                    props.survey.active.getParticipationRate(
                      props._session.dashboardDisplaySettings
                        .hideStartedForParticipation
                    ) + "%"
                  }
                  isActive
                  header={t("survey_participation_rate")}
                  title={t("survey_participation")}
                />

                <DashboardNumber
                  count={props.survey.active.participationSend}
                  icon={faPaperPlane}
                  header={t("users")}
                  title={t("survey_invited")}
                />

                <DashboardNumber
                  count={props.survey.active.getParticipationCount(
                    props._session.dashboardDisplaySettings
                      .hideStartedForParticipation
                  )}
                  icon={faEnvelopeOpen}
                  isInactive
                  header={t("templates")}
                  title={t("survey_count")}
                />
              </div>

              <Space />

              <div className="flex">
                <Space />
                <Button
                  className="primary"
                  onClick={() => setCurrentModal(MODAL_RESULTS)}
                >
                  {t("survey_link_dashboard")}
                </Button>
              </div>
            </div>
          )
        }
      </Card>
    );
  }

  return props._session.userRole === "ADMIN" ? (
    renderSurveyWidget()
  ) : (
    <div style={{ width: "600px" }}>
      <h2>{t("survey_last")}</h2>

      {renderSurveyWidget()}

      {props.survey.list.length > 1 && (
        <div>
          <h2>{t("survey_before")}</h2>

          <Card
            isWithoutPadding
            status={isLoading ? STATUS_LOADING : STATUS_LOADED}
          >
            <ListFields>
              <ListCell width={200}>{t("survey")}</ListCell>
            </ListFields>

            {props.survey.list.map((survey) => (
              <ListItem
                key={survey.id}
                isEditable
                onClick={() => toSurvey(survey)}
              >
                <div className="flex1">
                  <div>{survey.name}</div>
                  <div className="grey-t">{survey.dateStartLabel}</div>
                </div>
              </ListItem>
            ))}
          </Card>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  _session: state._session,
  survey: state.survey,
});

export default connect(mapStateToProps)(withTranslation()(HomeSurveysWidget));
