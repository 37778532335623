/**
 * ADD-TEMPLATE.MODAL
 * add new questionnaire
 */
import Modal from "@/components/modal";
import { useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, WithTranslation } from "react-i18next";
import TextInput from "@/components/text-input";
import { store } from "@/index";
// import store from "@/core/store";
import {
  STATUS_SAVED,
  STATUS_SAVE_ERROR,
  STATUS_SAVING,
} from "@/redux/_status.types";
import {
  templateAdd,
  templateInsert,
  templateInsert_AsSupervisor,
  templateEditName,
  templateStatus,
  templateActivate,
} from "@/redux/template.actions";
import { TemplateState } from "@/redux/template.types";
import { Session } from "@/redux/_session.types";
import { topicGet } from "@/redux/topic.actions";
import i18n from "@/translate/i18n";
import { NavigateFunction, useNavigate } from "react-router-dom";

interface StateProps extends WithTranslation {
  _session: Session;
  template: TemplateState;
}

interface OwnProps {
  onClose: Function;
}

type Props = StateProps & OwnProps;

function AddTemplateModal(props: Props) {
  const { t } = props;
  const navigate: NavigateFunction = useNavigate();

  //Init new template on start
  useEffect(() => {
    store.dispatch(templateAdd(i18n.language));
  }, []);

  //Reset url params on close
  function close() {
    props.onClose();
  }

  //Save the template
  //The function to use depends if the user is connected as supervisor or into an account
  async function next() {
    const addFunction =
      props._session.interfaceType === "SUPERVISOR"
        ? templateInsert_AsSupervisor
        : templateInsert;
    store.dispatch(templateStatus(STATUS_SAVING));
    const response: any = await store.dispatch(
      addFunction(props.template.active.id, props.template.active.name)
    );

    if (response.error) {
      store.dispatch(templateStatus(STATUS_SAVE_ERROR));
    } else {
      store.dispatch(templateStatus(STATUS_SAVED));
      store.dispatch(templateActivate(response));
      store.dispatch(topicGet([]));
      navigate("/template/editor");
    }
  }

  return (
    <Modal
      isCloseButtonVisible
      status={props.template.status}
      onClose={close}
      onNext={next}
      title={t("template_create_new")}
    >
      <TextInput
        autoFocus
        isFullWidth
        onChange={(e) =>
          store.dispatch(
            templateEditName(props.template.active.language, e.value)
          )
        }
        title={t("template_name")}
        value={props.template.active.name[props.template.active.language]}
      />

      <div className="height-20" />
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  _session: state._session,
  template: state.template,
});

export default connect(mapStateToProps)(withTranslation()(AddTemplateModal));
