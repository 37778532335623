import Space from "@/components/space";
import { selectFilter, selectSession } from "@/redux/selectors/selectors";
import { Box, Button, Card } from "@mui/material";
import { t } from "i18next";
import { useSelector } from "react-redux";

export const DashboardRpsGollacWidget = () => {
  const session = useSelector(selectSession);
  const filter = useSelector(selectFilter);

  console.log(session);
  console.log(filter);

  return (
    <Card>
      {session.accountOptions.gollacFilterName ? (
        <>Widget</>
      ) : (
        <Box sx={{ m: 2, display: "flex", alignItems: "center" }}>
          <b>
            {t("filter_reference_manager_gollac")} : {t("utils_undefined")}{" "}
          </b>
          <Space />
          {session.userRole === "ADMIN" ? (
            <Button variant="outlined" href="/adm/filters">
              {t("adm_filter_route")}
            </Button>
          ) : (
            <Box>{t("contact_admin")}</Box>
          )}
        </Box>
      )}
    </Card>
  );
};
