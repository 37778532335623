/**
 * PROJECT-CLOSE-SURVEY.MODAL
 * Set dateEnd for a survey
 */
import Modal from "@/components/modal";
import { connect } from "react-redux";

import { withTranslation, WithTranslation } from "react-i18next";
import { store } from "@/index";
// import store from "@/core/store";
import {
  STATUS_SAVED,
  STATUS_SAVE_ERROR,
  STATUS_SAVING,
} from "@/redux/_status.types";
import { Session } from "@/redux/_session.types";
import EnterCodeWidget from "@/widgets/enter-code.widget";
import { SurveyState } from "@/redux/survey.types";
import {
  surveyEdit,
  surveyStatus,
  surveyUpdateClose,
} from "@/redux/survey.actions";
import { ProjectState } from "@/redux/project.types";
import { projectEdit } from "@/redux/project.actions";
import ModalConfirm from "@/components/modal-confirm";

interface StateProps extends WithTranslation {
  _session: Session;
  project: ProjectState;
  survey: SurveyState;
}

interface OwnProps {
  onClose: Function;
}

type Props = StateProps & OwnProps;

function ProjectCloseSurveyModal(props: Props) {
  const { t } = props;

  async function next() {
    store.dispatch(surveyStatus(STATUS_SAVING));
    const response: any = await store.dispatch(
      surveyUpdateClose(props.survey.active.id)
    );
    store.dispatch(
      surveyStatus(response.error ? STATUS_SAVE_ERROR : STATUS_SAVED)
    );

    if (!response.error) {
      store.dispatch(surveyEdit("dateEnd", new Date()));
      store.dispatch(
        projectEdit(
          "Surveys",
          props.project.active.Surveys.filter((x) =>
            x.id === props.survey.active.id ? (x.dateEnd = new Date()) : x
          )
        )
      );

      if (!props.survey.active.isTest) {
        store.dispatch(projectEdit("isClosed", true));
      }

      props.onClose();
    }
  }

  return props.project.active.status === "test" ? (
    <ModalConfirm
      onNo={() => props.onClose()}
      onYes={() => next()}
      text={t("survey_close_test")}
      textBold={t("utils_next_ask")}
    />
  ) : (
    <Modal onClose={() => props.onClose()} title={t("survey_close")}>
      <p>{t("survey_close_warning")}</p>

      <EnterCodeWidget
        onCancel={() => props.onClose()}
        onNext={() => next()}
        labelCta={t("survey_close_cta")}
        status={props.survey.status}
      />
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  _session: state._session,
  project: state.project,
  survey: state.survey,
});

export default connect(mapStateToProps)(
  withTranslation()(ProjectCloseSurveyModal)
);
