/**
 * TASKS-OPTIONS.WIDGET
 * Change options for an action plan
 */
import TextInput from "@/components/text-input";
import { store } from "@/index";
// import store from "@/core/store";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { actionPlanEdit, actionPlanUpdate } from "@/redux/action-plan.actions";
import { ActionPlanState } from "@/redux/action-plan.types";
import { formatDate } from "@/utils/format-date.utils";
import Button from "@/components/button";
import ListButton from "@/components/list-button";
import { faTimes, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { snakeCase } from "lodash";
import PageLoader from "@/components/page-loader";
import { STATUS_LOADED, STATUS_LOADING } from "@/redux/_status.types";
import { useEffect, useState } from "react";
import { userFetch } from "@/redux/user.actions";
import ListIcon from "@/components/list-icon";
import { User, UserState } from "@/redux/user.types";
import ContextMenu from "@/components/context-menu";
import ListItem from "@/components/list-item";
import SearchBar from "@/components/search-bar";
import Checkbox from "@/components/checkbox";

interface StateProps extends WithTranslation {
  actionPlan: ActionPlanState;
  user: UserState;
}

function TasksOptionsWidget(props: StateProps) {
  const { t } = props;

  //Widget is loading
  const [isLoading, setIsLoading] = useState(true);

  //List admins
  const [admins, setAdmins] = useState<User[]>([]);

  //User contact
  const [userContact, setUserContact] = useState<undefined | User>(undefined);

  //Searched admin
  const [searchedAdmin, setSearchedAdmin] = useState("");

  //Select admin
  const [showAdminsContext, setShowAdminsContext] = useState(false);

  //Load admins
  useEffect(() => {
    loadAdmins();

    async function loadAdmins() {
      const response: any = await store.dispatch(
        userFetch("", null, null, true, "")
      );

      if (response.error) {
        setAdmins([]);
      } else {
        setAdmins(
          response.map((x) => new User(x)).filter((x) => x.role === "ADMIN")
        );
      }

      setIsLoading(false);
    }
  }, []);

  //Update user contact
  useEffect(() => {
    setUserContact(
      admins.find((x) => x.id === props.actionPlan.active.UserIdContact)
    );
  }, [props.actionPlan.active.UserIdContact, admins]);

  //Get list of admin for contact id
  function getAdmins() {
    if (searchedAdmin.length) {
      return admins.filter(
        (x) => x.username.toLowerCase().indexOf(searchedAdmin) > -1
      );
    } else {
      return admins;
    }
  }

  //Set contact for the action plan
  function selectContact(userId: string | null) {
    store.dispatch(actionPlanEdit("UserIdContact", userId, true));
    setShowAdminsContext(false);
  }

  return (
    <PageLoader status={isLoading ? STATUS_LOADING : STATUS_LOADED}>
      <div className="flex">
        <TextInput
          onChange={(e) =>
            store.dispatch(actionPlanEdit("priority", e.value, false))
          }
          title={t("tasks_priority")}
          value={props.actionPlan.active.priority}
          onBlur={() =>
            store.dispatch(actionPlanUpdate(props.actionPlan.active))
          }
        />

        <div className="width-40" />

        <div className="flex flex-dcol">
          <div className="text-input-title rel">
            <div className="grey-t" style={{ marginBottom: 16 }}>
              {t("tasks_contact")}
            </div>

            {showAdminsContext && (
              <ContextMenu onClose={() => setShowAdminsContext(false)}>
                <SearchBar
                  onSearch={(e) => setSearchedAdmin(e.toLowerCase())}
                  status={STATUS_LOADED}
                />

                <div
                  style={{
                    marginTop: 14,
                    height: 186,
                    overflowY: "auto",
                  }}
                >
                  <ListItem
                    isSmall
                    isEditable
                    onClick={() => selectContact(null)}
                  >
                    {t("utils_undefined")}
                  </ListItem>

                  {getAdmins().map((admin) => (
                    <ListItem
                      key={admin.id}
                      isSmall
                      isEditable
                      onClick={() => selectContact(admin.id)}
                      isActive={
                        admin.id === props.actionPlan.active.UserIdContact
                      }
                    >
                      <ListIcon
                        fallbackIcon={faUserCircle}
                        image={admin.imageUrl}
                      />
                      {admin.username}
                    </ListItem>
                  ))}
                </div>
              </ContextMenu>
            )}
          </div>

          {userContact ? (
            <div
              className="flex _hover"
              onClick={() => setShowAdminsContext(true)}
            >
              <ListIcon
                fallbackIcon={faUserCircle}
                image={userContact.imageUrl}
              />
              {userContact.username}
            </div>
          ) : (
            <div className="_hover" onClick={() => setShowAdminsContext(true)}>
              {t("utils_undefined")}
            </div>
          )}
        </div>
      </div>

      <TextInput
        onChange={(e) =>
          store.dispatch(actionPlanEdit("expectedOutcome", e.value, false))
        }
        title={t("tasks_expected_outcome")}
        isFullWidth
        value={props.actionPlan.active.expectedOutcome}
        onBlur={() => store.dispatch(actionPlanUpdate(props.actionPlan.active))}
      />

      <div className="flex" style={{ height: 110 }}>
        {["dateStart", "dateEnd"].map((item) =>
          props.actionPlan.active[item] ? (
            <div className="flex" key={item}>
              <TextInput
                type="date"
                isFullWidth
                onChange={(e) =>
                  store.dispatch(actionPlanEdit(item, new Date(e.value), true))
                }
                title={t("tasks_" + snakeCase(item))}
                value={formatDate(props.actionPlan.active[item], false, true)}
              />
              <div style={{ marginTop: 54 }}>
                <ListButton
                  icon={faTimes}
                  onClick={() =>
                    store.dispatch(actionPlanEdit(item, null, true))
                  }
                />
              </div>
            </div>
          ) : (
            <div
              key={item}
              style={{
                marginRight: 20,
                marginTop: 36,
              }}
            >
              <Button
                onClick={() =>
                  store.dispatch(
                    actionPlanEdit(
                      item,
                      item === "dateStart"
                        ? new Date()
                        : new Date(Date.now() + 5184000000),
                      true
                    ) //+ 60 days
                  )
                }
                isFullWidth
                isWithBorder
              >
                {t("tasks_" + snakeCase(item) + "_cta")}
              </Button>
            </div>
          )
        )}
      </div>

      <Checkbox
        text={t("tasks_allow_add_tasks", {
          name: props.user.active.username,
        })}
        active={props.actionPlan.active.allowAddTask}
        onClick={(e) =>
          store.dispatch(actionPlanEdit("allowAddTask", e.value, true))
        }
      />

      <div className="height-40" />
    </PageLoader>
  );
}

const mapStateToProps = (state) => ({
  actionPlan: state.actionPlan,
  user: state.user,
});

export default connect(mapStateToProps)(withTranslation()(TasksOptionsWidget));
