/**
 * DASHBOARD-RPS-TEAMS.WIDGET
 * Repartition of users for a survey
 */
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import Card from "@/components/card";
import { useEffect, useState } from "react";
import { DashboardFilters } from "@/redux/filter.types";
import { store } from "@/index";
// import store from "@/core/store";
import {
  INSUFFICIENT_PARTICIPATION,
  STATUS_LOADED,
  STATUS_LOADING,
} from "@/redux/_status.types";
import NoResultsWidget from "./no-results.widget";
import { TopicState } from "@/redux/topic.types";
import { orderBy, snakeCase, sum } from "lodash";
import ListItem from "@/components/list-item";
import Space from "@/components/space";
import getRepartitionColor from "@/utils/get-repartition-color.utils";
import ListCell from "@/components/list-cell";
import QuestionSpecialHelp from "@/help/question-special.help";
import Chip from "@/components/chip";
import { fetchQuestions } from "@/redux/_archive.actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentDots } from "@fortawesome/free-solid-svg-icons";
import { Session } from "@/redux/_session.types";

interface StateProps {
  _session: Session;
  topic: TopicState;
}

interface OwnProps {
  dashboardFilters: DashboardFilters;
}

type Props = StateProps & OwnProps & WithTranslation;

function DashboardRpsQuestionsWidget(props: Props) {
  const { t } = props;

  //State
  const [status, setStatus] = useState(STATUS_LOADING); //Display loading circle
  const [questions, setQuestions] = useState<any[]>([]); //List of questions
  const [showHelp, setShowHelp] = useState(false); //Show help panel on the left

  //Load data on every change on filters and filterName
  useEffect(() => {
    async function load() {
      //Load data
      setStatus(STATUS_LOADING);
      const data: any = await store.dispatch(
        fetchQuestions(props.dashboardFilters, null)
      );
      setStatus(STATUS_LOADED);

      if (!data.error) {
        let newQuestions: any[] = [];

        //Loop on question to get data
        props.topic.list.forEach((topic) => {
          topic.Questions.forEach((question: any) => {
            //Reset repartition and search for current Question from archive database
            question.repartition = [];
            const currentQuestion = data.find(
              (x) => x.aid === question.aid && question.special
            );

            if (currentQuestion) {
              question.repartition = currentQuestion.repartition;
              question.specialAnswer = sum(question.repartition);
              if (question.specialAnswer) {
                question.negativesAnswers =
                  question.repartition.length > 2
                    ? question.repartition[0] + question.repartition[1]
                    : question.repartition[0];
                question.specialRate =
                  (question.negativesAnswers / question.specialAnswer) * 100;
                newQuestions.push(question);
              }
            }
          });
        });

        //Sort by values qvt
        newQuestions = orderBy(
          newQuestions,
          ["specialRate", "repartitionBad"],
          ["desc", "desc"]
        );

        //Tag for questions
        let countOrder1 = 0;
        let countOrder2 = 0;
        newQuestions.forEach((question) => {
          if (question.specialRate < 35) {
            question.order = 3;
          } else {
            if (
              question.special === "psychological-job-demands" ||
              question.special === "decision-latitude" ||
              question.special === "skill-use"
            ) {
              if (countOrder1 < 3) {
                question.order = 1;
                countOrder1++;
              } else {
                question.order = 3;
              }
            } else {
              if (countOrder2 < 3) {
                question.order = 2;
                countOrder2++;
              } else {
                question.order = 3;
              }
            }
          }
        });

        newQuestions.forEach((question) => {
          if (
            question.special === "skill-use" ||
            question.special === "decision-latitude"
          ) {
            question.count = newQuestions.filter(
              (x) =>
                x.special === "decision-latitude" || x.special === "skill-use"
            ).length;
            question.index =
              newQuestions
                .filter(
                  (x) =>
                    x.special === "decision-latitude" ||
                    x.special === "skill-use"
                )
                .findIndex((x: any) => x.aid === question.aid) + 1;
          } else {
            question.count = newQuestions.filter(
              (x) => x.special === question.special
            ).length;
            question.index =
              newQuestions
                .filter((x) => x.special === question.special)
                .findIndex((x: any) => x.aid === question.aid) + 1;
          }
        });

        //Update question list
        setQuestions(newQuestions);
      }
    }

    load();
  }, [props.topic.list, props.dashboardFilters]);

  //Get family of the question
  function getQuestionAxis(tag) {
    if (tag === "psychological-job-demands") {
      return t("rps_axis_stress");
    } else if (tag === "skill-use" || tag === "decision-latitude") {
      return t("rps_axis_autonomy");
    } else if (tag === "team-support" || tag === "manager-support") {
      return t("rps_social_support");
    } else {
      return t("rps_work_gratitude");
    }
  }

  //Color according tag
  function getTagColor(tag) {
    if (tag === "psychological-job-demands") {
      return "#ff9500";
    } else if (tag === "decision-latitude" || tag === "skill-use") {
      return "#8bbdd9";
    } else {
      return null;
    }
  }

  return status === INSUFFICIENT_PARTICIPATION ? (
    <NoResultsWidget />
  ) : (
    <Card className="flex1" status={status} exportName={t("rps_user_title")}>
      {showHelp && <QuestionSpecialHelp onClose={() => setShowHelp(false)} />}

      <div className="flex">
        <div className="flex2">
          <h2>{t("rps_user_title")}</h2>

          <p>
            {t("rps_user_text_1")}&nbsp;
            <b>{t("rps_user_text_2")}</b>
          </p>

          <p>
            {t("rps_user_text_3")}&nbsp;
            {t("rps_user_text_4")}
          </p>
        </div>

        <div className="width-100" />

        <div>
          <img src={require("@/assets/rps.png")} alt="rps" width="200px" />
        </div>
      </div>

      <div className="height-20" />

      {[1, 2, 3].map((order) => (
        <div key={order}>
          <p className="grey-t">
            <h2>
              <FontAwesomeIcon icon={faCommentDots} />
              &nbsp;
              {t("rps_user_details_" + order)}
            </h2>
          </p>

          <div
            className="flex"
            style={{
              marginTop: "32px",
              marginBottom: "12px",
            }}
          >
            <div className="flex1 grey-t">
              <b>{t("rps_user_details")}</b>
            </div>
            <ListCell width={210}>
              <b className="grey-t">{t("question_responses")}</b>
            </ListCell>
            <ListCell width={180}>
              <div className="grey-t">
                <b>{t("axis_rps")}</b>
              </div>
            </ListCell>
          </div>

          {questions.filter((x) => x.order === order).length === 0 && (
            <div style={{ margin: "30px 0px 60px 0px" }}>
              <p>
                <i>{t("rps_user_details_" + order + "_empty")}</i>
              </p>
            </div>
          )}

          <div style={{ margin: "0px -30px 38px -30px" }}>
            {questions
              .filter((x) => x.order === order)
              .map((question) => (
                <ListItem key={question.id}>
                  {question.label}

                  <Space />

                  <div className="width-10" />

                  <ListCell width={200}>
                    <div className="flex1">
                      <div className="details-topic-repartition flex flex1">
                        {question.repartition.map((count, i) => (
                          <div
                            key={i}
                            className="details-topic-repartition-item flex"
                            style={{
                              backgroundColor: getRepartitionColor(
                                i,
                                question.repartition.length - 1,
                                true,
                                props._session.dashboardDisplaySettings,
                                false
                              ),
                              width:
                                (count / question.specialAnswer) * 100 + "%",
                            }}
                          >
                            <div className="flex1" />
                          </div>
                        ))}
                      </div>
                      <div
                        className="flex grey-t"
                        style={{
                          fontSize: "12px",
                          marginTop: "2px",
                        }}
                      >
                        <Space />
                        {t("rps_responses_negatives", {
                          ratio: question.specialRate.toFixed(1),
                        })}
                      </div>
                    </div>
                  </ListCell>

                  <div className="width-10" />

                  <ListCell width={180}>
                    <div>
                      <div className="flex">
                        <Chip
                          color={getTagColor(question.special)}
                          onClick={() => setShowHelp(true)}
                        >
                          {getQuestionAxis(question.special)}&nbsp;(
                          {question.index}/{question.count})
                        </Chip>
                      </div>

                      <div
                        className="grey-t"
                        style={{ fontSize: "12px", marginTop: "8px" }}
                      >
                        {t("rps_" + snakeCase(question.special))}
                      </div>
                    </div>
                  </ListCell>
                </ListItem>
              ))}
          </div>
        </div>
      ))}
    </Card>
  );
}

const mapStateToProps = (state) => ({
  _session: state._session,
  topic: state.topic,
});

export default connect(mapStateToProps)(
  withTranslation()(DashboardRpsQuestionsWidget)
);
