/**
 * PROJECT-TEMPLATE.WIDGET
 * Display all topics for a template
 */

import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useState } from "react";
import { store } from "@/index";
// import store from "@/core/store";
import { TemplateState } from "@/redux/template.types";
import { AxisState } from "@/redux/axis.types";
import ListItem from "@/components/list-item";
import {
  faCaretRight,
  faClipboardList,
} from "@fortawesome/free-solid-svg-icons";
import { TopicState } from "@/redux/topic.types";
import SelectTemplateModal from "@/modals/select-template.modal";
import ListIcon from "@/components/list-icon";
import { projectEdit } from "@/redux/project.actions";
import Link from "@/components/link";
import ConfirmButtons from "@/components/confirm-buttons";
import { ProjectState } from "@/redux/project.types";
import Space from "@/components/space";
import Button from "@/components/button";
import ProjectStepErrorsWidget from "./project-step-errors.widget";
import { NavigateFunction, useNavigate } from "react-router-dom";

interface StateProps extends WithTranslation {
  axis: AxisState;
  project: ProjectState;
  template: TemplateState;
  topic: TopicState;
}

interface OwnProps {
  onNext: Function;
  onEdit: Function;
}

type Props = StateProps & OwnProps;

const MODAL_SWITCH_TEMPLATE = "MODAL_SWITCH_TEMPLATE";

function ProjectTemplateWidget(props: Props) {
  const { t } = props;
  const navigate: NavigateFunction = useNavigate();

  //Modal to display
  const [currentModal, setCurrentModal] = useState<string | null>(null);

  //Reset values
  function cancel() {
    store.dispatch(projectEdit("TemplateId", null));
    select();
    next(false, false);
  }

  function next(isVisited, isConfirmed) {
    props.onNext(isVisited, isConfirmed);
  }

  function select() {
    props.onEdit();
  }

  //Select another template
  //Reset populations by axes settings
  function selectTemplate(templateId) {
    store.dispatch(projectEdit("TemplateId", templateId));
    store.dispatch(projectEdit("segmentationByAxesRules", []));
    select();
  }

  return (
    <div>
      {currentModal === MODAL_SWITCH_TEMPLATE && (
        <SelectTemplateModal
          onClose={() => setCurrentModal(null)}
          onSelect={(templateId) => {
            setCurrentModal(null);
            selectTemplate(templateId);
          }}
        />
      )}

      <ListItem>
        <div className="setup-card-help">
          <p>{t("project_template_help")}</p>
          <div className="grey-t setup-card-help-content">
            <p>{t("project_template_help_2")}</p>
          </div>
        </div>
      </ListItem>

      <ListItem>
        <ListIcon
          fallbackIcon={faClipboardList}
          image={props.template.active.imageUrl}
        />

        <div className="flex1">
          <div style={{ marginBottom: 22 }} className="flex flex1">
            {props.template.active.id.length > 0 ? (
              <b>{props.template.active.label}</b>
            ) : (
              <div className="flex flex1">
                <b>{t("template_empty")}</b>
                <Space />
                <Button
                  className="primary"
                  onClick={() => setCurrentModal(MODAL_SWITCH_TEMPLATE)}
                >
                  {t("survey_template_select")}
                </Button>
              </div>
            )}
          </div>

          {props.template.active.id.length > 0 && (
            <div>
              <Link
                icon={faCaretRight}
                onClick={() => navigate("/template/editor")}
              >
                {t("template_edit")}
              </Link>

              <Link
                icon={faCaretRight}
                onClick={() => setCurrentModal(MODAL_SWITCH_TEMPLATE)}
              >
                {t("template_switch")}
              </Link>
            </div>
          )}
        </div>
      </ListItem>

      <ProjectStepErrorsWidget
        alert={props.project.active.alerts.template}
        step="template"
      />

      <ConfirmButtons
        isLocked={props.project.active.alerts.template.errors.length > 0}
        onCancel={() => cancel()}
        onConfirm={() => next(true, true)}
        onConfirmLater={() => next(true, false)}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  axis: state.axis,
  project: state.project,
  template: state.template,
  topic: state.topic,
});

export default connect(mapStateToProps)(
  withTranslation()(ProjectTemplateWidget)
);
