/**
 * TASKS-CLOSE.MODAL
 * End action plan
 */
import Modal from "@/components/modal";
import { useState } from "react";
import { connect } from "react-redux";
import { withTranslation, WithTranslation } from "react-i18next";
import { STATUS_LOADED, STATUS_SAVING } from "@/redux/_status.types";
import EnterCodeWidget from "@/widgets/enter-code.widget";
import { ActionPlanState } from "@/redux/action-plan.types";
import { actionPlanClose, actionPlanEdit } from "@/redux/action-plan.actions";
import { store } from "@/index";
// import store from "@/core/store";
import { UserState } from "@/redux/user.types";

interface StateProps extends WithTranslation {
  actionPlan: ActionPlanState;
  user: UserState;
}

interface OwnProps {
  onClose: Function;
}

type Props = StateProps & OwnProps;

function TaskCloseModal(props: Props) {
  const { t } = props;

  const [isSaving, setIsSaving] = useState(false);

  function close() {
    props.onClose();
  }

  //Trigger the action with the backend
  async function closeConfirm() {
    setIsSaving(true);
    const response: any = await store.dispatch(
      actionPlanClose(props.actionPlan.active.id)
    );
    setIsSaving(false);

    if (!response.error) {
      store.dispatch(actionPlanEdit("status", "closed", false));
      close();
    }
  }

  return (
    <Modal onClose={close} title={t("tasks_close")}>
      <p>
        <b>
          {t("tasks_ask_close", {
            name: props.user.active.username,
          })}
        </b>
      </p>

      <EnterCodeWidget
        labelCta={t("survey_close_cta")}
        onCancel={close}
        onNext={closeConfirm}
        status={isSaving ? STATUS_SAVING : STATUS_LOADED}
      />
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  actionPlan: state.actionPlan,
  user: state.user,
});

export default connect(mapStateToProps)(withTranslation()(TaskCloseModal));
