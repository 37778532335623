/**
 * DASHBOARD-PARTICIPATION.WIDGET
 * Statistics of participation for a survey
 */

import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import Card from "@/components/card";
import { SurveyState } from "@/redux/survey.types";
import { useEffect } from "react";
import { FilterState } from "@/redux/filter.types";
import { store } from "@/index";
// import store from "@/core/store";
import { loadParticipation } from "@/redux/_archive.actions";
import { NavigateFunction, useNavigate } from "react-router-dom";
import DashboardParticipationOverviewWidget from "./dashboard-participation-overview.widget";

interface StateProps extends WithTranslation {
  filter: FilterState;
  survey: SurveyState;
}

interface OwnProps {
  isPreview?: boolean;
}

type Props = StateProps & OwnProps;

function DashboardParticipationMiniWidget(props: Props) {
  const navigate: NavigateFunction = useNavigate();

  //Load survey data on load
  useEffect(() => {
    store.dispatch(loadParticipation(props.filter.dashboard, props.isPreview));
  }, [props.filter.dashboard, props.survey.active.randomData, props.isPreview]);

  const { t } = props;
  return (
    <Card
      className="_hover flex1 flex flex-dcol"
      exportName={t("survey_participation")}
      title={t("survey_participation")}
      onClick={() => navigate("/dashboard/participation")}
      status={props.survey.status}
    >
      <DashboardParticipationOverviewWidget />
    </Card>
  );
}

const mapStateToProps = (state) => ({
  filter: state.filter,
  survey: state.survey,
});

export default connect(mapStateToProps)(
  withTranslation()(DashboardParticipationMiniWidget)
);
