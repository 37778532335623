/**
 * PAGE.ACTIONS
 */

import {
  Page,
  PAGE_ACTIVATE,
  PAGE_ADD,
  PAGE_EDIT,
  PAGE_INIT,
  PAGE_GET,
  PAGE_REMOVE,
  PAGE_STATUS,
  PAGE_REPLACE,
  PAGE_CONTENT_TAGS,
  PageContentTag,
  PAGE_CHANGE,
  PAGE_SIDES,
  PAGE_HEATMAP_LIMIT,
} from "./page.types";
import { v4 as uuid } from "uuid";
import { store } from "@/index";
// import store from "@/core/store";
import { EditorState, RawDraftContentBlock, convertFromRaw } from "draft-js";
import {
  fetchAttributes,
  fetchMessages,
  fetchNote,
  fetchParticipation,
  fetchQuestions,
} from "./_archive.actions";
import { DashboardFilters, FilterLite } from "./filter.types";
import { Survey } from "./survey.types";
import { surveyFormatNote } from "./survey.actions";
import { populationToFilterLite } from "@/utils/filters-to-attributes.utils";
import { Population } from "./population.types";
import { topicGroupForReport } from "./topic.actions";
import { compact, flatMap, forOwn, groupBy, uniq } from "lodash";
import getGenerativeAssistantSummary from "@/utils/get-generative-assistant-summary.utils";
import { reportStatusMessage } from "./report.actions";
import i18n from "@/translate/i18n";
import { MESSAGE_CEIL_COUNT_SUMMARY } from "./message.types";
import { Session } from "./_session.types";

/* REDUX */

export const pageActivate = (page: Page) => ({
  type: PAGE_ACTIVATE,
  payload: {
    page,
  },
});

export const pageAdd = (page: Page, index: number) => ({
  type: PAGE_ADD,
  payload: {
    page,
    index,
  },
});

export const pageChange = (goNext: boolean) => ({
  type: PAGE_CHANGE,
  payload: {
    goNext,
  },
});

export const pageEdit = (key: string, value: any) => ({
  type: PAGE_EDIT,
  payload: {
    key,
    value,
  },
});

export const pageInit = () => ({
  type: PAGE_INIT,
});

export const pageGet = (pages: Page[]) => ({
  type: PAGE_GET,
  payload: {
    pages,
  },
});

export const pageRemove = (id: string) => ({
  type: PAGE_REMOVE,
  payload: {
    id,
  },
});

export const pageReplace = (page: Page) => ({
  type: PAGE_REPLACE,
  payload: {
    page,
  },
});

export const pageStatus = (status: string) => ({
  type: PAGE_STATUS,
  payload: {
    status,
  },
});

//API

export const pageFetch = (reportId: string) => ({
  type: "API",
  payload: {
    method: "GET",
    url: "/pages",
    data: {
      reportId,
    },
  },
});

export const pageFetch_AsSupervisor = (reportId: string) => ({
  type: "API",
  payload: {
    method: "GET",
    url: "/supervisor/pages",
    data: {
      reportId,
    },
  },
});

export const pageDestroy_AsSupervisor = (pageId: string) => ({
  type: "API",
  payload: {
    method: "DELETE",
    url: "/supervisor/page/delete/" + pageId,
  },
});

export const pageDestroy = (pageId: string) => ({
  type: "API",
  payload: {
    method: "DELETE",
    url: "/page/delete/" + pageId,
  },
});

//Activate and edit page
export const pageActivateAndEdit: any =
  (page: Page, key: string, value: any) => (dispatch) => {
    dispatch(pageActivate(page));
    dispatch(pageEdit(key, value));
  };

//Convert populations array to dashboard filters
//Return dashboard filters object that are used for the queries (is a population is selected)
export function pageConvertPopulationsToFilters(
  reportPopulations: Population[]
) {
  const dashboardFilters = new DashboardFilters();
  reportPopulations.forEach((population) => {
    if (population.aid) {
      population.id = population.aid.toString();
    }
  });

  dashboardFilters.customFilters = populationToFilterLite(
    [],
    reportPopulations
  );
  return dashboardFilters;
}

//Create page
export function pageCreateNew(data, reportId: string): Page {
  const accountOptions = store.getState()._session.accountOptions;
  const newPageId: string = uuid();
  const dateNow: number = Date.now();

  //Init surveyId
  const surveyId = store.getState().survey.active.id;
  PAGE_SIDES.forEach((side) => {
    if (data[side]?.screenshot) {
      data[side].screenshot.surveyId = data[side].screenshot.surveyId =
        surveyId;

      //Init heatmap param according admin parameters
      if (data[side]?.screenshot.type === "heatmap") {
        if (store.getState()._session.userRole === "ADMIN") {
          data[side].screenshot.filterName = accountOptions.heatmapFilterName;
          data[side].screenshot.resultsType = accountOptions.heatmapResultsType;
        }
      }
    }
  });

  //Init page
  const page = new Page(data);
  page.id = newPageId;
  page.timestamp = dateNow;
  page.ReportId = reportId;

  return page;
}

//Fetch note
export async function pageFetchNote(
  populations: Population[],
  isAccount: boolean,
  specificSurveyId: string | null
) {
  const dashboardFilters = pageConvertPopulationsToFilters(populations);
  const withSatisfaction =
    store.getState()._session.dashboardDisplaySettings.dashboardDisplayMode ===
    "satisfaction";
  const response: any = await store.dispatch(
    fetchNote(
      dashboardFilters,
      withSatisfaction,
      specificSurveyId ? specificSurveyId : undefined,
      undefined,
      isAccount
    )
  );
  return surveyFormatNote(response.note, withSatisfaction);
}

//Fetch participation
export async function pageFetchParticipation(
  populations: Population[],
  isAccount: boolean,
  specificSurveyId: string | null
) {
  const dashboardFilters = pageConvertPopulationsToFilters(populations);
  const response: any = await store.dispatch(
    fetchParticipation(
      dashboardFilters,
      specificSurveyId ? specificSurveyId : undefined,
      undefined,
      isAccount
    )
  );
  const participationRate = new Survey(response).getParticipationRate(
    store.getState()._session.accountOptions.hideStartedForParticipation
  );
  return participationRate.toString();
}

//Fetch population name
//For observer match names of the filters according attributes of the survey
export async function pageFetchPopulationName(
  session: Session,
  reportPopulations: Population[]
) {
  let populations =
    session.userRole === "ADMIN"
      ? reportPopulations
      : store.getState().filter.observerAttributes;
  if (session.userRole === "OBSERVER") {
    const filterNames = uniq(
      store.getState().filter.observerAttributes.map((x) => x.filterName)
    );

    let attributes: any[] = [];
    for (let i = 0; i < filterNames.length; i++) {
      const filterName = filterNames[i];
      attributes = attributes.concat(
        await store.dispatch(
          fetchAttributes(new DashboardFilters(), filterName)
        )
      );
    }

    populations = populations.filter(
      (x) => attributes.map((x) => x.aid.toString()).indexOf(x.id) > -1
    );
  }

  return populations.length
    ? populations.map((x) => x.name).join(", ")
    : session.accountName;
}

//Format editor state from raw content
//withTag differs if displayed in the editor box or in the preview
export function pageFormatEditorState(
  textBlocks: RawDraftContentBlock[],
  withTags: boolean
) {
  if (withTags) {
    textBlocks = pageFormatContentWithTags(textBlocks);
  }

  const contentState = convertFromRaw({ blocks: textBlocks, entityMap: {} });
  return EditorState.createWithContent(contentState);
}

//Format all the texts blocks with the tags
export function pageFormatContentWithTags(textBlocks: RawDraftContentBlock[]) {
  const outputBlocks: RawDraftContentBlock[] = [];

  textBlocks.forEach((textBlock) => {
    const text = pageReplaceTag(textBlock.text);

    //Compare length between texts
    //Adapt style limit in function
    const diff = text.length - textBlock.text.length;
    if (diff > 0 && textBlock.inlineStyleRanges.length) {
      const inlineStyleRanges = Array.from(textBlock.inlineStyleRanges);

      //Detect all last style range (where offset and lenght match lenght of text blocks
      //Update length
      inlineStyleRanges
        .filter((x) => x.offset + x.length === textBlock.text.length)
        .forEach((styleRange) => {
          styleRange.length = styleRange.length + diff;
        });

      outputBlocks.push(
        Object.assign({}, textBlock, {
          text,
          inlineStyleRanges,
        })
      );
    } else {
      outputBlocks.push(Object.assign({}, textBlock, { text }));
    }
  });

  return outputBlocks;
}

//Get specific value for a tag
export function pageGetTagText(tag: PageContentTag) {
  const text = store.getState().report.active.tagsData[tag];
  return text ? text : "";
}

//Get last surveys in order to fetch history data
export function pageGetLastSurvey(index: number) {
  //Get surveys associated to the project and sort them by date
  const surveys = store
    .getState()
    .survey.list.filter(
      (x) => x.ProjectId === store.getState().survey.active.ProjectId
    );
  surveys.sort(
    (d1, d2) =>
      new Date(d2.dateStart).getTime() - new Date(d1.dateStart).getTime()
  );

  //Return survey
  return surveys[index];
}

//Return id for the last survey
export function pageGetLastSurveyId(index: number) {
  const survey = pageGetLastSurvey(index);
  return survey ? survey.id : null;
}

//Get year of the survey
export function pageGetSurveyYear(index: number) {
  if (index === 0) {
    return new Date(store.getState().survey.active.dateStart).getFullYear();
  } else {
    const survey = pageGetLastSurvey(index);
    return survey ? new Date(survey.dateStart).getFullYear() : "";
  }
}

//Update text with matching variables
export function pageReplaceTag(text: string) {
  let modifiedText = String(text);
  const tags = PAGE_CONTENT_TAGS.filter(
    (x) => text.indexOf("{{" + x + "}}") > -1
  );
  tags.forEach((tag) => {
    const tagText = pageGetTagText(tag);
    if (tagText) {
      modifiedText = modifiedText.replaceAll("{{" + tag + "}}", tagText);
    }
  });

  return modifiedText.length > 0 ? modifiedText : text;
}

//Init dashboard filters for the widgets
export function pageInitDashboardFilters(pagePopulations: Population[]) {
  const reportPopulations = store.getState().report.active.options.populations;
  let dashboardFilters = new DashboardFilters();
  if (reportPopulations.length) {
    dashboardFilters = pageConvertPopulationsToFilters(reportPopulations);
  }

  if (pagePopulations.length) {
    forOwn(
      groupBy(pagePopulations, "filterName"),
      (populations, filterName) => {
        dashboardFilters.customFilters.push(
          new FilterLite({
            id: filterName,
            attributesIds: compact(populations.map((x) => x.aid)).map((x) =>
              x.toString()
            ),
          })
        );
      }
    );
  }

  return dashboardFilters;
}

//Init page generation
//For new report create a page for each organisation but do not duplicate pages for topics, axes etc...
export async function pageInitGenerate(
  inputPages: Page[],
  initNewReport: boolean
) {
  const pages: Page[] = [];

  //Fetch questions in order to get params for the AI summarize function
  const questions: any = await store.dispatch(
    fetchQuestions(store.getState().filter.dashboard, "open")
  );

  for (let i = 0; i < inputPages.length; i++) {
    const page = inputPages[i];

    //Action when creating new report
    if (initNewReport) {
      //For message create a page for each question
      if (
        page.contentLeft.type === "screenshot" &&
        page.contentLeft.screenshot.type === "messages"
      ) {
        //Filter topics
        let topics = store.getState().topic.list;
        if (!page.contentLeft.screenshot.displayAll) {
          topics = topics.filter(
            (x) =>
              page.contentLeft.screenshot.hideItems.indexOf(
                x.aid ? x.aid : ""
              ) === -1
          );
        }

        const questionsOpen = flatMap(topics, (x) => x.Questions).filter(
          (x) => x.type === "open"
        );
        for (let j = 0; j < questionsOpen.length; j++) {
          const question = questionsOpen[j];

          //Get Summarize if AI assistant is defined
          if (page.contentLeft.screenshot.summarize && question.aid) {
            const questionOptions = questions.find(
              (x) => x.aid === question.aid
            );
            if (questionOptions) {
              question.isPromptCustom = questionOptions.isPromptCustom;
              question.prompt = questionOptions.prompt;
            }

            //Fetch comments
            const comments: any = await store.dispatch(
              fetchMessages(
                store.getState().filter.dashboard,
                question.aid,
                question.fk_topic
              )
            );

            if (comments.length >= MESSAGE_CEIL_COUNT_SUMMARY) {
              //Summarize
              store.dispatch(
                reportStatusMessage(
                  i18n.t("report_status_message_ai", {
                    question: question.label,
                  })
                )
              );
              const summarize: any = await getGenerativeAssistantSummary(
                question,
                comments.map((x) => x.text)
              );
              const textBlocks: any = pageParseString(summarize);

              //Create new page
              const newPage = new Page(page);
              page.id = uuid();
              newPage.contentLeft.type = "text";
              newPage.contentLeft.textBlocks = textBlocks;
              pages.push(new Page(newPage));
            } else {
              store.dispatch(reportStatusMessage(""));
            }
          } else {
            page.id = uuid();
            page.QuestionOpen = question;
            pages.push(new Page(page));
          }
        }
      } else {
        pages.push(new Page(page));
      }
    } else {
      //For question create a page by question
      if (
        page.contentLeft.type === "screenshot" &&
        page.contentLeft.screenshot.type === "questions"
      ) {
        topicGroupForReport(page).forEach((topics) => {
          const newPage = new Page(page);
          newPage.Topics = topics;
          pages.push(newPage);
        });
      }
      //For heatmap divide the heatmap by rows
      else if (
        page.contentLeft.type === "screenshot" &&
        page.contentLeft.screenshot.type === "heatmap"
      ) {
        const heatmapPages = Math.ceil(
          store.getState().report.active.heatmapLength / PAGE_HEATMAP_LIMIT
        );
        for (let i = 0; i < heatmapPages; i++) {
          const newPage = new Page(page);
          newPage.heatmapOffset = i * PAGE_HEATMAP_LIMIT;
          pages.push(newPage);
        }
      } else {
        pages.push(new Page(page));
      }
    }
  }

  //Add page for each attribute if specified
  const newPages: Page[] = [];
  for (let i = 0; i < pages.length; i++) {
    const page = pages[i];

    if (page.options.filterName && page.options.filterMode === "all") {
      const attributes: any = await store.dispatch(
        fetchAttributes(pageInitDashboardFilters([]), page.options.filterName)
      );
      if (!attributes.error) {
        attributes.forEach((attribute) => {
          const newPage = new Page(page);
          newPage.options.filterMode = "one";
          newPage.options.populations = [
            new Population({
              aid: attribute.aid.toString(),
              id: attribute.filterName + "_" + attribute.name,
              filterName: attribute.filterName,
              name: attribute.name,
            }),
          ];

          if (initNewReport) {
            newPage.id = uuid();
          }

          newPages.push(newPage);
        });
      }
    } else {
      newPages.push(page);
    }
  }

  return newPages;
}

//Parse raw text (from ChatGPT API for exemple)
export function pageParseString(string: string) {
  const lines = string.split("\n").filter((line) => line.trim() !== "");

  return lines.map((line, index) => {
    // Handle bold text
    const boldPattern = /\*\*(.*?)\*\*/g;
    const boldMatches = [...line.matchAll(boldPattern)];

    const inlineStyleRanges: any[] = [];
    const plainText = line.replace(boldPattern, "$1"); // Replace bold markers with the plain text

    // Prepare inline styles for bold
    boldMatches.forEach((match) => {
      const offset = plainText.indexOf(match[1]);
      inlineStyleRanges.push({
        offset: offset,
        length: match[1].length,
        style: "BOLD",
      });
    });

    // Identify list items
    let blockType = "unstyled"; // Default block type
    if (/^\d+\./.test(line)) {
      blockType = "unordered-list-item";
    }

    //Force fontsize 10
    inlineStyleRanges.push({
      offset: 0,
      length: plainText.length,
      style: "fontsize-10",
    });

    return {
      key: `block${index}`,
      text: plainText,
      type: blockType,
      inlineStyleRanges,
      entityRanges: [],
      data: {},
    };
  });
}

//Get specific value for a tag
//Fetch data if required
export async function pageUpdateTagData(tag: PageContentTag) {
  const activeSurvey = store.getState().survey.active;
  const session = store.getState()._session;
  const reportPopulations = store.getState().report.active.options.populations;

  if (session.interfaceType === "SUPERVISOR") {
    return "";
  } else {
    switch (tag) {
      case "account_name":
        return session.accountName;

      case "account_note":
        return await pageFetchNote([], true, null);

      case "account_note_last_1":
        return await pageFetchNote([], true, pageGetLastSurveyId(1));

      case "account_note_last_2":
        return await pageFetchNote([], true, pageGetLastSurveyId(2));

      case "account_participation_rate":
        return await pageFetchParticipation([], true, null);

      case "account_participation_rate_last_1":
        return await pageFetchParticipation([], true, pageGetLastSurveyId(1));

      case "account_participation_rate_last_2":
        return await pageFetchParticipation([], true, pageGetLastSurveyId(2));

      case "axes_names":
        return store
          .getState()
          .axis.list.map((x) => "• " + x.label)
          .join("\n");

      case "population_name": {
        return await pageFetchPopulationName(session, reportPopulations);
      }

      case "participation_min":
        return session.participationMin.toString();

      case "survey_date_end": {
        const dateEnd = activeSurvey.dateEnd
          ? new Date(activeSurvey.dateEnd as Date)
          : new Date();
        return dateEnd.toLocaleDateString();
      }
      case "survey_date_start":
        return new Date(activeSurvey.dateStart).toLocaleDateString();

      case "survey_name":
        return activeSurvey.name;

      case "survey_note":
        return await pageFetchNote(reportPopulations, false, null);

      case "survey_note_last_1":
        return await pageFetchNote(
          reportPopulations,
          false,
          pageGetLastSurveyId(1)
        );

      case "survey_note_last_2":
        return await pageFetchNote(
          reportPopulations,
          false,
          pageGetLastSurveyId(2)
        );

      case "survey_participation_rate":
        return await pageFetchParticipation(reportPopulations, false, null);

      case "survey_participation_rate_last_1":
        return await pageFetchParticipation(
          reportPopulations,
          false,
          pageGetLastSurveyId(1)
        );

      case "survey_participation_rate_last_2":
        return await pageFetchParticipation(
          reportPopulations,
          false,
          pageGetLastSurveyId(2)
        );

      case "survey_year":
        return pageGetSurveyYear(0);

      case "survey_year_last_1":
        return pageGetSurveyYear(1);

      case "survey_year_last_2":
        return pageGetSurveyYear(2);

      default:
        return "";
    }
  }
}
